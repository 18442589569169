import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { SharedModule, AuthGuardService } from '../../shared';

// Components
import { VerifyEmailComponent } from './verify-email/verify-email.component';
import { VerifyDiscordComponent } from './verify-discord/verify-discord.component';
export const routes = [
  {
    path: 'email',
    component: VerifyEmailComponent,
    data: {
      title: 'DCP - Verify email',
      description: 'Email verification ',
      ogUrl: 'http://localhost/',
    },
  },
  {
    path: 'discord',
    component: VerifyDiscordComponent,
    canActivate: [AuthGuardService],
    data: {
      title: 'DCP - Verify Discord',
      description: 'Discord verification ',
      ogUrl: 'http://localhost/',
    },
  },
];

@NgModule({
  imports: [SharedModule, RouterModule.forChild(routes)],
  declarations: [VerifyEmailComponent, VerifyDiscordComponent],
  exports: [],
})
export class VerifyModule {}
