import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DropdownMenuModule } from '../dropdown-menu/dropdown-menu.module';
import { ShareButtonComponent } from './share-button.component';
import { IconModule } from '../icon/icon.module';



@NgModule({
  declarations: [ShareButtonComponent],
  imports: [
    CommonModule,IconModule,DropdownMenuModule
  ],
  exports:[ShareButtonComponent]
})
export class ShareButtonsModule { }
