<form *ngIf="mediaForm" [formGroup]="type === mediaType.VIDEO ? videoForm : mediaForm">
  <div *ngIf="type === mediaType.VIDEO">
    <div class="modal-box bg-white rounded flex border border-gray-border overflow-hidden">
      <div class="ratio-16-9">
        <div
          *ngIf="!isUploading"
          class="absolute top-0 left-0 w-full h-full bg-body flex fle-wrap items-center justify-center"
        >
          <app-icon [icon]="'video'" class="w-48 h-48 opacity-50"></app-icon>
        </div>
        <ng-container *ngIf="isDirectVideoUpload">
          <app-video-player
            class="absolute w-full h-full top-0"
            [hidden]="!isUploaded"
            (loadedData)="videoLoaded($event)"
            [videoConfig]="generateVideoConfig()"
          ></app-video-player>
        </ng-container>

        <div
          *ngIf="isUploading"
          class="absolute w-full h-full top-0 left-0 justify-center items-center bg-body flex flex-col"
        >
          <app-local-loader [hasTimeout]="false" class="h-40"></app-local-loader>
          <p class="mt-24">Your Video is Uploading...</p>
        </div>

        <div class="video-progress" *ngIf="isUploadProgress">
          <span [ngStyle]="{ width: uploadProgress }"></span>
        </div>
      </div>

      <div class="p-16">
        <div class="flex w-full bg-body rounded p-8 mb-16 items-center justify-between">
          <label class="w-full flex flex-wrap pl-8 value-label">
            {{ directVideoUploaded ? directVideoUploaded : 'Add Video' }}
          </label>
          <div class="url flex items-start">
            <div class="form-group flex-grow">
              <input formControlName="value" [hidden]="type === mediaType.VIDEO" />
            </div>
            <button class="btn btn-primary btn-sm" (click)="videoInput.click()">
              <app-icon [icon]="'search'" class="w-16 h-16"></app-icon>
              <label>Browse</label>
            </button>
            <input type="file" #videoInput (change)="onVideoInputChange($event)" accept="video/*,.mkv" class="hidden" />
          </div>
        </div>
        <div class="form-box flex flex-wrap w-full">
          <label class="flex flex-wrap mb-8 text-lg"> Add Video Cover </label>

          <div class="flex pb-16 form-group" [ngClass]="{ 'enabled-radio': !videoForm.get('cover').disabled }">
            <div *ngIf="!isVideoTypeMkv" class="form-check form-check-inline mr-32">
              <label class="radio" [ngClass]="{ disabled: videoForm.get('cover').disabled }">
                <input
                  type="radio"
                  formControlName="cover"
                  name="cover"
                  value="frame"
                  (change)="onChangeCoverType($event.target.value)"
                />
                <span class="checkmark"></span>
                Choose by time stamp or by frame
              </label>
            </div>
            <div class="form-check form-check-inline">
              <label class="radio" [ngClass]="{ disabled: videoForm.get('cover').disabled }">
                <input
                  type="radio"
                  formControlName="cover"
                  name="cover"
                  value="image"
                  (change)="onChangeCoverType($event.target.value)"
                />
                <span class="checkmark"></span>
                Upload custom image
              </label>
            </div>
            <p class="error-msg" *ngIf="isSaveClicked && !coverType" style="bottom: 8px; top: 24px">
              Please Choose Cover Image
            </p>
          </div>

          <div *ngIf="coverType === 'frame'" class="w-full flex flex-wrap md:flex-nowrap">
            <div class="mr-16 flex-1 mb-24">
              <div class="form-group number sm">
                <input
                  type="number"
                  trim="blur"
                  placeholder="Seconds"
                  [(ngModel)]="videoSeconds"
                  [ngModelOptions]="{ standalone: true }"
                  (keyup)="onKeyupSeconds($event.target.value)"
                  (keypress)="onKeypressSeconds($event)"
                  [disabled]="!isUploaded"
                  style="padding-left: 16px !important; padding-right: 0 !important"
                  step="0.1"
                  appInputNumber
                  #videoInputSeconds
                />
                <span>
                  <button (click)="addVideoSeconds(1)">
                    <app-icon [icon]="'arrow-left'"></app-icon>
                  </button>
                  <button (click)="addVideoSeconds(-1)">
                    <app-icon [icon]="'arrow-right'"></app-icon>
                  </button>
                </span>
                <p class="error-msg -bottom-16" *ngIf="isSecondsError">{{ secondsErrorMsg }}</p>
              </div>
              <label _ngcontent-wno-c352="" class="text-xs"> Time stamp(second)</label>
            </div>

            <div class="md:mr-16 flex-1 mb-24">
              <div class="form-group number sm">
                <input
                  type="number"
                  trim="blur"
                  placeholder="Frames"
                  [(ngModel)]="videoFrames"
                  [ngModelOptions]="{ standalone: true }"
                  (keyup)="onKeyupFrames($event.target.value)"
                  (keypress)="onFrameKeypress($event)"
                  [disabled]="!isUploaded"
                  style="padding-left: 16px !important; padding-right: 0 !important"
                  appInputNumber
                />
                <span>
                  <button (click)="addVideoFrames(1)">
                    <app-icon [icon]="'arrow-left'"></app-icon>
                  </button>
                  <button (click)="addVideoFrames(-1)">
                    <app-icon [icon]="'arrow-right'"></app-icon>
                  </button>
                </span>
              </div>
              <label class="text-xs">Frame number</label>
            </div>
            <button
              [disabled]="!isUploaded"
              (click)="getCurrentFrame()"
              class="btn btn-secondary mb-24 w-full md:w-auto"
            >
              <span class="block m-auto">Use Current Frame</span>
            </button>
          </div>

          <div *ngIf="coverType === mediaType.IMAGE" class="w-full flex flex-wrap md:flex-nowrap">
            <div class="flex w-full bg-body rounded p-8 mb-16 items-center justify-between relative">
              <label class="w-full flex flex-wrap pl-8 value-label">
                {{ coverImageName ? coverImageName : 'Add Cover' }}
              </label>
              <div class="url flex items-start">
                <button class="btn btn-primary btn-sm" (click)="coverInput.click()">
                  <app-icon [icon]="'search'" class="w-16 h-16"></app-icon>
                  <label>Browse</label>
                </button>
                <input type="file" #coverInput (change)="onCoverInputChange($event)" accept="image/*" class="hidden" />
                <p
                  class="absolute text-error text-xs -bottom-16 right-0"
                  style="top: 40px; white-space: nowrap"
                  *ngIf="isCoverEror"
                >
                  {{ coverErrorMsg }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <ng-container *ngTemplateOutlet="fields; context: { $implicit: videoForm }"></ng-container>
      </div>
    </div>
  </div>

  <div *ngIf="type !== mediaType.VIDEO">
    <div class="modal-box bg-white rounded flex flex-wrapborder border border-gray-border overflow-hidden">
      <div class="ratio-16-9">
        <div *ngIf="!isUploading" class="bg-body flex items-center justify-center w-full h-full absolute top-0 left-0">
          <app-icon [icon]="type === mediaType.IMAGE ? 'image' : 'pdf'" class="w-48 h-48 opacity-25"></app-icon>
        </div>
        <div *ngIf="fileUploaded || imageUploaded">
          <img
            [hidden]="!isUploaded"
            class="absolute top-0 left-0 object-cover w-full h-full"
            [src]="fileUploaded || imageUploaded"
            (load)="imageLoaded()"
          />
        </div>
        <div *ngIf="isUploading" class="absolute w-full h-full top-0 left-0 flex justify-center items-center bg-body">
          <app-local-loader [hasTimeout]="false" class="h-40"></app-local-loader>
        </div>
      </div>
      <div class="p-16">
        <div class="flex w-full bg-body rounded p-8 mb-16 items-center" *ngIf="type !== mediaType.IMAGE">
          <label class="w-full flex flex-wrap pl-8 value-label">
            {{ uploadedFileName ? uploadedFileName : 'Add File' }}
          </label>
          <div class="url flex items-start">
            <div class="form-group flex-grow">
              <input formControlName="value" [hidden]="type === mediaType.FILE || type === mediaType.DOC" />
            </div>
            <button class="btn btn-primary btn-sm" (click)="fileInput.click()">
              <app-icon [icon]="'search'" class="w-16 h-16"></app-icon>
              <label>Browse</label>
            </button>
            <input type="file" #fileInput (change)="docUpload($event)" accept="application/pdf" class="hidden" />
          </div>
        </div>
        <div class="form-box w-full" *ngIf="type === mediaType.IMAGE">
          <label class="w-full flex flex-wrap mb-8 text-lg"> Add Image </label>
          <div class="url flex items-start">
            <div class="form-group flex-grow">
              <input
                placeholder="Paste image url *"
                formControlName="value"
                required
                trim="blur"
                (blur)="attachImage()"
                [ngClass]="{
                  error:
                    mediaForm.controls.value.invalid &&
                    (mediaForm.controls.value.dirty || mediaForm.controls.value.touched)
                }"
                class="w-full"
              />
              <p
                class="error-msg"
                *ngIf="mediaForm.controls.value.invalid && mediaForm.controls.value.touched"
                style="bottom: 8px"
              >
                Please enter a valid image url
              </p>
            </div>
            <span class="text-bold mx-4 mt-8">or</span>
            <button class="btn btn-primary" (click)="imageInput.click()">
              <app-icon [icon]="'search'" class="w-16 h-16 min-w-16"></app-icon>
              <label>Browse</label>
            </button>
            <input type="file" #imageInput (change)="imageUpload($event)" accept="image/*" class="hidden" />
          </div>
        </div>
        <ng-container *ngTemplateOutlet="fields; context: { $implicit: mediaForm }"></ng-container>
      </div>
    </div>
  </div>
  <ng-template #fields let-form>
    <ng-container *ngIf="from !== 'round'">
      <div class="form-box flex flex-wrap w-full">
        <label class="flex flex-wrap justify-between w-full mb-8 text-lg">
          Add Title
          <p class="ml-auto">{{ form.get('title').value.length }}/35</p>
        </label>
        <div class="form-group w-full">
          <input
            placeholder="Title *"
            trim="blur"
            placeholder="Title"
            formControlName="title"
            maxlength="35"
            required
            class="mr-0 w-full"
            [ngClass]="{
              error: form.controls.title.invalid && (form.controls.title.dirty || form.controls.title.touched)
            }"
          />
          <p
            class="error-msg"
            *ngIf="form.controls.title.errors && (form.controls.title.dirty || form.controls.title.touched)"
            style="bottom: 8px"
          >
            Please Enter a Title
          </p>
        </div>
      </div>

      <div class="form-box flex flex-wrap w-full">
        <label class="flex w-full flex-wrap mb-8 text-lg">
          Add Description (optional)
          <p class="ml-auto">{{ form.get('description').value.length }}/500</p>
        </label>
        <div class="form-group w-full">
          <textarea
            formControlName="description"
            placeholder="Description"
            rows="1"
            class="mr-0 w-full"
            maxlength="500"
          ></textarea>
        </div>
      </div>
    </ng-container>

    <div class="form-btns flex flex-wrap justify-end">
      <button (click)="cancelUploadModal()" class="btn btn-secondary mr-16">
        <span class="block m-auto">Cancel</span>
      </button>
      <button (click)="formSave()" [disabled]="!isUploaded" class="btn btn-primary">
        <span class="block m-auto text-white">Save</span>
      </button>
    </div>
  </ng-template>
</form>
