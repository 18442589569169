import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  Renderer2,
} from '@angular/core';
import { UserTypes, AuthenticatedUserQuery, SharedService, AuthenticatedUser, ProposalService } from '../../../shared';
import { environment } from '../../../../environments';
import { dropdown } from '../../../app.animations';
import { JoyrideService } from 'ngx-joyride';
import { GlobalTourService } from '../../../shared/services/global-tour-service.service';
import { DistributionService } from '../../../shared/services/distribution.service';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss'],
  host: {
    class: 'flex items-center justify-between w-full h-64 border-b border-gray-border md:px-24 px-12',
  },
  animations: [dropdown],
})
export class TopbarComponent implements OnInit {
  @Input() user: AuthenticatedUser;
  @Input() dark = false;
  @Input() gray_scale = false;
  @Input() sticky = false;
  @Input() wrapper: string;

  @Output() showInboxBar = new EventEmitter();
  @Output() createProposal = new EventEmitter();
  @Output() createCQ = new EventEmitter();
  @Output() openChat = new EventEmitter();
  @Output() mobileMenuClosed = new EventEmitter();
  @Output() logout = new EventEmitter();
  @Output() changeTheme = new EventEmitter();
  @Output() changeScale = new EventEmitter();

  @ViewChild('btnChat', { static: false }) btnChat: ElementRef;
  @ViewChild('add', { static: false }) add: ElementRef;

  openAddMenu = false;
  menuOpen = false;
  userMenuOpen = false;
  themePanelOpen = false;
  stickyThreshold = 50;
  loginModalRef: string;
  statistics: string;
  profilePlaceholderURL = environment.profile_placeholder
    ? environment.profile_placeholder
    : 'https://www.gravatar.com/avatar/0.43227576652898625';
  isChatRoomOpen = false;
  showProposal = !environment.limited_proposal_functionality;
  showCq = !environment.limited_cq_functionality;
  showWatch = !environment.limited_watch_functionality;
  environment = environment;
  chatUrl: string;

  constructor(
    public authenticatedUserQuery: AuthenticatedUserQuery,
    public proposalService: ProposalService,
    private sharedService: SharedService,
    private renderer: Renderer2,
    public joyRideService: JoyrideService,
    private globalTour: GlobalTourService,
    private distributionService:DistributionService
  ) {
    this.globalTour.tourAction.subscribe((action: string) => {
      if (action === 'openAddMenu') {
        this.add.nativeElement.click();
      }
    });
    this.sharedService.getToggleUserMenu().subscribe((message) => {
      const messageReceived = message;
      if (messageReceived.userMenu === true) {
        this.openCloseUserMenu();
      }
    });
    this.sharedService.getCreateProposal().subscribe(() => {
      this.createProposalForRound();
    });
    this.sharedService.getCreateDistribution().subscribe(() => {
      this.createDistribution();
    });
    this.sharedService.getCreateCQ().subscribe(() => {
      this.createCQ.emit();
    });
  }

  openMenu() {
    this.openAddMenu = !this.openAddMenu;
  }

  ngOnInit() {
    this.chatUrl = `${environment.discord_base_url}/${environment.discord_server_id}/${environment.discord_general_channel_id}`;
    this.authenticatedUserQuery.select().subscribe();
  }

  createDistribution() {
    if (environment.readOnlyMode) {
      this.authenticatedUserQuery.openMaintainanceModal();
      return;
    }
    if (this.user) {
      this.distributionService.createDistributionSubject$.next(null);
    } else {
      this.authenticatedUserQuery.unAuthAction.next({ slug: 'Create Distribution' });
    }
  }

  createProposalForRound() {
    if (environment.readOnlyMode) {
      this.authenticatedUserQuery.openMaintainanceModal();
      return;
    }
    if (this.user) {
      this.proposalService.startCreateProposalProcess(null);
    } else {
      this.authenticatedUserQuery.unAuthAction.next({ slug: 'Create Proposal' });
    }
  }
  createQuery() {
    if (environment.readOnlyMode) {
      this.authenticatedUserQuery.openMaintainanceModal();
      return;
    }
    if (this.user) {
      this.createCQ.emit();
    } else {
      this.authenticatedUserQuery.unAuthAction.next({ slug: 'Create Creative Query' });
    }
  }

  toggleTheme() {
    this.changeTheme.emit();
  }

  toggleScale() {
    this.changeScale.emit();
  }

  getUserRoleName() {
    switch (this.user.user_type_id) {
      case UserTypes.MODERATOR:
        return 'Moderator';
      case UserTypes.ADMINISTRATOR:
        return 'Administrator';
      default:
        return 'Member';
    }
  }

  onLogout() {
    this.logout.emit();
  }

  showInboxBarFn() {
    this.showInboxBar.emit();
  }

  openCloseLeftMenu() {
    const wrapper: HTMLElement = this.renderer.selectRootElement('#app-wrapper', true);
    if (wrapper.classList.contains('left-side-open')) {
      this.renderer.removeClass(wrapper, 'left-side-open');
    } else {
      this.renderer.addClass(wrapper, 'left-side-open');
    }
    this.menuOpen = !this.menuOpen;
  }

  openCloseUserMenu() {
    const wrapper: HTMLElement = this.renderer.selectRootElement('#app-wrapper', true);
    if (wrapper.classList.contains('user-side-open')) {
      this.renderer.removeClass(wrapper, 'user-side-open');
    } else {
      this.renderer.addClass(wrapper, 'user-side-open');
      this.mobileMenuClosed.emit();
    }
    this.userMenuOpen = !this.userMenuOpen;
  }

  initMenu() {
    this.themePanelOpen = false;
  }

  toggleThemePanel() {
    this.themePanelOpen = !this.themePanelOpen;
  }
}
