<button class="btn-icon-transparent icon-dark-blue close ml-auto opacity-50 hover:opacity-100" (click)="dialogRef.close()">
  <app-icon [icon]="'close'" class="w-20 h-20 white">
  </app-icon>
</button>
<ng-container *ngIf="files && files.length">
  <div class="slide-arrow left fixed left-0 top-0 h-full z-0" *ngIf="currentFileIndex>0" (click)="changeSlide(0)">
    <app-icon icon="arrow-right"></app-icon>
  </div>
  <div class="slide-arrow right fixed rigth-0 top-0 h-full z-0" *ngIf="currentFileIndex < this.files.length-1" (click)="changeSlide(1)">
    <app-icon icon="arrow-right"></app-icon>
  </div>
</ng-container>
<div
  class="overlay-content h-full w-full"
  [@slideContent]="animationState"
  (@slideContent.start)="onAnimationStart($event)"
  (@slideContent.done)="onAnimationDone($event)"
>
  <div class="relative h-full w-full" [ngClass]="{ video_container: file.type === 'video' }">
    <div class="flex justify-center align-center w-full h-full" *ngIf="file.type === fileType.IMAGE && loading">
      <app-local-loader color="white" class="w-48 h-48"></app-local-loader>
    </div>
    <ng-container *ngIf="file.type === fileType.IMAGE">
      <div class="image-container" >
        <img
          alt="image"
          [@fade]="loading ? 'fadeOut' : 'fadeIn'"
          (load)="onLoad()"
          [src]="file.url"
        />
      </div>
    </ng-container>
    <ng-container  *ngIf="file.type === fileType.PDF">
      <div class="flex justify-center align-center w-full h-full" *ngIf="loading">
        <app-local-loader color="white" class="w-48 h-48"></app-local-loader>
      </div>
      <pdf-viewer
        (on-progress)="loadingProgress()"
        [src]="file.url"
        [render-text]="true"
        [original-size]="false"
        (after-load-complete)="pdfLoaded()"
      >
      </pdf-viewer>
    </ng-container>
    <div class="video relative z-20" *ngIf="file.type === fileType.VIDEO">
      <div class="ratio-16-9">
        <app-video-player
          class="absolute top-0 w-full h-full"
          [videoConfig]="selectedFile"
        ></app-video-player>
      </div>
    </div>
  </div>
</div>
