import { Injectable } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { ApiService } from './api.service';
import {
  Proposal,
  ModerationRejectReason,
  ProposalAIReport,
  FileProgressObject,
  TokenEarning,
  ReputationEarning,
  UploadedFile,
} from '../../app.datatypes';
import { environment } from '../../../environments';
import { HttpHeaders, HttpEventType, HttpErrorResponse, HttpResponse, HttpEvent } from '@angular/common/http';
import { catchError, last, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ProposalService {
  private url = environment.url;
  public startCreateProposalSubject$ = new Subject<string>();

  constructor(private apiService: ApiService) {}

  getProposal(id: string): Observable<Proposal> {
    return this.apiService.get('api/proposals/' + id);
  }

  getEarnings(id: string, skip = 0): Observable<TokenEarning[]> {
    return this.apiService.get('api/proposals/' + id + '/earnings?skip=' + skip);
  }

  getAllEarnings(id: string, skip = 0): Observable<TokenEarning[]> {
    return this.apiService.get('api/proposals/' + id + '/all-earnings?skip=' + skip);
  }

  getReputationEarnings(id: string, skip = 0): Observable<ReputationEarning[]> {
    return this.apiService.get('api/proposals/' + id + '/reputation-earnings?skip=' + skip);
  }

  getRoundRelatedProposals(proposalRounds: string): Observable<Proposal[]> {
    return this.apiService.get('api/proposals/round-related/' + proposalRounds);
  }

  getRoundRelatedProposalsLimit(proposalRounds: string, skip = 0, limit = 20): Observable<Proposal[]> {
    const api = `api/proposals/round-related/${proposalRounds}?limit=${limit}&skip=${skip}`;
    return this.apiService.get(api);
  }

  getProposalResults(id: string): Observable<Proposal> {
    return this.apiService.get('api/proposals/' + id + '/results');
  }

  getProposalRejectReasons(): Observable<ModerationRejectReason[]> {
    return this.apiService.get('api/moderation-reject-reasons');
  }

  uploadImageByUrl(url: string) {
    return this.apiService.post('api/media/upload-image-by-url', { image_url: url });
  }

  uploadImage(data) {
    const url = 'api/media/upload-image';
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('ngsw-bypass', 'true');
    return this.apiService.post(url, data, { headers: headers, observe: 'events', reportProgress: true }).pipe(
      catchError((error) => {
        return this.apiService.catchError(error);
      })
    );
  }

  uploadPdfDoc(data): Observable<HttpEvent<UploadedFile>> {
    const url = 'api/media/upload-pdf';
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');
    headers.append('ngsw-bypass', 'true');
    return this.apiService.post(url, data, { headers: headers, observe: 'events', reportProgress: true });
  }

  createFileRecordOnUpload(data) {
    return this.apiService.post('api/video-upload', data);
  }

  createProposalAIReport(
    proposal: Proposal,
    pdfFile: File,
    videoFile: File,
    fileProgressObject: FileProgressObject
  ): Observable<HttpResponse<any>> {
    const formData = new FormData();
    formData.append('proposal_id', proposal._id);
    if (pdfFile) {
      formData.append('pdf_file', pdfFile);
    }
    if (videoFile) {
      formData.append('video_file', videoFile);
    }
    return this.apiService.postWithAttachment(`api/ai/report/proposal`, formData).pipe(
      map((event) => {
        switch (event.type) {
          case HttpEventType.UploadProgress:
            fileProgressObject.progress = Math.round(
              ((event.total ? event.total : 1) * 100) / (event.total ? event.total : 1)
            );

            break;
          case HttpEventType.Response:
            return event;
        }
      }),
      tap((event: any) => {
        if (typeof event === 'object' || (event && event.body && event.body.data)) {
          return event.body.data;
        }
      }),
      last(),
      catchError((error: HttpErrorResponse) => {
        fileProgressObject.inProgress = false;
        fileProgressObject.canRetry = true;

        return of(`${fileProgressObject.file.name ? fileProgressObject.file.name : ''} upload failed.`);
      })
    );
  }

  getAIReportStatus(proposal: Proposal): Observable<ProposalAIReport> {
    return this.apiService.post(`api/ai/report/${proposal.ai_report_id}`, {});
  }

  getAIReportContent(proposal: Proposal): Observable<ProposalAIReport> {
    return this.apiService.get(`api/ai/report/${proposal.ai_report_id}`, {});
  }

  startCreateProposalProcess(proposalRound?: string): void {
    const value = proposalRound ? proposalRound : null;
    this.startCreateProposalSubject$.next(value);
  }
}
