<div class="relative h-full">
  <video
    #videoPlayer
    x-webkit-airplay="allow"
    oncontextmenu="return false;"
    (play)="playVideo($event)"
    (playing)="isPlaying = true"
    (pause)="isPlaying = false"
    (mouseenter)="onMouseEnter($event)"
    (mouseleave)="onMouseLeave($event)"
    (loadeddata)="onLoadedData($event)"
    (timeupdate)="handleTimeUpdate()"
    (progress)="handleProgress()"
    (waiting)="isPlaying = false"
    (ended)="isPlaying = false"
    (playing)="isPlaying = true"
    [muted]="videoConfig.autoplay || videoConfig.hoverPlay"
    [controls]="isIOS"
    playsinline="true"
    webkit-playsinline="true"
    [src]="videoConfig.src"
    type="video/mp4"
    [poster]="videoConfig.poster"
    [ngStyle]="styleConfig"
    [className]="videoConfig.classes"
    [loop]="videoConfig.loop"
    [autoplay]="videoConfig.autoplay"
    [ngClass]="{ 'object-fill': isLandscape }"
  >
    {{ videoConfig.showText }}
  </video>

  <div
    class="absolute top-0 left-0 h-full w-full flex justify-center items-center flex-col z-10 text-white"
    [ngClass]="!isPlaying && !isCasting && !isCastConnecting ? 'loader-background' : 'bg-black'"
    *ngIf="(isCasting || isCastConnecting || !isPlaying) && videoConfig.controls !== false"
  >
    <img
      src="assets/icons/icons/play.svg"
      class="md:w-[2vw] md:h-[2vw] cursor-pointer"
      (click)="playPause()"
      alt="play"
      *ngIf="!isPlaying && !isCasting && !isCastConnecting"
    />
    <ng-container *ngIf="isCasting || isCastConnecting">
      <svg viewBox="0 0 800 800" fill="none" xmlns="http://www.w3.org/2000/svg" class="w-56 h-56 white mb-24">
        <path
          d="M150 150C122.425 150 100 172.425 100 200V300H150V200H650V600H450V650H650C677.575 650 700 627.575 700 600V200C700 172.425 677.575 150 650 150H150Z"
          fill="white"
        />
        <path
          d="M100 400V350C265.425 350 400 484.575 400 650H350C350 641.384 349.586 632.857 348.73 624.463C335.904 498.557 229.234 400 100 400Z"
          fill="white"
          [ngClass]="{ 'animate-pulse': isCastConnecting }"
          style="animation-delay: 600ms"
        />
        <path
          d="M100 500V450C210.275 450 300 539.725 300 650H250C250 567.275 182.725 500 100 500Z"
          [ngClass]="{ 'animate-pulse': isCastConnecting }"
          style="animation-delay: 300ms"
          fill="white"
        />
        <path
          d="M100 650V550C155.225 550 200 594.775 200 650H100Z"
          [ngClass]="{ 'animate-pulse delay-150': isCastConnecting }"
          fill="white"
        />
        <path
          d="M185 241H615V569H452.5C452.5 569 433.574 466.661 359.5 393C285.01 318.926 185 300 185 300V241Z"
          fill="white"
        />
      </svg>
      <ng-container *ngIf="!isCastConnecting">
        <p>Your video is playing on {{ castDevice }}</p>
        <button class="btn btn-primary mt-24" (click)="stopCast()">Stop Casting</button>
      </ng-container>
    </ng-container>
  </div>
</div>

<div
  class="flex items-center px-8 h-40 absolute z-50 bottom-0 w-full control-bar"
  *ngIf="videoConfig.controls !== false && !isIOS"
  (mouseenter)="onMouseEnter($event)"
  [style.opacity]="displayControllsOpacity"
>
  <button (click)="playPause()">
    <app-icon [icon]="isPlaying ? 'pause' : 'play'" class="w-16 h-16 white"></app-icon>
  </button>
  <div #progressBar class="progress-bar">
    <div class="watched-bar" [style.width]="watchedProgress + '%'"></div>
    <div class="loaded-bar" [style.width]="loadPercentage + '%'"></div>
    <div class="playhead"></div>
  </div>
  <p class="time-remaining text-white mr-8">
    {{ durationRemaining }}
  </p>
  <button class="mr-12" (click)="toggleMute()">
    <app-icon [icon]="isFullVolume ? 'speaker' : 'mute'" class="w-16 h-16 white"></app-icon>
  </button>
  <button class="mr-12" (click)="toggleFullscreen()" *ngIf="fullscreenButton">
    <app-icon [icon]="isFullScreen ? 'sm-screen' : 'full-screen-2'" class="w-16 h-16 white"></app-icon>
  </button>
  <button
    class="mr-12"
    [appDropdownMenu]="resdropdown"
    (click)="$event.stopPropagation()"
    *ngIf="videoConfig?.resolutions?.length"
  >
    <app-icon [icon]="'gear'" class="w-20 h-20 white"></app-icon>
  </button>

  <button class="mr-12" (click)="startSession()" *ngIf="chromecastButton && castContext">
    <app-icon [icon]="'cast'" class="w-20 h-20 white"></app-icon>
  </button>
  <button class="mr-12" (click)="togglePictureInPicture()" *ngIf="pipButton">
    <app-icon icon="pip" class="w-16 h-16 white"></app-icon>
  </button>
  <button *ngIf="isMoreButton" [appDropdownMenu]="dropdown">
    <app-icon icon="more" class="w-16 h-16 white"></app-icon>
  </button>
</div>
<app-dropdown-menu #resdropdown class="drop-menu" @dropdown [size]="'sm'">
  <div class="drop-menu">
    <ng-container *ngFor="let option of videoConfig.resolutions">
      <button
        (click)="onResolutionChange(option)"
        class="menu-button relative"
        [ngClass]="{ active: option.video_details.heightInPx === selectedres }"
      >
        <span
          >{{ option.video_details.heightInPx }}p
          <span class="text-xxs absolute -top-4 right-4" *ngIf="option.video_details.heightInPx > 720">{{
            option.video_details.heightInPx === 2160 ? '4k' : 'HD'
          }}</span>
        </span>
      </button>
    </ng-container>
  </div>
</app-dropdown-menu>
<app-dropdown-menu #dropdown class="drop-menu right" [@dropdown]>
  <div>
    <button class="menu-button" *ngIf="!pipButton" (click)="togglePictureInPicture()">
      <app-icon [icon]="'pip'" class="w-16 h-16 mr-8 -mb-4"></app-icon>
      Picture in pic mode
    </button>
    <button class="menu-button" *ngIf="!chromecastButton && castContext" (click)="startSession()">
      <app-icon [icon]="'cast'" class="w-16 h-16 mr-8 -mb-4"></app-icon>
      Chromecast
    </button>
    <button class="menu-button" *ngIf="!fullscreenButton" (click)="toggleFullscreen()">
      <app-icon [icon]="isFullScreen ? 'sm-screen' : 'full-screen-2'" class="w-16 h-16 mr-8 -mb-4"></app-icon>
      Fullscreen mode
    </button>
  </div>
</app-dropdown-menu>
