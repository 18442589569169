export enum EntityTypeEnum {
  PROPOSAL = 'proposal',
  REVIEW = 'review',
  CREATIVE_QUERY = 'creative_query',
  QUIZ_QUESTION = 'quiz_question',
  VIDEO_DISTRIBUTION = 'video_distribution',
}

export interface EntityParams {
  type: EntityTypeEnum,
  id: string
}
