import { Component } from '@angular/core';
import { Observable } from "rxjs";
import { GalleryService } from '../../services/gallery.service';
import { VideoConfig } from '../../interface/video-config.interface';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
})
export class GalleryComponent {
  gallerySlides$: Observable<VideoConfig[]> = this.galleryService.getGallery();

  constructor(
    public galleryService: GalleryService
  ) {
  }
}
