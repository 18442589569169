import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GalleryService } from '../../services/gallery.service';
import { ImgSizePipe } from '../../pipes/img-size/img-size.pipe';

@Component({
  standalone: true,
  selector: 'app-dcp-image',
  imports: [CommonModule, ImgSizePipe],
  templateUrl: './dcp-image.component.html',
})
export class DcpImageComponent implements OnInit {
  @Input() src: string;

  isLoading = true;
  imageScaled = true;

  constructor(private readonly galleryService: GalleryService) {}

  ngOnInit(): void {
    this.galleryService.getImageScaled().subscribe((scaled: boolean) => {
      this.imageScaled = !!scaled;
    });
  }
}
