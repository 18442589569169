import { Component, DestroyRef, ElementRef, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DiscordChatService, ProgressService, SEOService, ToasterService, ProgressRef } from '../../../shared';
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

@Component({
  selector: 'app-verify-discord',
  templateUrl: './verify-discord.component.html'
})
export class VerifyDiscordComponent implements OnInit {
  token: string;
  progress: ProgressRef;

  constructor(
    private readonly discordChatService: DiscordChatService,
    private readonly toaster: ToasterService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private _seoService: SEOService,
    private progressService: ProgressService,
    private elRef: ElementRef,
    private destroyRef: DestroyRef
  ) {
    this.route.queryParams.subscribe(params => {
      this.token = params['t'];
    });
  }

  ngOnInit(): void {
    this._seoService.createTitleForPage();
    if (this.token) {
      this.progress = this.progressService.showSpinner(this.progress, this.elRef);
      this.discordChatService
        .checkVerification(this.token)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe({
          next: () => {
            this.progressService.hideSpinner(this.progress);
            this.toaster.openToastr(
              'Thank you, your Discord account has been successfully verified.',
              'Success',
              'check',
              3000
            );
            this.router.navigate(['/account/user']);
          },
          error: () => {
            this.progressService.hideSpinner(this.progress);
            this.toaster.openToastr('Your Discord account was not verified', 'Error', 'error', 3000);
            this.router.navigate(['/account/user']);
          },
        });
    }
  }
}
