<div class="border-b border-borderdark cursor-pointer py-16" (click)="readNotification(notification)">
  <div class="flex justify-between items-center mb-8">
    <app-icon *ngIf="notification.read_time === null" [icon]="'unread'" class="w-20 h-20 white">Unread</app-icon>
    <app-icon *ngIf="notification.read_time !== null" [icon]="'read'" class="w-20 h-20 white opacity-75"></app-icon>
    <div class="opacity-75 text-sm">
      {{ notification.created_at | date: 'medium' }}
    </div>
  </div>
  <div class="notification-body" [ngClass]="{ 'opacity-50': notification.read_time !== null }">
    <p *ngIf="!notification.template_variables">
      {{ notification.description }}
    </p>
    <p *ngIf="notification.template_variables && notification.template_variables.type === 'entity-action'">
      <app-entity-action
        [entity]="notification.template_variables.entity"
        [title]="notification.template_variables.entity_title"
        [action]="action"
        [url]="getEntityUrl(notification.template_variables.entity, notification.template_variables._id)"
        [actor]="notification.template_variables.actor"
      >
      </app-entity-action>
    </p>
    <p *ngIf="notification.template_variables && notification.template_variables.type === 'sub_entity_action'">
      <app-sub-entity-action
        [entity]="notification.template_variables.entity"
        [action]="notification.template_variables.action"
        [entityUrl]="getEntityUrl(notification.template_variables.entity, notification.template_variables._id)"
        [parent_entity]="notification.template_variables.parent_entity"
        [parent_entity_title]="notification.template_variables.parent_entity_title"
        [parentEntityUrl]="
          getEntityUrl(notification.template_variables.parent_entity, notification.template_variables.parent_entity_id)
        "
        [actor]="notification.template_variables.actor"
      >
      </app-sub-entity-action>
    </p>
  </div>
</div>
