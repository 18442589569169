<div class="activity-content">
  <app-tabs>
    <app-tab-item>
      <app-tab-label>Activity</app-tab-label>
      <app-tab-body>
        <svg
          width="125"
          height="104"
          viewBox="0 0 125 104"
          class="progress-bar"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M17.917 99C9.842 89.08 5 76.413 5 62.613 5 30.794 30.744 5 62.5 5S120 30.794 120 62.613c0 13.8-4.842 26.466-12.917 36.387"
            stroke="#E8E8E8"
            stroke-width="10"
            fill="none"
            fill-rule="evenodd"
            stroke-dasharray="260,9999"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <svg
          *ngIf="data.fillChart !== 0"
          width="125"
          height="104"
          viewBox="0 0 125 104"
          class="progress-bar"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M17.917 99C9.842 89.08 5 76.413 5 62.613 5 30.794 30.744 5 62.5 5S120 30.794 120 62.613c0 13.8-4.842
        26.466-12.917 36.387"
            stroke="#3b55fd"
            stroke-width="10"
            fill="none"
            fill-rule="evenodd"
            attr.stroke-dasharray="{{ data.dasharay }}"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>

        <p>{{ data.fillChart }}%</p>
        <p>{{ data.taken | number: '1.2-2' }}<br />of<br />{{ data.all | number: '1.2-2' }}</p>
        <p>Overall Activity</p>
        <div
          class="info tooltip-right-bottom"
          appTooltip="Your activity is based on the amount of reviewed projects and creative queries."
          placement="bottom"
        >
          <span>
            <app-icon [icon]="'info'" class="w-16 h-16"></app-icon>
          </span>
        </div>
      </app-tab-body>
    </app-tab-item>
    <app-tab-item>
      <app-tab-label>Potential Earnings</app-tab-label>
      <app-tab-body>
        <p class="countdown">
          Will update in:
          <ng-container *ngIf="data.potentialEarningTime.hours < 10"> 0</ng-container
          >{{ data.potentialEarningTime.hours }} :
          <ng-container *ngIf="data.potentialEarningTime.minutes < 10"> 0</ng-container
          >{{ data.potentialEarningTime.minutes }} :
          <ng-container *ngIf="data.potentialEarningTime.seconds < 10">0</ng-container
          >{{ data.potentialEarningTime.seconds }}
        </p>
        <div class="potential-earnings">
          <app-icon [icon]="'film'" class="w-32 h-32"></app-icon>{{ data.potentialEarning.toFixed(2) }}
        </div>

        <div
          class="info"
          appTooltip="Your potential earnings is the amount you would earn based on your current project and creative query review participation and accuracy if everything was to finish right now."
          placement="bottom"
        >
          <span>
            <app-icon [icon]="'info'" class="w-16 h-16"></app-icon>
          </span>
        </div>
      </app-tab-body>
    </app-tab-item>
  </app-tabs>
</div>
<ul class="activity-details">
  <li>
    <label>Project<br />Reviews</label>
    <p>{{ data.review }}/{{ data.reviewRight }}</p>
  </li>
  <li>
    <label>Reviews<br />Liked</label>
    <p>{{ data.likes }}/{{ data.likesRight }}</p>
  </li>
  <li *ngIf="!showCq">
    <label>Creative<br />Queries</label>
    <p>{{ data.creativeQuery }}/{{ data.creativeQueryRight }}</p>
  </li>
</ul>
