import { Component } from '@angular/core';
import { DialogData } from '../../../../app.datatypes';
import { DialogContext } from '../../../dialog/dialog-context';

@Component({
  selector: 'app-maintainance-dilaog',
  templateUrl: './maintainance-dilaog.component.html',
  styleUrls: ['./maintainance-dilaog.component.scss']
})
export class MaintainanceDilaogComponent {

  constructor(public dialog: DialogContext<DialogData>,) { }

}
