import { inject, signal } from '@angular/core';
import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";
import { Observable } from "rxjs";
import { PaymentStateService } from "../state";
import { tap } from "rxjs/operators";

export class AvailableDailyLimitService {
  #dailyLimit = signal(null);

  #paymentService = inject(PaymentStateService);

  getValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      return (control.value && this.getDailyLimit() && control.value > this.getDailyLimit()) ? { limitExceeded: true } : null;
    }
  }

  setDailyLimit(): Observable<number> {
    return this.#paymentService.getDailyLimit().pipe(
      tap(data => this.#dailyLimit.set(data))
    );
  }

  getDailyLimit(): number {
    return this.#dailyLimit();
  }

}
