import { Injectable, NgZone } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Toastr } from '../toastr/toastr';
import { filter } from 'rxjs/operators';
@Injectable({
  providedIn: 'root',
})
export class ToasterService {
  toastrSubject: BehaviorSubject<Toastr>;
  toast$: Observable<Toastr>;
  snackBarShown = false;

  constructor(private zone: NgZone) {
    this.toastrSubject = new BehaviorSubject<Toastr>(null);
    this.toast$ = this.toastrSubject.asObservable().pipe(filter(toast => toast !== null));
  }

  show(message: string, title: string, duration?: number, type = 'info') {
    const data = { toastrType: 'default', message, title, type, duration: duration || 5000 };
    this.zone.run(() => {
      this.toastrSubject.next(data);
    });
  }

  openErrorToastr(message, title, type = 'error', duration = 5000) {
    this.snackBarShown = true;
    const data = { toastrType: 'main', message, title, type, duration };
    this.zone.run(() => {
      this.toastrSubject.next(data);
    });
  }

  openToastr(message, action, type = 'info', duration = 0, isArray = false) {
    const data = { toastrType: 'main', message, action, type, isArray, duration };
    this.toastrSubject.next(data);
  }

  openToastrWithLink(message, action, type = 'info', duration = 0, link, linkType, buttonLabel) {
    const data = { toastrType: 'link', message, action, type, link, linkType, buttonLabel, duration };
    this.toastrSubject.next(data);
  }

  openContactSupportToastr(message, type = 'error', notificationType = '') {
    const data = { toastrType: 'contactSupport', message, type };
    this.toastrSubject.next(data);
  }

  openValidationErrorsToastr(message, errors, type = 'error', duration = 10000, notificationType = '') {
    const data = { toastrType: 'main', message, errors, type, duration };
    this.toastrSubject.next(data);
  }

  openToastrWithLowBalanceSheet(message, type = 'error', toastrData) {
    const data = { toastrType: 'lowBalanceSheet', message, type, toastrData };
    this.toastrSubject.next(data);
  }

  openReloadToastr(message, action, type = 'info') {
    const data = { toastrType: 'reload', message, action, type };
    this.toastrSubject.next(data);
  }

  closeToastr() {
    this.snackBarShown = false;
  }
}
