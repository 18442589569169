import { Component, Input, Output, EventEmitter, Renderer2, HostListener } from '@angular/core';
import { JoyrideService } from 'ngx-joyride';
import { environment } from '../../../../environments';
import { AuthenticatedUserQuery } from '../../../shared';
import { ProposalByRoundService } from '../../../shared/services/new/proposal-by-round/proposal-by-round.service';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
})
export class NavComponent {
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.checkMini();
  }
  @Input() loginModalRef: string;
  @Output() closeNav = new EventEmitter();
  isReferralProgram = environment.referral_program;
  liElements = [];
  scrollDelta = 100;
  width = 0;
  showProposal = !environment.limited_proposal_functionality;
  showCq = !environment.limited_cq_functionality;
  showModeration = !environment.limited_moderation_functionality;
  showQuiz = !environment.limited_quiz_functionality;
  showWatch = !environment.limited_watch_functionality;
  showSwag = !environment.limited_swag_functionality;
  isAuthenticated: boolean;
  isMini = false;
  constructor(
    public authenticatedUserQuery: AuthenticatedUserQuery,
    private renderer: Renderer2,
    public joyrideService: JoyrideService,
    private proposalByRoundService: ProposalByRoundService
  ) {
    authenticatedUserQuery.isLoggedIn$.subscribe((res) => {
      this.isAuthenticated = res;
    });
    this.checkMini();
  }

  checkMini(): void {
    this.isMini = window.innerWidth > 991 && window.innerWidth < 1280;
  }

  scrollRight(evt): void {
    evt.stopPropagation();
    let element = evt.target['parentElement'];
    while (element['id'] !== 'navigation') {
      element = element['parentElement'];
    }
    let scrollLeft = element.scrollLeft;
    scrollLeft += this.scrollDelta;
    element.scrollTo({ left: scrollLeft, top: 0, behavior: 'smooth' });
  }

  scrollLeft(evt): void {
    let element = evt.target['parentElement'];
    while (element['id'] !== 'navigation') {
      element = element['parentElement'];
    }
    let scrollLeft = element.scrollLeft;
    scrollLeft -= this.scrollDelta;
    element.scrollTo({ left: scrollLeft, top: 0, behavior: 'smooth' });
  }

  onScroll(event: Event): void {
    if (this.width === 0) {
      this.liElements = event.target['children'];
      for (let i = 1; i < this.liElements.length - 1; i++) {
        this.width += this.liElements[i].clientWidth;
      }
    }

    const currentScroll = event.target['clientWidth'] + event.target['scrollLeft'];

    this.liElements = event.target['children'];
    if (this.width === currentScroll) {
      this.liElements[this.liElements.length - 1].classList.add('hide');
    } else {
      this.liElements[this.liElements.length - 1].classList.remove('hide');
    }

    if (event.target['scrollLeft'] === 0) {
      this.liElements[0].classList.add('hide');
    } else {
      this.liElements[0].classList.remove('hide');
    }
  }

  blockClick(): void {
    this.closeLeftMenu();
  }

  buyEnabled(): boolean {
    return environment.buy_enabled;
  }

  dismissInitialView(): void {
    this.proposalByRoundService.setVisibilityStatus(true);
  }

  closeLeftMenu(): void {
    const wrapper: HTMLElement = this.renderer.selectRootElement('#app-wrapper', true);
    this.renderer.removeClass(wrapper, 'left-side-open');
  }
}
