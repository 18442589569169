import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LocalLoaderComponent } from './local-loader.component';



@NgModule({
  declarations: [LocalLoaderComponent],
  imports: [
    CommonModule
  ],
  exports:[LocalLoaderComponent]
})
export class LocalLoaderModule { }
