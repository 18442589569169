import { Component, Input } from '@angular/core';
import { SharedService } from '../../services';

@Component({
  selector: 'app-no-items',
  templateUrl: 'no-items.component.html',
  styleUrls: ['no-items.component.scss'],
})
export class NoItemsComponent {
  @Input() itemName: string;
  @Input() image: string;

  constructor(private sharedService: SharedService) {}

  createQuery(): void {
    this.sharedService.setCreateCQ();
  }

  createProposal(): void {
    this.sharedService.setCreateProposal();
  }
  createDistribution(): void {
    this.sharedService.setCreateDistribution();
  }
}
