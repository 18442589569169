<div class="flex justify-between items-center dialog-header">
  <h4 class="font-semibold">BlockChain Under Maintenance</h4>
  <button class="btn-dark-blue" (click)="dialog.close()">
    <app-icon [icon]="'close'" class="w-16 h-16 opacity-75 hover:opacity-100"></app-icon>
  </button>
</div>
<div class=" dialog-content">
    <p class="text-lg">T4L3NT Net is currently getting some upgrades. The DCP platform will be available during this process; however, the following services will be temporarily unavailable until the updates are complete:</p>
    <ul class="list-disc p-16">
      <li>
        Submit Project
      </li>
      <li>
        Submit Review/Evaluation
      </li>
      <li>
        Submit Like to Project Review
      </li>
      <li>
        Submit Creative Query
      </li>
      <li>
        Submit Creative Query Response
      </li>
      <li>
        Daily Quiz
      </li>
      <li>
        Transactions from Buy page
      </li>
      <li>
        Withdraw/Deposit to/from Internal & External wallets
      </li>
      <li>
        Moderations
      </li>
    </ul>
</div>
