<div class=" dialog-content">
    <form [formGroup]="personalDetailsForm" class="grid gap-24 mt-16" *ngIf="personalDetailsForm" autocomplete="off">
      <div class="form-group pb-0 max-w-300" *ngIf="personalDetailsPending.age_group">
        <label>Age Group</label>
        <app-dcp-select
          [options]="ageGroupList"
          [placeholder]="'Age'"
          (selected)="selectAgeGroup($event)"
        >
        </app-dcp-select>
        <p class="error-msg" *ngIf="personalDetailsForm.get('age_group').errors?.required && personalDetailsForm.get('age_group')?.touched">
          Age Group is Required
        </p>
      </div>
      <div class="form-group pb-0 max-w-300" *ngIf="personalDetailsPending.sex">
          <label>Gender</label>
          <app-dcp-select
            id="gender"
            [options]="genderList"
            (selected)="selectGender($event)"
            placeholder="Gender"
            required
          ></app-dcp-select>
          <p class="error-msg" *ngIf="personalDetailsForm.get('sex')?.touched && personalDetailsForm.get('sex').errors?.required">
            Gender is Required
          </p>
      </div>
      <div class="form-group pb-0 max-w-300" *ngIf="personalDetailsPending.country">
        <label>Country </label>
        <app-dcp-select
          id="country"
          [filterOption]="true"
          [selectedDefault]="selectedCountry"
          [options]="countryListDD"
          (selected)="selectCountry($event)"
          placeholder="Country"
          required
        ></app-dcp-select>
        <p class="error-msg" *ngIf="personalDetailsForm.get('country')?.touched && personalDetailsForm.get('country').errors?.required">
          Country is Required
        </p>
      </div>
      <div class="form-group  max-w-300 pb-0" *ngIf="personalDetailsPending.ethnicity">
        <label>Ethnicity</label>
        <app-dcp-multiselect
          id="ethnicity"
          *ngIf="ethnicityListDD"
          [limit]="2"
          [options]="ethnicityListDD"
          (selected)="selectEthnicity($event)"
          placeholder="Ethnicity"
          placement="top"
          type="info"
        ></app-dcp-multiselect>
        <p class="error-msg" *ngIf="personalDetailsForm.get('ethnicity')?.touched && personalDetailsForm.get('ethnicity').errors?.required">
          Ethnicity is Required
        </p>
      </div>
      <div class="form-group  max-w-300 pb-0" *ngIf="personalDetailsPending.languages">
        <label>Languages</label>
        <app-dcp-multiselect
          [options]="languageList"
          [placeholder]="'Language(s) you know'"
          [filterOption]="true"
          (selected)="selectLanguage($event)"
        >
        </app-dcp-multiselect>
        <p class="error-msg" *ngIf="personalDetailsForm.get('languages').errors?.required && personalDetailsForm.get('languages').touched">
          Languages is Required
        </p>
      </div>
    </form>
</div>
<div class="dialog-footer mt-32">
  <button class="btn btn-primary w-full" (click)="submit()" [disabled]="personalDetailsForm.invalid">
      Submit
    </button>
</div>
