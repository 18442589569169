<div *ngIf="questions">
  <div *ngFor="let question of questions; let i = index; trackBy: trackQuestion">
    <h3 class="text-lg my-16 font-semibold">{{ question.description }}</h3>
    <div class="answer-holder">
      <ng-container *ngFor="let option of question.options; let j = index; trackBy: trackOption">
        <div *ngIf="option.type === 'text'" class="option-box full">
          <label for="" class="radio ml-16">
            <input type="radio" disabled />
            <span class="checkmark"></span>
            {{ option.value }}
          </label>
        </div>

        <div class="option-box full media" *ngIf="option.type === 'image'">
          <div class="flex-1 p-16">
            <label for="" class="radio ">
              <input type="radio" disabled />
              <span class="checkmark"></span>
              {{ option.title }}
            </label>
            <div class="content" style="padding-top: 0 !important;">
              <div *ngIf="option?.description" class="description">{{ option?.description }}</div>
            </div>
          </div>
          <div class="media ratio-16-9">
            <img
              (click)="
                showPreview($event, {
                  name: 'Option Image',
                  url: option.value,
                  type: 'image'
                })
              "
              class="option-image"
              alt="option-image"
              [src]="option.value"
            />
          </div>
        </div>
        <div class="option-box full media" *ngIf="option.type === 'doc'">
          <div class="flex-1 p-16">
            <label for="" class="radio ">
              <input type="radio" disabled />
              <span class="checkmark"></span>
              {{ option.title }}
            </label>
            <div class="content" style="padding-top: 0 !important;">
              <div *ngIf="option?.description" class="description">{{ option?.description }}</div>
            </div>
          </div>
          <div class="media ratio-16-9">
            <img
              (click)="
                showPreview($event, {
                  name: 'Option Image',
                  url: option.value,
                  type: 'pdf'
                })
              "
              class="option-image"
              alt="option-doc"
              [src]="option.thumbnail_url"
            />
          </div>
        </div>

        <div class="option-box full media" *ngIf="option.type === 'video'">
          <div class="flex-1 p-16">
            <label for="" class="radio ">
              <input type="radio" disabled />
              <span class="checkmark"></span>
              {{ option.title }}
            </label>
            <div class="content" style="padding-top: 0 !important;">
              <div *ngIf="option?.description" class="description">{{ option?.description }}</div>
            </div>
          </div>
          <div class="video-container ratio-16-9" *ngIf="option.videoBlobUrl">

            <ng-container *ngIf="option.isDirectVideoUpload">

              <app-video-player
                class="absolute top-0 w-full h-full"
                [videoConfig]="generateVideoConfig(option)"
              ></app-video-player>

            </ng-container>
            <div
              class="overlay"
              (click)="
                showPreview($event, {
                  name: 'Video',
                  url: option.value,
                  type: 'video'
                })
              "
            ></div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
