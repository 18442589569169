<div class="grid grid-cols-2 gap-16">
  <div
    class="image-block ratio-3-4 bg-body rounded overflow-hidden cursor-pointer border border-gray-border"
    *ngFor="let item of proposalDocs; let i = index"
    [ngClass]="{ 'hover:opacity-75': !expandedUrl }"
    (click)="showPreview(item, 'pdf')"
  >
    <img class="z-0" [src]="item.file_preview_url" alt="preview" />
    <div class="p-16 z-50 relative bg-body" [ngClass]="{ expand: item.url === expandedUrl }">
      <div class="flex items-center">
        <app-icon [icon]="'pdf'" class="w-24 h-24 mr-8"></app-icon>
        <p>{{ item.docTitle }}</p>
        <button
          class="btn-icon-transparent icon-xs ml-auto icon-black btn-position"
          (click)="$event.stopPropagation(); expand(item)"
        >
          <app-icon [icon]="!expandedUrl ? 'maximize' : 'minimize'" [style.fill]="checkIconColor"></app-icon>
        </button>
      </div>
      <p class="mt-16" [ngClass]="{ 'line-clamp-3': !expandedUrl }">{{ item.description }}</p>
    </div>
  </div>
</div>
