import { BrowserModule, HammerGestureConfig, HammerModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { LOCALE_ID, NgModule, ErrorHandler, APP_INITIALIZER } from '@angular/core';
import { AppComponent } from './app.component';
import { Router, RouterModule } from '@angular/router';
import { AppRoutes } from './app.routes';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule, AuthenticatedUserService, PhoneMaskDirective } from './shared';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { SuspendDialogComponent } from './containers/administration/user-management/suspend-dialog/suspend-dialog.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments';
import { AkitaNgDevtools } from '@datorama/akita-ngdevtools';
import { AkitaNgRouterStoreModule } from '@datorama/akita-ng-router-store';
import * as Sentry from '@sentry/angular-ivy';
import { HttpTokenInterceptor } from './interceptors/http.token.interceptor';
import { UnauthorizedInterceptor } from './interceptors/unauthorized.interceptor';
import { TimeagoModule } from 'ngx-timeago';
import { DatePipe } from '@angular/common';
import { NgIdleModule } from '@ng-idle/core';
import { DcpModalModule } from './shared/components/dcp-modal';
import { ToastrModule } from './shared/toastr/toastr.module';
import { DialogModule } from './shared/dialog/dialog.module';
import { ConfirmToBuyDialogComponent } from './containers/proposal/confirm-to-buy-dialog/confirm-to-buy-dialog.component';
import { VerifyModule } from './containers/verify/verify.module';
import { AuthService } from './shared/services/auth.service';
import * as Hammer from 'hammerjs';
import { ApplyModeratorDialogComponent } from './containers/account/dashboard/apply-moderator-dialog/apply-moderator-dialog.component';
import { RefuseModeratorDialogComponent } from './containers/account/dashboard/refuse-moderator-dialog/refuse-moderator-dialog.component';
import { TourModalComponent } from './containers/tour-modal/tour-modal.component';
import { JoyrideModule } from 'ngx-joyride';
import { AuthModule } from './containers/auth/auth.module';
import { LayoutModule } from './components/layout/layout.module';
import { InboxSidebarModule } from './components/inbox-sidebar/inbox-sidebar.module';
import { DcpSelectModule } from './shared/modules/components/dcp-select/dcp-select.module';
import { CheckboxModule } from './shared/modules/components/checkbox/checkbox.module';
import { MultiselectModule } from './shared/modules/components/dcp-multiselect/multiselect.module';
import { ProposalCreateModule } from './containers/proposal/proposal-create/proposal-create.module';
import { QueryCreateModule } from './containers/creative-query/query-create/query-create.module';
import { WalletModule } from './wallet/wallet.module';
import { ErrorHandlerInterceptor } from './interceptors/error-handler.interceptor';
import { DistributionCreateModule } from './containers/distribution/distribution-create/distribution-create.module';

export function initializeUserState(authService: AuthService, authenticatedUserService: AuthenticatedUserService) {
  return async () => {
    if (authService) {
      authService.startRefreshJWTTimer();
    }
    return authenticatedUserService.initializeAppUserState();
  };
}

export class HammerConfig extends HammerGestureConfig {
  overrides = {
    swipe: { direction: Hammer.DIRECTION_HORIZONTAL },
    pinch: { enable: false },
    rotate: { enable: false },
  };
}

// import dev only modules
let dev = [AkitaNgDevtools.forRoot()];

// if production clear dev imports and set to prod mode
if (environment.production || environment.staging) {
  dev = [];
}
@NgModule({
  declarations: [
    AppComponent,
    SuspendDialogComponent,
    ConfirmToBuyDialogComponent,
    ApplyModeratorDialogComponent,
    RefuseModeratorDialogComponent,
    TourModalComponent,
    PhoneMaskDirective,
  ],
  imports: [
    SharedModule.forRoot(),
    VerifyModule,
    CKEditorModule,
    BrowserModule,
    BrowserAnimationsModule,
    HammerModule,
    RouterModule.forRoot(AppRoutes, { scrollPositionRestoration: 'enabled' }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production || environment.staging,
    }),
    ...dev,
    AkitaNgRouterStoreModule,
    TimeagoModule.forRoot(),
    NgIdleModule.forRoot(),
    DcpModalModule,
    ToastrModule,
    DialogModule,
    JoyrideModule.forRoot(),
    AuthModule,
    LayoutModule,
    InboxSidebarModule,
    DcpSelectModule,
    CheckboxModule,
    MultiselectModule,
    ProposalCreateModule,
    DistributionCreateModule,
    QueryCreateModule,
    WalletModule.forRoot(),
  ],
  providers: [
    ...(environment.staging
      ? [
          {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
              showDialog: true,
            }),
          },
          {
            provide: Sentry.TraceService,
            deps: [Router],
          },
        ]
      : []),
    {
      provide: APP_INITIALIZER,
      useFactory: initializeUserState,
      deps: [AuthService, AuthenticatedUserService, ...(environment.staging ? [Sentry.TraceService] : [])],
      multi: true,
    },
    // { provide: HAMMER_GESTURE_CONFIG, useClass: GestureConfig },
    { provide: LOCALE_ID, useValue: 'en-US' },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpTokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UnauthorizedInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorHandlerInterceptor,
      multi: true,
    },
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: HammerConfig,
    },
    DatePipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
