import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Toastr } from '../toastr';

@Component({
  selector: 'app-contact-support-toastr',
  templateUrl: './contact-support-toastr.component.html',
  styleUrls: ['./contact-support-toastr.component.scss'],
})
export class ContactSupportToastrComponent implements OnInit {
  @Input() toast: Toastr;
  @Input() i: number;
  @Output() remove = new EventEmitter<number>();

  constructor() {}

  ngOnInit(): void {}

  removeToastr(event) {
    this.remove.emit(event);
  }
}
