import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { ToasterService } from './toaster.service';

@Injectable()
export class ErrorsHandlerService implements ErrorHandler {
  constructor(private injector: Injector) {}

  handleError(error: Error) {
    const toasterService = this.injector.get(ToasterService);
    toasterService.openToastr(error.message, error.name);
  }
  handleSubmitError(error) {
    const resultMessage = [];
    if (error.status === 422 || error.status === 403) {
      const fields = error.error.errors;
      for (const field of Object.keys(fields)) {
        for (let i = 0; i < fields[field].length; i++) {
          resultMessage.push(`${fields[field][i]}`);
        }
      }
    }
  }
}
