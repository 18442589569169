import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import { Router } from '@angular/router';
import { AuthenticatedUserService, ToasterService } from '../shared';

@Injectable()
export class UnauthorizedInterceptor<T> implements HttpInterceptor {
  constructor(
    private router: Router,
    private toasterService: ToasterService,
    private authenticatedUserService: AuthenticatedUserService
  ) {}

  intercept(req: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<T>> {
    return next.handle(req).pipe(
      tap(
        err => {
          if (err instanceof HttpErrorResponse && err.status === 401) {
            if (localStorage.getItem('refresh_token')) {
              this.authenticatedUserService.clientSideLogOut();
            }
            if (!this.router.url.endsWith('login')) {
              // Redirect the browser to login, rather than navigate to reload state.
              window.location.href = '/login';
            }
          }
        }
      )
    );
  }
}
