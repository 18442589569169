<div class="p-16 rounded border cursor-pointer {{revert?'border-blue':'border-gray-border'}} gap-16 flex items-center" *ngIf="boxMode; else viewMode" [routerLink]="['/account/referral']" >
    <app-icon [icon]="'announce'" class="min-w-20 min-h-20 w-20 h-20 {{revert?'primary':'white'}}"></app-icon>
    <div class="content">
        <p class="{{revert?'text-blue':'text-white'}} font-semibold text-base">Refer to Earn</p>
        <p class="{{revert?'text-blue':'text-white'}} text-xs leading-tight">DCP Referral Program:  Earn $5 USD in FILMCredits for each successful Referral</p>
    </div>
</div>
<ng-template #viewMode>
    <div>
        <div class="border-b border-gray-border py-8 mb-16 flex justify-between bg-white" id="account">
            <h4 class="text-xl font-bold">Referral Program</h4>
        </div>
        <h5 class="text-lg font-bold ">DCP Referral Program: Earn $5 USD in FILMCredits for each successful Referral</h5>
        <p class="text-base ">Welcome to the Decentralized Pictures Referral Program. As a valued member of our community, we want to offer you the opportunity to invite friends, family, and colleagues to join our platform and be rewarded for your efforts.<br/><br/>

        To participate in our Referral Program, there are a few eligibility requirements. First and foremost, you and the referred user must verify your SMS and email, as well as complete all non-identifiable demographic data (found on your account page). For each invite to be successful, both parties will have to have completed these steps, once completed, both invited and inviting user will be rewarded.<br/><br/>

        We have implemented multiple Tiers to acknowledge your reputation and dedication to our platform. As you achieve different reputation milestones, you will gain additional monthly invites.<br/><br/>

        Tier 1: Starting with a reputation of 0, you'll have access to one referral per month. This allows you to invite someone to experience our platform and enjoy its numerous benefits.<br/><br/>

        Tier 2: Once you reach a reputation of 100, you'll unlock even more referral power. In this tier, you'll have access to 10 referrals per month, giving you the ability to invite multiple people and expand our community together.<br/><br/>

        Tier 3: Congratulations! When your reputation surpasses 1000, you'll enter our highest tier. Here, you'll have an allowance of 25 referrals per month. Share your love for our platform far and wide.<br/><br/>

        For every successful referral, both you and your invited user will earn $5 USD worth of FILMCredits. So, start inviting your friends, family, and colleagues today! Not only will you help grow and strengthen our community, but you'll also earn FILMCredits along the way.<br/><br/>
        <div class="border border-gray-border rounded bg-white py-16" *ngIf="referallData">
            <div class="flex justify-between tbody px-24 pb-4">
                <p class="text-base font-normal">Which Tier you are in?</p>
                <p class="text-xl font-bold">{{referallData.usersTier}}</p>
            </div>
            <div class="flex justify-between tbody px-24 py-4">
                <p class="text-base font-normal">Total number of referrals (based on user Tier)</p>
                <p class="text-xl font-bold">{{referallData.monthlyInvitesQuota}}</p>
            </div>
            <div class="flex justify-between tbody px-24 py-4">
                <p class="text-base font-normal">Total referral invites remaining (unused/unclaimed)</p>
                <p class="text-xl font-bold">{{referallData.monthlyInvitesQuota - referallData.invitesThisMonth}}</p>
            </div>
            <div class="flex justify-between tbody px-24 py-4">
                <p class="text-base font-normal">Total number of completed referrals</p>
                <p class="text-xl font-bold">{{referallData.timesAwarded}}</p>
            </div>
            <div class="flex justify-between tbody px-24 pt-4">
                <p class="text-base font-normal">Total amount of FILMCredits earned</p>
                <span class="flex items-center">
                    <app-icon [icon]="'film'" class="w-20 h-20 mr-4"></app-icon>
                    <p class="text-xl font-bold">{{(referallData?.totalEarned || 0) | filmPrice}}</p>
                </span>
            </div>
        </div>
        <div class="flex flex-col items-center mt-48 social-icons">
            <p class="text-base">Share with Friends</p>
            <div class="flex items-center gap-32 mt-24">
                <img [src]="item.icon" alt=""*ngFor="let item of shareButtons" class="cursor-pointer" (click)="socialShare(item,1)">
            </div>
            <div class="form-group w-full lg:w-1/2 addon-e p-0" *ngIf="referallData">
                <label></label>
                <input type="text" class="pointer-events-none truncate" [(ngModel)]="referallData.referralURL"/>
                <app-icon [icon]="'copy'" class="primary cursor-pointer" (click)="socialShare(null,0)"></app-icon>
            </div>
        </div>
    </div>
    <div class="w-full h-full flex flex-col items-center justify-center absolute top-0 left-0 bg-backdrop" *ngIf="loading">
        <app-local-loader [hasTimeout]="false" class="w-32 h-32"></app-local-loader>
    </div>
</ng-template>
