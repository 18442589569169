import { MediaType } from '../../../../../../app.datatypes';
import { environment } from '../../../../../../../environments';
import { ToasterService } from '../../../../../..//shared/services';

export function base64ToFile(base64Image: string): Blob {
  const split = base64Image.split(',');
  const type = split[0].replace('data:', '').replace(';base64', '');
  const byteString = atob(split[1]);
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i += 1) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], { type });
}

export function validateFile(file: File, toastService: ToasterService): MediaType {
  if (file.type.includes('image')) {
    if (Math.round(file.size / 1024 / 1024) > environment.max_image_size) {
      toastService.openToastr('Upload File Size is greater than 2.5 Mb ', 'FILE SIZE ERROR', 'error', 3000);
      return null;
    }
    return MediaType.IMAGE;
  } else if (file.type.match('video.*')) {
    if (file.size > environment.max_video_size) {
      toastService.openErrorToastr('File too Big. The file size can not exceed 1GB', 'Video upload error');
      return null;
    }
    return MediaType.VIDEO;
  } else {
    if (file.size / 1048576 >= environment.max_pdf_doc_size) {
      toastService.openToastr(
        file.name + ' size is greater than ' + environment.max_pdf_doc_size + ' Mb ',
        'FILE SIZE ERROR',
        'error',
        3000
      );
      return null;
    }
    return MediaType.FILE;
  }
}
