import { NgModule } from '@angular/core';
import { UploadAvatarComponent } from './upload-avatar/upload-avatar.component';
import { DcpCropperComponent } from './cropper';
import { ImageCropperComponent } from './dcp-image-cropper/image-cropper.component';
import { CoverImageCropperComponent } from './cover-image-cropper/cover-image-cropper.component';
import { SharedModule } from '../../../../shared/shared.module';
import { VideoPlayerModule } from '../video-player/video-player.module';

@NgModule({
  declarations: [UploadAvatarComponent, DcpCropperComponent, ImageCropperComponent, CoverImageCropperComponent],
  imports: [SharedModule, VideoPlayerModule],
  exports: [UploadAvatarComponent, CoverImageCropperComponent],
})
export class UploadAvatarModule {}
