import { trigger, transition, query, animateChild, style, animate } from '@angular/animations';
import { Component, DestroyRef, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Proposal, ProcessStatus, RoundProgress, Distribution } from '../../../../app.datatypes';
import { Router } from '@angular/router';
import { ProposalStateService, PaymentStateService, SharedModule } from '../../../../shared';
import { ShareButtonsModule } from '../../../../shared/modules/components/share-button/share-buttons.module';
import { ProposalCommonModule } from '../proposal-common.module';
import { CustomVideoPlayerModule } from '../../../../shared/components/custom-video-player/custom-video-player.module';
import { VideoPlayerModule } from '../../../../shared/modules/components/video-player/video-player.module';
import { VideoConfig } from '../../../../shared/interface/video-config.interface';
import { VideoPlayerComponent } from '../../../../shared/modules/components/video-player/video-player.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { DcpImageComponent } from '../../../../shared/components/dcp-image/dcp-image.component';
import { firstValueFrom, timer } from 'rxjs';

@Component({
  selector: 'app-proposal-round-hover-view',
  templateUrl: './proposal-round-hover-view.component.html',
  styleUrls: ['./proposal-round-hover-view.component.scss'],
  animations: [
    trigger('host', [
      transition(':leave', [query('@box', [animateChild()])]),
      transition(':enter', [query('@box', [animateChild()])]),
    ]),
    trigger('box', [
      transition(':leave', [
        style({
          transform: 'scale(1)',
        }),
        animate(
          '300ms ease-in',
          style({
            transform: 'scale(0)',
          })
        ),
      ]),
      transition(':enter', [
        style({
          transform: 'scale(0.5)',
        }),
        animate(
          '100ms ease-out',
          style({
            transform: 'scale(1)',
          })
        ),
      ]),
    ]),
  ],
  imports: [
    SharedModule,
    ShareButtonsModule,
    ProposalCommonModule,
    CustomVideoPlayerModule,
    VideoPlayerModule,
    DcpImageComponent,
  ],
  standalone: true,
})
export class ProposalRoundHoverViewComponent implements OnInit {
  @ViewChild(VideoPlayerComponent) video: VideoPlayerComponent;
  top = 0;
  left = 0;
  right: boolean;
  bottom: boolean;
  entity: Distribution | Proposal;
  width: number = 480;
  height: number = 270;
  progress: RoundProgress;
  actions: EventEmitter<string> = new EventEmitter();
  muted = localStorage.getItem('muted') || 'yes';
  proposalStatus = ProcessStatus;
  shareButtonClicked = false;
  isDistribution: boolean;
  videoConfig: VideoConfig;

  constructor(
    private sanitizer: DomSanitizer,
    private router: Router,
    private proposalService: ProposalStateService,
    private destroyRef: DestroyRef,
    protected paymentService: PaymentStateService
  ) {}

  ngOnInit(): void {
    this.isDistribution = this.router.url === '/dcp-plus';
    this.getVideo();
    if (!this.isDistribution) {
      this.proposalService
        .getProposal(this.entity._id)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe((entity: Distribution) => {
          this.entity = entity;
          this.getVideo();
        });
    }
  }

  handleVolume(): void {
    this.muted = this.muted === 'yes' ? 'no' : 'yes';
    localStorage.setItem('muted', this.muted);
    this.video.videoPlayer.videoElement.nativeElement.muted = this.muted === 'yes';
  }

  getVideo(): void {
    let videoUrl = null;
    let poster = null;
    if (this.isDistribution) {
      const video = this.entity.teaser;
      videoUrl = video.url + '?ngsw-bypass=true';
      poster = video.thumbnailUrl;
    } else if (this.entity.videos?.length) {
      const video = this.entity.videos[0];
      videoUrl = video.url + '?ngsw-bypass=true';
      poster = video.thumbnailUrl;
      if (video?.videoBlob?.size) {
        videoUrl = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(video.videoBlob));
      }
    }
    this.videoConfig = {
      src: videoUrl,
      poster: poster,
      videoControlOptions: 'nofullscreen noremoteplayback',
      loop: true,
      autoplay: true,
      controls: false,
      classes: 'h-full w-full object-cover',
    };
  }

  videoLoaded(): void {
    firstValueFrom(timer(500)).then(() => {
      this.video.videoPlayer.videoElement.nativeElement.muted = this.muted === 'yes';
    });
  }
}
