<div class="dialog-header">
  <div class="relative">
    <h3>Hey You!</h3>
    <button
      class="w-16 hover:opacity-75 absolute right-0 top-0"
      style="z-index: 10;"
      appTooltip="Cancel"
      placement="bottom"
      (click)="onCancel()"
    >
      <app-icon class="h-16 w-16" [icon]="'close'"></app-icon>
    </button>
  </div>
</div>

<div class="dialog-content">
  <p class="mb-16">Have a project you would like to submit to one of our active Awards? Click below to get started. </p>
  <div class="w-full flex flex-col items-center justify-center h-256" *ngIf="loading">
    <app-local-loader [hasTimeout]="false" class="w-40 h-40"></app-local-loader>
  </div>
  <div class="rounded p-8 border border-gray-border mb-16 cursor-pointer hover:bg-gray-border" (click)="submitProposal(round)" *ngFor="let round of rounds">
    <p class="font-semibold text-lg mb-8">
      {{ round?.title }}
    </p>
    <div class="block sm:flex">
      <div class="flex-1 mb-8 sm:mb-0">
        <p class="uppercase text-xs">Submission Deadline</p>
        <p>{{ round?.submission_deadline_date | date }}</p>
      </div>
      <div class="flex-1 mb-8 sm:mb-0">
        <p class="uppercase text-xs">Submission Fee</p>
        <p>
          <app-icon [icon]="'film'" class="w-16 h-16 mr-4 -mb-2"></app-icon
          >{{ calculateFilmFee(round?.submission_fee) | filmPrice }}
        </p>
      </div>
      <div class="flex-1 mb-8 sm:mb-0">
        <p class="uppercase text-xs">Min Reward Pool</p>
        <p><app-icon [icon]="'film'" class="w-16 h-16 mr-4 -mb-2"></app-icon>{{ round?.minimum_bounty | filmPrice }}</p>
      </div>
      <div class="flex-1 ">
        <p class="uppercase text-xs">Reward</p>
        <p>${{ round?.reward | filmPrice }}</p>
      </div>
    </div>
  </div>
  <p class="text-semibold">Don’t have any FILMCredits? Visit the Buy page and get some today!</p>
</div>
<div class="dialog-footer justify-between items-center">
  <form [formGroup]="showPromptForm">
    <app-checkbox text="Don't show this again" formControlName="stopPrompt" class="mr-16"></app-checkbox>
  </form>
  <div class="flex flex-wrap gap-16">
    <button class="btn btn-secondary" (click)="onBuy()">
      Buy Film
    </button>
    <button class="btn btn-primary" (click)="startTour()">
      Start Tour
    </button>
    <button class="btn btn-primary" (click)="onSubmit()">
      Submit Project
    </button>
  </div>
</div>
