import { Injectable } from '@angular/core';
import { Moderation } from '../../../../app.datatypes';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { ApiService } from '../../../index';
import { environment } from '../../../../../environments/environment';
import { HttpParams } from '@angular/common/http';
import { LoadingService } from '../loading/loading.service';
import { EntityParams } from '../../../interface/entity-type-enum';

interface FilterParams {
  [filter: string]: string
}

@Injectable()
export class ModerationsService {
  private moderationPath = 'api/moderations';
  private moderations$ = new BehaviorSubject<Moderation[]>([])

  private skip = 0;
  private limit = environment.grid_skip_limit;
  private filterParams$ = new BehaviorSubject<FilterParams>({});

  private canFetchMore = true;
  private doFetch$ = new BehaviorSubject<boolean>(true);

  constructor(
    private apiService: ApiService,
    private loadingService: LoadingService
    ) {}

  getPortion(entityParams?: EntityParams): Observable<Moderation[]> {
    return combineLatest([this.doFetch$, this.filterParams$]).pipe(
      switchMap(([_, params]) => this.fetch(params, entityParams))
    )
  }

  loadMore(): void {
    this.doFetch$.next(true);
  }

  get hasNextPage(): boolean {
    return this.canFetchMore;
  }

  setFilterParams(params: FilterParams): void {
    this.resetModerations();
    this.skip = 0;
    this.filterParams$.next(params);
  }

  private resetModerations(): void {
    this.moderations$.next([]);
  }

  private setSkipParam(responseLength: number): void {
    if (responseLength === this.limit) {
      this.skip += responseLength;
    }
    this.canFetchMore = responseLength === this.limit;
  }

  private fetch(filterParams: FilterParams, entityParams: EntityParams): Observable<Moderation[]> {
    const url = entityParams
      ? `${this.moderationPath}/${entityParams.type}/${entityParams.id}`
      : this.moderationPath;

    this.loadingService.changeStatus(true);
    let params = new HttpParams({
      fromObject: {
        limit: this.limit,
        skip: this.skip,
        ...filterParams
      }
    });

    return this.apiService.get(url, { params }).pipe(
      switchMap((moderations: Moderation[]) => {
        this.setSkipParam(moderations?.length);
        const currentModerations = this.moderations$.getValue();
        this.moderations$.next([...currentModerations, ...moderations])

        return this.moderations$.asObservable();
      }),
      tap(() => this.loadingService.changeStatus(false)),
      catchError(error => {
        this.loadingService.changeStatus(false);
        return this.apiService.catchError(error);
      })
    );
  }
}
