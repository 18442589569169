import {ModuleWithProviders, NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import {OperationService} from "./operation/operation.service";
import {TorusService} from "./torus.service";
import {ErrorHandlingPipe} from "./pipes/error-handling.pipe";

@NgModule({
  declarations: [
    ErrorHandlingPipe
  ],
  imports: [
    CommonModule
  ],
})
export class WalletModule {
  static forRoot(): ModuleWithProviders<WalletModule> {
    return {
      ngModule: WalletModule,
      providers: [
        { provide: 'windowObject', useValue: window },
        OperationService,
        TorusService,
        ErrorHandlingPipe
      ]
    }
  }
}
