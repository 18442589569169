import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconModule } from '../../modules/components/icon/icon.module';
import { RoundOverviewComponent } from './round-overview.component';
import { ExpandedInfoModule } from '../expanded-info/expanded-info.module';
import { ModerationsOverviewModule } from '../moderations-overview/moderations-overview.module';
import { CustomVideoPlayerModule } from '../custom-video-player/custom-video-player.module';
import { VideoPlayerModule } from '../../modules/components/video-player/video-player.module';
import { TooltipModule } from '../../modules/directives/tooltip/tooltip.module';
import { ShortenPipeModule } from '../../modules/pipes/shorten-pipe/shorten-pipe.module';
import { DcpImageComponent } from '../dcp-image/dcp-image.component';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [RoundOverviewComponent],
  exports: [RoundOverviewComponent],
  imports: [
    CommonModule,
    IconModule,
    ExpandedInfoModule,
    ModerationsOverviewModule,
    CustomVideoPlayerModule,
    VideoPlayerModule,
    TooltipModule,
    ShortenPipeModule,
    DcpImageComponent,
    RouterModule,
  ],
})
export class RoundOverviewModule {}
