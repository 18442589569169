import { ElementRef, Pipe, PipeTransform } from '@angular/core';

const LARGE = ['large', 'lg', 'l'];
const MEDIUM = ['medium', 'md', 'med', 'm']
const SMALL = ['small', 'sm', 's']

const MDWIDTH = 520;
const LGWIDTH = 1455;

@Pipe({
  name: 'imgSize',
  standalone: true,
})
export class ImgSizePipe implements PipeTransform {
  constructor(private el: ElementRef){};
  transform(value: string, requestedSize: string): string {
    if (!value) {
      return '';
    }

   const width=this.el?.nativeElement?.offsetWidth;
    const originalSRC = value;
    let newSRC = originalSRC;
    let size = requestedSize?.toLowerCase(),
      ext;
    if (LARGE.includes(size) || width >= LGWIDTH) {
      ext = '_lg';
    } else if (MEDIUM.includes(size)|| (width<LGWIDTH)) {
      ext = '_md';
    } else if (SMALL.includes(size) || (width < MDWIDTH)) {
      ext = '_sm';
    }
    if (ext) {
      const pos = originalSRC.lastIndexOf('.');
      newSRC = originalSRC.substring(0, pos) + ext + originalSRC.substring(pos);
    }
    return newSRC;
  }
}
