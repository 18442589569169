<app-logo class="w-logo block mt-16 ml-24 logo-white"></app-logo>
<app-nav class="flex-1 overflow-y-auto flex flex-col"></app-nav>
<button class="show-hide-menu" (click)="openCloseLeftMenu()">
  <span class="animated-arrow right arrow-white" [ngClass]="{ left: isOpen, right: !isOpen }"></span>
</button>
<div class="relative">
  <button class="btn-icon btn-sm btn-white hidden" (click)="showFooter()">
    <app-icon [icon]="'more'" *ngIf="!footerOpen"></app-icon>
    <app-icon [icon]="'close'" *ngIf="footerOpen"></app-icon>
  </button>

  <app-footer [ngClass]="{ show: footerOpen }"></app-footer>
</div>
