import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { map } from 'rxjs/operators';
import { ResponseData, VimeoThumbnailUrl } from '../../.././../src/app/app.datatypes';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class VimeoService {
  private validVimeoOptions = ['thumbnail_small', 'thumbnail_medium', 'thumbnail_large'];
  public urlPattern = 'https://(?:www.)?(vimeo.com)/(.*?)(?:z|$|&)';
  public apiURL = 'https://api.vimeo.com/videos';

  constructor(private http: HttpClient, private sanitizer: DomSanitizer) {}

  public urlValidator(url: string): boolean {
    const regex = new RegExp(this.urlPattern);
    return regex.test(url);
  }

  public getVideoId(url: string): string {
    const urlPartArr = url.split(/^.*(vimeo\.com\/)((channels\/[A-z]+\/)|(groups\/[A-z]+\/videos\/))?([0-9]+)/);
    return urlPartArr[5];
  }

  public getThumbnailUrl(url): Observable<VimeoThumbnailUrl> {
    if (this.urlValidator(url)) {
      const videoId = this.getVideoId(url);
      const thumbUrl = this.apiURL + '/' + videoId;
      return this.http.get(thumbUrl);
    }
  }

  public isEmbeddable(url: string): Observable<boolean> {
    if (this.urlValidator(url)) {
      const metaDataUrl = this.apiURL + '?links=' + url;
      return this.http.get(metaDataUrl).pipe(
        map((metaData: ResponseData) => {
          return metaData?.data[0]?.embed;
        })
      );
    }
  }

  public embed(url: string, autoplay?: boolean) {
    let query = '?';
    if (autoplay) {
      query += 'autoplay=1';
    }

    if (this.urlValidator(url)) {
      const videoId = this.getVideoId(url);
      return this.sanitizer.bypassSecurityTrustHtml(
        '<iframe src="https://player.vimeo.com/video/' +
          videoId +
          query +
          '" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>'
      );
    }
  }
}
