<label class="checkbox">
  <input
    type="checkbox"
    [checked]="isChecked"
    (change)="onChanged($event)"
    (blur)="onBlur($event)"
    [disabled]="disabled"
  />
  <span class="checkmark"></span>
  {{ text }}
</label>
