import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogHolderDirective } from './dialog-holder.directive';
import { DialogContainerComponent } from './dialog-container/dialog-container.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DialogService } from './dialog.service';

@NgModule({
  declarations: [DialogHolderDirective, DialogContainerComponent],
  imports: [CommonModule, BrowserAnimationsModule],
  exports: [DialogContainerComponent, DialogHolderDirective],
  providers: [DialogService],
})
export class DialogModule {}
