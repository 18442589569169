import { Component, inject } from '@angular/core';
import { DialogData } from '../../../../app.datatypes';
import { DialogContext } from '../../../../shared/dialog/dialog-context';

@Component({
  selector: 'app-ios-pwa',
  templateUrl: './ios-pwa.component.html',
})
export class IosPwaComponent {
  dialog = inject(DialogContext<DialogData>);
}
