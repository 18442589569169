import { Component, ComponentRef, Input, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { UntypedFormBuilder, FormGroup, Validators } from '@angular/forms';
import { EmbedVideoService, PaymentStateService } from '../../../../../shared';
import {
  AboutProposalData,
  BountyData,
  PricingFees,
  Proposal,
  ProposalDocsItem,
  ProposalImageItem,
  ProposalRound,
  ProposalVideoItem,
} from '../../../../../app.datatypes';
import { JoyrideService } from 'ngx-joyride';
import { environment } from '../../../../../../environments';
import { ProposalRoundHoverViewComponent } from '../../../proposal-common/proposal-round-hover-view/proposal-round-hover-view.component';

@Component({
  selector: 'app-proposal-create-step-three',
  templateUrl: './proposal-create-step-three.component.html',
  styleUrls: ['./proposal-create-step-three.component.scss'],
})
export class ProposalCreateStepThreeComponent implements OnInit {
  @Input() proposal: Proposal;
  @Input() proposalReview: Proposal;
  @Input() selectedRound: ProposalRound;
  @Input() completed: boolean;
  @Input() days: number;
  @Input() prices: PricingFees;
  @Input() stepTwoData: AboutProposalData;
  @Input() stepThreeData: BountyData;
  @ViewChild('previewWrapper', { read: ViewContainerRef }) previewWrapper: ViewContainerRef;
  stepFourForm: FormGroup;
  itemType = [];
  timeout: ReturnType<typeof setTimeout>;
  submitFeeFilm: number;
  proposalHovered: ComponentRef<ProposalRoundHoverViewComponent>;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private embedService: EmbedVideoService,
    private paymentService: PaymentStateService,
    public readonly joyrideService: JoyrideService
  ) {}

  ngOnInit(): void {
    if (this.joyrideService.isTourInProgress()) {
      this.selectedRound = environment.proposalDemo.proposal_round as any;
    }
    this.submitFeeFilm = this.paymentService.calculateDollarToFilmPricing(this.selectedRound?.submission_fee);
    this.buildForm();
  }

  async buildForm(): Promise<void> {
    this.stepFourForm = await this.formBuilder.group({
      videos: this.proposal.videos
        ? this.formBuilder.array(
            this.proposal.videos.map((video) => {
              return this.createVideoField(video);
            })
          )
        : this.formBuilder.array([]),
      cover_id: [this.proposal.cover_id ? this.proposal.cover_id : null],
      images: this.proposal.images
        ? this.formBuilder.array(
            this.proposal.images.map((imageData) => {
              return this.createImageField(imageData);
            })
          )
        : this.formBuilder.array([]),
      docs: this.proposal.docs
        ? this.formBuilder.array(
            this.proposal.docs.map((doc) => {
              return this.createDocField(doc);
            })
          )
        : this.formBuilder.array([]),
      cover_url: [this.proposal.cover_url ? this.proposal.cover_url : null, Validators.required],
      cover_type: [this.proposal.cover_type ? this.proposal.cover_type : null, Validators.required],
    });
  }

  createVideoField(video: ProposalVideoItem): FormGroup {
    if (video) {
      this.itemType.push({
        item: video,
        type: 'video',
        video: this.embedService.embed(video.url),
      });
      return this.formBuilder.group({
        url: [video.url ? video.url : null],
        mediaTitle: [video.mediaTitle ? video.mediaTitle : null],
        description: [video.description ? video.description : null],
      });
    }
    return this.formBuilder.group({
      url: [null],
      mediaTitle: [null],
      description: [null],
    });
  }

  mouseOver(event): void {
    if (window.innerWidth < 768 || this.proposalHovered) {
      return;
    }
    let proposal = JSON.parse(JSON.stringify(this.proposal));
    if (this.proposalReview?.videos?.length) {
      proposal.videos = this.proposalReview.videos;
    }
    this.timeout = setTimeout(() => {
      const elem = event.target;
      this.proposalHovered = this.previewWrapper.createComponent(ProposalRoundHoverViewComponent);

      this.proposalHovered.instance.entity = proposal;
      this.proposalHovered.instance.right = true;
      this.proposalHovered.instance.progress = { days: this.days, completed: false, percentProgress: 0 };
      this.proposalHovered.instance.top = 128;
      this.proposalHovered.instance.width = elem.offsetWidth + 110;
      this.proposalHovered.instance.height = elem.offsetHeight + 32;
      this.proposalHovered.instance.actions.subscribe((event: string) => {
        this.previewWrapper.clear();
        this.proposalHovered = null;
      });
    }, 500);
  }

  mouseOut(): void {
    clearTimeout(this.timeout);
  }

  createImageField(imageData: ProposalImageItem): FormGroup {
    const dt = new Date();
    if (imageData?.url !== null) {
      this.itemType.push({
        item: imageData,
        type: 'image',
        thumb: imageData.url,
        id: dt.getTime(),
      });
      return this.formBuilder.group({
        url: [imageData.url ? imageData.url : null],
        mediaTitle: [imageData.mediaTitle ? imageData.mediaTitle : null],
        description: [imageData.description ? imageData.description : null],
      });
    }
    return this.formBuilder.group({
      url: [null],
      mediaTitle: [null],
      description: [null],
    });
  }

  createDocField(doc: ProposalDocsItem): FormGroup {
    if (doc?.url !== null) {
      return this.formBuilder.group({
        url: [doc.url ? doc.url : null],
        file_preview_url: [doc.file_preview_url ? doc.file_preview_url : null],
        docTitle: [doc.docTitle ? doc.docTitle : null],
        description: [doc.description ? doc.description : null],
      });
    }
    return this.formBuilder.group({
      url: [null],
      file_preview_url: [null],
      docTitle: [null],
      description: [null],
    });
  }
}
