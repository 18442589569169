<div class="flex justify-between items-center dialog-header">
  <h4 class="font-semibold">Choose which tour to start</h4>
  <button class="btn-dark-blue" (click)="dialog.close()">
    <app-icon [icon]="'close'" class="w-16 h-16 opacity-75 hover:opacity-100"></app-icon>
  </button>
</div>
<div class=" dialog-content">
  <div class="flex  justify-between items-center mb-16 " *ngFor="let tour of tours; let i = index">
    <p>{{ i + 1 }}. {{ tour.label }}</p>
    <button class="btn btn-primary" [disabled]="!tour.steps" (click)="startTour(tour.steps,i, tour.delay)">
      {{ !tour.steps ? 'Coming soon' : 'Start' }}
    </button>
  </div>
</div>
