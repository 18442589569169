<button (click)="close()" class="absolute right-8 top-8">
  <app-icon [icon]="'close'" class="w-12 h-12 white hover:opacity-75"></app-icon>
</button>

<div class="flex">
  <app-icon [icon]="data?.type" class="min-w-24 w-24 h-24 mr-16 white"></app-icon>

  <div class="pr-24 flex-grow">
    <ng-content></ng-content>
  </div>
</div>
