import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { map, take } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { AuthenticatedUserQuery } from '../state/authenticated-user/authenticated-user.query';

@Injectable()
export class LoggedInAuthGuard  {
  constructor(private _router: Router, private authenticatedUserQuery: AuthenticatedUserQuery) {}

  canActivate(): Observable<boolean> {
    return this.authenticatedUserQuery.isLoggedIn$.pipe(
      map(isAuth => {
        if (isAuth) {
          return true;
        }
        return false;
      }),
      take(1)
    );
  }
}
