import { Injectable } from '@angular/core';

declare const gtag: any;

@Injectable({ providedIn: 'root' })
export class AnalyticsService {
  trackEvent(eventUrl: string): void {
    gtag('event', eventUrl, { send_to: 'AW-327002040/oLGdCPj6l84YELjP9psB' });
  }
}
