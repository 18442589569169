import { Component, DestroyRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import BaloonEditor from '@ckeditor/ckeditor5-build-balloon';
import {
  Country,
  Distribution,
  DistributionDemography,
  Genre,
  Language,
  MediaType,
  MediaTypes,
  MpaRating,
  RoyaltyMap,
  SaveProposalMediaList,
  Video,
} from '../../../../app.datatypes';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthenticatedUser, ProposalStateService, ToasterService, UserService } from '../../../../shared';
import { forkJoin } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { SafeHtml, SafeUrl } from '@angular/platform-browser';
import { CroppedImage } from '../../../../shared/modules/components/image-cropper/cover-image-cropper/cover-image-cropper.component';
import { validateFile } from '../../../../shared/modules/components/image-cropper/dcp-image-cropper/utils/blob.utils';

@Component({
  selector: 'app-distribution-create-step-one',
  templateUrl: './distribution-create-step-one.component.html',
  styleUrls: ['./distribution-create-step-one.component.scss'],
})
export class DistributionCreateStepOneComponent implements OnInit {
  @Input() distribution: Distribution;
  languageList: Language[];
  mediaType = MediaType;
  genreList: Genre[];
  mpaRatingList: MpaRating[];
  coverId: string;
  uploadedCroppedCoverImage: string;
  @Output() setData = new EventEmitter<Distribution>();
  teaser: Video;
  videoData: Video;
  stepForm: FormGroup;
  @Input() user: AuthenticatedUser;
  isCoverUploaded: boolean;
  mediaList = [];
  distributionType: number;
  isOpenAdditionalModal = false;
  Editor = BaloonEditor;
  mediaTypes = MediaTypes;
  isOpenModal = false;
  uploadType: string;
  mode: string;
  imageUploaded: string | SafeUrl;
  fileUploaded: string;
  videoUploaded: string | SafeHtml;
  isDirectVideoUpload = false;
  modalTitle: string;
  modalValue: string;
  modalDescription: string;
  videoBlob: Blob;
  imageToUploadBlob: Blob;
  index: number;
  selectedMediaFile: any;
  selectedLanguages: Language[] | String[] = [];
  selectedGeneres: Genre[] | String[] = [];
  demoGraphy: DistributionDemography;

  ckConfig = {
    placeholder: 'Type the description here!',
    toolbar: ['Heading', 'Bold', 'Italic', 'BulletedList', 'NumberedList', 'Blockquote', 'Outdent', 'Indent'],
  };
  countryList: Country[];
  royaltyMaps: RoyaltyMap[] = [];
  is_film_supported = false;
  is_usdc_supported = false;
  wallet_address_usdc: string;
  constructor(
    private proposalStateService: ProposalStateService,
    private formBuilder: FormBuilder,
    private destroyref: DestroyRef,
    private toastService: ToasterService,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    forkJoin([
      this.proposalStateService.getGenres(),
      this.proposalStateService.getLanguages(),
      this.userService.getCountries(),
      this.proposalStateService.getMpaRatings(),
    ])
      .pipe(takeUntilDestroyed(this.destroyref))
      .subscribe((results: [Genre[], Language[], Country[], MpaRating[]]) => {
        this.genreList = results[0];
        this.languageList = results[1];
        this.countryList = results[2];
        this.mpaRatingList = Object.values(results[3]);
        const naIndex = this.genreList.findIndex((item) => item.slug === 'N/A');
        this.genreList.unshift(this.genreList.splice(naIndex, 1)[0]);
      });
    this.initData();
  }

  initData(): void {
    this.selectedLanguages = this.distribution?.languages || [];
    this.selectedGeneres = this.distribution?.genres || [];
    this.stepForm = this.formBuilder.group({
      title: [this.distribution?.title, [Validators.required, Validators.maxLength(191)]],
      content: [this.distribution?.log_line, [Validators.required, Validators.maxLength(500)]],
      summary: [this.distribution?.description, Validators.required],
      genres: [this.distribution?.genres?.map((value) => value.slug), Validators.required],
      languages: [this.distribution?.languages?.map((value) => value.iso), Validators.required],
      genreAdd: null,
      teaser: [this.distribution?.teaser],
      video_data: [this.distribution?.video_data],
      access_type: [this.distribution?.access_type],
      mpa_rating: [this.distribution?.mpa_rating, Validators.required],
      review_discount_percentage: [this.distribution.review_discount_percentage],
      reward_contribution: [this.distribution.reward_contribution],
      review_reward_percentage: [this.distribution.review_reward_percentage],
      affiliate_marketing: [this.distribution?.affiliate_marketing],
      affiliate_marketing_percentage: [this.distribution?.affiliate_marketing_percentage, Validators.max(100)],
    });

    if (this.distribution?.cover_url?.length) {
      this.isCoverUploaded = true;
    }

    this.teaser = this.distribution?.teaser;
    this.videoData = this.distribution?.video_data;
    const pay = ['rental_price', 'purchase_price'];
    const free = ['reward_contribution', 'review_reward_percentage'];
    [...pay, ...free].forEach((control: string) => {
      this.stepForm.addControl(control, new FormControl());
    });
    free.forEach((control: string) => {
      this.stepForm.get(control).setValue(0);
    });
    if (this.distribution) {
      [...pay].forEach((control: string) => {
        this.stepForm.get(control).setValue(this.distribution[control]);
      });
    }
    this.buildMedia();
    this.stepForm.get('access_type').valueChanges.subscribe((value: string) => {
      if (value === 'pay-to-watch') {
        pay.forEach((val: string) => {
          this.stepForm.get(val).setValidators(Validators.required);
          this.stepForm.get(val).updateValueAndValidity();
        });
      } else {
        pay.forEach((val: string) => {
          this.stepForm.get(val).removeValidators(Validators.required);
          this.stepForm.get(val).setValue(null);
          this.stepForm.get(val).updateValueAndValidity();
        });
      }
      this.validateFormData();
    });
    this.stepForm.get('access_type').setValue('pay-to-watch');
  }

  buildMedia(): void {
    if (this.distribution) {
      if (this.distribution?.cover_url) {
        this.uploadedCroppedCoverImage = this.distribution?.cover_url;
        this.isCoverUploaded = true;
      }
      // Reset mediaList when rebuilding the list to prevent duplicated entries.
      this.mediaList = [];
      let index = 1;
      this.distribution?.videos?.forEach((video) => {
        const data = {
          index: index + 1,
          type: MediaType.VIDEO,
          value: video?.url,
          title: video?.mediaTitle,
          description: video?.description,
          videoData: video?.url,
          thumbnailUrl: video?.thumbnailUrl,
          isDirectVideoUpload: video?.isDirectVideoUpload,
          videoBlob: video?.videoBlob,
          file_id: video?.file_id,
        };
        index = index + 1;
        this.mediaList.push(data);
      });
      this.distribution?.images?.forEach((image) => {
        const data = {
          index: index + 1,
          type: MediaType.IMAGE,
          value: image?.url,
          title: image?.mediaTitle,
          description: image?.description,
        };
        index = index + 1;
        this.mediaList.push(data);
      });
      this.distribution?.docs?.forEach((doc) => {
        const data = {
          index: index + 1,
          type: MediaType.FILE,
          value: doc?.url,
          title: doc?.docTitle,
          description: doc?.description,
          fileData: doc?.file_preview_url,
        };
        index = index + 1;
        this.mediaList.push(data);
      });
    }
  }

  coverUploaded(event: CroppedImage): void {
    this.isCoverUploaded = true;
    this.coverId = event.id;
    this.uploadedCroppedCoverImage = event.url;
    this.validateFormData();
  }

  validateFormData(): void {
    let data = this.stepForm.value;
    data.log_line = data.content;
    data.description = data.summary;
    data.royalty_map = this.royaltyMaps;
    data.is_usdc_supported = this.is_usdc_supported;
    data.is_film_supported = this.is_film_supported;
    data.wallet_address_usdc = this.wallet_address_usdc;
    const isValid = {
      isStepOneValid:
        this.stepForm.valid &&
        this.isCoverUploaded &&
        ((data.is_film_supported && this.royaltyMaps?.length) || (data.is_usdc_supported && data.wallet_address_usdc)),
    };
    const mediaData = {
      isCoverUploaded: this.isCoverUploaded,
      coverId: this.coverId,
      cover_url: this.uploadedCroppedCoverImage,
      cover_type: MediaType.IMAGE,
    };
    if (this.mediaList?.length && isValid) {
      data.videos = [];
      data.images = [];
      data.docs = [];
      for (const item of this.mediaList) {
        if (item?.type === MediaType.VIDEO) {
          const videoData = {
            url: item?.value,
            mediaTitle: item?.title,
            description: item?.description,
            thumbnailUrl: item?.thumbnailUrl,
            isDirectVideoUpload: item?.isDirectVideoUpload,
            file_id: item?.file_id,
            videoBlob: item?.videoBlob,
          };
          data.videos.push(videoData);
        }
        if (item?.type === MediaType.IMAGE) {
          const imageData = {
            url: item?.value,
            mediaTitle: item?.title,
            description: item?.description,
          };
          data.images.push(imageData);
        }
        if (item?.type === MediaType.FILE) {
          const fileData = {
            url: item?.value,
            file_preview_url: item?.fileData,
            docTitle: item?.title,
            description: item?.description,
          };
          data.docs.push(fileData);
        }
      }
    }
    data.demography = this.demoGraphy;
    data = { ...data, ...isValid, ...mediaData };
    this.setData.emit(data);
  }

  editDistribution(data: Video): void {
    this.uploadType = 'video';
    this.mode = 'edit';
    this.videoUploaded = data?.url;
    this.isDirectVideoUpload = true;
    this.modalTitle = data?.mediaTitle;
    this.modalDescription = data?.description;
    this.videoBlob = data?.videoBlob;
    this.isOpenModal = true;
  }

  openAdditionalMedia(): void {
    this.isOpenAdditionalModal = true;
  }

  cancelModal(): void {
    this.isOpenAdditionalModal = false;
    this.isOpenModal = false;
    this.distributionType = 0;
    this.selectedMediaFile = null;
  }

  openModal(ev?: Event, type?: string): void {
    const files = (<HTMLInputElement>ev?.target)?.files;
    if (files) {
      this.selectedMediaFile = files[0];
    }
    if (!type && files) {
      type = validateFile(files[0], this.toastService);
      if (!type) {
        return;
      }
    }

    this.uploadType = type;
    this.mode = 'add';
    this.imageUploaded = '';
    this.fileUploaded = '';
    this.videoUploaded = '';
    this.isDirectVideoUpload = false;
    this.modalTitle = '';
    this.modalValue = '';
    this.videoBlob = null;
    this.modalDescription = '';
    this.isOpenModal = true;
  }

  saveMedia(data: SaveProposalMediaList): void {
    if (this.distributionType) {
      const videoData = {
        url: data.value,
        mediaTitle: data.title,
        description: data.description,
        thumbnailUrl: data.thumbnailUrl,
        file_id: data.file_id,
        videoBlob: data.videoBlob,
      };

      if (this.distributionType === 1) {
        this.teaser = videoData;
        this.stepForm.get('teaser').setValue(videoData);
      } else {
        this.videoData = videoData;
        this.stepForm.get('video_data').setValue(videoData);
      }
    } else {
      if (data?.index > 0) {
        for (let i = 0; i < this.mediaList?.length; i++) {
          if (this.mediaList[i]?.index === data?.index) {
            this.mediaList[i].value = data?.value;
            this.mediaList[i].title = data?.title;
            this.mediaList[i].description = data?.description;
            this.mediaList[i].fileData = data?.fileData;
            this.mediaList[i].videoData = data?.videoData;
            this.mediaList[i].thumbnailUrl = data?.thumbnailUrl;
            this.mediaList[i].file_id = data?.file_id;
            this.mediaList[i].isDirectVideoUpload = data?.isDirectVideoUpload;
            this.mediaList[i].videoBlob = data?.videoBlob;
            break;
          }
        }
      } else {
        const index = this.mediaList.length;
        data.index = index + 1;
        this.mediaList.push(data);
      }
    }
    this.distributionType = null;
    this.validateFormData();
    this.isOpenModal = false;
  }

  deleteDisribution(teaser: boolean): void {
    if (teaser) {
      this.teaser = null;
      this.stepForm.get('teaser').setValue(null);
    } else {
      this.videoData = null;
      this.stepForm.get('video_data').setValue(null);
    }
    this.validateFormData();
  }

  deleteMedia(index: number): void {
    if (index) {
      this.mediaList = this.mediaList.filter((x) => x?.index !== index);
      this.validateFormData();
    }
  }

  editMedia(data: SaveProposalMediaList): void {
    if (data) {
      this.uploadType = data?.type;
      this.mode = 'edit';
      this.index = data?.index;
      this.imageUploaded = data?.value;
      this.fileUploaded = data?.fileData;
      this.videoUploaded = data?.videoData;
      this.isDirectVideoUpload = data?.isDirectVideoUpload;
      this.modalTitle = data?.title;
      this.modalDescription = data?.description;
      this.videoBlob = data?.videoBlob;
      this.isOpenModal = true;
    }
  }

  selectGenre(evt): void {
    const result: Genre[] = [];
    if (evt.length) {
      for (const item of evt) {
        result.push(item.slug);
      }
    }
    this.stepForm.controls['genres'].setValue(result);
    this.validateFormData();
  }

  selectLanguage(evt): void {
    const result = [];
    if (evt.length) {
      for (const item of evt) {
        result.push(item.iso);
      }
    }
    this.stepForm.controls['languages'].setValue(result);
    this.validateFormData();
  }

  onCountrySelected(evt): void {
    const countries = [];
    for (const item of evt) {
      countries.push(item['iso_2']);
    }
    this.demoGraphy = { country_exclude: countries };
    this.validateFormData();
  }

  selectMpaRating(evt): void {
    this.stepForm.controls['mpa_rating'].setValue(evt);
    this.validateFormData();
  }

  setRoyaltyMaps(distribution: Partial<Distribution>): void {
    this.royaltyMaps = distribution.royalty_map;
    this.is_usdc_supported = distribution.is_usdc_supported;
    this.is_film_supported = distribution.is_film_supported;
    this.wallet_address_usdc = distribution.wallet_address_usdc;
    this.validateFormData();
  }
}
