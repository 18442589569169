import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VideoPlayerComponent } from './video-player.component';
import { DropdownMenuModule } from '../dropdown-menu/dropdown-menu.module';
import { IconModule } from '../icon/icon.module';
import { CustomVideoPlayerModule } from "../../../components/custom-video-player/custom-video-player.module";



@NgModule({
  declarations: [VideoPlayerComponent],
  imports: [
    CommonModule, IconModule, DropdownMenuModule, CustomVideoPlayerModule
  ],
  exports:[VideoPlayerComponent]
})
export class VideoPlayerModule { }
