import { Component, OnInit, Output, EventEmitter, ElementRef, Input } from '@angular/core';
import { Observable, Subject, throwError } from 'rxjs';
import { ProgressService, UserService, AuthenticatedUser, SharedService, ProgressRef } from '../../shared';
import { catchError, switchMap, takeUntil, tap } from 'rxjs/operators';
import { itemsAnimation, notificationsIn } from '../../app.animations';
import { UserNotification } from '../../app.datatypes';

@Component({
  selector: 'app-inbox-sidebar',
  templateUrl: './inbox-sidebar.component.html',
  styleUrls: ['./inbox-sidebar.component.scss'],
  animations: [notificationsIn, itemsAnimation],
})
export class InboxSidebarComponent implements OnInit {
  @Input() user: AuthenticatedUser;
  @Output() showInboxBar = new EventEmitter();
  @Output() readMsg = new EventEmitter();

  private ngUnsubscribe: Subject<void> = new Subject<void>();

  userNotifications: UserNotification[] = [];
  progress: ProgressRef;
  skip = 0;
  isMoreAvailable = true;
  notificationCount: number;

  constructor(
    private userService: UserService,
    private progressService: ProgressService,
    private elRef: ElementRef,
    private sharedService: SharedService
  ) {
  }

  ngOnInit(): void {
    this.notificationCount = this.user.notifications_count;
    this.getNotifications().subscribe();
  }

  getNotifications(): Observable<UserNotification[]> {
    this.progress = this.progressService.showSpinner(this.progress, this.elRef);
    return this.userService.getUserNotifications(this.skip).pipe(
      catchError(error => {
        this.progressService.hideSpinner(this.progress);
        return throwError(() => error);
      }),
      tap((result: UserNotification[]) => {
        this.progressService.hideSpinner(this.progress);
        const oldData = this.userNotifications;

        if (result.length > 0) {
          result.forEach(notification => {
            oldData.push(notification);
          });
          this.userNotifications = oldData;
          this.skip = this.skip + result.length;

          if (result.length < 10) {
            this.isMoreAvailable = false;
          }
        } else {
          this.isMoreAvailable = false;
        }
      }),
      takeUntil(this.ngUnsubscribe)
    );
  }

  loadMore(): void {
    if (this.isMoreAvailable) {
      this.getNotifications().subscribe();
    }
  }

  readNotification(id: string): void {
    this.userService.postReadUserNotification(id).subscribe(
      result => this.handleNotificationUpdateSuccess(result),
      error => this.handleNotificationUpdateError(error)
    );
  }

  handleNotificationUpdateSuccess(result: UserNotification): void {
    const notificationIndex = this.userNotifications.findIndex(n => n._id === result._id);
    this.userNotifications[notificationIndex] = result;
    this.readMsg.emit();
    this.sharedService.setUpdateNotificationCount(true, false);
    --this.notificationCount;
  }

  handleNotificationUpdateError(error: string): void {
    throw new Error(error);
  }

  showInboxBarFn(): void {
    this.showInboxBar.emit();
  }

  onMarkAllRead(): void {
    this.userService.readAllUserNotification().pipe(
      switchMap((result: UserNotification[]) => {
        if (result) {
          this.userNotifications = [];
          this.skip = 0;
          this.notificationCount = 0;
          this.sharedService.setUpdateNotificationCount(true, true);
          return this.getNotifications();
        }
      })
    ).subscribe();
  }
}
