import { ElementRef, EventEmitter, Injectable, signal } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class VideoPlayerService {
  videos = signal([]);
  videoDuration$: Subject<string> = new Subject();
  resolutionChange = new EventEmitter<number>();
  addVideoPlayer(videoPlayer: ElementRef): void {
    const videos = [...this.videos()];
    videos.push(videoPlayer);
    this.videos.set(videos);
  }

  removeVideoPlayer(player: ElementRef): void {
    const videos = [...this.videos()];
    this.videos.set(videos.filter((el) => el !== player));
  }
}
