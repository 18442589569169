import { Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appInputNumber]',
})
export class InputNumberDirective {
  parentElement: any; // any is okay here.
  currentElement: any; // any is okay here.

  constructor(private elRef: ElementRef, private renderer: Renderer2) {}

  @HostListener('wheel') onWheel() {
    return false;
  }

  @HostListener('focus') onFocus() {
    this.setElement();
    this.onFocusInput();
  }

  @HostListener('blur') onBlur() {
    this.setElement();
    this.onBlurInput();
  }

  @HostListener('keyup') onKeyup() {
    this.setElement();
    this.onKeyupInput();
  }

  setElement() {
    this.currentElement = this.elRef.nativeElement;
    this.parentElement = this.elRef.nativeElement.parentElement;
  }

  onFocusInput() {
    if (
      this.currentElement.classList.contains('ng-invalid') &&
      this.currentElement.classList.contains('ng-untouched')
    ) {
      this.renderer.addClass(this.parentElement, 'focus');
    }
    if (this.currentElement.classList.contains('ng-invalid') && this.currentElement.classList.contains('ng-touched')) {
      this.renderer.addClass(this.parentElement, 'error');
    }
    if (this.currentElement.classList.contains('ng-valid')) {
      this.renderer.addClass(this.parentElement, 'focus');
    }
  }

  onBlurInput() {
    this.renderer.removeClass(this.parentElement, 'focus');

    if (this.currentElement.classList.contains('ng-invalid')) {
      this.renderer.addClass(this.parentElement, 'error');
    }
    if (this.currentElement.classList.contains('ng-valid')) {
      this.renderer.removeClass(this.parentElement, 'error');
    }
  }

  onKeyupInput() {
    if (this.currentElement.classList.contains('ng-invalid')) {
      this.renderer.removeClass(this.parentElement, 'focus');
      this.renderer.addClass(this.parentElement, 'error');
    }
    if (this.currentElement.classList.contains('ng-valid')) {
      this.renderer.removeClass(this.parentElement, 'error');
      this.renderer.addClass(this.parentElement, 'focus');
    }
  }
}
