<div class="dialog-header">{{ title }}</div>
<div class="dialog-content">
  <p>
    {{ message }}
  </p>
</div>
<div class="dialog-footer">
  <button class="btn btn-secondary mr-16" (click)="onCancel()">
    Cancel
  </button>
  <button class="btn btn-primary" (click)="onRedirect()">
    {{ button }}
  </button>
</div>
