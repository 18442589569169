import { Component, Output, TemplateRef, ViewChild, EventEmitter, Input, OnInit } from '@angular/core';
import { DropdownPanel } from './dropdown-panel';

@Component({
  selector: 'app-dropdown-menu',
  templateUrl: './dropdown-menu.component.html',
  styleUrls: ['./dropdown-menu.component.scss'],
})
export class DropdownMenuComponent<T> implements DropdownPanel<T> {
  @Input() size = '';
  @ViewChild(TemplateRef) templateRef: TemplateRef<T>;
  @Output() closed = new EventEmitter<void>();
}
