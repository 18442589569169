import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilmPricePipe } from './film-price.pipe';



@NgModule({
  declarations: [FilmPricePipe],
  imports: [
    CommonModule
  ],
  exports:[FilmPricePipe]
})
export class FilmpriceModule { }
