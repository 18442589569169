import { Component } from '@angular/core';
import { JoyrideService } from 'ngx-joyride';
import { GlobalTourService } from '../../shared/services/global-tour-service.service';
import { DialogData } from '../../app.datatypes';
import { DialogContext } from '../../shared/dialog/dialog-context';
import { Router } from '@angular/router';

@Component({
  selector: 'app-tour-modal',
  templateUrl: './tour-modal.component.html',
  styleUrls: ['./tour-modal.component.scss'],
})
export class TourModalComponent {
  constructor(
    private readonly joyrideService: JoyrideService,
    public dialog: DialogContext<DialogData>,
    private globalTourService: GlobalTourService,
    private router: Router
  ) {}
  tours = [
    {
      label: 'Proposal Creation',
      steps: [
        'proposalcreate1',
        'proposalcreate3',
        'proposalcreate4',
        'proposalcreate5',
        'proposalcreate6',
        'proposalcreate9',
        'proposalcreate10',
        'proposalcreatenext3',
        'proposalcreate11',
        'proposalcreatenext4',
        'proposalcreatefinish',
      ],
      delay: 200,
    },
    {
      label: 'Creative Query Creation',
      steps: ['cq1', 'cq2', 'cq3', 'cq4', 'cq5', 'cq6', 'cq7', 'cq8', 'cq9', 'cq10'],
    },
    {
      label: 'Engaging with a Creative Query',
      steps: ['cqe1', 'cqe2@creative-query', 'cqe3', 'cqe4', 'cqe5', 'cqe8', 'cqe9'],
    },
    {
      label: 'Review, Evaluations & Liking Reviews',
      steps: [
        'po1',
        'po2',
        'po3',
        'po4',
        'po5@proposal-details',
        'po6',
        'po7',
        'po8',
        'po9',
        'po12',
        'po10',
        'po11',
        'po13',
        'po15',
        'po16_0',
      ],
    },
    {
      label: 'Proposal Round Overview',
      steps: ['pro1', 'pro2', 'pro3_0', 'pro4_0', 'pro5', 'pro6', 'pro7', 'pro8', 'pro9'],
    },
  ];

  startTour(steps: string[], index: number, delay: number = 400) {
    if (index === 0 || index === 1) {
      this.globalTourService.tourAction.emit('openAddMenu');
    }
    this.joyrideService
      .startTour({
        steps: steps,
        waitingTime: delay,
        logsEnabled: true,
        showPrevButton: false,
      })
      .subscribe(
        (_step) => {
          /*Do something*/
        },
        (_error) => {
          /*handle error*/
        },
        () => {
          this.globalTourService.tourClosed.emit(true);
          this.router.navigateByUrl('/academy');
        }
      );
    this.dialog.close();
  }
}
