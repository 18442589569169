import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, DestroyRef, ElementRef, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { throwError } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { Distribution, UserBadges } from '../../../app.datatypes';
import { UserTypes } from '../../enumerations';
import { AuthenticatedUser, AuthenticatedUserQuery } from '../../state';
import { ProgressRef, UserService, ProgressService } from '../../services';
import { DistributionService } from '../../services/distribution.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-public-profile',
  templateUrl: './public-profile.component.html',
  styleUrls: ['./public-profile.component.scss'],
  animations: [
    trigger('fadeInOut', [
      state('in', style({ opacity: 100 })),
      transition('* => void', [animate(300, style({ opacity: 0 }))]),
    ]),
  ],
})
export class PublicProfileComponent implements OnInit {
  user: AuthenticatedUser;
  isOwner = false;

  isEmailVerified: boolean;
  // Spinner variables
  progress: ProgressRef;
  badges: UserBadges[] = [];
  distributions: Distribution[];

  constructor(
    private userService: UserService,
    public authenticatedUserQuery: AuthenticatedUserQuery,
    private route: ActivatedRoute,
    private progressService: ProgressService,
    private elRef: ElementRef,
    private readonly distributionService: DistributionService,
    private readonly destroyRef: DestroyRef,
    private readonly router: Router
  ) {}

  ngOnInit(): void {
    this.route.params
      .pipe(
        switchMap((params) => {
          if (!this.user && params['id']) {
            this.progress = this.progressService.showSpinner(this.progress, this.elRef);
            return this.userService.getUserProfile(params['id']).pipe(
              tap((user) => {
                this.user = user;
                if (this.user?.badges && this.user?.badges.length > 0) {
                  this.badges = this.user.badges;
                }
                this.progressService.hideSpinner(this.progress);
                this.isOwner = this.authenticatedUserQuery.isOwner(this.user._id);
              }),
              catchError((error) => {
                this.progressService.hideSpinner(this.progress);
                return throwError(() => error);
              })
            );
          } else if (!this.user) {
            return this.authenticatedUserQuery.authenticatedUser$.pipe(
              tap((user) => {
                this.user = user;
                this.isOwner = this.authenticatedUserQuery.isOwner(this.user._id);
              })
            );
          } else {
            this.isOwner = this.authenticatedUserQuery.isOwner(this.user._id);
          }
        }),
        switchMap((user) => {
          return this.distributionService.getStarredDistributions(user._id).pipe(
            tap((distributions) => {
              this.distributions = distributions;
            })
          );
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();
  }

  get role(): string {
    switch (this.user.user_type_id) {
      case UserTypes.MODERATOR:
        return 'moderator';
      case UserTypes.ADMINISTRATOR:
        return 'administrator';
      default:
        return '';
    }
  }

  redirectTo(id: string, isAffiliate: boolean): void {
    let url = `/dcp-plus/${id}`;
    if (isAffiliate) {
      url += `a?=${this.user._id}`;
    }

    this.router.navigateByUrl(url);
  }
}
