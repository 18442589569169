<div class="rounded w-full h-auto overflow-hidden relative">
  <app-video-player [videoConfig]="videoConfig" class="block" *ngIf="videoConfig"></app-video-player>
  <ng-container *ngIf="!videoConfig">
    <div
      class="flex flex-col justify-center items-center h-full w-full absolute top-0 z-50 infinite-loading-msg cursor-pointer hover:bg-backdrop-dark transition-all duration-300"
      (click)="startStreaming()"
      [ngClass]="{ 'bg-backdrop-dark ': streamLoading }"
    >
      <ng-container *ngIf="streamLoading">
        <app-local-loader class="md:w-[3vw] md:h-[3vw]" color="white" [hasTimeout]="false"></app-local-loader>
        <p class="text-white mt-16 font-semibold text-lg">Please wait, your video is loading…</p>
      </ng-container>
      <img src="assets/icons/icons/play.svg" class="md:w-[4vw] md:h-[4vw]" alt="play" *ngIf="!streamLoading" />
    </div>
    <img [src]="distribution.cover_url" class="w-full h-full" alt="cover" />
  </ng-container>
</div>
