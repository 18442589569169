import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { environment } from '../../../environments';

export const LimitedWatchGuard: CanActivateFn = () => {
  const router = inject(Router);
  if (environment.limited_watch_functionality) {
    router.navigateByUrl('');
  }
  return true;
};
