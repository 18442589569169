import { Injectable } from '@angular/core';
import { EMPTY, Observable } from 'rxjs';
import { catchError, tap, switchMap, map } from 'rxjs/operators';
import { applyTransaction, ID } from '@datorama/akita';
import { ReviewStore } from './review.store';
import {
  Review,
  SubmitReviewRequest,
  SubmitLikeRequest,
  ReviewData,
  VideoDistributionReview,
} from '../../../app.datatypes';
import { environment } from '../../../../environments';
import { ApiService } from '../../services';
import { AuthenticatedUserService } from '../authenticated-user';
import { ProposalQuery, ProposalStore, ProposalStateService } from '../proposal';
import { ReviewQuery } from './review.query';
import { DistributionService } from '../../services/distribution.service';

@Injectable({ providedIn: 'root' })
export class ReviewStateService {
  constructor(
    private reviewStore: ReviewStore,
    private reviewQuery: ReviewQuery,
    private proposalStateService: ProposalStateService,
    private apiService: ApiService,
    private authenticatedUserService: AuthenticatedUserService,
    private proposalQuery: ProposalQuery,
    private readonly proposalStore: ProposalStore,
    private readonly distributionService: DistributionService
  ) {}

  handleError(error: any) {
    this?.reviewStore?.setError(error);
    this?.reviewStore?.setLoading(false);
    return this?.apiService.catchError(error) || EMPTY;
  }

  loadReview(id: string): Observable<Review> {
    this.reviewStore.setLoading(true);
    return this.apiService.get(`api/reviews/${id}`).pipe(
      tap((review: Review) => {
        this.reviewStore.setLoading(false);
        this.reviewStore.upsert(id, review);
      }),
      catchError(this.handleError.bind(this))
    );
  }

  getProposalReviews(proposalId: ID | string, skip: number): Observable<Review[]> {
    this.reviewStore.setLoading(true);
    const api = `api/proposals/${proposalId}/reviews?limit=${environment.review_skip_limit}&skip=${skip}&field=newest`;
    return this.apiService.get(api).pipe(
      tap(() => {
        this.reviewStore.setLoading(false);
      }),
      catchError(this.handleError.bind(this))
    );
  }

  getDistributionReviews(id: ID | string, skip = 0): Observable<Review[]> {
    this.reviewStore.setLoading(true);
    const api = `api/distributions/${id}/reviews?limit=${environment.review_skip_limit}&skip=${skip}&field=newest`;
    return this.apiService.get(api).pipe(
      tap(() => {
        this.reviewStore.setLoading(false);
      }),
      catchError(this.handleError.bind(this))
    );
  }

  postDistributionReview(review: ReviewData): Observable<VideoDistributionReview> {
    return this.apiService.post('api/distributions/reviews/', review).pipe(
      tap(() => {
        applyTransaction(() => {
          this.reviewStore.setLoading(false);
        });
      }),
      catchError(this.handleError.bind(this))
    );
  }

  postReview(review: ReviewData): Observable<Review> {
    this.reviewStore.setLoading(true);
    return this.apiService.post('api/reviews/', review).pipe(
      tap((response: Review) => {
        applyTransaction(() => {
          this.reviewStore.setLoading(false);
          this.reviewStore.add(response, { prepend: true });
          this.updateScores(response);
        });
      }),
      catchError(this.handleError.bind(this))
    );
  }

  putReview(review: ReviewData): Observable<Review> {
    this.reviewStore.setLoading(true);
    let url = 'api/reviews/';

    return this.apiService.put(url + review._id, review).pipe(
      tap((response: Review) => {
        this.reviewStore.setLoading(false);
        response.status = 'to-be-moderated';
        this.reviewStore.update(review._id, response);
        this.updateScores(response);
      }),
      catchError(this.handleError.bind(this))
    );
  }

  cancel(id: string): Observable<Review> {
    this.reviewStore.setLoading(true);
    return this.apiService.get('api/reviews/' + id + '/cancel').pipe(
      tap((response: Review) => {
        this.reviewStore.setLoading(false);
        response.status = 'cancelled';
        this.reviewStore.update(id, response);
      }),
      catchError(this.handleError.bind(this))
    );
  }

  setStatus(id: string, status: string): void {
    this.reviewStore.update(id, { status });
  }

  setLockedData(id: string, locked_by: any, locked_at: any): void {
    this.reviewStore.update(id, { locked_by, locked_at });
  }

  submitReview(req: SubmitReviewRequest, isDistribution = false): Observable<Review> {
    let url = 'api/reviews/';
    if (isDistribution) {
      url = 'api/distributions/reviews/';
    }
    this.reviewStore.setLoading(true);
    return this.apiService
      .post(url + req.reviewId + '/submit', {
        sig: req.signature,
        nonce: req.nonce,
        pk: req.pk,
      })
      .pipe(
        tap((response: Review) => {
          applyTransaction(() => {
            this.reviewStore.setLoading(false);
            if (!isDistribution) {
              this.reviewStore.update(req.reviewId, response);
              this.updateScores(response);
              this.proposalStateService.setOnOwnReviewStatusChange(response.proposal_id, response.status);
            } else {
              this.distributionService.updateReviewStatus(response.video_distribution_id, response.status);
            }
          });
        }),
        catchError(this.handleError.bind(this))
      );
  }

  updateReview(id: string, data: Partial<Review>): void {
    this.reviewStore.update(id, data);
  }

  updateScores(reviewResponse: Review) {
    this.proposalStateService.updateScores(reviewResponse.proposal_id, reviewResponse);
  }

  /** ==============================================================================
   * ========================== Review moderation related ==========================
   * ==============================================================================*/

  updateSkip(scroll: number): void {
    this.reviewStore.update({
      skip: this.reviewStore.getValue().skip + environment.grid_skip_limit,
      scroll,
    });
  }

  getAuthenticatedUserStateReviews(userid, search = ''): Observable<Review[]> {
    this.reviewStore.setLoading(true);
    const value = this.reviewQuery.getValue();
    let apiUrl = 'api/reviews/user-reviews/';
    if (userid) {
      apiUrl += userid + '/';
    }
    apiUrl += '?&skip=' + value.skip + (search ? '&search=' + encodeURIComponent(search) : '');
    return this.apiService.get(apiUrl).pipe(
      tap((reviews: Review[]) => {
        this.reviewStore.add(reviews);
        this.reviewStore.setLoading(false);
        this.reviewStore.update({ skip: value.skip + reviews.length });
        if (reviews.length < 20) {
          this.reviewStore.update({ apiEndReached: true });
        }
        this.reviewStore.setHasCache(true);
      }),
      catchError(this.handleError.bind(this))
    );
  }

  getAllToBeModerated(isQuiz: boolean, isDistribution = false): Observable<Review[]> {
    this.reviewStore.setLoading(true);
    let api = 'api/reviews/moderate';
    if (isQuiz) {
      api = 'api/quizzes/question/moderate';
    } else if (isDistribution) {
      api = 'api/distributions/reviews/moderate';
    }
    api =
      api +
      '?limit=' +
      environment.grid_skip_limit +
      '&skip=' +
      this.reviewStore.getValue().moderationReviewSkip +
      (this.reviewStore.getValue().moderationFilters.unlockedOnly ? '&unlocked=true' : '');
    return this.apiService.get(api).pipe(
      tap((reviews: Review[]) => {
        this.reviewStore.add(reviews);
        this.reviewStore.setLoading(false);
        if (reviews.length < environment.grid_skip_limit) {
          this.reviewStore.update({ moderationQueryApiEndReached: true });
        }
        this.reviewStore.update({ moderationQueryLoaded: true });
      }),
      catchError(this.handleError.bind(this))
    );
  }

  lockReview(reviewId: string, isDistribution = false): Observable<Review> {
    this.reviewStore.setLoading(true);
    let api = `api/reviews/${reviewId}/lock`;
    if (isDistribution) {
      api = `api/distributions/reviews/${reviewId}/lock`;
    }
    return this.apiService.put(api, null).pipe(
      tap((response: Review) => {
        this.reviewStore.setLoading(false);
        this.authenticatedUserService.setModeratedEntity({ entity: 'review', id: reviewId });
        this.reviewStore.update(reviewId, response);
      })
    );
  }

  unlockReview(reviewId: string, isDistribution = false): Observable<Review> {
    this.reviewStore.setLoading(true);
    let api = `api/reviews/${reviewId}/unlock`;
    if (isDistribution) {
      api = `api/distributions/reviews/${reviewId}/unlock`;
    }
    return this.apiService.put(api, null).pipe(
      tap((response: Review) => {
        response.locked_at = null;
        response.locked_by = null;
        this.reviewStore.setLoading(false);
        this.reviewStore.update(reviewId, response);
      })
    );
  }

  updateModerationFilter(filter: any, isQuiz: boolean, isDistribution = false): void {
    applyTransaction(() => {
      this.reviewStore.remove(this.getAllToBeModerated(isQuiz, isDistribution));
      this.reviewStore.update({
        moderationFilters: { unlockedOnly: filter.unlockedOnly },
        moderationReviewSkip: 0,
      });
      this.reviewStore.setLoading(false);
    });
  }

  setModerationReviewActive(id: string): void {
    this.reviewStore.update({ moderationReviewActive: id });
  }

  reviewModerated(id: string, status: string): void {
    this.reviewStore.update(id, { status, to_be_moderated: false });
    this.authenticatedUserService.setUnlocked();
  }

  getReview(id: string): Observable<any> {
    return this.reviewQuery.selectEntity(id).pipe(
      switchMap((review) => {
        if (!review) {
          return this.apiService.get('api/reviews/' + id);
        } else {
          return this.reviewQuery.selectEntity(id);
        }
      }),
      catchError((error) => {
        return this.apiService.catchError(error);
      })
    );
  }

  // Moved from Proposal-state-service
  updateReviewSkip(id, scroll) {
    if (id && this.proposalQuery.getEntity(id) && !this.proposalQuery.getEntity(id).reviews_api_reached) {
      const review_skip = this.proposalQuery.getEntity(id).reviews_skip
        ? this.proposalQuery.getEntity(id).reviews_skip
        : 0;
      this.proposalStore.update(id, (entity) => ({
        reviews_skip: review_skip + environment.grid_skip_limit,
        reviews_scroll: scroll,
      }));
    }
  }

  likeReview(reviewId: any, is_like: boolean): Observable<any> {
    return this.apiService.post(`api/reviews/${reviewId}/like`, { is_like }).pipe(
      map((response) => {
        return {
          totalVotes: response.newEntityTotalVotes,
          reviews_score: response.newEntityTotalReviews,
          evaluations_score: response.newEntityTotalEvaluations,
          likes_score: response.newEntityTotalLikes,
          reviewsArray: response.reviewsArray,
          sign: response.sign,
        };
      })
    );
  }

  likeDistributionReview(reviewId: any, is_like: boolean): Observable<any> {
    return this.apiService.post(`api/distributions/reviews/${reviewId}/like`, {
      is_like,
    });
  }

  submitLikeReview(req: SubmitLikeRequest): Observable<any> {
    const request = {
      sig: req.signature,
      nonce: req.nonce,
      pk: req.pk,
      is_like: req.is_like ? 1 : 0,
    };
    return this.apiService.post(`api/reviews/${req.reviewId}/like/${req.likeId}/submit`, request);
  }

  setEditable(id: string): Observable<Review> {
    return this.apiService.put(`api/reviews/${id}/set-editable`, null);
  }
}
