<div class="days" *ngIf="progress" [ngClass]="{'reverted': reverted}">
    <span *ngIf="!progress.completed" class="flex items-center">
        <div class="days-progress_circle">
            <svg class="progress-ring-out" width="34" height="34">
                <circle class="progress-ring__circle_out" stroke-width="4" fill="transparent" r="15" cx="17" cy="17" />
            </svg>
            <svg  class="progress-ring" width="34" height="34" #proposalId>
                <circle class="progress-ring__circle" stroke-width="4" fill="transparent" r="15" cx="17" cy="17" [ngStyle]="{'stroke-dasharray': strokeDasharray,'stroke-dashoffset':strokeDashoffset}" />
            </svg>
            <span >{{ (progress.days>365?progress.days/365:progress.days)| number: '1.0-0' }}</span>
        </div>
        <p class="text-sm whitespace-nowrap ml-8">{{progress.days>365?'years':progress.type }} to go</p>
    </span>
    <span *ngIf="completed" class="font-bold">
    Finished {{ progress.days }} {{ progress.type}} Ago
    </span>
</div>