import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Distribution, Proposal, ProposalImageItem, ProposalVideoItem } from '../../app.datatypes';
import { VideoConfig } from '../interface/video-config.interface';

interface VideoOptions {
  url: string;
  blob: Blob;
  thumbnailUrl: string;
  converted?: any;
  failed?: any;
  resolutions?: any;
  videoId?: string;
  provider?: string;
  title: string;
  description: string;
}

@Injectable()
export class GalleryService {
  #galleryData$ = new BehaviorSubject<VideoConfig[]>([]);
  #proposal$ = new BehaviorSubject<Proposal | Distribution>(null);
  #imageScaled = new BehaviorSubject<boolean>(null);
  addImageToGallery(url: string, title = null, description = null): void {
    this.#galleryData$.getValue().push({
      type: 'image',
      src: url,
      title: title,
      description: description,
    });
    this.#galleryData$.next([...this.#galleryData$.getValue()]);
  }

  resetGallery(): void {
    this.#galleryData$.next([]);
  }

  getGallery(): Observable<VideoConfig[]> {
    return this.#galleryData$.asObservable();
  }

  setImageScaled(scaled: boolean) {
    this.#imageScaled.next(scaled);
  }

  getImageScaled(): Observable<boolean> {
    return this.#imageScaled.asObservable();
  }

  setProposal(proposal: Proposal | Distribution): void {
    this.#proposal$.next(proposal);
    this.#initGallery();
  }

  getProposal(): Proposal | Distribution {
    return this.#proposal$.getValue();
  }

  addS3VideoToGallery(options: VideoOptions): void {
    const { url, blob, thumbnailUrl, resolutions, title, description } = options;
    this.#galleryData$.getValue().push({
      type: 'video',
      poster: thumbnailUrl,
      src: url,
      resolutions: resolutions,
      title: title,
      description: description,
      videoBlob: blob,
    });
    this.#galleryData$.next([...this.#galleryData$.getValue()]);
  }

  #initGallery(): void {
    this.resetGallery();
    // Add cover image to gallery
    const teaser = this.#proposal$.getValue()?.teaser;
    if (teaser) {
      this.addS3VideoToGallery({
        url: teaser.url.toString(),
        blob: teaser.videoBlob,
        thumbnailUrl: teaser.thumbnailUrl,
        converted: teaser.converted,
        failed: false,
        resolutions: teaser.resolutions,
        title: teaser.mediaTitle,
        description: teaser.description,
      });
    }

    // Generate embedded code
    this.#proposal$.getValue()?.videos?.forEach((video: ProposalVideoItem) => {
      if (video.isDirectVideoUpload) {
        this.addS3VideoToGallery({
          url: video.url,
          blob: video.videoBlob,
          thumbnailUrl: video.thumbnailUrl,
          converted: video.converted,
          failed: video.failed,
          resolutions: video.resolutions,
          title: video.mediaTitle,
          description: video.description,
        });
      }
    });

    this.addImageToGallery(this.#proposal$.getValue()?.cover_url || '/assets/img/no-img.svg');

    // generate image gallery
    this.#proposal$.getValue()?.images?.forEach((img: ProposalImageItem) => {
      // Exclude cover image from attachments
      if (img.url.toString() === this.#proposal$.getValue().cover_url) {
        return;
      }
      this.addImageToGallery(img.url, img.mediaTitle, img.description);
    });
  }
}
