import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Toastr } from '../toastr';

@Component({
  selector: 'app-link-toastr',
  templateUrl: './link-toastr.component.html'
})
export class LinkToastrComponent implements OnInit {
  @Input() toast: Toastr;
  @Input() i: number;
  @Output() remove = new EventEmitter<number>();
  @Output() setOffset = new EventEmitter<number>();
  constructor(private router: Router) {}

  ngOnInit(): void {
    setTimeout(() => {
      this.setOffset.emit(this.i);
    });
  }

  onRedirect() {
    this.router.navigate(['/' + this.toast?.link], { queryParams: { type: this.toast?.linkType } });
  }

  removeToastr(event) {
    this.remove.emit(event);
  }
}
