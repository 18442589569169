<app-toastr-template
  class="toast toast-{{ toast.type }}"
  [style.bottom.px]="i * 100"
  [data]="toast"
  [index]="i"
  (remove)="removeToastr($event)"
>
  <p class="text-sm" *ngIf="toast?.message">{{ toast?.message }}</p>
  <div class="action" *ngIf="toast?.action">
    <p class="m-0 text-sm" *ngIf="toast?.action">{{ toast?.action }}</p>
  </div>
  <div class="flex justify-end mt-16">
    <button class="btn btn-sm btn-white" (click)="onRelaod()">
      Reload Now
    </button>
  </div>
</app-toastr-template>
