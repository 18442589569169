import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, catchError, combineLatest, map, of, switchMap, tap } from 'rxjs';
import {
  Distribution,
  DistributionEarning,
  ProcessStatus,
  PurchaseRequest,
  RoyaltyMap,
  SelectedData,
  SubmitProposalRequest,
  DistributionAffiliatedEarning,
  ProposalRound,
  DistributionList,
} from '../../app.datatypes';
import { DistributionQuery, DistributionStore } from '../state/distribution';
import { ApiService } from './api.service';
import { environment } from '../../../environments';
import { applyTransaction } from '@datorama/akita';
import { AuthenticatedUserService } from '../state';
import { HorizontalSliderService } from './new/horizontal-slider.service';

@Injectable({
  providedIn: 'root',
})
export class DistributionService {
  public createDistributionSubject$ = new Subject<string>();
  distributionList$ = new BehaviorSubject<DistributionList[]>([
    {
      title: 'animation',
      skip: 0,
    },
  ]);
  constructor(
    private readonly distributionStore: DistributionStore,
    private readonly apiService: ApiService,
    private readonly distributionQuery: DistributionQuery,
    private readonly authenticatedUserService: AuthenticatedUserService,
    private readonly horizontalSliderService: HorizontalSliderService
  ) {}

  handleError(error: any) {
    this.distributionStore?.setError(error);
    this.distributionStore?.setLoading(false);
    return this.apiService?.catchError(error);
  }

  getDistributionEarnings(): Observable<DistributionAffiliatedEarning[]> {
    this.distributionStore.setLoading(true);
    return this.apiService.get('api/distributions/affiliated-earnings').pipe(
      map((res) => {
        this.distributionStore.setLoading(false);
        return Object.values(res);
      })
    );
  }

  getAllDistributions(): Observable<Distribution[]> {
    let hasCache = this.distributionQuery.getHasCache();
    const filterValue = this.distributionQuery.getValue();
    if (!hasCache && !filterValue.error) {
      this.distributionStore.setLoading(true);
      this.distributionStore.update({ loaded: false });
      /** To do: Refactor when akita store will be removed completely */
      return this.apiService
        .get(
          `api/distributions?&limit=${environment.grid_skip_limit}&direction=desc&skip=${
            this.distributionQuery.getValue().skip
          }`
        )
        .pipe(
          tap((distributions) => {
            applyTransaction(() => {
              this.distributionStore.add(distributions);
              this.distributionStore.setHasCache(true);
              this.distributionStore.update({ skip: filterValue.skip + distributions.length });
              if (distributions.length < environment.grid_skip_limit) {
                this.distributionStore.update({ apiEndReached: true });
              }
              this.distributionStore.setLoading(false);
              this.distributionStore.update({ loaded: true });
            });
          }),
          catchError(this.handleError.bind(this))
        );
    } else {
      return of(this.distributionQuery.getAll());
    }
  }

  getDistributionsByGenre(entity: DistributionList, datalimit?: number): Observable<Distribution[]> {
    const limit = this.horizontalSliderService.limit;
    if (!datalimit) {
      datalimit = limit.child;
    }

    const skip = entity.skip ?? 0;
    /** To do: Refactor when akita store will be removed completely */
    return this.apiService
      .get(`api/distributions?genre=${entity.title}&limit=${datalimit}&skip=${skip}&direction=desc`)
      .pipe(
        tap((distributions) => {
          const pagination = this.horizontalSliderService.handlePaginationProperties(
            distributions.length + skip,
            entity.page ?? 1,
            entity.leftPositionSlider ?? 0
          );
          entity.skip = skip + distributions.length;
          Object.assign(entity, pagination);
          const updatedValue = this.distributionList$.getValue().map((item) => {
            if (item.title === entity.title) {
              return entity; // Update the property
            }
            return item;
          });
          this.distributionList$.next(updatedValue);

          this.distributionStore.add(distributions);
        }),
        catchError(this.handleError.bind(this))
      );
  }

  unlockModeration(): void {
    this.authenticatedUserService.setUnlocked();
  }

  getDistribution(id: string): Observable<Distribution> {
    let query = this.distributionQuery.selectEntity(id);
    return query.pipe(
      switchMap((distribution: Distribution) => {
        if (!distribution?.complete_data_fetched) {
          return this.apiService.get(`api/distributions/${id}`).pipe(
            tap((distribution) => {
              distribution.complete_data_fetched = true;
              this.distributionStore.upsert(id, distribution);
            })
          );
        } else {
          return of(distribution);
        }
      }),
      catchError((error) => {
        return this.apiService.catchError(error);
      })
    );
  }

  unlockDistribution(id: string): Observable<Distribution> {
    this.distributionStore.setLoading(true);
    return this.apiService.put('api/distributions/' + id + '/unlock', null).pipe(
      tap((distribution) => {
        distribution.locked_at = undefined;
        distribution.locked_by = undefined;
        this.distributionStore.upsert(distribution._id, distribution);
        this.distributionStore.setLoading(false);
      }),
      catchError(this.handleError.bind(this))
    );
  }

  removeDistribution(id: string): Observable<Distribution> {
    this.distributionStore.setLoading(true);
    return this.apiService.delete(`api/distributions/${id}/remove`).pipe(
      tap(() => {
        this.distributionStore.remove(id);
        this.distributionStore.setLoading(false);
      }),
      catchError(this.handleError.bind(this))
    );
  }

  distributionModerated(distribution: Distribution): void {
    this.distributionStore.update(distribution._id, distribution);
  }

  unsetActiveDistribution(): void {
    this.distributionStore.update({ moderationProposalActive: null, locked_at: undefined, locked_by: undefined });
  }

  getAllModerationDistribution(): Observable<Distribution[]> {
    this.distributionStore.setLoading(true);
    const skip = this.distributionQuery.getValue().moderationSkip;
    const api = 'api/distributions/moderate?&limit=' + environment.grid_skip_limit + '&skip=' + skip;
    return this.apiService.get(api).pipe(
      tap((distributions) => {
        applyTransaction(() => {
          this.distributionStore.add(distributions);
          this.distributionStore.setLoading(false);
          this.distributionStore.update({
            moderationDistributionApiEndReached: distributions.length < environment.grid_skip_limit,
            moderationSkip: distributions.length,
          });
        });
      }),
      catchError(this.handleError.bind(this))
    );
  }

  setActive(id: string): void {
    this.distributionStore.setActive(id);
  }

  getUserDistribution(isPurchasedDistribution: boolean, filter?: SelectedData): Observable<Distribution[]> {
    let hasCache = this.distributionQuery.getHasCache();
    if (!hasCache) {
      const direction = 'desc';
      let skip: number;
      this.distributionStore.setLoading(true);
      let url = 'api/distributions/by-user?';
      if (isPurchasedDistribution) {
        url = 'api/distributions/purchased?';
        skip = this.distributionQuery.getValue().purchasedDistributionSkip;
      } else {
        skip = this.distributionQuery.getValue().userDistributionSkip;
      }
      let api = url + 'limit=20&direction=' + direction + '&skip=' + skip;

      if (filter?.status) {
        api += '&status=' + encodeURIComponent(filter.status);
      }

      if (filter?.sortBy) {
        api += '&field=' + filter.sortBy;
      }

      return this.apiService.get(api).pipe(
        tap((distributions) => {
          this.distributionStore.add(distributions);
          if (isPurchasedDistribution) {
            this.distributionStore.update({
              purchasedDistributionSkip: distributions.length + skip,
            });
          } else {
            this.distributionStore.update({
              userApiEndReached: distributions.length < 20,
              userDistributionSkip: distributions.length + skip,
            });
          }

          this.distributionStore.setLoading(false);
          this.distributionStore.setHasCache(true);
        }),
        catchError((error) => this.apiService.catchError(error))
      );
    } else {
      return this.distributionQuery.selectAll();
    }
  }

  reset(): void {
    this.distributionStore.reset();
  }

  updateCache() {
    this.distributionStore.setHasCache(false);
  }

  rentBuyDistribution(
    id: string,
    is_usdc: boolean,
    purchaseType: string,
    is_external_checkout: boolean,
    affiliate_user = null
  ) {
    return this.apiService
      .post('api/distributions/purchase', {
        is_usdc,
        reference_id: id,
        purchase_type: purchaseType,
        is_external_checkout: is_external_checkout,
        affiliate_user,
      })
      .pipe(catchError(this.handleError.bind(this)));
  }

  submitPurchase(req: PurchaseRequest) {
    return this.apiService.post(`api/distributions/purchase/${req._id}/submit`, {
      sig: req.signature,
      nonce: req.nonce,
      pk: req.pk,
    });
  }

  saveDistributionAsDraft(request: Distribution): Observable<Distribution> {
    this.distributionStore.setLoading(true);
    return this._upsertProposalState(this.apiService.post(`api/distributions/`, request));
  }

  updateDistributionDraft(id: string, request: Distribution): Observable<Distribution> {
    this.distributionStore.setLoading(true);
    return this._upsertProposalState(this.apiService.put(`api/distributions/${id}`, request));
  }

  private _upsertProposalState(observable: Observable<Distribution>): Observable<Distribution> {
    return observable.pipe(
      tap((distribution: Distribution) => {
        this.distributionStore.upsert(distribution._id, distribution);
        this.distributionStore.setLoading(false);
      }),
      catchError(this.handleError.bind(this))
    );
  }

  prepareForSubmit(id: string, request: Distribution, resubmit = false): Observable<Distribution> {
    this.distributionStore.setLoading(true);
    let api = `api/distributions/prepare-for-submit`;
    if (resubmit) {
      api = `api/distributions/${id}/prepare-for-resubmit`;
    }
    return this._upsertProposalState(resubmit ? this.apiService.put(api, request) : this.apiService.post(api, request));
  }

  submitDistribution(req: SubmitProposalRequest, resubmit = false): Observable<Distribution> {
    this.distributionStore.setLoading(true);
    let api = `api/distributions/${req.proposalId}`;
    if (resubmit) {
      api = api + '/resubmit';
    } else {
      api = api + '/submit';
    }
    const payload = {
      sig: req.signature,
      nonce: req.nonce,
      pk: req.pk,
    };
    return this._upsertProposalState(resubmit ? this.apiService.put(api, payload) : this.apiService.post(api, payload));
  }

  getVideoStream(id: string, res: number): Observable<string> {
    return this.apiService.get(`api/distributions/${id}/play?res=${res}`);
  }

  updateStatusRoyaltyMap(id: string, status: ProcessStatus, is_updating_royalty_map: boolean): void {
    this.distributionStore.update(id, { status, is_updating_royalty_map });
  }

  update(distribution: Distribution): void {
    this.distributionStore.update(distribution._id, { ...distribution });
  }

  updateStared(isStared: boolean, id: string): void {
    this.distributionStore.update(id, { is_stared: isStared });
  }

  updateReviewStatus(id: string, reviewStatus: string): void {
    this.distributionStore.update(id, { has_reviewed_video_distribution: true, own_review_status: reviewStatus });
  }

  toggleFeature(id): Observable<Distribution> {
    this.distributionStore.setLoading(true);
    return this.apiService.put(`api/distributions/set-featured/${id}`, null).pipe(tap((res) => this.update(res)));
  }

  startPayout(id: string, partial_amount: number, isUsdcPayout: boolean) {
    let url = `api/distributions/${id}/payout`;
    if (isUsdcPayout) {
      url = `api/distributions/${id}/payout-usdc`;
    }
    return this.apiService
      .post(url, partial_amount ? { partial_amount } : null)
      .pipe(tap((distribution: Distribution) => this.update(distribution)));
  }

  lockProposal(id: string): Observable<Distribution> {
    this.distributionStore.setLoading(true);
    const proposal = this._upsertProposalState(this.apiService.put(`api/distributions/${id}/lock`, null));
    this.authenticatedUserService.setModeratedEntity({ entity: 'distribution', id: id });
    return proposal;
  }

  getSortedDistributions(): Observable<Distribution[]> {
    this.distributionStore.setLoading(true);

    return combineLatest([
      this.apiService.get('api/distributions/featured'),
      this.apiService.get('api/distributions/continue-watching'),
    ]).pipe(
      tap(([featured, watched]) => {
        this.distributionStore.add(featured);
        const listDistributions = this.distributionList$.getValue();
        const pagination = this.horizontalSliderService.handlePaginationProperties(watched.length);
        if (watched.length) {
          this.distributionStore.add(watched);
          listDistributions.unshift({
            title: 'Continue Watching',
            skip: watched.length,
            ...pagination,
          });
        }
        listDistributions.push({
          title: 'N/A',
          skip: 0,
          ...pagination,
        });
        this.distributionList$.next(listDistributions);
        this.distributionStore.setLoading(false);
      })
    );
  }

  getPastEarnings(id: string): Observable<DistributionEarning[]> {
    this.distributionStore.setLoading(true);
    return this.apiService.get(`api/distributions/${id}/earnings`).pipe(
      tap(() => {
        this.distributionStore.setLoading(false);
      }),
      catchError(this.handleError.bind(this))
    );
  }

  updateRoyaltyMap(
    id: string,
    royalty_map: RoyaltyMap[],
    is_film_supported: boolean,
    is_usdc_supported: boolean
  ): Observable<Distribution> {
    this.distributionStore.setLoading(true);
    return this.apiService
      .post(`api/distributions/${id}/update-royalty-map`, { royalty_map, is_usdc_supported, is_film_supported })
      .pipe(
        tap(() => {
          this.distributionStore.setLoading(false);
        }),
        catchError(this.handleError.bind(this))
      );
  }

  cancelDistribution(distribution): Observable<Distribution> {
    this.distributionStore.setLoading(true);
    return this.apiService.get(`api/distributions/${distribution._id}/cancel`).pipe(
      tap(() => {
        applyTransaction(() => {
          if (distribution.status === 'approved') {
            const skip = this.distributionQuery.getValue().skip - 1;

            if (this.distributionQuery.getActive() && this.distributionQuery.getActive()._id === distribution._id) {
              this.distributionStore.setActive(null);
            }
            this.distributionStore.update({ skip, userProposalApiEndReached: false });
          } else if (distribution.status === 'to-be-moderated') {
            const moderationProposalSkip = this.distributionQuery.getValue().moderationProposalSkip - 1;

            if (this.distributionQuery.getValue().moderationProposalActive === distribution._id) {
              this.distributionStore.update({
                moderationProposalSkip,
                moderationProposalActive: null,
                userProposalApiEndReached: false,
              });
            } else {
              this.distributionStore.update({
                moderationProposalSkip,
                userProposalApiEndReached: false,
              });
            }
          }
          this.distributionStore.update(distribution._id, { status: 'cancelled' });
          this.distributionStore.setLoading(false);
        });
      }),
      catchError((error) => {
        this.distributionStore.setLoading(false);
        this.distributionStore.setError(error);
        return this.apiService.catchError(error);
      })
    );
  }

  handlePlayBackTime(id: string, play_duration: number): void {
    this.apiService
      .put(`api/distributions/${id}/set-video-duration`, {
        play_duration,
      })
      .pipe(catchError(this.handleError.bind(this)))
      .subscribe();
  }

  toggleStar(id: string): Observable<Distribution> {
    return this.apiService
      .put(`api/distributions/toggle-star`, {
        _id: id,
      })
      .pipe(catchError(this.handleError.bind(this)));
  }

  getStarredDistributions(userId: string): Observable<Distribution[]> {
    return this.apiService.get(`api/distributions/stared/${userId}`).pipe(catchError(this.handleError.bind(this)));
  }
}
