import { NgModule } from '@angular/core';
import { LoginPageComponent } from './login-page.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { LogoModule } from '../../shared/modules/components/logo/logo.module';
import { RouterModule } from '@angular/router';
import { FlagPickerModule } from '../../shared/modules/components/flag-picker/flag-picker.module';
import { OnlyNumberDirective } from '../../shared/modules/directives/only-number/only-number.directive';
import { CheckboxModule } from '../../shared/modules/components/checkbox/checkbox.module';
import { PersonalDetailUpdateDialogComponent } from './personal-detail-update-dialog/personal-detail-update-dialog.component';
import { SharedModule, UnAuthGuardService } from '../../shared';
import { DcpSelectModule } from '../../shared/modules/components/dcp-select/dcp-select.module';
import { MultiselectModule } from '../../shared/modules/components/dcp-multiselect/multiselect.module';

@NgModule({
  declarations: [LoginPageComponent, LoginComponent, RegisterComponent, PersonalDetailUpdateDialogComponent],
  imports: [
    SharedModule,
    LogoModule,
    FlagPickerModule,
    OnlyNumberDirective,
    CheckboxModule,
    DcpSelectModule,
    MultiselectModule,
    RouterModule.forChild([
      {
        path: 'login',
        component: LoginPageComponent,
        canActivate: [UnAuthGuardService],
        data: {
          title: 'DCP - Login ',
          description: 'Login',
          ogUrl: 'http://localhost/',
        },
      },
    ]),
  ],
  exports: [LoginComponent, RegisterComponent],
})
export class AuthModule {}
