<div class="h-full flex items-center w-full" (click)="$event.stopPropagation()">
  <button class="btn-icon mr-24 show-hide-menu" (click)="openCloseLeftMenu()">
    <app-icon [icon]="'menu'" class="left-menu-open"></app-icon>
    <app-icon [icon]="'close'" class="left-menu-close"></app-icon>
  </button>
  <ng-template #doneButton>
    <button class="btn btn-primary">Done</button>
  </ng-template>
  <ng-template #prevButton>
    <button class="btn btn-secondary mr-8">Prev</button>
  </ng-template>
  <ng-template #nextButton>
    <button class="btn btn-primary">Next</button>
  </ng-template>

  <div class="md:flex items-center dropdown" [appDropdownMenu]="dropdown" placement="start" #add>
    <button class="btn btn-success min-w-0" id="topbar-create">Submit</button>

    <app-dropdown-menu #dropdown class="drop-menu right" [@dropdown]>
      <div>
        <button
          #createProposalButton
          *ngIf="showProposal"
          (click)="createProposalForRound()"
          class="menu-button"
          id="createProposal"
          [prevTemplate]="prevButton"
          [nextTemplate]="nextButton"
          [doneTemplate]="doneButton"
          joyrideStep="proposalcreate1"
          title="Create Project"
          text="To submit a project, click Project."
          (next)="createProposalButton.click()"
          stepPosition="right"
        >
          <app-icon [icon]="'proposal'" class="w-16 h-16 mr-8 -mb-4"></app-icon>
          Project
        </button>
        <button *ngIf="showWatch" (click)="createDistribution()" class="menu-button" stepPosition="right">
          <app-icon [icon]="'play'" class="w-16 h-16 mr-8 -mb-4"></app-icon>
          To DCP+
        </button>
        <button
          #createCQButton
          *ngIf="showCq"
          (click)="createQuery()"
          class="menu-button"
          id="createQuery"
          joyrideStep="cq1"
          title="Create Creative Query"
          text="To submit a Creative Query, click 'Create Creative Query"
          (next)="createCQButton.click()"
        >
          <app-icon [icon]="'bulb'" class="w-16 h-16 mr-8 -mb-4"></app-icon>
          Creative Query
        </button>
      </div>
    </app-dropdown-menu>
  </div>

  <a
    class="btn-icon btn-chat relative ml-24"
    id="chat-button"
    *ngIf="user && user._id && authenticatedUserQuery.isRegistrationComplete"
    appTooltip="Open Chat"
    placement="bottom"
    [href]="chatUrl"
    target="_blank"
  >
    <app-icon [icon]="'chat'"></app-icon>
  </a>
</div>
<app-logo class="w-logo logo-white" [ngClass]="{ 'unauth-logo': !user }"></app-logo>
<ng-container *ngIf="!user">
  <button class="btn btn-primary" [routerLink]="['/login']" routerLinkActive="router-link-active">
    Register/Login
  </button>
</ng-container>
<app-user-avatar
  *ngIf="user && user._id"
  [avatar_url]="user.avatar_url"
  class="mobile-user min-w-40"
  (click)="openCloseUserMenu()"
></app-user-avatar>
<button class="btn-icon mr-24 user-hide-menu" (click)="openCloseUserMenu()">
  <app-icon [icon]="'close'"></app-icon>
</button>
<div class="flex h-full pl-16 desktop-menu ml-auto" *ngIf="user && user._id">
  <app-topbar-menu [user]="user" (showInboxBar)="showInboxBarFn()" class="ml-auto"></app-topbar-menu>
  <div class="md:flex h-full items-center dropdown" [appDropdownMenu]="dropdown">
    <app-user-avatar class="w-40 h-40" [avatar_url]="user.avatar_url"></app-user-avatar>

    <app-dropdown-menu #dropdown class="drop-menu" (mouseleave)="initMenu()" [@dropdown]>
      <app-user-menu
        [themePanelOpen]="themePanelOpen"
        (toggleThemePanelBtn)="toggleThemePanel()"
        (logout)="onLogout()"
        (changeTheme)="toggleTheme()"
        (changeScale)="toggleScale()"
      >
      </app-user-menu>
    </app-dropdown-menu>
  </div>
</div>
