<div appDialogHolder></div>
<div
  class="flex min-h-full h-full w-full relative overlfow-hidden bg-sidebar"
  #root="dir"
  #app_wrapper
  dir="ltr"
  id="app-wrapper"
  [ngClass]="{ 'user-menu-hide': showInfoBox }"
  [@slideInLeft]
>
  <app-sidebar
    *ngIf="checkRoute()"
    [user]="user"
    [dark]="user && user.is_dark_theme"
    class="flex flex-col w-sidebar"
  ></app-sidebar>
  <main
    [ngClass]="{
      'rounded-tr-xl rounded-br-xl notifications': showInfoBox,
      'h-full relative bg-body rounded-tl-xl rounded-bl-xl transition duration-300': checkRoute(),
      'full-screen-component': handleFullScreen()
    }"
    [@panelIn]
  >
    <app-topbar
      *ngIf="checkRoute()"
      [user]="user"
      [dark]="user?.is_dark_theme"
      (logout)="logout()"
      (createCQ)="createCreativeQueryFN(user)"
      (changeTheme)="toggleTheme()"
      (changeScale)="toggleScale()"
      (showInboxBar)="showInboxBarFn()"
      (mobileMenuClosed)="initMenu()"
    >
    </app-topbar>
    <section
      [ngClass]="{ 'router-wrapper dcp-light-theme h-full': checkRoute(), 'full-screen-component': handleFullScreen() }"
    >
      <router-outlet></router-outlet>
    </section>
  </main>
  <app-inbox-sidebar
    *ngIf="user !== undefined && user._id && showInfoBox === true"
    [user]="user"
    (showInboxBar)="showInboxBarFn()"
    [ngClass]="{ show: showInfoBox }"
    [@notificationsIn]
  ></app-inbox-sidebar>
  <app-user-menu
    [userDetails]="true"
    (logout)="logout()"
    (changeTheme)="toggleTheme()"
    (changeScale)="toggleScale()"
    [themePanelOpen]="themePanelOpen"
    (toggleThemePanelBtn)="toggleThemePanel()"
    (showInboxBar)="showInboxBarFn()"
    class="mobile-right-menu p-16"
    *ngIf="user !== undefined && user._id"
  ></app-user-menu>
</div>

<app-proposal-create
  [createMode]="true"
  [selectedRoundId]="selectedProposalRound"
  *ngIf="isCreatingProposal"
  [ngClass]="{ hidden: !isCreatingProposal && !isCreatingCQ }"
  (closeCreateProposalSidebar)="closeCreateProposalSidebar()"
></app-proposal-create>

<app-distribution-create *ngIf="isCreatingDistribution" (closeCreateSidebar)="isCreatingDistribution = false">
</app-distribution-create>

<app-query-create
  *ngIf="isCreatingCQ"
  [ngClass]="{ hidden: !isCreatingProposal && !isCreatingCQ }"
  (closeCreateCQSidebar)="closeCreativeQuerySidebar()"
></app-query-create>

<app-login
  *ngIf="unAuthAction && unAuthAction.action !== 'verify' && unAuthAction?.action !== 'register'"
  [slug]="unAuthAction.slug"
></app-login>

<app-register
  *ngIf="unAuthAction?.action === 'verify' || unAuthAction?.action === 'register'"
  [slug]="unAuthAction?.slug"
></app-register>
<app-dcp-modal id="inactivity-modal-1" style="display: none">
  <div class="fulscreen on" (click)="refreshPage()">
    <div class="text-center info-box danger flex-col" *ngIf="!timedOut && timeoutSeconds > 0" [@slideInRight]>
      Your session is about to expire in <br />
      <p style="font-size: 40px">{{ timeoutSeconds }}</p>
      seconds.
      <br />
      Please resume activity to prevent <br />losing your current session.
    </div>
    <div *ngIf="timedOut" [@slideInRight] class="info-box text-center">
      Your session has expired due to inactivity. <br />
      Please click anywhere to reload the page.
    </div>
    <div class="backdrop backdrop-blur"></div>
  </div>
</app-dcp-modal>

<app-toastr-container></app-toastr-container>
