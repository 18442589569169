import { Component, DestroyRef, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  AuthenticatedUserQuery,
  AuthenticatedUserService,
  ProgressRef,
  ProgressService,
  SEOService,
  ToasterService,
  UserVerificationStatus,
} from '../../../shared';
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

@Component({
  selector: 'app-verify-email',
  templateUrl: './verify-email.component.html'
})
export class VerifyEmailComponent implements OnInit, OnDestroy {
  destroy = new Subject();
  confirmed: boolean;
  token: string;
  // Spinner variables
  progress: ProgressRef;

  constructor(
    private authenticatedUserService: AuthenticatedUserService,
    public authenticatedUserQuery: AuthenticatedUserQuery,
    private toster: ToasterService,
    private router: Router,
    private route: ActivatedRoute,
    private _seoService: SEOService,
    private progressService: ProgressService,
    private elRef: ElementRef,
    private destroyRef: DestroyRef
  ) {
    this.route.queryParams.subscribe(params => {
      this.token = params['t'];
    });
  }

  ngOnInit() {
    this._seoService.createTitleForPage();
    if (this.token) {
      this.progress = this.progressService.showSpinner(this.progress, this.elRef);
      this.authenticatedUserService
        .confirmEmailVerification(this.token)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(
          res => {
            this.progressService.hideSpinner(this.progress);
            this.onEmailVerified();
          },
          error => {
            this.progressService.hideSpinner(this.progress);
            this.toster.openToastr('Your email was not verified: ' + error.error?.message, 'Error', 'error', 3000);
            this.router.navigate(['/signup/email-verify']);
          }
        );
    }

    this.authenticatedUserQuery.authenticatedUser$.subscribe(user => {
      if (user?.email_verification_status === UserVerificationStatus.COMPLETE && !this.confirmed) {
        this.onEmailVerified();
      }
    });
  }

  onEmailVerified() {
    if (this.confirmed) {
      return;
    }
    this.confirmed = true;
    this.toster.openToastr('Thank you, your email has been successfully verified.', 'Success', 'check', 3000);
  }

  navigate() {
    this.authenticatedUserQuery.isLoggedIn$.pipe(takeUntil(this.destroy)).subscribe(loggedIn => {
      if (loggedIn) {
        this.router.navigate(['/']);
      } else {
        this.router.navigate(['/login']);
      }
    });
  }

  ngOnDestroy() {
    this.destroy.next(true);
    this.destroy.complete();
  }
}
