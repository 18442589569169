import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TabsComponent } from './tabs.component';
import { TabLabelComponent } from './tab-label.component';
import { TabItemComponent } from './tab-item.component';
import { TabBodyComponent } from './tab-body.component';

@NgModule({
  declarations: [TabsComponent, TabLabelComponent, TabItemComponent, TabBodyComponent],
  imports: [CommonModule],
  exports: [TabsComponent, TabLabelComponent, TabItemComponent, TabBodyComponent],
})
export class TabsModule {}
