import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CheckoutData, Distribution } from '../../../../app.datatypes';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthenticatedUser } from '../../../../shared';

@Component({
  selector: 'app-distribution-create-step-three',
  templateUrl: './distribution-create-step-three.component.html',
})
export class DistributionCreateStepThreeComponent {
  @Input() distribution: Distribution;
  @Input() isLoading = false;
  @Input() user: AuthenticatedUser;
  @Input() submissionFee: number;
  @Input() moderationFee: number;
  form: UntypedFormGroup = this.formBuilder.group({
    termsAccepted: [null, Validators.required],
  });
  isExternalCheckout: any;
  checkoutAmount: number;
  @Output() setData = new EventEmitter<CheckoutData>();
  checkoutFormValidity = true;
  constructor(private formBuilder: UntypedFormBuilder) {}

  bountyTotalChange($event) {
    this.checkoutAmount = $event;
  }

  changeCheckoutType(type: boolean): void {
    this.isExternalCheckout = type;
    this.setChecked();
  }

  checkoutFormValidityChange(validity): void {
    this.checkoutFormValidity = validity;
    this.setChecked();
  }

  setChecked() {
    if (this.form) {
      const data = this.form.value;
      data.isValid = this.form.valid && this.checkoutFormValidity;
      data.is_external_checkout = this.isExternalCheckout;
      this.setData.emit(data);
    }
  }
}
