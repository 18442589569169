<div class="w-full h-full overflow-hidden">
  <app-login
    *ngIf="!isRegister"
    (changePage)="changePage()"
  >
  </app-login>

  <app-register
    *ngIf="isRegister"
    outerContainer="true"
  ></app-register>
  <app-logo class="logo-white"></app-logo>
  <img class="film" src="assets/img/login-particles/film.svg" alt="" />
  <img class="logo" src="assets/img/login-particles/logo.svg" alt="" />
  <img class="circle" src="assets/img/login-particles/circle.svg" alt="" />
  <img class="film1" src="assets/img/login-particles/film.svg" alt="" />
  <img class="circle2" src="assets/img/login-particles/circle.svg" alt="" />
  <img class="circle1" src="assets/img/login-particles/circle.svg" alt="" />
</div>
