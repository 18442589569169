import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, tap, switchMap } from 'rxjs/operators';
import { applyTransaction } from '@datorama/akita';
import { arrayUpdate, arrayRemove } from '@datorama/akita';
import { ApiService } from '../../services';
import {
  CreativeQuery,
  CreativeQuerySubmit,
  SubmitCreativeQuerySubmissionRequest,
  SubmitCreativeQueryRequest,
  Instruction,
  CreativeQueryResult,
  AnswerCreativeQueryRequest,
  TokenEarning,
} from '../../../app.datatypes';
import { CreativeQueryFilter, CreativeQueryStore } from './creative-query.store';
import { CreativeQueryQuery } from './creative-query.query';
import { environment } from '../../../../environments';
import { AuthenticatedUserService } from '../authenticated-user';

@Injectable({
  providedIn: 'root',
})
export class CreativeQueryStateService {
  constructor(
    private cqStore: CreativeQueryStore,
    private cqQuery: CreativeQueryQuery,
    private api: ApiService,
    private authenticatedUserService: AuthenticatedUserService
  ) {}

  setActive(id) {
    this.cqStore.setActive(id);
  }

  setUserQueryActive(id) {
    this.cqStore.update({ userQueryActive: id });
  }

  setModerationQueryActive(id) {
    this.cqStore.update({ moderationQueryActive: id });
  }

  update(id, data: object) {
    this.cqStore.update(id, data);
  }

  setLockedData(id, locked_by, locked_at) {
    this.cqStore.update(id, { locked_by, locked_at });
  }

  onAnswerCreateUpdate(id, total_votes, total_stake) {
    this.cqStore.update(id, {
      total_votes,
      total_stake,
    });
  }

  queryModerated(cq: CreativeQuery) {
    this.cqStore.update(cq._id, cq);
    this.authenticatedUserService.setUnlocked();
  }

  unsetActiveCq() {
    this.cqStore.update({ moderationQueryActive: null, locked_at: undefined, locked_by: undefined });
  }

  handleError(error) {
    this.cqStore.setError(error);
    this.cqStore.setLoading(false);
    return this.api.catchError(error);
  }

  storeCreativeQuery(creativeQuery: CreativeQuery, id: string) {
    if (id) {
      this.cqStore.update(id, creativeQuery);
    } else {
      this.cqStore.add(creativeQuery, { prepend: true });
    }
  }

  getCreativeQuery(id: string): Observable<CreativeQuery> {
    return id ? this.getQuery(id) : of(new CreativeQuery());
  }

  getCQEarnings(id: string, skip = 0): Observable<TokenEarning[]> {
    const api = `api/creative-query/${id}/earnings`;
    return this.api.get(api);
  }

  putCreativeQuery(id: string, data): Observable<CreativeQuery> {
    return this.api.put(`api/creative-query/${id}`, data).pipe(
      tap((creativeQuery) => {
        this.cqStore.update(id, creativeQuery);
      })
    );
  }

  putPrepareForReSubmitCreativeQuery(id: string, data): Observable<CreativeQuery> {
    return this.api.put(`api/creative-query/${id}/prepare-for-resubmit`, data).pipe(
      tap((creativeQuery) => {
        this.cqStore.update(id, creativeQuery);
      })
    );
  }

  postDraftCreativeQuery(data): Observable<CreativeQuery> {
    return this.api
      .post('api/creative-query', data)
      .pipe(tap((creativeQuery) => this.storeCreativeQuery(creativeQuery, data.creative_query_id)));
  }

  postPrepareForSubmitCreativeQuery(data): Observable<CreativeQuery> {
    return this.api
      .post('api/creative-query/prepare-for-submit', data)
      .pipe(tap((creativeQuery) => this.storeCreativeQuery(creativeQuery, data.creative_query_id)));
  }

  // Creative Query submitted to blockChain
  postCreativeQuerySubmit(data: SubmitCreativeQueryRequest): Observable<CreativeQuery> {
    return this.api
      .post(`api/creative-query/${data.creativeQueryId}/submit`, {
        sig: data.signature,
        nonce: data.nonce,
        pk: data.pk,
      })
      .pipe(
        tap((cq) => {
          this.cqStore.update(data.creativeQueryId, cq);
        })
      );
  }

  // Creative Query resubmitted to blockChain
  postCreativeQueryResubmit(data: SubmitCreativeQueryRequest): Observable<CreativeQuery> {
    return this.api
      .post(`api/creative-query/${data.creativeQueryId}/resubmit`, {
        sig: data.signature,
        nonce: data.nonce,
        pk: data.pk,
      })
      .pipe(
        tap((cq) => {
          this.cqStore.update(data.creativeQueryId, cq);
        })
      );
  }

  updateFilters(filters: CreativeQueryFilter) {
    applyTransaction(() => {
      this.cqStore.remove(this.cqQuery.getAllUserAndActiveQueryIds());
      this.cqStore.update({
        filters,
        skip: 0,
        scroll: 1,
        loaded: false,
        apiEndReached: false,
        userQuerySkip: 0,
        userQueryScroll: 1,
        userQueryApiEndReached: false,
        userQueryLoaded: false,
      });
    });
  }

  updateShowFilters(showFilters: boolean) {
    this.cqStore.update({ ui: { showFilters } });
  }

  /**  Populate state with  creative queries */
  getListCreativeQueries(): Observable<CreativeQuery[]> {
    const filters = this.prepareFilters();
    return this.api.get(`api/creative-query/`, { params: filters }).pipe(
      tap((creativeQueries) => {
        this.cqStore.add(creativeQueries);
        if (creativeQueries.length < environment.grid_skip_limit) {
          this.cqStore.update({ apiEndReached: true });
        }
        this.cqStore.setLoading(false);
      }),
      catchError(this.handleError.bind(this))
    );
  }

  /**  Populate state with user creative queries */
  getAll(): Observable<CreativeQuery[]> {
    this.cqStore.setLoading(true);
    const api =
      'api/creative-query/user?limit=' + environment.grid_skip_limit + '&skip=' + this.cqQuery.getValue().userQuerySkip;

    return this.api.get(api).pipe(
      tap((cq) => {
        this.cqStore.add(cq);
        this.cqStore.update({
          userQueryLoaded: true,
        });
        this.cqStore.setLoading(false);
        if (cq.length < environment.grid_skip_limit) {
          this.cqStore.update({ userQueryApiEndReached: true });
        }
      }),
      catchError(this.handleError.bind(this))
    );
  }

  /**  Populate state with to be moderated creative queries */
  getAllForModeration(): Observable<CreativeQuery[]> {
    this.cqStore.setLoading(true);
    const api =
      'api/creative-query/moderate?limit=250' /*environment.grid_skip_limit + */ +
      '&skip=' +
      this.cqQuery.getValue().moderationQuerySkip +
      (this.cqQuery.getValue().moderationFilters.unlockedOnly ? '&unlocked=true' : '');
    return this.api.get(api).pipe(
      tap((creativeQuerys) => {
        this.cqStore.add(creativeQuerys);
        this.cqStore.setLoading(false);
        if (creativeQuerys.length < environment.grid_skip_limit) {
          this.cqStore.update({ moderationQueryApiEndReached: true });
        }
        this.cqStore.update({ moderationQueryLoaded: false });
      }),
      catchError(this.handleError.bind(this))
    );
  }

  updateModerationFilter(filter) {
    applyTransaction(() => {
      this.cqStore.remove(this.getAll());
      this.cqStore.update({
        moderationFilters: { unlockedOnly: filter.unlockedOnly },
        moderationQuerySkip: 0,
      });

      this.cqStore.setLoading(false);
    });
  }

  calculateDaysRemaining(creativeQuery: CreativeQuery) {
    if (!creativeQuery.expired_at) {
      return null;
    }
    const sDate = new Date(creativeQuery.approved_at);
    const eDate = new Date(creativeQuery.expired_at);
    const diffTime = Math.abs(eDate.getTime() - sDate.getTime());
    const today = new Date();
    const completed = today.getTime() - sDate.getTime();
    let days = 0;
    if (diffTime - completed >= 0) {
      days = Math.ceil((diffTime - completed) / (1000 * 60 * 60 * 24));
    }
    return days;
  }

  prepareFilters() {
    const filters = { ...this.cqQuery.getValue().filters };
    Object.keys(filters).forEach((key) => !!filters[key] || delete filters[key]);
    return { ...filters, skip: this.cqQuery.getValue().skip };
  }

  updateSkip(scroll) {
    this.cqStore.update({ skip: Object.keys(this.cqStore._value().entities).length, scroll });
  }

  updateUserSkip(userQueryScroll) {
    this.cqStore.update({
      userQuerySkip: this.cqQuery.getValue().userQuerySkip + environment.grid_skip_limit,
      userQueryScroll,
    });
  }

  updateModerationSkip(moderationQueryScroll) {
    this.cqStore.update({
      moderationQuerySkip: this.cqQuery.getValue().moderationQuerySkip + environment.grid_skip_limit,
      moderationQueryScroll,
    });
  }

  lockCreativeQuery(id: string): Observable<CreativeQuery> {
    this.cqStore.setLoading(true);
    const proposal = this._upsertCqState(this.api.put('api/creative-query/' + id + '/lock', null));
    this.authenticatedUserService.setModeratedEntity({ entity: 'creative-query', id: id });
    return proposal;
  }

  unlockCreativeQuery(id: string): Observable<CreativeQuery> {
    this.cqStore.setLoading(true);
    return this.api.put('api/creative-query/' + id + '/unlock', null).pipe(
      tap((cq) => {
        this.authenticatedUserService.setUnlocked();
        cq.locked_at = undefined;
        cq.locked_by = undefined;
        this.cqStore.upsert(cq._id, cq);
        this.cqStore.setLoading(false);
      }),
      catchError(this.handleError.bind(this))
    );
  }

  cancelCreativeQuery(creativeQuery: CreativeQuery) {
    return this.api.get(`api/creative-query/${creativeQuery._id}/cancel`).pipe(
      tap((cQuery) => {
        if (creativeQuery.status === 'approved') {
          const skip = this.cqQuery.getValue().skip - 1;

          if (this.cqQuery.getActive() && this.cqQuery.getActive()._id === creativeQuery._id) {
            this.cqStore.setActive(null);
          }
          this.cqStore.update({ skip, userQueryApiEndReached: false });
        } else if (creativeQuery.status === 'to-be-moderated') {
          const moderationQuerySkip = this.cqQuery.getValue().moderationQuerySkip - 1;

          if (this.cqQuery.getValue().moderationQueryActive === creativeQuery._id) {
            this.cqStore.update({
              moderationQuerySkip,
              moderationQueryActive: null,
              userQueryApiEndReached: false,
            });
          } else {
            this.cqStore.update({
              moderationQuerySkip,
              userQueryApiEndReached: false,
            });
          }
        }
        this.cqStore.update(creativeQuery._id, { status: 'cancelled' });
        this.cqStore.setLoading(false);
      }),
      catchError(this.handleError.bind(this))
    );
  }

  createAnswerCreativeQuery(data: AnswerCreativeQueryRequest, fake = null): Observable<CreativeQuerySubmit> {
    return this.api.post(`api/creative-query-submissions/`, data);
  }

  updateAnswerCreativeQuery(data: AnswerCreativeQueryRequest, cqAnswerId: string): Observable<CreativeQuerySubmit> {
    return this.api.put(`api/creative-query-submissions/${cqAnswerId}`, data);
  }

  // Submit answers  blockchain transaction
  submitCreativeQuerySubmission(req: SubmitCreativeQuerySubmissionRequest): Observable<CreativeQuerySubmit> {
    return this.api.post(`api/creative-query-submissions/${req.submitId}/submit`, {
      sig: req.signature,
      nonce: req.nonce,
      pk: req.pk,
    });
  }

  invalidateCache() {
    this.cqStore.setHasCache(false);
  }

  /**
   *
   * @param id : creative query ID
   *
   * Get creative query entity from store. Or get it form API call and insert it into store,
   *  with returning its observable.
   *
   */

  getQuery(id): Observable<CreativeQuery> {
    return this.cqQuery.selectEntity(id).pipe(
      switchMap((query) => {
        if (!query) {
          return this.api.get('api/creative-query/' + id).pipe(tap((cq) => this.cqStore.add(cq)));
        } else {
          return this.cqQuery.selectEntity(id);
        }
      }),
      catchError((error) => {
        return this.api.catchError(error);
      })
    );
  }

  resetFailed(id): Observable<CreativeQuery> {
    return this.api.put('api/creative-query/' + id + '/reset', null).pipe(
      switchMap((cq) => {
        this.cqStore.update(id, cq);
        return of(cq);
      })
    );
  }

  private _upsertCqState(observerable: Observable<CreativeQuery>) {
    return observerable.pipe(
      tap((cq) => {
        this.cqStore.upsert(cq._id, cq);
        this.cqStore.setLoading(false);
      }),
      catchError(this.handleError.bind(this))
    );
  }

  getReviewCqInstruction(): Observable<Instruction> {
    return this.api.get('api/instructions/cq_review');
  }
}
