<div
  class="grid grid-cols-1 sm:grid-cols-1 gap-24"
  joyrideStep="proposalcreate10"
  *ngIf="distribution"
  text="Review proposal details, if correct, proceed to Checkout."
>
  <div class="col-span-13">
    <h4 class="mb-8">Your Project Details</h4>
    <app-tabs class="block tabs-create-panel link">
      <app-tab-item>
        <app-tab-label>
          <label>Gallery</label>
          <app-tab-body>
            <app-gallery></app-gallery>
          </app-tab-body>
        </app-tab-label>
      </app-tab-item>
      <app-tab-item>
        <app-tab-label>
          <label>Feature</label>
        </app-tab-label>
        <app-tab-body *ngIf="distribution.video_data">
          <app-distribution-player [distribution]="distribution"></app-distribution-player>
        </app-tab-body>
      </app-tab-item>
    </app-tabs>
    <h4 class="my-16 font-bold" *ngIf="distribution?.title">
      {{ distribution?.title }}
    </h4>
    <h5 class="font-bold">Log Line:</h5>
    <pre class="distribution-content">{{ distribution?.log_line }}</pre>
    <br />
    <h5 class="font-bold mt-3">Description:</h5>
    <p [innerHTML]="distribution?.description | safeHtml" class="distribution-content"></p>
    <br />
    <div class="attachments" *ngIf="distribution?.docs?.length">
      <app-attachments [proposalDocs]="distribution.docs"></app-attachments>
    </div>
  </div>
</div>
