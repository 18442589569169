import {EventEmitter, Injectable} from '@angular/core';
import {applyTransaction} from '@datorama/akita';
import {Observable} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';
import {environment} from '../../../../../src/environments';
import {
  ExternalCheckoutRequest,
  Payment,
  PricingFees,
  WalletLog
} from '../../../../../src/app/app.datatypes';
import {ApiService} from '../../services';
import {PaymentStore} from './payment.store';
import {TransactionStateService} from '../transactions';
import {PaymentQuery} from './payment.query';

@Injectable({ providedIn: 'root' })

export class PaymentStateService {

  public externalCheckout$: EventEmitter<ExternalCheckoutRequest>;

  constructor(
    private paymentStore: PaymentStore,
    private paymentQuery: PaymentQuery,
    private transactionStateService: TransactionStateService,
    private apiService: ApiService
  ) {
    this.externalCheckout$ = new EventEmitter();
  }

  handleError(error: any) {
    this.paymentStore?.setError(error);
    this.paymentStore?.setLoading(false);
    return this.apiService?.catchError(error);
  }

  connectUserWallet(address: string, email: string, pk: string, sig: string): Observable<WalletLog> {
    return this.apiService.post('api/wallet', { address, email, pk, sig }, {}, false);
  }

  getPayments(skip = 0, limit = 5): Observable<Payment[]> {
    return this.apiService.get('api/payments?skip=' + skip + '&limit=' + limit).pipe(
      tap(payments => {
        applyTransaction(() => {
          this.paymentStore.upsertMany(payments);
          this.paymentStore.setLoading(false);
          payments.forEach(payment => {
            if (payment.transaction) {
              this.transactionStateService.upsertTransaction(payment.transaction);
            }
          });
          if (payments.length < environment.grid_skip_limit) {
            this.paymentStore.update({ apiEndReached: true });
          }
        });
      }),
      catchError(this.handleError.bind(this))
    );
  }

  getDailyLimit(): Observable<number> {
    return this.apiService.post('api/payments/available-daily-limit', {});
  }

  getPayment(id): Observable<Payment> {
    return this.apiService.get(`api/payments/${id}`).pipe(
      tap(payment => {
        applyTransaction(() => {
          this.paymentStore.upsert(id, payment);
          if (payment.transaction) {
            this.transactionStateService.upsertTransaction(payment.transaction);
          }
          this.paymentStore.setLoading(false);
        });
      }),
      catchError(this.handleError.bind(this))
    );
  }

  updatePayment(payment: Payment) {
    this.paymentStore.update(payment);
  }

  updatePaymentStatus(id: string, status: string) {
    this.paymentStore.update(id, { status: status });
  }

  postPayment(payment): Observable<Payment> {
    return this.apiService.post('api/payments', payment);
  }

  processExternalCheckout(payment: ExternalCheckoutRequest): Observable<Payment> {
    return this.apiService.post('api/payments/external-checkout', payment);
  }

  cancelPayment(paymentId) {
    return this.apiService.get(`api/payments/${paymentId}/cancel`);
  }

  getFees(): Observable<PricingFees> {
    return this.apiService.get('api/fees').pipe(
      tap(fees => {
        applyTransaction(() => {
          this.paymentStore.update({ fees: fees });
        });
      }),
      catchError(this.handleError.bind(this))
    );
  }

  updateFees() {
    this.apiService
      .get('api/fees')
      .pipe(
        tap(fees => {
          applyTransaction(() => {
            this.paymentStore.update({ fees: fees });
          });
        }),
        catchError(this.handleError.bind(this))
      )
      .subscribe();
  }

  updateFeesStore(fees: PricingFees) {
    this.paymentStore.update({ fees: fees });
  }

  calculateDollarToFilmPricing(dollars: number, type = PriceConversionType.SALE): number {
    const film = dollars / (type == PriceConversionType.SALE ?
      this.paymentQuery.saleFilmPricing : this.paymentQuery.filmPricing);
    if (film.toString() === 'Infinity') {
      return 0;
    }
    return film;
  }

  calculateFilmToDollarPricing(film: number, type = PriceConversionType.SALE): number {
    return film * (type == PriceConversionType.SALE ?
      this.paymentQuery.saleFilmPricing : this.paymentQuery.filmPricing);
  }

  getFilmPricing(): number {
    return this.paymentQuery.filmPricing;
  }

  getSaleFilmPricing(): number {
    return this.paymentQuery.saleFilmPricing;
  }

  getQuote(dollarAmount: Number, handleError = true): Observable<any> {
    return this.apiService.post('api/payments/simplex/quote', { amount: dollarAmount }, {}, handleError).pipe(
      tap(quote => {
        applyTransaction(() => {
          this.paymentStore.update({ quote: quote });
        });
      })
    );
  }
}

export enum PriceConversionType {
  MARKET,
  SALE
}
