import { Component, Input } from '@angular/core';
import { AuthenticatedUserQuery } from '../../state';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { ProposalRound } from '../../../app.datatypes';

interface Tabs {
  title: string;
  content: string;
}

enum Criterion {
  Rules = 'Rules',
  Eligibility = 'Eligibility',
}

@Component({
  selector: 'app-expanded-info',
  templateUrl: './expanded-info.component.html',
  styleUrls: ['./expanded-info.component.scss'],
})
export class ExpandedInfoComponent {
  @Input() set roundChanges(round: ProposalRound) {
    this.tabs = [
      { title: Criterion.Rules, content: round.rules },
      { title: Criterion.Eligibility, content: round.eligibility },
    ];
  }
  expandIconColor = '#212121';

  tabs: Tabs[];
  expanded = false;
  textColor: SafeStyle;
  criterion = Criterion;

  constructor(public authenticatedUserQuery: AuthenticatedUserQuery, public sanitizer: DomSanitizer) {
    this.textColor = this.sanitizer.bypassSecurityTrustStyle('var(--global-black-color)');
  }

  get checkIconColor(): string {
    return !this.authenticatedUserQuery.user.is_dark_theme && this.expanded
      ? this.textColor.toString()
      : this.expandIconColor;
  }

  expand(): void {
    this.expanded = !this.expanded;
  }
}
