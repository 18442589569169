<app-toastr-template
  class="toast toast-{{ toast.type }}"
  [style.bottom.px]="toast.offsetHeight"
  [data]="toast"
  [index]="i"
  (remove)="removeToastr($event)"
>
  <p class="text-sm" *ngIf="!toast?.isArray; else arrayIs">{{ toast?.message }}</p>
  <ng-template #arrayIs>
    <p class="text-sm" #arrayIs class="d-block" *ngFor="let msg of toast?.message">{{ msg }}</p>
  </ng-template>

  <div *ngIf="toast?.errors" class="mt-4">
    <ul>
      <div *ngFor="let error of toast?.errors">
        <li *ngFor="let message of error">{{ message }}</li>
      </div>
    </ul>
  </div>
</app-toastr-template>
