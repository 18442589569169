import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class WindowScrollService {
  private scrollTop$ = new Subject();
  private scrollEndReached$ = new Subject();
  containerHeight$ = new Subject();
  contentHeight: any; // any is ok here
  maxPosition = 0;

  constructor(private router: Router) {
    this.initCheckingRoutes();
    this.checkEndPosition();
  }

  emitScrollPosition(position: number) {
    this.scrollTop$.next(position);
  }

  resetMaxPosition() {
    this.maxPosition = 0;
  }

  getScrollPosition(): Observable<any> {
    // any is ok here
    return this.scrollTop$.asObservable();
  }

  getScrollPositionEnd(): Observable<any> {
    // any is ok here
    return this.scrollEndReached$.asObservable();
  }

  init() {
    this.containerHeight$.pipe(distinctUntilChanged()).subscribe(height => {
      this.contentHeight = height;
    });
  }

  initCheckingRoutes() {
    this.router.events
      .pipe(
        map(() => this.router.url),
        distinctUntilChanged()
      )
      .subscribe(url => this.resetMaxPosition());
  }

  checkEndPosition() {
    this.scrollTop$.asObservable().subscribe((position: number) => {
      this.containerHeight$.pipe(distinctUntilChanged()).subscribe((height: number) => {
        const scrollHeight = height - document.documentElement.clientHeight;
        if (position >= scrollHeight && this.maxPosition < scrollHeight) {
          this.maxPosition = position;

          this.scrollEndReached$.next(this.maxPosition);
        }
      });
    });
  }

  hideFooter() {
    const footer = document.getElementById('footer');
  }
}
