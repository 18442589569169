import {
  Component,
  OnInit,
  Input,
  OnChanges,
  EventEmitter,
  Output,
  HostListener,
  OnDestroy,
  ViewChildren,
  QueryList,
} from '@angular/core';
import { FilePreviewOverlayService, SharedService } from '../../../services';
import { VideoConfig } from '../../../../shared/interface/video-config.interface';
import { CommonModule } from '@angular/common';
import { IconModule } from '../../../../shared/modules/components/icon/icon.module';
import { VideoPlayerModule } from '../../../../shared/modules/components/video-player/video-player.module';
import { DcpImageComponent } from '../../dcp-image/dcp-image.component';
import { Distribution, SliderActions } from '../../../../app.datatypes';
import { PurchaseType } from '../../../../containers/distribution/distribution-details/distribution-purchase-block/distribution-purchase-block.component';
import { Router } from '@angular/router';
import { VideoPlayerComponent } from '../../../../shared/modules/components/video-player/video-player.component';
import { firstValueFrom, timer } from 'rxjs';

@Component({
  selector: 'app-slider',
  standalone: true,
  imports: [CommonModule, IconModule, VideoPlayerModule, DcpImageComponent],
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
})
export class SliderComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChildren(VideoPlayerComponent) video: QueryList<VideoPlayerComponent>;
  @Input() slides: VideoConfig[] = [];
  @Input() previewsOnly = false;
  @Input() showIndicators = false;
  slideWidth = '0px';
  sliderPanelSelectedWidth = '0px';
  sliderPanelWidth = 0;
  leftPosition = 0;
  leftPositionSlider = 0;
  selectedImageSlide = 0;
  tumbsMaxRight = 0;
  selectedSlide: VideoConfig;
  @Output() sliderActions: EventEmitter<{ action: SliderActions; data: object }> = new EventEmitter();
  timer: any;
  muted = localStorage.getItem('banner_muted') || 'yes';
  descriptionVisible = false;

  constructor(
    private sharedService: SharedService,
    private previewDialog: FilePreviewOverlayService,
    private router: Router
  ) {}

  ngOnDestroy(): void {
    clearInterval(this.timer);
  }

  @HostListener('mouseenter')
  onMouseEnter() {
    clearInterval(this.timer);
  }

  @HostListener('mouseleave')
  onMouseLeave() {
    this.startTimer();
  }

  ngOnInit(): void {
    this.slideWidth = this.slides.length * 25 + '%';
    this.sliderPanelWidth = this.slides.length * 25;

    this.sliderPanelSelectedWidth = this.slides.length * 100 + '%';

    const widthImage = 100 / this.slides.length;
    this.tumbsMaxRight = 100 - 4 * widthImage;
    this.tumbsMaxRight = this.tumbsMaxRight * -1;

    this.selectSlide(0);
    if (this.previewsOnly) {
      this.startTimer();
    }
  }

  ngOnChanges(): void {
    this.slideWidth = this.slides.length * 25 + '%';
    if (this.slides.length * 25 < 100) {
      this.slideWidth = '100%';
    }
    this.sliderPanelWidth = this.slides.length * 25;
    this.sliderPanelSelectedWidth = this.slides.length * 100 + '%';
    const widthImage = 100 / this.slides.length;
    this.tumbsMaxRight = 100 - 4 * widthImage;
    this.tumbsMaxRight = this.tumbsMaxRight * -1;
    this.selectSlide(0);
    this.leftPositionSlider = 0;
    this.leftPosition = 0;
    this.selectedImageSlide = 0;
  }

  moveLeftNavigation(): void {
    const widthImage = (100 / this.slides.length) * 4;

    if (Math.abs(this.leftPosition) > 0 && this.sliderPanelWidth > 100) {
      this.leftPosition = this.leftPosition + widthImage;
      if (this.leftPosition > 0) {
        this.leftPosition = 0;
      }
    }
  }

  moveRightNavigation(): void {
    const widthImageSlide = (100 / this.slides.length) * 4;

    const widthImage = (100 / this.slides.length) * 4;
    const maxRight = 100 - widthImage;

    if (Math.abs(this.leftPosition) < maxRight && this.sliderPanelWidth > 100) {
      this.leftPosition = this.leftPosition - widthImageSlide;

      if (Math.abs(this.leftPosition) > maxRight) {
        this.leftPosition = -1 * maxRight;
      }
    }
  }

  moveLeftSlider(evt: Event): void {
    evt?.stopImmediatePropagation();
    if (this.selectedImageSlide === 0) {
      return;
    }
    this.selectedImageSlide--;
    this.selectSlide(this.selectedImageSlide);
    const widthImage = 100 / this.slides.length;

    if (Math.abs(this.leftPositionSlider) > 0) {
      this.leftPositionSlider = this.leftPositionSlider + widthImage;
      this.moveLeftNavigation();
    }
    this.sharedService.setOnSliderPauseVideo();
  }

  mouseHover(): void {
    firstValueFrom(timer(500)).then(() => {
      this.video.get(this.selectedImageSlide).videoPlayer.videoElement.nativeElement.muted = this.muted === 'yes';
    });
  }

  moveRightSlider(evt: Event, pause = true): void {
    evt?.stopImmediatePropagation();
    if (this.selectedImageSlide === this.slides.length - 1) {
      this.selectedImageSlide = -1;
      this.leftPositionSlider = 0;
    }

    this.selectedImageSlide++;
    this.selectSlide(this.selectedImageSlide);
    const widthImage = 100 / this.slides.length;
    const maxRight = 100 - widthImage;

    if (Math.abs(this.leftPositionSlider) < maxRight) {
      this.moveRightNavigation();
      this.leftPositionSlider = this.leftPositionSlider - widthImage;
    }

    if (pause) {
      this.sharedService.setOnSliderPauseVideo();
    }
  }

  selectImage(index: number): void {
    const slideWidth = 100 / this.slides.length;
    this.leftPositionSlider = -1 * index * slideWidth;
    this.selectSlide(index);
    this.selectedImageSlide = index;
    this.sharedService.setOnSliderPauseVideo();
  }

  selectSlide(index: number): void {
    this.selectedSlide = this.slides[index];
  }

  showPreview(event: Event, file: { name: string; url: string; type: string }): void {
    this.previewDialog.open({
      image: { ...file, isArray: true, files: this.slides },
    });
    event.stopPropagation();
  }

  clickVideo(entity: Distribution): void {
    this.showDescription();
    if (entity) {
      this.sliderActions.emit({ action: SliderActions.CLICK, data: { entity } });
    }
  }

  showDescription(): void {
    if (window.innerWidth <= 768) {
      this.descriptionVisible = true;
      firstValueFrom(timer(1000)).then(() => {
        this.descriptionVisible = false;
      });
    }
  }

  handleVolume(): void {
    this.muted = this.muted === 'yes' ? 'no' : 'yes';
    localStorage.setItem('banner_muted', this.muted);
    this.video.get(this.selectedImageSlide).videoPlayer.videoElement.nativeElement.muted = this.muted === 'yes';
  }

  rentPurchase(purchase = false): void {
    const entity = this.selectedSlide.entity;
    if (entity) {
      this.sliderActions.emit({
        action: SliderActions.PURCHASE,
        data: { entity, type: purchase ? PurchaseType.BUY : PurchaseType.RENT },
      });
    }
  }

  redirectToPlay(): void {
    this.router.navigate(['/dcp-plus', this.selectedSlide.entity._id, 'play']);
  }

  onDescription(): void {
    this.selectedSlide.isDescriptionShow = !this.selectedSlide.isDescriptionShow;
  }

  startTimer() {
    if (this.previewsOnly) {
      this.timer = setInterval(() => {
        this.moveRightSlider(null, false);
      }, 10000); // 10 seconds per slide
    }
  }
}
