import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticatedUserQuery } from '../state/authenticated-user/authenticated-user.query';

@Injectable()
export class ZeroDayPaymentGuardService  {
  constructor(private appService: AuthenticatedUserQuery, private router: Router) {}

  canActivate() {
    return ['completed', 'pending'].indexOf(this.appService.user.zero_day_payment_status) >= 0;
  }
}
