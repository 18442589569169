import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconModule } from '../../modules/components/icon/icon.module';
import { AttachmentsComponent } from './attachments.component';

@NgModule({
  declarations: [AttachmentsComponent],
  imports: [CommonModule, IconModule],
  exports: [AttachmentsComponent],
})
export class AttachmentModule {}
