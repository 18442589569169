import { Proposal } from '../../../app.datatypes';
import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig, ActiveState } from '@datorama/akita';

export interface ProposalState extends EntityState<Proposal>, ActiveState {
  ui: {
    showFilters: boolean;
  };

  moderationFilters: {
    unlockedOnly: boolean;
  };

  filters: {
    sortBy: string;
    reviewed: string;
    liked: string;
    genreFilter: Array<string>;
    proposalRoundFilter: Array<string>;
    search: string;
    statusFilter: string;
    status?: string;
    type: string;
  };

  addedProposal?: string;

  // Review Proposals  specific
  skip: number;
  scroll: number;
  apiEndReached: boolean;
  loaded: boolean;

  // Moderation Proposals  specific
  moderationProposalSkip: number;
  moderationProposalScroll: number;
  moderationProposalActive: string;
  moderationProposalApiEndReached: boolean;
  moderationProposalLoaded: boolean;

  // User proposals specific
  userProposalSkip: number;
  userProposalScroll: number;
  userProposalActive: string;
  userProposalApiEndReached: boolean;
  userProposalLoaded: boolean;
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'proposal', idKey: '_id', resettable: true })
export class ProposalStore extends EntityStore<ProposalState> {
  constructor() {
    super({
      ui: { showFilters: false },
      moderationFilters: {
        unlockedOnly: true,
      },
      filters: {
        sortBy: 'latest',
        reviewed: 'all',
        liked: 'all',
        genreFilter: ['all'],
        proposalRoundFilter: ['all'],
        search: '',
        statusFilter: '',
        type:'all'
      },
      skip: 0,
      scroll: 1,
      apiEndReached: false, // will track proposal which is not in state list

      loaded: false,
      moderationProposalLoaded: false,
      moderationProposalSkip: 0,
      moderationProposalScroll: 1,
      moderationProposalApiEndReached: false,

      userProposalLoaded: false,
      userProposalSkip: 0,
      userProposalScroll: 1,
      userProposalApiEndReached: false,
      // prevent loader showing on state initialization
      loading: false,
    });
  }

  resetShowHide(showFilters: boolean) {
    this.update({
      ui: { showFilters },
      skip: 0,
      scroll: 1,
      apiEndReached: false,
      filters: {
        sortBy: 'latest',
        reviewed: 'all',
        liked: 'all',
        genreFilter: ['all'],
        proposalRoundFilter: ['all'],
        search: '',
        statusFilter: '',
        type:'all'
      },
    });
  }
}
