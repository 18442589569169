import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-entity-action',
  templateUrl: './entity-action.component.html'
})
export class EntityActionComponent{
  @Input() entity: any; // any is ok here
  @Input() title: any; // any is ok here
  @Input() action: any; // any is ok here
  @Input() url: any; // any is ok here
  @Input() actor: any; // any is ok here
}
