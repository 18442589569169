<div
  class="z-2 h-40 flex items-center border border-gray-border rounded relative select-wrapper py-8 px-16"
  [ngClass]="{ disabled: disabled, open: isSelectOpen }"
  id="dh-{{ compId }}"
  (click)="!disabled && openClose($event)"
  #dhCompId
>
  <span class="animated-arrow arrow-text" [ngClass]="{ 'arrow-down': isSelectOpen }"></span>

  <div class="w-full h-full  placeholder" *ngIf="selectedOption === null">
    <ng-container *ngIf="placeholder">
      {{ placeholder }}
    </ng-container>
    <ng-container *ngIf="placeholder === undefined">
      Select
    </ng-container>
  </div>
  <ng-container *ngIf="selectedOption">
    <span class="selected-option option-{{ selectedOption.slug }}">{{ selectedOption[optionNameField] }} </span>
  </ng-container>
  <span class="animated-arrow arrow-text" [ngClass]="{ 'arrow-down': isSelectOpen }"></span>
</div>

<div class="backdrop" id="bd-{{ compId }}" *ngIf="isSelectOpen" (click)="openClose($event)" #bdCompId></div>

<div
  class="select-dropdown"
  id="dd-{{ compId }}"
  [ngClass]="{ open: isSelectOpen }"
  (click)="$event.stopPropagation()"
  #ddCompId
>
  <div class="filter" *ngIf="filterOption">
    <span>
      <input type="text" (keyup)="filterOptions($event)" id="filterTextInput-{{ compId }}" #filterTextInputCompId />
      <button (click)="clearText()">
        <app-icon [icon]="'search'" class="opacity-50" *ngIf="filterText === ''"></app-icon>
        <app-icon [icon]="'close'" *ngIf="filterText !== ''" class="blue hover:opacity-75"> </app-icon>
      </button>
    </span>
  </div>
  <ul>
    <li
      *ngFor="let option of options"
      (click)="select(option, $event)"
      class="option option-{{ option.slug }}"
      id="id-{{ option.slug }}"
      [ngClass]="{ selected: option.selected, hidden: !option.visible && filterOption }"
      #idOptionSlug
    >
      {{ option[optionNameField] }}
      <div *ngIf="option?.color" class="card-color color-{{ option?.color }}"></div>
    </li>
  </ul>
</div>
