import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import {
  Review,
  Moderation,
  ModerationRequest,
} from '../../app.datatypes';

@Injectable({
  providedIn: 'root',
})
export class ReviewService {
  constructor(private apiService: ApiService) {}

  getAuthenticatedUserStateEvaluations(userid, skip = 0, search = ''): Observable<Review[]> {
    let apiUrl = 'api/users/evaluations/';
    if (userid) {
      apiUrl += userid + '/';
    }
    apiUrl += '?&skip=' + skip + (search ? '&search=' + encodeURIComponent(search) : '');
    return this.apiService.get(apiUrl);
  }

  postModerate(moderation: ModerationRequest): Observable<Moderation> {
    return this.apiService.post('api/moderations', moderation);
  }
}
