import { Component, EventEmitter, Input, Output, OnInit, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { dropdown } from '../../../../app.animations';
import { ToasterService } from '../../../services';

@Component({
  selector: 'app-dcp-multiselect',
  templateUrl: './dcp-multiselect.component.html',
  styleUrls: ['./dcp-multiselect.component.scss'],
  animations: [dropdown],
})
export class DcpMultiselectComponent implements OnInit {
  @Input() options: any; // any is ok here
  @Input() placeholder: string;
  @Input() filterOption = false;
  @Input() selectedOptions = [];
  @Input() limit: number;
  @Input() optionNameField = 'name';
  @Input() disabled = false;
  @Input() disableOthers: string = 'Choose not to answer';
  @Output() selected = new EventEmitter<any[]>();

  @ViewChild('dhCompId', { static: false }) dhCompId: ElementRef;
  @ViewChild('ddCompId', { static: false }) ddCompId: ElementRef;
  @ViewChild('bdCompId', { static: false }) bdCompId: ElementRef;
  filterText = '';
  isMultiselectOpen = false;
  compId: number;

  constructor(private renderer: Renderer2, private toasterService: ToasterService) {}

  ngOnInit() {
    const dateInit = new Date();
    this.compId = dateInit.getTime();
    this.initializeVisible();
    this.initializeSelected();
  }

  initializeSelected() {
    this.selectedOptions?.forEach((option) => {
      const filteredOption = this.options.find((elem) => {
        if ((option._id && elem._id === option._id) || (option.slug && elem.slug === option.slug) || elem.name === option) {
          return elem;
        }
      });
      if (filteredOption) {
        filteredOption.selected = true;
      }
    });
  }

  initializeVisible() {
    if (this.options) {
      for (let i = 0; i < this.options?.length; i++) {
        this.options[i].visible = true;
      }
    }
  }

  openClose(evt) {
    evt?.stopImmediatePropagation();
    this.isMultiselectOpen = !this.isMultiselectOpen;
    const ddWrapper: HTMLElement = this.renderer.selectRootElement('#dcp-dd-wrapper', true);
    const dh = this.dhCompId?.nativeElement;
    const dd = this.ddCompId?.nativeElement;
    if (this.isMultiselectOpen) {
      setTimeout(() => {
        const bd = this.bdCompId?.nativeElement;
        ddWrapper.append(bd);
      }, 1000);
      const dhTop = dh.getBoundingClientRect().top;
      const dhLeft = dh.getBoundingClientRect().left;

      if (document.getElementsByTagName('body')[0].offsetHeight < dhTop + 330) {
        dd.style['bottom'] = '40px';
        dd.style['top'] = 'inherit';
      } else {
        dd.style['bottom'] = 'inherit';
        dd.style['top'] = dhTop + 40 + 'px';
      }
      dd.style['left'] = dhLeft + 'px';
      ddWrapper.append(dd);
    } else {
      this.selected.emit(this.selectedOptions);
      dh.append(dd);
    }
  }

  filterOptions(evt) {
    evt.stopImmediatePropagation();
    this.filterText = evt.target.value;
    for (const iterator of this.options) {
      if (this.filterText === '') {
        iterator.visible = true;
      } else {
        const caseUnsensitive = iterator.name.toLowerCase();
        iterator.visible = caseUnsensitive.indexOf(this.filterText) !== -1;
        if (caseUnsensitive.indexOf(this.filterText) !== -1) {
          iterator.visible = true;
        } else {
          iterator.visible = false;
        }
      }
    }
  }

  clearText() {
    this.filterText = '';
    if (this.filterText === '') {
      for (const iterator of this.options) {
        iterator.visible = true;
      }
    }
  }

  select(option, evt) {
    evt.stopImmediatePropagation();
    if (this.limit && this.selectedOptions.length === this.limit) {
      this.toasterService.openErrorToastr('You may only select up to two options', 'Ethnicity');
      this.openClose(null);
      return;
    } else if (option.selected === undefined) {
      option.selected = false;
    }
    option.selected = !option.selected;
    this.selectedOptions = [];
    for (const iterator of this.options) {
      const items = this.options.filter((item) => item.selected)?.length > 1;
      if ((iterator.name === this.disableOthers && option.name !== this.disableOthers) || (iterator.name !== this.disableOthers && option.name === this.disableOthers) && items) {
        iterator.selected = false;
      }
      if (iterator.selected) {
        this.selectedOptions.push(iterator);
      }
    }
  }

  removeSelected(option, evt) {
    evt.stopImmediatePropagation();
    option.selected = false;
    this.selectedOptions = this.selectedOptions.filter((item) => item !== option);
    this.options.find((elem) => elem._id === option._id).selected = false;
    this.selected.emit(this.selectedOptions);
  }
}
