import { ChangeDetectorRef, Component, OnInit, Renderer2 } from '@angular/core';
import { ToasterService } from '../../services/toaster.service';
import { Toastr } from '../toastr';

@Component({
  selector: 'app-toastr-container',
  templateUrl: './toastr-container.component.html'
})
export class ToastrContainerComponent implements OnInit {
  toasts: Toastr[] = [];

  constructor(private toasterService: ToasterService, private renderer: Renderer2, private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.toasterService.toast$.subscribe(toast => {
      this.toasts = this.toasts.filter((item) => item.toastrType !== toast.toastrType);
      this.toasts = [toast, ...this.toasts];
      if (toast?.duration > 0) {
        setTimeout(() => this.toasts.pop(), toast?.duration);
      } else if (toast?.duration === 0) {
        setTimeout(() => this.toasts.pop(), 5000);
      }
    });
  }

  setOffset(index: number) {
    let totalOffset = 0;
    this.toasts.forEach((toast, i) => {
      if (i > index) {
        const elm = this.renderer.selectRootElement('.toast-element' + i + ' .toast', true);
        totalOffset = totalOffset + elm.offsetHeight;
      }
    });
    this.toasts[index].offsetHeight = totalOffset === 0 ? 0 : totalOffset + 8;
    this.cdr.detectChanges();
  }

  remove(index: number) {
    this.toasts = this.toasts.filter((v, i) => i !== index);
    this.toasts.forEach(toast => {
      toast.offsetHeight = 0;
    });
  }
}
