import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Toastr } from '../toastr';

@Component({
  selector: 'app-toastr-template',
  templateUrl: './toastr-template.component.html',
  styleUrls: ['./toastr-template.component.scss'],
})
export class ToastrTemplateComponent {
  @Input() data: Toastr;
  @Input() index: number;
  @Output() remove = new EventEmitter<number>();
  close() {
    this.remove.emit(this.index);
  }
}
