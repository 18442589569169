import {
  ChangeDetectorRef,
  Component,
  DestroyRef,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  AuthenticatedUser,
  AuthenticatedUserQuery,
  ErrorsHandlerService,
  PaymentQuery,
  PaymentStateService,
  ProposalRoundService,
  ProposalStateService,
  SEOService,
  SharedService,
  TezosWalletService,
  ToasterService,
  UnAuthAction,
  WindowScrollService,
} from '../../../shared';
import { ActivatedRoute, Params, Router } from '@angular/router';
import {
  AboutProposalData,
  CheckoutData,
  Genre,
  Language,
  PricingFees,
  Proposal,
  ProposalMediaData,
  ProposalRound,
  ProposalRoundData,
  ProcessStatus,
  RedirectDialogData,
  SubmitProposalRequest,
  ExternalCheckoutRequest,
  ExternalCheckoutAction,
  ProposalVideoItem,
  ProposalImageItem,
  ProposalDocsItem,
} from '../../../app.datatypes';
import { DomSanitizer } from '@angular/platform-browser';
import { EMPTY, forkJoin, from, throwError, Observable, of, firstValueFrom, timer } from 'rxjs';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { switchMap, tap, catchError } from 'rxjs/operators';
import { ComponentCanDeactivateDirective } from '../../../shared/services/component-can-deactivate';
import { fade, panelIn } from '../../../app.animations';
import { environment } from '../../../../environments';
import { DialogService } from '../../../shared/dialog/dialog.service';
import { JoyrideService } from 'ngx-joyride';
import { GlobalTourService } from '../../../shared/services/global-tour-service.service';
import { RedirectDialogComponent } from '../../../shared/modules/dialog/redirect-dialog/redirect-dialog.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { GalleryService } from '../../../shared/services/gallery.service';

@Component({
  selector: 'app-proposal-create',
  templateUrl: './proposal-create.component.html',
  styleUrls: ['./proposal-create.component.scss'],
  animations: [
    trigger('fadeInOut', [
      state('in', style({ opacity: 100 })),
      transition('* => void', [animate(300, style({ opacity: 0 }))]),
    ]),
    panelIn,
    fade,
  ],
  providers: [GalleryService],
})
export class ProposalCreateComponent extends ComponentCanDeactivateDirective implements OnInit {
  @ViewChild('imageInput', { static: true }) imageInput: ElementRef;
  @ViewChild('docInput', { static: true }) docInput: ElementRef;
  @ViewChild('title', { static: false }) titleElem: ElementRef;
  @ViewChild('additionalBounty', { static: false }) additionalBountyElem: ElementRef;
  @ViewChild('tab1', { static: false }) tab1Elem: ElementRef;
  @ViewChild('tab2', { static: false }) tab2Elem: ElementRef;
  @ViewChild('tab3', { static: false }) tab3Elem: ElementRef;
  @ViewChild('tab4', { static: false }) tab4Elem: ElementRef;
  @ViewChild('tab5', { static: false }) tab5Elem: ElementRef;
  @ViewChild('tab6', { static: false }) tab6Elem: ElementRef;

  @Input() createMode?: boolean;
  @Input() selectedRoundId: string;
  @Output() closeCreateProposalSidebar = new EventEmitter();
  stepTwoButtonClicked = false;
  // @splitting form into groups
  days = 20;
  imageFile: string;
  isStepOneReady = false;
  isStepTwoReady = false;
  isStepFourReady = false;
  tabOneActive = true;
  tabTwoActive = false;
  tabThreeActive = false;
  tabFourActive = false;
  stepperSelectedIndex = 0;
  selectedRound: ProposalRound;
  isNew = true;
  user: AuthenticatedUser;
  proposalStatus = ProcessStatus;
  proposal: Proposal;
  proposalReview: Proposal;
  minBountyToBeAdded: number;
  roundList: ProposalRound[];
  languageList: Language[];
  genreList: Genre[];
  submitFeeFilm = null;
  submitFeeDollars = null;
  prices: PricingFees;
  readyToSave = false;
  submitting = false;
  completed = false;
  isBountyUpdated = false;
  uploadedCroppedCoverImage: string;
  mediaList = [];
  coverId: string;
  proposalMediaData: ProposalMediaData;
  isCoverUploaded = false;
  stepOneData: ProposalRoundData;
  stepTwoData: AboutProposalData;
  stepFiveData: CheckoutData;
  isSavedDraft = false;
  isBalanceZero = false;
  isLoading = true;

  constructor(
    private proposalStateService: ProposalStateService,
    private proposalRoundService: ProposalRoundService,
    private paymentQuery: PaymentQuery,
    private paymentStateService: PaymentStateService,
    private toastService: ToasterService,
    private router: Router,
    private route: ActivatedRoute,
    public sanitizer: DomSanitizer,
    private _seoService: SEOService,
    private errorService: ErrorsHandlerService,
    public windowScrollService: WindowScrollService,
    private authenticatedUserQuery: AuthenticatedUserQuery,
    private cdRef: ChangeDetectorRef,
    private tezosWalletService: TezosWalletService,
    private dialogService: DialogService,
    public readonly joyrideService: JoyrideService,
    private globalTourService: GlobalTourService,
    private destroyRef: DestroyRef,
    private sharedService: SharedService,
    private galleryService: GalleryService
  ) {
    super();
    this.route.queryParams.subscribe((params: Params) => {
      this.stepperSelectedIndex = params['step'] || 0;
      if (params['createMode']) {
        this.createMode = params['createMode'];
      }
    });
    this.globalTourService.tourClosed.subscribe(() => {
      this.closeCreateForm();
    });
    this.globalTourService.tourAction.subscribe((action: string) => {
      switch (action) {
        case 'next1':
          this.moveToTab2();
          break;
        case 'next2':
          this.moveToTab3();
          break;
      }
    });

    this.sharedService.getStopLoader().subscribe((loader: boolean) => {
      this.isLoading = loader;
    });
  }

  ngOnInit(): void {
    this.windowScrollService.hideFooter();

    this.authenticatedUserQuery.authenticatedUser$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((user: AuthenticatedUser) => {
        this.user = user;
      });

    this.authenticatedUserQuery.unAuthAction
      .pipe(
        switchMap((action: UnAuthAction) => {
          if (action === null && this.authenticatedUserQuery.isRegistrationComplete) {
            return this.prepareForSubmit();
          }
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();

    this._seoService.createTitleForPage();

    forkJoin([
      this.proposalRoundService.getOpenProposalRounds(),
      this.proposalStateService.getGenres(),
      this.proposalStateService.getLanguages(),
    ])
      .pipe(
        switchMap((results) => {
          this.roundList = results[0];
          this.genreList = results[1];
          this.languageList = results[2];
          const naIndex = this.genreList.findIndex((item) => item.slug === 'N/A');
          this.genreList.unshift(this.genreList.splice(naIndex, 1)[0]);

          if (this.route.snapshot.params && this.route.snapshot.params['id']) {
            return this.proposalStateService.getProposal(this.route.snapshot.params['id']).pipe(
              tap((proposalResponse: Proposal) => {
                this.proposal = proposalResponse;
                this.selectedRoundId = this.proposal.proposal_round_id;
                if (this.proposal.blockchain_confirmed) {
                  this.getSelectedRound();
                }
                this.setData();
                this.isSavedDraft = true;
                this.isNew = false;
              })
            );
          } else {
            this.proposal = new Proposal();
            this.galleryService.setImageScaled(false);
            this.isStepOneReady = true;
            this.activateTab(1);
            this.isLoading = false;
            if (!this.joyrideService.isTourInProgress() && this.user.drafts) {
              const data: RedirectDialogData = {
                title: 'You do have draft projects.',
                message: 'Do you want to review your draft projects before you start new one?',
                button: 'Go to Your projects',
                link: 'user/projects',
              };

              return this.dialogService.open(RedirectDialogComponent, data).pipe(
                tap((result) => {
                  if (result?.success) {
                    this.closeCreateProposalSidebar.emit();
                  }
                })
              );
            }
            return EMPTY;
          }
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();
    this.refreshFees();
  }

  setData(): void {
    this.calculateMinBountyToBeAdded();
    this.isStepOneReady = true;
    this.buildMedia();
    if (this.proposal.blockchain_confirmed) {
      this.tabTwoActive = true;
    }
    this.isLoading = false;
  }

  setStepOne(data: ProposalRoundData): void {
    this.stepOneData = data;
    this.isStepTwoReady = true;
    this.cdRef.detectChanges();
    this.selectedRound = data.selectedRound;
    this.calculateMinBountyToBeAdded();
    if (!this.isNew) {
      this.setAllStepsData();
    }
  }

  setStepTwo(data: AboutProposalData): void {
    this.stepTwoData = data;
    this.saveProposalMedia(this.stepTwoData);

    if (this.stepTwoData.isValid) {
      this.isStepFourReady = true;
      this.saveDraft();
    }
    this.cdRef.detectChanges();
  }

  setStepFive(data: CheckoutData): void {
    this.stepFiveData = data;
    this.cdRef.detectChanges();
  }

  getSelectedRound(): void {
    if (this.roundList?.length) {
      this.selectedRound = this.roundList.find((round: ProposalRound) => round._id === this.selectedRoundId);
      const data: ProposalRoundData = {
        proposal_round_id: this.selectedRoundId,
        isValid: true,
        selectedRound: this.selectedRound,
      };
      this.setStepOne(data);
    }
  }

  setAllStepsData(): void {
    if (this.proposal) {
      this.stepFiveData = {
        bounty: this.proposal.bounty ? this.proposal.bounty : 0,
        extra_bounty:
          !this.proposal.blockchain_confirmed && this.proposal.bounty
            ? this.proposal.bounty - this.selectedRound.minimum_bounty
            : 0,
        termsAccepted: false,
        isValid: false,
        is_external_checkout: false,
      };
    }
  }

  buildMedia(): void {
    if (this.proposal) {
      let index = 1;
      if (this.proposal?.cover_url) {
        this.uploadedCroppedCoverImage = this.proposal?.cover_url;
        this.isCoverUploaded = true;
      }
      // Reset mediaList when rebuilding the list to prevent duplicated entries.
      this.mediaList = [];
      this.processVideos(index);
      this.processImages(index);
      this.processDocs(index);

      this.proposalMediaData = {
        videos: this.proposal?.videos,
        images: this.proposal?.images,
        docs: this.proposal?.docs,
        cover_id: this.proposal?.cover_id,
        cover_url: this.proposal?.cover_url,
        cover_type: this.proposal?.cover_type,
      };
      this.refreshFees();
    }
  }

  private processVideos(index: number): void {
    if (this.proposal.videos?.length) {
      for (const video of this.proposal.videos) {
        const data = {
          index: ++index,
          type: 'video',
          value: video.url,
          title: video.mediaTitle,
          description: video.description,
          videoData: video.url,
          videoBlob: video.videoBlob,
          isDirectVideoUpload: video.isDirectVideoUpload,
          file_id: video.file_id,
        };
        this.mediaList.push(data);
      }
    }
  }

  private processImages(index: number): void {
    if (this.proposal.images?.length) {
      for (const image of this.proposal.images) {
        const data = {
          index: ++index,
          type: 'image',
          value: image.url,
          title: image.mediaTitle,
          description: image.description,
        };
        this.mediaList.push(data);
      }
    }
  }

  private processDocs(index: number): void {
    if (this.proposal.docs?.length) {
      for (const doc of this.proposal.docs) {
        const data = {
          index: ++index,
          type: 'file',
          value: doc.url,
          title: doc.docTitle,
          description: doc.description,
          fileData: doc.file_preview_url,
        };
        this.mediaList.push(data);
      }
    }
  }

  saveProposalMedia(data: AboutProposalData): void {
    this.mediaList = data?.mediaList;
    this.coverId = data?.coverId;
    this.uploadedCroppedCoverImage = data?.cover_url;
    this.isCoverUploaded = data?.isCoverUploaded;
    const videoUrl = [];
    const imageUrl = [];
    const docUrl = [];
    for (const item of this.mediaList) {
      if (item?.type === 'video') {
        const videoData = {
          url: item?.value,
          mediaTitle: item?.title,
          description: item?.description,
          thumbnailUrl: item?.thumbnailUrl,
          isDirectVideoUpload: item?.isDirectVideoUpload,
          file_id: item?.file_id,
          videoBlob: item?.videoBlob,
        };
        videoUrl.push(videoData);
      }
      if (item?.type === 'image') {
        const imageData = {
          url: item?.value,
          mediaTitle: item?.title,
          description: item?.description,
        };
        imageUrl.push(imageData);
      }
      if (item?.type === 'file') {
        const fileData = {
          url: item?.value,
          file_preview_url: item?.fileData,
          docTitle: item?.title,
          description: item?.description,
        };
        docUrl.push(fileData);
      }
    }

    this.proposalMediaData = {
      videos: videoUrl,
      images: imageUrl,
      docs: docUrl,
      cover_id: this.coverId,
      cover_url: this.uploadedCroppedCoverImage,
      cover_type: 'image',
    };

    this.refreshFees();
  }

  refreshFees(): void {
    this.proposalReview = new Proposal();
    this.proposalReview.images = [];
    this.proposalReview.videos = [];
    this.proposalReview.docs = [];
    this.proposalReview.cover_type = 'image';

    let images = [];
    let videos = [];
    let docs = [];

    if (this.mediaList?.length || this.isCoverUploaded) {
      images = this.proposalMediaData.images;
      videos = this.proposalMediaData.videos;
      docs = this.proposalMediaData.docs;
      this.proposalReview.cover_url = this.proposalMediaData.cover_url;
      this.proposalReview.cover_type = this.proposalMediaData.cover_type;
    }

    if (videos) {
      for (const video of videos) {
        this.proposalReview.videos.push({
          file_id: video?.file_id,
          url: video?.url,
          videoBlob: video.videoBlob,
          thumbnailUrl: video?.thumbnailUrl,
          mediaTitle: video?.mediaTitle,
          description: video?.description,
          isDirectVideoUpload: video?.isDirectVideoUpload,
        });
      }
    }
    if (images) {
      for (const image of images) {
        this.proposalReview.images.push({
          url: image?.url,
          mediaTitle: image.mediaTitle,
          description: image.description,
        });
      }
    }
    if (docs) {
      for (const doc of docs) {
        this.proposalReview.docs.push({
          url: doc?.url,
          docTitle: doc?.docTitle,
          description: doc?.description,
          file_preview_url: doc?.file_preview_url,
        });
      }
    }

    this.prices = this.paymentQuery.fees;
    this.galleryService.setProposal(this.proposalReview);
  }

  activateTab(index: number): void {
    this.tabOneActive = false;
    this.tabTwoActive = false;
    this.tabThreeActive = false;
    this.tabFourActive = false;
    switch (index) {
      case 1:
        this.tabOneActive = true;
        break;
      case 2:
        this.tabTwoActive = true;
        break;
      case 3:
        this.tabThreeActive = true;
        break;
      case 4:
        this.tabFourActive = true;
        break;
      default:
        this.tabOneActive = true;
        break;
    }
    if (index > 3) {
      this.refreshFees();
    }
  }

  moveToTab1(): void {
    document.getElementById('tab1').click();
    this.activateTab(1);
  }

  moveToTab2(): void {
    document.getElementById('tab2')?.click();
    this.activateTab(2);
  }

  moveToTab3(): void {
    document.getElementById('tab4').click();
    this.activateTab(3);
  }

  moveToTab4(): void {
    document.getElementById('tab5').click();
    this.activateTab(4);
  }

  calculateMinBountyToBeAdded(): void {
    const roundBounty = this.selectedRound ? this.selectedRound.minimum_bounty : 0;
    const proposalBounty = this.proposal && this.proposal.bounty ? this.proposal.bounty : 0;
    if (this.proposal.blockchain_confirmed) {
      this.minBountyToBeAdded = Math.max(roundBounty, proposalBounty);
    } else {
      this.minBountyToBeAdded = roundBounty;
    }
  }

  canDeactivate(): boolean {
    return !this.readyToSave;
  }

  closeCreateForm(): boolean {
    if (!this.canDeactivate()) {
      if (confirm('You have unsaved changes! If you leave, your changes will be lost.')) {
        this.closeCreateProposalSidebar.emit();
        return true;
      } else {
        return false;
      }
    } else {
      this.closeCreateProposalSidebar.emit();
    }
  }

  closeForm(): void {
    this.closeCreateProposalSidebar.emit();
  }

  getFees(): Observable<PricingFees> {
    return this.paymentStateService.getFees().pipe(
      tap(() => {
        if ((this.isNew || !this.proposal.blockchain_confirmed) && this.selectedRound) {
          this.submitFeeDollars = this.selectedRound.submission_fee;
          this.submitFeeFilm = this.paymentStateService.calculateDollarToFilmPricing(this.submitFeeDollars);
        } else if (this.proposal.blockchain_confirmed && !this.isNew) {
          this.submitFeeDollars = this.paymentQuery.proposalResubmissionFee;
          this.submitFeeFilm = this.paymentStateService.calculateDollarToFilmPricing(this.submitFeeDollars);
        }
      })
    );
  }

  saveDraft(redirect = false): void {
    this.isLoading = true;
    this.getFees()
      .pipe(
        switchMap(() => {
          this.readyToSave = false;
          const submission_fee = Number(this.submitFeeFilm?.toFixed(8));
          if (this.proposalMediaData) {
            this.setProposalMediaData();
          }
          const merged = {
            ...this.stepOneData,
            ...this.stepTwoData,
            ...this.stepFiveData,
            ...this.proposalMediaData,
            submission_fee,
          };
          if (!this.isSavedDraft) {
            return this.proposalStateService.saveProposalAsDraft(merged);
          } else {
            return this.proposalStateService.updateProposalDraft(this.proposal._id, merged);
          }
        }),
        catchError((error) => {
          errorHandler(error);
          return throwError(() => error);
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe((proposal: Proposal) => {
        this.proposal = proposal;
        this.isSavedDraft = true;
        this.readyToSave = false;
        if (redirect) {
          this.router.navigate(['/account/wallet'], {
            queryParams: {
              redirectUri: `/proposal/${proposal._id}/edit`,
            },
          });
        }
        this.isLoading = false;
      });

    const errorHandler = (error) => {
      this.refreshFees();
      if (
        error?.error?.errors?.bounty?.length &&
        error?.error?.errors?.bounty[0] === 'The bounty may not be greater than 0.'
      ) {
        this.isBalanceZero = true;
      } else {
        this.toastService.openToastr('There was a problem saving your project', 'Saving Error');
      }
      this.isLoading = false;
    };
  }

  submit(): void {
    this.getFees()
      .pipe(
        switchMap(() => this.prepareForSubmit()),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();
  }

  private prepareForSubmit(): Observable<Proposal> {
    if (this.authenticatedUserQuery.isRegistrationComplete) {
      this.sharedService.setStopLoader(true);
      this.setProposalMediaData();
      if (!this.submitFeeFilm) {
        this.submitting = false;
        this.toastService.openErrorToastr('There was a problem determining the submission fee', 'Submission Error');
      }
      if (this.isNew || !this.proposal.blockchain_confirmed) {
        return this.newSubmit();
      } else {
        return this.resubmit();
      }
    } else {
      this.authenticatedUserQuery.unAuthAction.next({ slug: 'Create Proposal', action: 'verify' });
    }
  }

  newSubmit(): Observable<Proposal> {
    const submission_fee = this.submitFeeFilm ? Number(this.submitFeeFilm.toFixed(6)) : 0;
    const merged = {
      ...this.stepOneData,
      ...this.stepTwoData,
      ...this.stepFiveData,
      ...this.proposalMediaData,
      submission_fee,
    };

    if (this.proposal._id) {
      merged.proposal_id = this.proposal._id;
    }

    return this.proposalStateService.prepareProposalForSubmit(merged).pipe(
      switchMap((proposal: Proposal) => {
        this.proposal = proposal;
        this.isSavedDraft = true;
        const submitReq = new SubmitProposalRequest();
        submitReq.proposalId = this.proposal._id;
        submitReq.nonce = TezosWalletService.randomNonce;

        if (environment.readOnlyMode) {
          this.authenticatedUserQuery.openMaintainanceModal();
          return;
        }

        return from(
          this.tezosWalletService.submitProposalSignature(
            proposal.blockchain_id,
            proposal.submission_fee || 0,
            proposal.bounty || 0,
            {
              contributionId: proposal.submission_contribution,
              transactionId: proposal.submission_transaction,
            },
            submitReq.nonce
          )
        ).pipe(
          switchMap((signature) => {
            submitReq.signature = signature.sig;
            submitReq.pk = this.tezosWalletService.connectedWalletPublicKey;

            return this.proposalStateService
              .submitProposal(submitReq)
              .pipe(switchMap((data: Proposal) => this.checkSubmitProposalStatus(proposal, data)));
          })
        );
      }),
      tap((proposal: Proposal) => this.processProposalSubmit(proposal)),
      catchError((error) => {
        this.refreshFees();
        this.submitting = false;
        this.isLoading = false;
        this.sharedService.setStopLoader(false);
        this.submitErrorHandler(error);
        return throwError(() => error);
      })
    );
  }

  private processProposalSubmit(proposal: Proposal): void {
    if (proposal && proposal.status === ProcessStatus.WAITING_FOR_PAYMENT) {
      const externalCheckoutRequest = new ExternalCheckoutRequest();
      externalCheckoutRequest.action =
        this.isNew || !proposal.blockchain_confirmed
          ? ExternalCheckoutAction.PROPOSAL_SUBMIT
          : ExternalCheckoutAction.PROPOSAL_RESUBMIT;
      externalCheckoutRequest.reference_id = proposal._id;
      externalCheckoutRequest.payment_id = proposal.pending_payment_id;
      this.paymentStateService.externalCheckout$.emit(externalCheckoutRequest);
    } else {
      this.submitSuccessHandler(proposal);
    }
    this.isLoading = false;
  }

  private submitErrorHandler(error: Error): void {
    this.refreshFees();
    this.toastService.openToastr('There was a problem submitting your project', 'Submission Error', 'error', 3000);
    this.errorService.handleSubmitError(error);
    this.submitting = false;
    this.isLoading = false;
  }

  private submitSuccessHandler(proposal: Proposal): void {
    this.toastService.openToastrWithLink(
      'Thank you for your submission. Your project must be moderated before becoming visible to other users.',
      'Project Submitted',
      'info',
      5000,
      'account/activity',
      'proposals',
      'Check Activity'
    );
    this.readyToSave = false;
    this.closeCreateProposalSidebar.emit();
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate(['/proposal', proposal._id]));
  }

  checkSubmitProposalStatus(proposal: Proposal, data: Proposal): Observable<Proposal> {
    this.submitting = false;
    if (proposal.status === this.proposalStatus.WAITING_FOR_SUBMISSION) {
      return this.proposalStateService.updateDraftProposal(proposal._id);
    } else {
      return of(data);
    }
  }

  resubmit(): Observable<Proposal> {
    const resubmission_fee = this.submitFeeFilm ? Number(this.submitFeeFilm.toFixed(6)) : 0;
    const merged = {
      ...this.stepOneData,
      ...this.stepTwoData,
      ...this.stepFiveData,
      ...this.proposalMediaData,
      resubmission_fee,
      blockchain_id: this.proposal.blockchain_id,
    };

    return this.proposalStateService.prepareProposalForResubmit(this.proposal._id, merged).pipe(
      switchMap((proposal: Proposal) => {
        const submitReq = new SubmitProposalRequest();
        submitReq.proposalId = proposal._id;
        submitReq.nonce = TezosWalletService.randomNonce;
        if (environment.readOnlyMode) {
          this.authenticatedUserQuery.openMaintainanceModal();
          return;
        }

        return from(
          this.tezosWalletService.resubmitProposalSignature(
            proposal.blockchain_id,
            proposal.resubmission_fee || 0,
            proposal.extra_bounty || 0,
            {
              contributionId: proposal.resubmission_contribution,
              transactionId: proposal.resubmission_transaction,
            },
            submitReq.nonce
          )
        ).pipe(
          switchMap((signature) => {
            submitReq.signature = signature.sig;
            submitReq.pk = this.tezosWalletService.connectedWalletPublicKey;
            return this.proposalStateService
              .resubmitProposal(submitReq)
              .pipe(switchMap((data: Proposal) => this.checkResubmitProposalStatus(proposal, data)));
          })
        );
      }),
      tap((proposal: Proposal) => this.processProposalSubmit(proposal)),
      catchError((error) => {
        this.refreshFees();
        this.submitting = false;
        this.resubmitErrorHandler(error);
        return throwError(() => error);
      })
    );
  }

  private resubmitErrorHandler(error: Error): void {
    this.submitting = false;
    this.toastService.openToastr('There was a problem updating your project', 'Update Error', 'error', 3000);
    this.errorService.handleSubmitError(error);
  }

  setProposalMediaData(): void {
    this.proposalMediaData.videos = this.proposalMediaData?.videos?.map((value: ProposalVideoItem) => {
      return value;
    });
    this.proposalMediaData.images = this.proposalMediaData?.images?.map((value: ProposalImageItem) => {
      return value;
    });
    this.proposalMediaData.docs = this.proposalMediaData?.docs?.map((value: ProposalDocsItem) => {
      return value;
    });
  }

  checkResubmitProposalStatus(proposal: Proposal, data: Proposal): Observable<Proposal> {
    this.submitting = false;
    if (
      proposal.status === ProcessStatus.WAITING_FOR_SUBMISSION ||
      proposal.status === ProcessStatus.DENIED ||
      proposal.status === ProcessStatus.APPROVED
    ) {
      return this.proposalStateService.updateDraftProposal(proposal._id);
    } else {
      return of(data);
    }
  }

  validateStepTwoForm(): void {
    if (!this.stepTwoData.isValid) {
      this.stepTwoButtonClicked = true;
      this.globalTourService.createFormValidation.emit();
    } else {
      this.moveToTab3();
    }
  }
}
