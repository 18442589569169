import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LoadingService { // This service manually triggers (for using out of Store)
  private loading$ = new ReplaySubject<boolean>(1);
  private loadingCalls = 0;
  
  isLoading(): Observable<boolean> {
    return this.loading$.asObservable().pipe(
      distinctUntilChanged()
    )
  }

  changeStatus(process: boolean): void {
    process ? this.loadingCalls++ : this.loadingCalls--;
    this.loading$.next(this.loadingCalls > 0);
  }
}
