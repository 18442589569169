import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Proposal, ProposalRound } from '../../../../app.datatypes';
import { LoadingService } from '../loading/loading.service';
import { ApiService } from '../../api.service';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { ProposalStore } from '../../../state';
import { HorizontalEntitiesLimit, HorizontalSliderService } from '../horizontal-slider.service';

interface DoFetchConfig {
  scrolled: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class ProposalByRoundService {
  limit = {} as HorizontalEntitiesLimit;
  private isLastPage = false;
  private isLastPageIsHistory = false;
  public proposalRounds$ = new BehaviorSubject<ProposalRound[]>([]);
  private doFetch$ = new BehaviorSubject<DoFetchConfig | undefined>(null);
  private isVisible = new BehaviorSubject<boolean>(true);

  private loadingService = inject(LoadingService);
  private apiService = inject(ApiService);
  private proposalStore = inject(ProposalStore);

  private horizontalSiderService = inject(HorizontalSliderService);

  getProposalsByRounds(): Observable<ProposalRound[]> {
    return this.doFetch$.pipe(
      tap(() => this.loadingService.changeStatus(true)),
      switchMap((config: DoFetchConfig) => {
        if (!config || (!config.scrolled && this.proposalRounds$.getValue().length)) {
          return this.proposalRounds$.asObservable();
        } else {
          if (config) {
            if (this.isLastPage) {
              return this.getPortionOfProposalRounds(true);
            } else {
              return this.getPortionOfProposalRounds();
            }
          } else {
            return this.getPortionOfProposalRounds();
          }
        }
      }),
      tap(() => this.loadingService.changeStatus(false))
    );
  }

  doFetch(config?: DoFetchConfig): void {
    if (!this.isLastPageIsHistory) {
      this.doFetch$.next(config);
    }
  }

  getPortionOfProposalRounds(isHistory = false): Observable<ProposalRound[]> {
    this.limit = this.horizontalSiderService.limit;
    const historyParam = isHistory ? '/history' : '';
    const api = `api/proposals${historyParam}/by-round?limit=${this.limit.parent}&skip=${this.limit.skip}&proposals_limit=${this.limit.child}`;
    return this.apiService.get(api).pipe(
      tap((awards: ProposalRound[]) => {
        awards.forEach((award: ProposalRound) => {
          const pagination = this.horizontalSiderService.handlePaginationProperties(award.proposals.length);
          Object.assign(award, pagination);
          this.proposalStore.add(award.proposals);
        });
      }),
      switchMap((awards: ProposalRound[]) => {
        const isLastPageResult = awards.length < this.limit.parent;
        this.limit.skip = this.limit.skip + awards.length;
        if (isHistory) {
          this.isLastPageIsHistory = isLastPageResult;
        } else {
          this.isLastPage = isLastPageResult;
          if (this.isLastPage) {
            this.limit.skip = 0;
          }
        }

        this.proposalRounds$.next([...this.proposalRounds$.getValue(), ...awards]);
        return isLastPageResult && !this.isLastPageIsHistory
          ? this.getPortionOfProposalRounds(true)
          : this.proposalRounds$.asObservable();
      }),
      catchError((error) => this.apiService.catchError(error))
    );
  }

  getProposalsByRoundId(id: string, skip: number, limit?: number, showAll = false): Observable<Proposal[]> {
    this.limit = this.horizontalSiderService.limit;
    let url = 'api/proposals/paginate-by-round';
    if (!showAll) {
      return this.apiService
        .get(`${url}/${id}?limit=${this.limit.parent}&skip=${skip}`)
        .pipe(tap((proposals: Proposal[]) => this.addProposalToRounds(proposals, id)));
    } else {
      return this.apiService
        .get(`${url}/${id}?limit=${limit}&skip=${skip}`)
        .pipe(tap((proposals: Proposal[]) => this.updateProposalToRound(proposals)));
    }
  }

  addProposalToRounds(proposals: Proposal[], id: string): void {
    const proposalRounds = this.proposalRounds$.getValue();
    proposalRounds.forEach((proposalRound: ProposalRound) => {
      if (proposalRound._id === id) {
        if (!proposalRound.proposals) {
          proposalRound.proposals = [];
        }
        proposalRound.proposals.push(...proposals);
      }
      this.proposalRounds$.next(proposalRounds);
    });
    this.proposalStore.add(proposals);
  }

  updateProposalToRound(proposals: Proposal[]): void {
    this.proposalStore.add(proposals);
  }

  getProposalByRounds(): Observable<ProposalRound[]> {
    return this.proposalRounds$.asObservable();
  }

  resetProposalByRounds(): void {
    this.proposalRounds$.next([]);
  }

  resetAll(): void {
    this.isLastPage = false;
    this.isLastPageIsHistory = false;
    this.proposalRounds$.next([]);
    this.doFetch$.next(null);
    this.limit.skip = 0;
  }

  setVisibilityStatus(isVisible: boolean): void {
    this.isVisible.next(isVisible);
  }

  getVisibilityStatus(): boolean {
    return this.isVisible.getValue();
  }
}
