<button class="btn-icon-transparent icon-black" [appDropdownMenu]="dropdown">
  <app-icon [icon]="'share'"></app-icon>
</button>
<app-dropdown-menu #dropdown class="drop-menu" [@dropdown] [size]="'sm'">
  <div class="drop-menu" (mouseleave)="actions.emit()">
    <button (click)="mailMe()" class="menu-button">
      <app-icon [icon]="'mail'" class="w-16 h-16 mr-8 -mb-3"></app-icon>
      Email Link
    </button>
    <button (click)="copyMessage()" class="menu-button">
      <app-icon [icon]="'copy'" class="w-16 h-16 mr-8 -mb-3"></app-icon>
      Copy Link
    </button>
    <button (click)="copyMessage(true)" *ngIf="actionName" class="menu-button">
      <app-icon [icon]="'proposal'" class="w-16 h-16 mr-8 -mb-3"></app-icon>
      Project Link
    </button>
    <a href="https://www.facebook.com/sharer.php?u={{ shareLink }}" class="menu-button">
      <app-icon [icon]="'facebook'" class="w-16 h-16 mr-8 -mb-3"></app-icon>
      Facebook
    </a>
    <a href="https://twitter.com/intent/tweet?url={{ shareLink }}" class="menu-button">
      <app-icon [icon]="'tweeter'" class="w-16 h-16 mr-8 -mb-3"></app-icon>
      Twitter
    </a>
    <a href="https://api.whatsapp.com/send/?phone&text={{ shareLink }}" class="menu-button">
      <app-icon [icon]="'whatsapp'" class="w-16 h-16 mr-8 -mb-3"></app-icon>
      Whatsapp
    </a>
    <a href="https://t.me/share/url?url={{ shareLink }}" class="menu-button">
      <app-icon [icon]="'telegram'" class="w-16 h-16 mr-8 -mb-3"></app-icon>
      Telegram
    </a>
  </div>
</app-dropdown-menu>
