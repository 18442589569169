import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RedirectDialogData } from '../../../../app.datatypes';
import { DialogContext } from '../../../../shared/dialog/dialog-context';
import { DialogService } from '../../../../shared/dialog/dialog.service';

@Component({
  selector: 'app-redirect-dialog',
  templateUrl: './redirect-dialog.component.html'
})
export class RedirectDialogComponent implements OnInit {
  title: string;
  message: string;
  button: string;
  link: string;

  constructor(
    public dialog: DialogContext<RedirectDialogData>,
    private dialogService: DialogService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.title = this.dialog.data.title;
    this.message = this.dialog.data.message;
    this.button = this.dialog.data.button;
    this.link = this.dialog.data.link;
  }

  onRedirect() {
    this.router.navigate([this.link]);
    this.dialog.close();
    const data = { success: true };
    this.dialogService.dialogBehaviour.next(data);
  }

  onCancel() {
    this.dialog.close();
    const data = { success: false };
    this.dialogService.dialogBehaviour.next(data);
  }
}
