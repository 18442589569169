import { Component, Input } from '@angular/core';
import { Option, Question } from '../../../../app.datatypes';
import { FilePreviewOverlayService } from '../../../../shared/services';
import { VideoConfig } from "../../../../shared/interface/video-config.interface";

@Component({
  selector: 'app-preview-create-query',
  templateUrl: './preview-create-query.component.html'
})
export class PreviewCreateQueryComponent {
  @Input() questions: Question[];

  constructor(private previewDialog: FilePreviewOverlayService) {}

  showPreview(event, file) {
    this.previewDialog.open({
      image: file,
    });
    event.stopPropagation();
  }

  generateVideoConfig(option: Option): VideoConfig {
    return {
      classes: 'absolute top-0 left-0 w-full h-full',
      poster: option?.thumbnail_url,
      src: option.videoBlobUrl
    }
  }
}
