<form [formGroup]="form" class="p-24 overflow-y-auto" autocomplete="off" style="height: calc(100% - 72px)">
  <div class="grid sm:grid-cols-2 gap-32">
    <div>
      <app-logo class="logo-black w-128 block mb-16"></app-logo>
      <label class="mb-16 d-block sm:mb-8 text-base font-semibold">
        Please review and agree to the Terms of Submission and choose your preferred payment method for submission. Good
        luck with your submission.
      </label>
      <app-dcp-checkout
        [isLoading]="isLoading"
        class="block max-w-500"
        [user]="user"
        [films]="checkoutAmount"
        (changeCheckoutType)="changeCheckoutType($event)"
        (formValidity)="checkoutFormValidityChange($event)"
      >
      </app-dcp-checkout>
      <p class="-mt-8 text-error mb-16" *ngIf="checkoutAmount > user.balance && !isExternalCheckout">
        You have an insufficient Current Balance for this amount. Please choose another payment method to continue with
        this amount.
      </p>
      <label class="checkbox">
        <input type="checkbox" id="terms" (change)="setChecked()" formControlName="termsAccepted" />
        <span class="checkmark" style="border-width: 2px !important"></span>
        By clicking this checkbox, you agree to our
        <a rel="noopener noreferrer" href="https://decentralized.pictures/terms-of-service" target="_blank"
          >Terms of Service</a
        >
        and
        <a rel="noopener noreferrer" href="https://decentralized.pictures/community-guidelines" target="_blank"
          >Community Guidelines.</a
        >
      </label>
    </div>
    <div>
      <div class="rounded max-w-500 ml-auto">
        <app-bounty-info
          [title]="distribution?.blockchain_confirmed ? 'Resubmission summary' : 'Submission summary'"
          [isExternalCheckout]="isExternalCheckout"
          (recalculateTotal)="bountyTotalChange($event)"
          [submissionFee]="distribution?.blockchain_confirmed ? null : submissionFee"
          [canAdd]="false"
          [moderationFee]="moderationFee"
          [showMinBounty]="distribution?.reward_contribution"
          [minimumBounty]="distribution?.blockchain_confirmed ? null : distribution?.reward_contribution"
          [resubmissionFee]="distribution?.blockchain_confirmed ? submissionFee : null"
          class="mb-16"
        >
        </app-bounty-info>
      </div>
    </div>
  </div>
</form>
