<div class="flex w-full justify-between">
  <app-tabs class="flex-grow tabs-create-panel link flex flex-col">
    <app-tab-item *ngFor="let tab of tabs" [cursorClass]="'pointer'">
      <app-tab-label>{{ tab.title }}</app-tab-label>
      <app-tab-body>
        <div class="rules-wrapper" [ngClass]="{ expand: expanded }">
          <div class="rules-inner-wrapper">
            <div class="flex w-full justify-between">
              <p class="text-base font-bold" *ngIf="expanded">
                {{ tab.title }}
              </p>

              <button *ngIf="expanded" class="btn-icon-transparent icon-xs ml-auto icon-black" (click)="expand()">
                <app-icon [icon]="!expanded ? 'maximize' : 'minimize'"
                          [style.fill]="checkIconColor"
                ></app-icon>
              </button>
            </div>

            <div
              *ngIf="tab.content; else noCriteria"
              class="rules-content"
              [innerHTML]="tab.content | safeHtml">
            </div>
          </div>
        </div>

        <ng-template #noCriteria>
          <ng-container *ngIf="tab.title === criterion.Rules">
            <p class="rules-content">No Rules Available</p>
          </ng-container>

          <ng-container *ngIf="tab.title === criterion.Eligibility">
            <p class="rules-content">No Criteria for this Award</p>
          </ng-container>
        </ng-template>
      </app-tab-body>
    </app-tab-item>
  </app-tabs>

  <button *ngIf="!expanded" class="btn-icon-transparent icon-xs ml-auto icon-black btn-position" (click)="expand()">
    <app-icon [icon]="!expanded ? 'maximize' : 'minimize'"
              [style.fill]="checkIconColor"
    ></app-icon>
  </button>
</div>

