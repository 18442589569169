import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Toastr } from '../toastr';

@Component({
  selector: 'app-main-toastr',
  templateUrl: './main-toastr.component.html'
})
export class MainToastrComponent implements OnInit {
  @Input() toast: Toastr;
  @Input() i: number;
  @Output() remove = new EventEmitter<number>();
  @Output() setOffset = new EventEmitter<number>();

  ngOnInit(): void {
    setTimeout(() => {
      this.setOffset.emit(this.i);
    });
  }

  removeToastr(event) {
    this.remove.emit(event);
  }
}
