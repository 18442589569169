<form [formGroup]="checkoutForm" autocomplete="off">
  <div>
    <div class="flex justify-between gap-16">
      <p class="text-base font-semibold">Payment Method</p>
      <p class="text-sm text-blue flex items-center opacity-100 font-semibold">
        Current balance
        <app-icon [icon]="'film'" class="w-16 primary h-16 ml-8 mr-4 inline-block"></app-icon>
        {{ user?.balance | filmPrice }}
      </p>
    </div>

    <app-dcp-select
      [options]="paymentMethods"
      [disabled]="disabled"
      (selected)="$event && changePaymentMethod($event.slug)"
      [selectedDefault]="selectedPaymentMethod"
      placeholder="Payment Method"
      class="mb-16"
    ></app-dcp-select>

    <div
      class="w-full items-center flex gap-16 sm:flex-row flex-col"
      *ngIf="selectedPaymentMethod !== paymentMethodSlug.WALLET"
    >
      <div class="form-group xl">
        <label>Exchange Amount</label>
        <app-icon [icon]="'usd'" class="absolute top-32 left-12 w-32 h-32 opacity-50 mt-8"></app-icon>
        <input
          placeholder="USD"
          id="dollars"
          formControlName="dollars"
          style="padding-left: 48px !important; pointer-events: none !important"
        />
        <p class="error-msg" *ngIf="!fullyPaidInternal && checkoutForm.get('dollars')?.hasError('min')">
          Exchange Amount must be at least $0.01.
        </p>

        <p class="error-msg" *ngIf="checkoutForm.get('dollars').hasError('limitExceeded')">
          Exchange amount value cannot be grater then ${{ availableDailyLimitService.getDailyLimit() }}.
        </p>
      </div>
      <app-icon [icon]="'exchange'" class="w-16 min-w-16 h-16 rotate-90 sm:rotate-0"> </app-icon>

      <div class="form-group xl">
        <label>{{ showUSDC ? 'USDC' : 'FILM' }} Amount</label>
        <app-icon
          [icon]="showUSDC ? 'usdc' : 'film'"
          class="absolute top-32 left-12 w-32 h-32 opacity-50 mt-8"
        ></app-icon>
        <input
          id="films"
          formControlName="films"
          [placeholder]="showUSDC ? 'USDC' : 'FILM'"
          readonly
          style="padding-left: 48px !important; pointer-events: none !important"
        />
      </div>
    </div>
    <div
      class="info-box self-center bg-blue mb-24"
      *ngIf="checkoutForm.get('dollars').value < 1 && selectedPaymentMethod !== paymentMethodSlug.WALLET"
    >
      <app-icon [icon]="'warning'"> </app-icon>
      Minimum Exchange Amount is $1. Price will be adjusted at checkout. All additional funds purchased will be
      deposited to your internal wallet for future use
    </div>

    <div
      class="info-box self-center {{ showMessage ? 'warning' : 'error' }} mb-24"
      *ngIf="!externalOnly && (paymentError || showMessage)"
    >
      <app-icon [icon]="'warning'"></app-icon>
      <ng-container *ngIf="paymentError">
        {{ paymentError }}
      </ng-container>
      <ng-container *ngIf="showMessage">
        Are you sure you want to use your Current Balance for this payment? If yes, click Submit for Moderation.
        Otherwise, please edit your value below.
      </ng-container>
    </div>
    <div class="form-group xl" *ngIf="selectedPaymentMethod !== paymentMethodSlug.WALLET && user.balance && !showUSDC">
      <label>Use Current Balance (FILM)</label>
      <app-icon [icon]="'film'" class="absolute top-32 left-12 w-32 h-32 opacity-50 mt-8"></app-icon>
      <input
        id="internal_portion"
        style="padding-left: 48px !important; width: 220px"
        formControlName="internalPortion"
        type="number"
        min="0"
        max="maxInternalPortionAvailable"
      />
      <label class="text-xs"> Enter the value you wish to use from your Current Balance. </label>
      <p
        class="error-msg mb-16 !whitespace-normal !relative"
        *ngIf="checkoutForm.get('internalPortion').errors?.max && checkoutForm.get('internalPortion').touched"
      >
        {{ checkoutForm.get('internalPortion').errors.max }}
      </p>
    </div>
  </div>
</form>
