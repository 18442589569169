import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InboxSidebarComponent } from './inbox-sidebar.component';
import { NotificationComponent } from './inbox/notification/notification.component';
import { RouterModule } from '@angular/router';
import { EntityActionComponent } from './inbox/entity-action/entity-action.component';
import { SubEntityActionComponent } from './inbox/sub-entity-action/sub-entity-action.component';
import { IconModule } from '../../shared/modules/components/icon/icon.module';



@NgModule({
  declarations: [InboxSidebarComponent,NotificationComponent,EntityActionComponent,SubEntityActionComponent],
  imports: [
    CommonModule,IconModule,RouterModule
  ],
  exports: [InboxSidebarComponent]
})
export class InboxSidebarModule { }
