import {Injectable} from '@angular/core';
import {QueryEntity} from "@datorama/akita";
import {SwagProductState, SwagProductStore} from "./swag-product-state.service";
import { Subject } from 'rxjs';
import { SwagItem } from '../../../app.datatypes';

export interface ProcessedItem{totalAmount:number,items:SwagItem[]}

@Injectable({
  providedIn: 'root',
})
export class SwagProductQuery extends QueryEntity<SwagProductState> {
  processedItems$: Subject<ProcessedItem> = new Subject();
  constructor(protected store: SwagProductStore) {
    super(store);
  }

  getAllSwagProducts() {
    return this.selectAll({});
  }

  getAllCartProducts() {
    return this.selectAll({
      filterBy: (entity) => entity.addedToCart,
    });
  }

  getSwagProductById(swagProductId) {
    return this.getEntity(swagProductId);
  }

  handleCart(id, data: boolean) {
    this.store.update(id, { addedToCart: data });
  }

  handleEntity(id, data: { name: string; value: string | number }) {
    this.store.update(id, { [data.name]: data.value });
  }
}
