import { Component, Inject, HostListener, EventEmitter } from '@angular/core';
import { trigger, state, style, transition, animate, AnimationEvent } from '@angular/animations';

import { FilePreviewOverlayRef } from './file-preview-overlay-ref';
import { FILE_PREVIEW_DIALOG_DATA } from './file-preview-overlay.tokens';
import { Image, ProgressRef } from '../../../../services';
import { VideoConfig } from '../../../../interface/video-config.interface';

const ESCAPE = '27';
const ANIMATION_TIMINGS = '400ms cubic-bezier(0.25, 0.8, 0.25, 1)';
export enum FileType {
  PDF = 'pdf',
  IMAGE = 'image',
  VIDEO = 'video',
}
@Component({
  selector: 'app-file-preview-overlay',
  templateUrl: './file-preview.component.html',
  styleUrls: ['./file-preview.component.scss'],
  animations: [
    trigger('fade', [
      state('fadeOut', style({ opacity: 0 })),
      state('fadeIn', style({ opacity: 1 })),
      transition('* => fadeIn', animate(ANIMATION_TIMINGS)),
    ]),
    trigger('slideContent', [
      state('void', style({ transform: 'translate3d(0, 25%, 0) scale(0.9)', opacity: 0 })),
      state('enter', style({ transform: 'none', opacity: 1 })),
      state('leave', style({ transform: 'translate3d(0, 25%, 0)', opacity: 0 })),
      transition('* => *', animate(ANIMATION_TIMINGS)),
    ]),
  ],
})
export class FilePreviewOverlayComponent {
  loading = true;
  animationState: 'void' | 'enter' | 'leave' = 'enter';
  animationStateChanged = new EventEmitter<AnimationEvent>();
  progress: ProgressRef;
  file: Image;
  fileType = FileType;
  files: VideoConfig[];
  currentFileIndex: number;
  selectedFile: VideoConfig;
  @HostListener('document:keydown', ['$event']) private handleKeydown(event: KeyboardEvent) {
    if (event.key === ESCAPE) {
      this.dialogRef.close();
    }
  }

  constructor(public dialogRef: FilePreviewOverlayRef, @Inject(FILE_PREVIEW_DIALOG_DATA) image) {
    this.file = image;
    if (image.isArray) {
      this.files = image.files;
      this.currentFileIndex = this.files.findIndex((res) => res.src === this.file.url);
    } else if (image.type === FileType.VIDEO) {
      this.selectedFile = {
        poster: image.videoData.thumb,
        src: image.videoData.videoSrc + '?ngsw-bypass=true',
      };
    }
  }

  onLoad(): void {
    this.loading = false;
  }

  onAnimationStart(event: AnimationEvent): void {
    this.animationStateChanged.emit(event);
  }

  onAnimationDone(event: AnimationEvent): void {
    this.animationStateChanged.emit(event);
  }

  startExitAnimation(): void {
    this.animationState = 'leave';
  }

  loadingProgress(): void {
    this.loading = true;
  }

  pdfLoaded(): void {
    this.loading = false;
  }

  changeSlide(type: number): void {
    if (type === 0) {
      --this.currentFileIndex;
    } else {
      ++this.currentFileIndex;
    }
    this.selectedFile = this.files[this.currentFileIndex];
    this.selectedFile = this.files[this.currentFileIndex];
    this.file.type = this.selectedFile.type;
    this.file.url = this.selectedFile.src;
  }
}
