import { Injectable, ComponentRef, ViewContainerRef } from '@angular/core';
import { DialogContainer } from './dialog-container';

@Injectable()
export class DialogContext<T> {
  private componentRef: ComponentRef<DialogContainer>;
  private containerRef: ViewContainerRef;
  data?: T;
  public _resolve: Function;
  private _reject: Function;
  private _promise: Promise<any>; // any is ok here

  constructor() {}

  private hide() {
    this.containerRef?.remove(this.containerRef?.indexOf(this.componentRef?.hostView));
  }

  close(args?) {
    this.hide();
  }

  reject(reason) {
    this.hide();
    this._reject(reason);
  }

  public promise(
    componentRef: ComponentRef<DialogContainer>,
    containerRef: ViewContainerRef
  ): Promise<DialogContainer> {
    return (
      this._promise ||
      (this._promise = new Promise((resolve, reject) => {
        this.componentRef = componentRef;
        this.containerRef = containerRef;
        this._resolve = resolve;
        this._reject = reject;
      }))
    );
  }
}
