import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticatedUserQuery } from '../state/authenticated-user/authenticated-user.query';
import { ToasterService } from './toaster.service';
import { UserTypes } from '../enumerations/user-types.enum';

@Injectable()
export class ModeratorGuardService  {
  constructor(
    private appService: AuthenticatedUserQuery,
    private router: Router,
    private toasterService: ToasterService
  ) {}

  canActivate() {
    if (this.appService.user.user_type_id < UserTypes.MODERATOR) {
      this.toasterService.openToastr('You must be a moderator', 'Only accessible for moderators');
      this.router.navigate(['/account']);
    }
    return true;
  }
}
