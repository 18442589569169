import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReferralBoxComponent } from './referral-box/referral-box.component';
import { EmailShareDialogComponent } from './email-share-dialog/email-share-dialog.component';
import { SharedModule } from '../../../shared.module';



@NgModule({
  declarations: [ReferralBoxComponent,EmailShareDialogComponent],
  imports: [
    CommonModule,SharedModule
  ],
  exports:[ReferralBoxComponent]
})
export class ReferralModule { }
