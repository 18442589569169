import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dashReplace'
})
export class DashReplacePipe implements PipeTransform {

  transform(value: string): string {
    if (value && value.length) {
      return value.split('_').join(' ');
    }

    return value;
  }
}
