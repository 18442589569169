import { ChangeDetectorRef, Component, DestroyRef, ElementRef, Input, OnInit } from '@angular/core';
import { AuthenticatedUserQuery, ProgressRef, ProgressService } from '../../index';
import { FormControl, FormGroup } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { BreakpointObserver } from '@angular/cdk/layout';
import { CreativeQuery, Moderation, Proposal, ProcessStatus, Review } from '../../../app.datatypes';
import { LoadingService } from '../../services/new/loading/loading.service';
import { EntityParams, EntityTypeEnum } from '../../interface/entity-type-enum';
import { ModerationsService } from '../../services/new/moderations/moderations.service';
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { ActivatedRoute } from "@angular/router";

type EntityType = Proposal | Review | CreativeQuery;

@Component({
  selector: 'app-moderations-overview',
  templateUrl: './moderations-overview.component.html',
  styleUrls: ['./moderations-overview.component.scss'],
  providers: [ModerationsService]
})
export class ModerationsOverviewComponent implements OnInit {
  @Input() showSearch = true;
  @Input() entity: EntityType;
  @Input() entityType: EntityTypeEnum;
  @Input() mobileViewForAllScreens = false;
  @Input() isAddTransparent = false;

  dataSourceTable$: Observable<Moderation[]>;
  isMoreAvailable = true;
  filterForm = new FormGroup({
    search: new FormControl(),
  });

  progress: ProgressRef;
  isSearchOpen: boolean;
  isFilterOpen = false;
  isMobileScreen = false;
  noData = '-';
  proposalStatus = ProcessStatus;

  moderationPath = 'moderations';

  constructor(
    public authenticatedUserQuery: AuthenticatedUserQuery,
    public moderationsService: ModerationsService,
    private progressService: ProgressService,
    private elRef: ElementRef,
    private breakpointObserver: BreakpointObserver,
    private cdRef: ChangeDetectorRef,
    private loadingService: LoadingService,
    private destroyRef: DestroyRef,
    public activatedRoute: ActivatedRoute
  ) {
  }

  ngOnInit(): void {
    this.setObserverOnSearch();
    this.checkScreenSize();
    this.observeOnLoading();

    this.dataSourceTable$ = this.moderationsService.getPortion(this.getQueryParams(this.entity, this.entityType));
  }

  getQueryParams(entity: EntityType, type: EntityTypeEnum): EntityParams | undefined {
    switch (type) {
      case EntityTypeEnum.PROPOSAL:
        return { type: EntityTypeEnum.PROPOSAL, id: this.entity._id };
      case EntityTypeEnum.REVIEW:
        return { type: EntityTypeEnum.REVIEW, id: this.entity._id };
      case EntityTypeEnum.CREATIVE_QUERY:
        return { type: EntityTypeEnum.CREATIVE_QUERY, id: this.entity._id };
      default:
        return undefined;
    }
  }

  loadMore(): void {
    this.moderationsService.loadMore();
  }

  trackById(index: number, moderation: Moderation): string {
    return moderation._id;
  }

  private setObserverOnSearch(): void {
    this.filterForm.get('search').valueChanges.pipe(
      debounceTime(500),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe((searchQuery: string) => {
      const param = searchQuery.length ? { search: searchQuery } : {};

      this.moderationsService.setFilterParams(param);
    })
  }

  private checkScreenSize(): void {
    const layoutChanges = this.breakpointObserver.observe(['(orientation: portrait)']);
    layoutChanges.subscribe(() => {
      this.isMobileScreen = this.breakpointObserver.isMatched('(max-width: 640px)');
      this.isFilterOpen = !this.isMobileScreen;
      this.cdRef.detectChanges();
    })
  }

  private observeOnLoading(): void {
    this.loadingService.isLoading().pipe(
      takeUntilDestroyed(this.destroyRef)
    ).subscribe((status: boolean) => {
      if (status) {
        this.progress = this.progressService.showSpinner(this.progress, this.elRef);
      } else {
        this.progressService.hideSpinner(this.progress);
      }
    })
  }
}
