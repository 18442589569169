import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Proposal, ProposalRound, ProcessStatus, Review } from '../../../app.datatypes';
import { EntityTypeEnum } from '../../interface/entity-type-enum';
import { AuthenticatedUserQuery } from '../../state';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { VideoConfig } from '../../interface/video-config.interface';

type DetailsButtonType = 'text' | 'icon';

@Component({
  selector: 'app-round-overview',
  templateUrl: './round-overview.component.html',
  styleUrls: ['./round-overview.component.scss'],
})
export class RoundOverviewComponent {
  @Input({ required: true }) round: ProposalRound;
  @Input() showDetailsButtonType: DetailsButtonType = 'text';
  @Input() entity: Proposal | Review;
  @Input() mobileViewModerationTable = false;
  @Input() showModerationTable = false;
  @Input() isAddTransparent = false;
  @Output() detailsDisplayChanged = new EventEmitter<boolean>();

  proposalStatus = ProcessStatus;
  EntityTypeEnum = EntityTypeEnum;
  shownDetails = false;
  textColor: SafeStyle;

  constructor(public authenticatedUserQuery: AuthenticatedUserQuery, public sanitizer: DomSanitizer) {
    this.updateTextColor();
  }

  generateVideoConfig(round: ProposalRound): VideoConfig {
    return {
      src: round.cover_url,
      poster: round.cover_thumbnail_url,
      videoControlOptions: 'nofullscreen noremoteplayback',
      classes: 'video-player',
    };
  }

  get textColorStyle(): string {
    this.updateTextColor();
    return this.textColor.toString();
  }

  updateTextColor(): void {
    this.textColor = this.sanitizer.bypassSecurityTrustStyle('var(--global-black-color)');
  }

  showRoundDetails(): void {
    this.shownDetails = !this.shownDetails;
    this.detailsDisplayChanged.emit(this.shownDetails);
  }

  getDays(): number {
    let sDate, eDate;
    if (this.round) {
      sDate = new Date(this.round.start_date);
      eDate = new Date(this.round.end_date);
      const diffTime = Math.abs(eDate.getTime() - sDate.getTime());
      const today = new Date();
      const completed = today.getTime() - sDate.getTime();
      if (diffTime - completed >= 0) {
        return Math.ceil((diffTime - completed) / (1000 * 60 * 60 * 24));
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  }
}
