<svg class="loader-svg -mt-4" [ngClass]="color" x="0px" y="0px" viewBox="0 0 150.1 201.9">
  <path class="loaderst line1" d="M23.7,201.6v-101l0,0.4c0-28.4,23-51.4,51.4-51.4s51.4,23,51.4,51.4"></path>
  <path class="loaderst line2" d="M126.4,0v101c0,28.4-23,51.4-51.4,51.4s-51.4-23-51.4-51.4"></path>
</svg>
<div *ngIf="timeLimitReached && hasTimeout" class="info-box info timeout-box">
  <app-icon [icon]="'info'"></app-icon>
  <div>
    <p>
      It appears to take longer than usual. If you're not suffering from a slow internet connection, please refresh the
      page.
    </p>
  </div>
</div>
