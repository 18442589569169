import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DcpModalComponent } from './dcp-modal.component';
import { IconModule } from '../../modules/components/icon/icon.module';

@NgModule({
  imports: [CommonModule, IconModule],
  declarations: [DcpModalComponent],
  exports: [DcpModalComponent],
})
export class DcpModalModule {}
