import { Component, Input } from '@angular/core';
import { Distribution } from '../../../../app.datatypes';

@Component({
  selector: 'app-distribution-create-step-two',
  templateUrl: './distribution-create-step-two.component.html',
  styleUrls: ['./distribution-create-step-two.component.scss'],
})
export class DistributionCreateStepTwoComponent {
  @Input() distribution: Distribution;
}
