import { NgModule } from '@angular/core';
import { FilePreviewOverlayComponent } from './file-preview-overlay/file-preview-overlay.component';
import { FilePreviewOverlayToolbarComponent } from './file-preview-overlay-toolbar/file-preview-overlay-toolbar.component';
import { VideoPlayerModule } from '../video-player/video-player.module';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { CommonModule } from '@angular/common';
import { IconModule } from '../icon/icon.module';
import { LocalLoaderModule } from '../local-loader/local-loader.module';



@NgModule({
  declarations: [FilePreviewOverlayComponent, FilePreviewOverlayToolbarComponent],
  imports: [CommonModule, VideoPlayerModule,PdfViewerModule,IconModule,LocalLoaderModule],
  exports: [FilePreviewOverlayComponent, FilePreviewOverlayToolbarComponent],
})
export class FilePreviewModule {}
