<div class="dialog-header">
  <div class="relative">
    <h3>install PWA on iOS</h3>
    <button
      class="w-16 hover:opacity-75 absolute right-0 top-0"
      style="z-index: 10"
      appTooltip="Cancel"
      placement="bottom"
      (click)="dialog.close()"
    >
      <app-icon class="h-16 w-16" [icon]="'close'"></app-icon>
    </button>
  </div>
</div>

<div class="dialog-content">
  <p class="mb-16">
    Install the DCP app on your home screen for quick, easy access when you’re on the go. Close this modal, tap the
    <b>share</b> icon, and then tap on <b>Add to home screen</b>.
  </p>
</div>
