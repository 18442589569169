import { Component, OnInit, Output, EventEmitter, Input, Renderer2 } from '@angular/core';
import { UserEngagementData } from '../../../../../app/app.datatypes';
import { environment } from '../../../../../../src/environments';
import { AuthenticatedUser, AuthenticatedUserQuery, SharedService } from '../../../../shared';
import { AuthService } from '../../../../shared/services/auth.service';

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
})
export class UserMenuComponent implements OnInit {
  showProposal = !environment.limited_proposal_functionality;
  showDistribution = !environment.limited_watch_functionality;
  showCq = !environment.limited_cq_functionality;
  showRep = !environment.limited_reputation_functionality;
  showSwag = !environment.limited_swag_functionality;
  jiraServiceDeskUrl = environment.jira_servicedesk_url;
  isReferralProgram = environment.referral_program;
  constructor(
    public authenticatedUserQuery: AuthenticatedUserQuery,
    private authService: AuthService,
    private sharedService: SharedService,
    private renderer: Renderer2
  ) {}

  @Input() userDetails = false;
  @Input() themePanelOpen = false;
  @Output() logout = new EventEmitter();
  @Output() changeTheme = new EventEmitter();
  @Output() changeScale = new EventEmitter();
  @Output() toggleThemePanelBtn = new EventEmitter();
  @Output() showInboxBar = new EventEmitter();

  user: AuthenticatedUser;
  reputation = 0;
  info: UserEngagementData = {
    likes: 0,
    likesRight: 0,
    review: 0,
    reviewRight: 0,
    creativeQuery: 0,
    creativeQueryRight: 0,
    fillChart: 0,
    fill: 0,
    dasharay: '0',
    all: 0,
    taken: 0,
    potentialEarning: 0,
    potentialEarningTime: {
      hours: 0,
      minutes: 0,
      seconds: 0,
    },
  };
  ngOnInit() {
    this.authenticatedUserQuery.authenticatedUser$.subscribe((user) => {
      this.user = user;
      this.reputation = this.user.reputation;
    });

    this.authenticatedUserQuery.authenticatedUser$.subscribe((state) => {
      if (state.user_engagement_data && state.potential_earnings !== undefined) {
        this.info = Object.assign({}, state.user_engagement_data);
        this.info.potentialEarningTime = Object.assign({}, state.user_engagement_data.potentialEarningTime);
        this.info.potentialEarning = state.potential_earnings;
      }
    });
  }

  onLogout() {
    this.logout.emit();
    this.closeUserMenu();
  }

  toggleTheme() {
    this.authService.postSetUserSettings('is_dark_theme', !this.user.is_dark_theme).subscribe((res) => {
      this.changeTheme.emit();
    });
  }

  toggleScale() {
    this.authService.postSetUserSettings('is_grayscale', !this.user.is_grayscale).subscribe((res) => {
      this.changeScale.emit();
    });
  }

  closeUserMenu() {
    const wrapper: HTMLElement = this.renderer.selectRootElement('#app-wrapper', true);
    this.renderer.removeClass(wrapper, 'user-side-open');
  }

  toggleThemePanel() {
    this.toggleThemePanelBtn.emit();
  }
  showInfoBoxFn() {
    this.sharedService.setToggleUserMenu(true);
    setTimeout(() => {
      this.showInboxBar.emit();
    }, 300);
  }
}
