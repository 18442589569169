<div
  class="z-2 h-40 px-16 border border-gray-border rounded relative multiselect-wrapper"
  [ngClass]="{ disabled: disabled, open: isMultiselectOpen }"
  id="dh-{{ compId }}"
  (click)="disabled === false && openClose($event)"
  #dhCompId
>
  <div class="flex items-center h-full flex-1 overflow-x-auto mr-16" *ngIf="selectedOptions.length !== 0">
    <span *ngFor="let selectedOption of selectedOptions" class="pill m-4"
      >{{ selectedOption[optionNameField] || selectedOption  }}
      <button
        (click)="removeSelected(selectedOption, $event)"
        [disabled]="disabled"
        [ngClass]="{ 'cursor-not-allowed': disabled }"
      >
        <app-icon [icon]="'close'" class="w-12 h-12 ml-8"></app-icon>
      </button>
    </span>
  </div>
  <span class="animated-arrow arrow-text" [ngClass]="{ 'arrow-down': isMultiselectOpen }"></span>
  <div class="w-full flex flex-col justify-center h-full" *ngIf="selectedOptions.length === 0">
    <ng-container *ngIf="placeholder">
      {{ placeholder }}
    </ng-container>
    <ng-container *ngIf="placeholder === undefined">
      Select
    </ng-container>
  </div>
</div>

<div class="backdrop" #bdCompId *ngIf="isMultiselectOpen" (click)="openClose($event)"></div>
<div
  class="multiselect-dropdown"
  id="dd-{{ compId }}"
  [ngClass]="{ open: isMultiselectOpen }"
  (click)="$event.stopPropagation()"
  #ddCompId
>
  <div class="filter" *ngIf="filterOption">
    <span>
      <input autocomplete="off" type="text" (keyup)="filterOptions($event)" id="filterTextInput-{{ compId }}" #filterTextInputCompId />
      <button (click)="filterTextInputCompId.value='';clearText()">
        <app-icon [icon]="'search'" class="opacity-50" *ngIf="filterText === ''"></app-icon>
        <app-icon [icon]="'close'" *ngIf="filterText !== ''" class="blue hover:opacity-75"> </app-icon>
      </button>
    </span>
  </div>

  <ul>
    <li
      *ngFor="let option of options"
      (click)="select(option, $event)"
      class="option"
      [ngClass]="{ selected: option.selected, hidden: !option.visible }"
    >
      {{ option[optionNameField] }}
    </li>
  </ul>
</div>
