<div class="lg:hidden p-16" style="z-index: 51">
  <div class="toggle-button">
    <button class="btn-icon btn-sm" (click)="onToggle()">
      <app-icon [icon]="isTogglelNav ? 'close' : 'more'"></app-icon>
    </button>
    <div class="filters-button">
      <button
        [routerLink]="['/administration/create-project-award']"
        type="button"
        class="btn btn-primary"
        color="accent"
        *ngIf="isMobileScreen"
        [ngClass]="{ 'btn-sm': isMobileScreen }"
      >
        Create Award
      </button>
      <button
        *ngIf="
          type === 'userManagement' ||
          type === 'moderationsOverview' ||
          type === 'userVerfications' ||
          type === 'manageProposalRounds' ||
          type === 'manageCreativeQueries' ||
          type === 'manageDistribution' ||
          type === 'userRolesLog'
        "
        class="search btn-icon btn-sm ml-16"
        (click)="openSearch()"
        [ngClass]="{ open: isToggelSearch }"
      >
        <app-icon [icon]="'search'"> </app-icon>
        <form [formGroup]="filterForm">
          <input type="text" placeholder="Search" formControlName="search" (keyup)="searchData()" />
        </form>

        <app-icon
          class="close cursor-pointer z-10 test"
          [icon]="'close'"
          (click)="$event.stopPropagation(); closeSearch($event)"
        ></app-icon>
      </button>
      <button
        *ngIf="type !== 'systemSettings' && type !== 'moderationsOverview'"
        class="btn-icon btn-sm ml-16"
        (click)="onToggleFilter()"
      >
        <app-icon [icon]="isToggleFilter ? 'close' : 'filter'"></app-icon>
      </button>
    </div>
  </div>
</div>
