<app-custom-video-player
  class="block w-full h-full bg-black"
  [videoConfig]="videoConfig"
  (videoEvent)="playVideo($event)"
  (mouseEntered)="onMouseEnter($event)"
  (mouseLeft)="onMouseLeave($event)"
  (loadedData)="onLoadedData($event)"
  [styleConfig]="styleConfig"
  *ngIf="videoConfig?.src"
></app-custom-video-player>
