<div class="flex justify-between items-center dialog-header">
    <h4 class="font-semibold">Share By Email</h4>
    <button class="btn-dark-blue" (click)="dialog.close()">
        <app-icon [icon]="'close'" class="w-16 h-16 opacity-75 hover:opacity-100"></app-icon>
    </button>
</div>
<div class="dialog-content">
    <form [formGroup]="form" (ngSubmit)="share()">
        <div class="form-group w-full addon-f">
            <label></label>
            <input type="text" placeholder="Enter email " formControlName="email"/>
            <app-icon [icon]="'mail'"></app-icon>
        </div>
        <div class="form-group pointer-events-none">
            <textarea
                rows="5"
                id="content"
                placeholder="Enter Message"
                formControlName="message"
            ></textarea>
        </div>
        <div class="flex justify-center">
            <button class="btn btn-primary" [disabled]="form.invalid">Share</button>
        </div>
    </form>
</div>
<div
  class="w-full h-full flex flex-col items-center justify-center absolute top-0 left-0 bg-backdrop" *ngIf="progress"
>
  <app-local-loader [hasTimeout]="false" class="w-32 h-32"></app-local-loader>
</div>