<div class="px-16" [ngClass]="{ preview: previewsOnly }">
  <div class="slider rounded-[8px]" (swipeleft)="moveRightSlider(null)" (swipeRight)="moveLeftSlider(null)">
    <div
      class="slides"
      [ngStyle]="{ width: sliderPanelSelectedWidth, transform: 'translateX(' + leftPositionSlider + '%)' }"
    >
      <div class="slide" *ngFor="let slide of slides; let i = index">
        <div
          *ngIf="slide.type === 'image'"
          (click)="showPreview($event, { name: slide.title, url: slide.src, type: 'image' })"
        >
          <app-dcp-image [src]="slide.src"></app-dcp-image>
        </div>
        <div *ngIf="slide.type === 'video'">
          <app-video-player
            [ngClass]="{ 'cursor-pointer': slide.entity }"
            (click)="$event.stopPropagation(); clickVideo(slide.entity)"
            (mouseEntered)="previewsOnly && mouseHover()"
            [videoConfig]="slide"
          ></app-video-player>
        </div>
      </div>
    </div>

    <button class="moveLeft" (click)="moveLeftSlider($event)" *ngIf="slides.length > 1 && selectedImageSlide !== 0">
      <app-icon [icon]="'arrow-left'"></app-icon>
    </button>
    <button
      class="moveRight"
      (click)="moveRightSlider($event)"
      *ngIf="slides.length > 1 && selectedImageSlide + 1 !== slides.length"
    >
      <app-icon [icon]="'arrow-right'"></app-icon>
    </button>
    <ng-container *ngIf="selectedSlide">
      <button
        *ngIf="previewsOnly"
        class="btn-icon absolute top-12 right-12 btn-sm ml-16"
        (click)="$event.stopPropagation(); handleVolume()"
      >
        <app-icon [icon]="muted === 'yes' ? 'volumedown' : 'volumeup'"></app-icon>
      </button>
      <div
        class="title-bar"
        [ngClass]="{ 'description-show': selectedSlide.isDescriptionShow || previewsOnly, show: descriptionVisible }"
      >
        <div class="title-bar-wrapper">
          <ng-container *ngIf="!selectedSlide.title">
            <p class="title">Project cover image</p>
          </ng-container>
          <ng-container *ngIf="selectedSlide.title">
            <p class="title">{{ selectedSlide.title }}</p>
            <ng-container *ngIf="selectedSlide.description">
              <p class="description" [innerHTML]="selectedSlide.description"></p>
            </ng-container>
          </ng-container>

          <button
            *ngIf="selectedSlide.description && !previewsOnly"
            class="btn-icon-transparent icon-sm"
            (click)="onDescription()"
          >
            <app-icon *ngIf="!selectedSlide.isDescriptionShow" [icon]="'arrow-right'" class="icon-blue"></app-icon>
            <app-icon *ngIf="selectedSlide.isDescriptionShow" [icon]="'arrow-left'" class="icon-blue"></app-icon>
          </button>
        </div>
        <div class="flex gap-12 mt-12 actions" *ngIf="selectedSlide.entity">
          <ng-container *ngIf="!selectedSlide.entity.purchase">
            <button class="btn btn-primary" (click)="rentPurchase()">
              Rent for 72hr
              <span class="flex ml-8 bg-[#f7f2f21f] items-center rounded-sm py-2 px-6">
                ${{ selectedSlide.entity.rental_price }}
              </span>
            </button>
            <button class="btn btn-primary" (click)="rentPurchase(true)">
              Buy
              <span class="flex ml-8 bg-[#f7f2f21f] items-center rounded-sm py-2 px-6">
                ${{ selectedSlide.entity.purchase_price }}
              </span>
            </button>
          </ng-container>
          <ng-container *ngIf="selectedSlide.entity.purchase">
            <button (click)="redirectToPlay()" class="btn btn-primary w-full">Watch Feature</button>
          </ng-container>
        </div>
      </div>
    </ng-container>
    <div class="flex py-16 justify-center indicators" *ngIf="showIndicators">
      <span
        class="w-[9px] h-[9px] rounded block mr-8"
        [ngClass]="{ active: i === selectedImageSlide }"
        *ngFor="let slide of slides; let i = index"
      ></span>
    </div>
  </div>
  <div class="backdrop"></div>
</div>

<div
  class="thumbs"
  *ngIf="slides.length > 1 && !previewsOnly"
  (swipeleft)="moveRightNavigation()"
  (swipeRight)="moveLeftNavigation()"
>
  <div class="slides" [ngStyle]="{ width: slideWidth, transform: 'translateX(' + leftPosition + '%)' }">
    <div
      class="slide rounded-[8px]"
      *ngFor="let slide of slides; let i = index"
      (click)="selectImage(i)"
      [ngClass]="{ selected: slide.src === selectedSlide.src }"
    >
      <app-dcp-image class="slide-item" [src]="slide.type === 'image' ? slide.src : slide.poster"></app-dcp-image>
    </div>
  </div>
  <ng-container *ngIf="sliderPanelWidth > 100">
    <button class="moveLeft" (click)="moveLeftNavigation()" *ngIf="leftPosition < 0">
      <app-icon [icon]="'arrow-left'"></app-icon>
    </button>
    <button class="moveRight" (click)="moveRightNavigation()" *ngIf="leftPosition !== tumbsMaxRight">
      <app-icon [icon]="'arrow-right'"></app-icon>
    </button>
  </ng-container>
</div>
