import { Component, OnInit, Input, Inject } from '@angular/core';
import { timer } from 'rxjs';

@Component({
  selector: 'app-local-loader',
  templateUrl: './local-loader.component.html',
  styleUrls: ['./local-loader.component.scss'],
  host: {
    class: 'relative',
  },
})
export class LocalLoaderComponent implements OnInit {
  @Input() color = 'black';
  width = '20%';
  timeLimitReached = false;
  @Input() hasTimeout = true;

  ngOnInit():void {
    timer(30 * 1000).subscribe(() => {
      this.timeLimitReached = true;
    });
  }
}
