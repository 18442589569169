<div id="section" autocomplete="off" class="h-full overflow-y-auto">
  <div
    class="round"
    *ngIf="joyrideService?.isTourInProgress()"
    (next)="selectRound(0)"
    joyrideStep="proposalcreate3"
    [title]="joyrideService?.isTourInProgress() ? 'Create Project' : ''"
    text="Choose the round which you would like to enter, and click Next."
  >
    <div class="w-full relative pl-32 pr-16 pt-16 pb-32">
      <p class="-mt-8 round-title mb-8">Genral Round</p>
      <div class="flex items-center">
        <p class="flex items-center text-sm mr-32">
          <app-icon [icon]="'calendar'" class="min-w-12 max-w-12 h-12 mr-4"></app-icon>
          Apr 14, 2022 - Apr 14, 2022
        </p>
        <p class="flex text-sm items-center">
          <app-icon [icon]="'prize'" class="w-12 h-12 mr-4"></app-icon>
          0
        </p>
      </div>
    </div>
  </div>
  <ng-container *ngIf="!showSelectedRound">
    <div
      *ngFor="let round of roundList; let i = index"
      id="round-{{ round._id }}"
      class="round"
      (click)="selectRound(round._id)"
      [ngClass]="{
        active: round._id === selectedRoundId,
        details: round.showRoundDetails === true,
        disabled: proposal,
        'video-cover': round?.cover_type === 'video'
      }"
    >
      <div class="flex justify-between w-full relative general-info">
        <div class="pl-32 pt-16">
          <p class="-mt-8 round-title mb-8">{{ round.title }}</p>
          <div class="flex items-center">
            <p class="flex items-center text-sm mr-32">
              <app-icon [icon]="'calendar'" class="min-w-12 max-w-12 h-12 mr-4"></app-icon>
              {{ round.start_date | date }} - {{ round?.minimum_participants_to_go ? 'TBD' : (round.end_date | date) }}
            </p>
          </div>
        </div>
        <button
          class="mt-auto ml-auto mr-16 text-blue flex items-center mb-12"
          (click)="$event.preventDefault(); $event.stopPropagation(); showRoundDetails(round)"
        >
          <ng-container *ngIf="!round.showRoundDetails">Show </ng-container>
          <ng-container *ngIf="round.showRoundDetails">Hide</ng-container>
          &nbsp;Details
          <span
            class="ml-4 animated-arrow arrow-primary"
            [ngClass]="{ 'arrow-up': round.showRoundDetails === true }"
          ></span>
        </button>
        <img *ngIf="round?.cover_url && round?.cover_type !== 'video'" class="cover" [src]="round?.cover_url" alt="" />

        <app-video-player
          [videoConfig]="generateVideoConfig(round)"
          (videoEvent)="playVideo()"
          *ngIf="round?.cover_url && round?.cover_type === 'video'"
        ></app-video-player>
      </div>

      <div class="border-t border-gray-border grid grid-cols-1 md:grid-cols-4" *ngIf="round.showRoundDetails === true">
        <div class="col-span-1 md:border-r border-gray-border">
          <div class="row-span-1 border-b border-gray-border p-8">
            <label class="flex items-center">
              <p class="text-sm">Submission End</p>
              <app-icon
                [icon]="'info'"
                class="ml-4 w-12 h-12 opacity-50 hover:opacity-100 inline-block cursor-pointer"
                appTooltip="Submitted Projects are eligible to be edited until 24 hours prior to Submission End."
                placement="top"
              >
              </app-icon>
            </label>
            <p class="text-xl font-bold">
              {{
                round?.minimum_participants_to_go
                  ? round?.minimum_participants_to_go - round.proposal_count > 0
                    ? round?.minimum_participants_to_go - round.proposal_count
                    : 0
                  : (round?.submission_deadline_date | date)
              }}
            </p>
            <span class="text-xxs" *ngIf="round?.minimum_participants_to_go">Projects to go until Submission End</span>
          </div>
          <div class="row-span-1 border-b border-gray-border p-8">
            <label class="flex items-center">
              <p class="text-sm">Min. Entry Fee</p>
              <app-icon
                [icon]="'info'"
                class="ml-4 w-12 h-12 opacity-50 hover:opacity-100 inline-block"
                appTooltip="Minimum cost to enter your project to this award"
                placement="top"
              >
              </app-icon>
            </label>
            <p *ngIf="prices" class="text-xl font-bold break-all flex justify-end">
              <app-icon [icon]="'film'" class="w-20 min-w-20 h-20 mr-8 mt-4"></app-icon
              >{{ calculateFilmFee(round.submission_fee) + round.minimum_bounty | number : '1.0-2' }} / ${{
                round?.submission_fee + calculateFilmToDollar(round.minimum_bounty) | number : '1.2-2'
              }}
            </p>
          </div>
          <div class="row-span-1 p-8">
            <label class="flex items-center">
              <p class="text-sm">Award</p>
              <app-icon
                [icon]="'info'"
                class="ml-4 w-12 h-12 opacity-50 hover:opacity-100 inline-block"
                appTooltip="Award for winning project."
                placement="top"
              >
              </app-icon>
            </label>
            <p class="text-xl font-bold text-right flex items-center justify-end">
              ${{ round.reward || 0 | number : '1.2-2' }}
            </p>
          </div>
        </div>
        <div class="grid-col-1 md:col-span-3 md:grid grid-rows-2">
          <div
            class="row-span-1 border-t md:border-t-0 border-b border-gray-border flex flex-col p-8 pb-0 relative"
            style="overflow-y: auto; max-height: 136px"
          >
            <app-expanded-info
              [roundChanges]="round"
              [expandIconColor]="authenticatedUserQuery.user.is_dark_theme ? 'white' : textColorStyle"
            ></app-expanded-info>
          </div>

          <div class="h-64 row-span-1 border-gray-border flex flex-col p-8 overflow-x-auto">
            <p class="text-sm">Evaluation Categories</p>
            <ul class="csv flex-grow overflow-x-auto flex">
              <li *ngFor="let score of round.scores" class="pill mx-4">
                {{ score.name }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="showSelectedRound && selectedRound">
    <app-round-overview [round]="selectedRound"></app-round-overview>
  </ng-container>
</div>
