import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticatedUserQuery } from '../../../state';

@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss'],
})
export class LogoComponent {
  @Input() color: string;

  constructor(public authenticatedUserQuery: AuthenticatedUserQuery, private router: Router) {}

  onClick(): void {
    if (this.authenticatedUserQuery.isRegistrationComplete) {
      this.router.navigate(['/']);
    }
  }
}
