import { ElementRef, Injectable } from '@angular/core';
import { ComponentPortal } from '@angular/cdk/portal';
import { DynamicOverlay } from './dynamic-overlay.service';
import { OverlayRef } from '@angular/cdk/overlay';
import { LocalLoaderComponent } from '../modules/components/local-loader/local-loader.component';

@Injectable()
export class ProgressService {
  constructor(private dynamicOverlay: DynamicOverlay) {}

  public showProgress(elRef: ElementRef) {
    if (elRef) {
      const result: ProgressRef = { overlayRef: null };
      this.dynamicOverlay.setContainerElement(elRef.nativeElement);
      const positionStrategy = this.dynamicOverlay
        .position()
        .global()
        .centerHorizontally()
        .centerVertically();
      result.overlayRef = this.dynamicOverlay.create({
        positionStrategy: positionStrategy,
        hasBackdrop: true,
      });
      result.overlayRef.attach(new ComponentPortal(LocalLoaderComponent));
      return result;
    } else {
      return null;
    }
  }

  detach(result: ProgressRef) {
    if (result) {
      if (result.overlayRef) {
        result.overlayRef.detach();
      }
    }
  }

  showSpinner(progress: ProgressRef, elRef: ElementRef): ProgressRef {
    if (progress && progress.overlayRef && progress.overlayRef.hasAttached()) {
      return progress;
    } else {
      return this.showProgress(elRef);
    }
  }

  hideSpinner(progress: ProgressRef): ProgressRef {
    if (progress && progress.overlayRef && progress.overlayRef.hasAttached()) {
      this.detach(progress);
      return progress;
    }
  }
}

export interface ProgressRef {
  overlayRef: OverlayRef;
}
