import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmBuyDialogData } from '../../../../../src/app/app.datatypes';
import { ConfirmToBuyDialogComponent } from '../../../../../src/app/containers/proposal/confirm-to-buy-dialog/confirm-to-buy-dialog.component';
import { DialogService } from '../../dialog/dialog.service';
import { Toastr } from '../toastr';

@Component({
  selector: 'app-low-balance-toastr',
  templateUrl: './low-balance-toastr.component.html'
})
export class LowBalanceToastrComponent implements OnInit {
  @Input() toast: Toastr;
  @Input() i: number;
  @Output() remove = new EventEmitter<number>();

  balanceDiff: number;

  constructor(private router: Router, private dialogService: DialogService) {}

  ngOnInit(): void {
    this.balanceDiff = Number(Math.round(this.toast?.toastrData?.minBuy * 1000000) / 1000000);
  }

  removeToastr(event) {
    this.remove.emit(event);
  }

  buy() {
    const data: ConfirmBuyDialogData = {
      queryParams: {
        redirectUri: this.toast.toastrData?.redirectUri,
        minBuy: this.toast.toastrData.minBuy,
      },
    };
    this.dialogService.open(ConfirmToBuyDialogComponent, data).subscribe(result => {
      if (result?.success) {
        this.removeToastr(this.i);
      }
    });
  }
}
