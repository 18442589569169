import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Transaction } from '../../../../../src/app/app.datatypes';

export interface TransactionState extends EntityState<Transaction> {
  filters: {
    search: string;
  };
  skip: number;
  apiEndReached: boolean;
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'transactions', idKey: '_id', resettable: true })
export class TransactionStore extends EntityStore<TransactionState> {
  constructor() {
    super({
      ui: { showFilters: false },
      filters: {
        search: '',
      },
      skip: 0,
      apiEndReached: false,
    });
  }
}
