<div class="dialog-header">
  Confirmation
</div>

<div class="dialog-content">
  <p>Your film balance is not sufficient for this transaction, would you like to buy?</p>
</div>

<div class="dialog-footer">
  <button class="btn btn-secondary mr-16" (click)="dialog.close()">Cancel</button>
  <button class="btn btn-primary" (click)="submit()">Yes</button>
</div>
