import { environment_common } from './environment-common';
  export const environment = Object.assign(environment_common, {
  build_id: '86d976c6',
  production: true,
  staging: false,
  local: false,
  url: 'https://api.decentralized.pictures/',
  client_id: '625d7820ca269be1c80a48c2',
  client_secret: 'c49t4jkFIGyKRYxoKBbzvTO8TxRFtfuLnHRrGBA6',
  chain_id: 'b832d9d9',
  film_controller_address: 'KT1HnUrq3KkSbjM92vypZhXqznUxQkBgapJu',
  film_ledger_address: 'KT1RYT2ypZB7hiTJhfQBFi8g9Ar8VJMkce9b',
  explorer_url: 'https://explorer.tlnt.net',
  chat_invite_url: 'https://discord.gg/FBKpZQNeAc',
  discord_server_id: '362368707684597761',
  discord_general_channel_id: '362368707684597763',
  echo_server_url: 'https://ws.decentralized.pictures',
  echo_server_key: '8aea9dd117c65e4b619722e45559a213',
  limited_proposal_functionality: false,
  limited_cq_functionality: false,
  limited_quiz_functionality: false,
  limited_reputation_functionality: false,
  limited_moderation_functionality: false,
  limited_watch_functionality: true,
  limited_swag_functionality: true,
  kukai_wallet_url: 'https://kukai.decentralized.pictures',
  t4l3nt_rpc_endpoint: 'https://rpc.decentralized.pictures',
  simplex_url: 'https://checkout.simplexcc.com',
  google_analytics_id: 'G-3QFKDYXWRD'
});

const _CONSTANTS = {
  NAME: 'T4L3NT Mainnet',
  NETWORK: 'mainnet',
  MAINNET: true,
  API_URL: 'https://explorer-api.tlnt.net/v1',
  NODE_URL: 'https://rpc.decentralized.pictures',
  BLOCK_EXPLORER_URL: 'https://explorer.tlnt.net'
};
export const CONSTANTS = _CONSTANTS;

