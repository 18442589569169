import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'statusText',
})
export class StatusTextPipe implements PipeTransform {
  transform(value, args?): string {
    if (typeof value === 'string') {
      switch (value) {
        case 'to-be-moderated':
          return 'Waiting for Moderation';
        case 'waiting-for-submission':
          return 'Waiting for Submission';
        case 'waiting-for-confirmation':
          return 'Confirming Submission';
        case 'waiting-for-payment':
          return 'Processing Payment';
        case 'approved':
          return 'Approved';
        case 'active':
          return 'Active';
        case 'denied':
          return 'Denied';
        case 'finished':
          return 'Finished';
        case 'archived':
          return 'Archived';
        case 'cancelled':
          return 'Canceled';
        case 'upcoming':
          return 'Upcoming';
        default:
          return value;
      }
    }
    return value;
  }
}
