<div
  class="absolute rounded-lg proposal bg-white shadow-md cursor-pointer overflow-hidden"
  (mouseleave)="!shareButtonClicked && actions.emit('mouseLeave')"
  (click)="actions.emit('click')"
  @box
  [ngStyle]="{
    top: bottom ? '' : top + 'px',
    left: right ? '' : left + 'px',
    right: right ? '10px' : '',
    width: width + 'px',
    bottom: bottom ? '0' : ''
  }"
>
  <div
    *ngIf="videoConfig?.src; else img"
    class="w-full rounded-tl-lg rounded-tr-lg overflow-hidden"
    [ngClass]="{ height: height + 'px' }"
  >
    <app-video-player (loadedData)="videoLoaded()" [videoConfig]="videoConfig"></app-video-player>

    <button class="btn-icon absolute top-12 right-12 btn-sm ml-16" (click)="$event.stopPropagation(); handleVolume()">
      <app-icon [icon]="muted === 'yes' ? 'volumedown' : 'volumeup'"></app-icon>
    </button>
  </div>
  <ng-template #img>
    <app-dcp-image
      [ngClass]="{ height: height + 'px' }"
      class="w-full rounded-tl-lg rounded-tr-lg"
      [src]="entity.cover_url"
    ></app-dcp-image>
  </ng-template>
  <div class="details w-full">
    <div class="mb-8">
      <div class="flex justify-between items-center">
        <h5 class="font-extrabold truncate text-xl">{{ entity.title }}</h5>
        <app-share-button
          (click)="$event.stopPropagation(); shareButtonClicked = true"
          (actions)="shareButtonClicked = false"
          [id]="entity._id"
          type="project"
          *ngIf="entity.status === proposalStatus.APPROVED || entity.status === proposalStatus.FINISHED"
        ></app-share-button>
      </div>
      <p class="font-semibold truncate text-lg opacity-75" *ngIf="!isDistribution">
        Award name: {{ entity.proposal_round?.title }}
      </p>
    </div>
    <div class="flex items-center justify-between gap-18">
      <app-round-progress-widget *ngIf="progress?.days" [progress]="progress"></app-round-progress-widget>
      <div class="flex w-full" *ngIf="isDistribution">
        <div class="mr-56 text-xs">
          72hr rental
          <span class="font-semibold text-base flex items-center">
            <ng-container *ngIf="entity.is_usdc_supported === true">
              <app-icon [icon]="'usdc'" class="w-[14px] h-[14px] mr-4 opacity-50"></app-icon>
              {{ entity.rental_price | number : '1.0-2' }}
            </ng-container>
            <ng-container *ngIf="entity.is_film_supported !== false">
              <app-icon [icon]="'film'" class="w-[14px] h-[14px] ml-8 mr-4 opacity-50"></app-icon>
              {{ paymentService.calculateDollarToFilmPricing(entity.rental_price) | number : '1.0-2' }}</ng-container
            >
            <app-icon [icon]="'usd'" class="w-[14px] h-[14px] ml-8 mr-4 opacity-50"></app-icon
            >{{ entity.rental_price | number : '1.0-2' }}
          </span>
        </div>
        <div class="text-xs">
          Buy:
          <span class="font-semibold text-base flex items-center">
            <ng-container *ngIf="entity.is_usdc_supported === true">
              <app-icon [icon]="'usdc'" class="w-[14px] h-[14px] mr-4 opacity-50"></app-icon>
              {{ entity.purchase_price | number : '1.0-2' }}
            </ng-container>
            <ng-container *ngIf="entity.is_film_supported !== false">
              <app-icon [icon]="'film'" class="w-[14px] h-[14px] ml-8 mr-4 opacity-50"></app-icon>
              {{ paymentService.calculateDollarToFilmPricing(entity.purchase_price) | number : '1.0-2' }}
            </ng-container>
            <app-icon [icon]="'usd'" class="w-[14px] h-[14px] ml-8 mr-4 opacity-50"></app-icon
            >{{ entity.purchase_price | number : '1.0-2' }}
          </span>
        </div>
        <p class="font-semibold text-base flex items-center ml-auto self-end" *ngIf="entity.stars">
          <app-icon icon="stars" class="w-[20px] h-[20px] mr-4"></app-icon>
          {{ entity.stars }}
        </p>
      </div>
      <ng-container *ngIf="!isDistribution">
        <span class="flex" *ngIf="!entity.proposal_round?.hide_scores && (entity.score || entity.statistics?.score)">
          <app-icon [icon]="'totalscore'" class="w-20 h-20 mr-8 opacity-50"></app-icon>
          <p class="font-bold text-sm">
            {{ ((entity.score || entity.statistics?.score) * 10 | number : '1.0-0') || 0 }}%
          </p>
        </span>
        <span class="flex">
          <app-icon [icon]="'bounty'" class="w-20 h-20 mr-8 opacity-50"></app-icon>
          <p class="font-bold text-sm">
            {{ entity.balance || entity.statistics?.balance | filmPrice }} /
            {{ entity.total_contributed_bounty | filmPrice }}
          </p>
        </span>
        <span class="flex">
          <app-icon [icon]="'stake'" class="w-20 h-20 mr-8 opacity-50"></app-icon>
          <p class="font-bold text-sm">{{ entity.staked || 0 | filmPrice }} / {{ entity.total_stake | filmPrice }}</p>
        </span>
        <span class="flex">
          <app-icon [icon]="'vote-box'" class="w-20 h-20 mr-8 opacity-50"></app-icon>
          <p class="font-bold text-sm">{{ entity.votes_score || entity.statistics?.total_votes || 0 }}</p>
        </span>
      </ng-container>
    </div>
  </div>
</div>
