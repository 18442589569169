import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { TransactionState, TransactionStore } from './transaction.store';

@Injectable({ providedIn: 'root' })
export class TransactionQuery extends QueryEntity<TransactionState> {
  constructor(protected store: TransactionStore) {
    super(store);
  }

  getPaymentTransactions() {
    return this.selectAll({
      filterBy: transaction => {
        return transaction.payment_id !== null;
      },
    });
  }

  getPendingTransactions() {
    return this.selectAll({
      filterBy: transaction => {
        return transaction.status === 'waiting-for-confirmation';
      },
    });
  }

  getTransactionByPaymentId(payment_id: string) {
    return this.selectEntity(entity => entity.payment_id === payment_id);
  }
}
