import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Toastr } from '../toastr';

@Component({
  selector: 'app-reload-toastr',
  templateUrl: './reload-toastr.component.html'
})
export class ReloadToastrComponent {
  @Input() toast: Toastr;
  @Input() i: number;
  @Output() remove = new EventEmitter<number>();

  requestReloadKey = '_dcp_force_reload';
  onRelaod() {
    localStorage.setItem(this.requestReloadKey, 'true');
    window.location.reload();
  }

  removeToastr(event) {
    this.remove.emit(event);
  }
}
