import { NgModule } from '@angular/core';
import { QueryCreateComponent } from './query-create.component';
import { PreviewCreateQueryComponent } from './preview-create-query/preview-create-query.component';
import { SharedModule } from '../../../shared';
import { DcpCheckoutModule } from '../../../shared/modules/components/checkout/dcp-checkout.module';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { CheckboxModule } from '../../../shared/modules/components/checkbox/checkbox.module';
import { MultiselectModule } from '../../../shared/modules/components/dcp-multiselect/multiselect.module';
import { DcpSelectModule } from '../../../shared/modules/components/dcp-select/dcp-select.module';
import { VideoPlayerModule } from "../../../shared/modules/components/video-player/video-player.module";
import { LogoModule } from "../../../shared/modules/components/logo/logo.module";

@NgModule({
  declarations: [QueryCreateComponent, PreviewCreateQueryComponent],
    imports: [SharedModule, DcpCheckoutModule, CKEditorModule, CheckboxModule, MultiselectModule, DcpSelectModule, VideoPlayerModule, LogoModule],
  exports: [QueryCreateComponent],
})
export class QueryCreateModule {}
