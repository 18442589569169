import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { DistributionState, DistributionStore } from './distribution.store';
import { Observable } from 'rxjs';
import { Distribution, Genre, Language, ProcessStatus } from '../../../app.datatypes';

@Injectable({ providedIn: 'root' })
export class DistributionQuery extends QueryEntity<DistributionState> {
  constructor(protected store: DistributionStore) {
    super(store);
  }

  getAllApprovedDistributions(): Observable<Distribution[]> {
    return this.selectAll({
      filterBy: (entity: Distribution) => {
        return entity.status === 'approved';
      },
    });
  }

  getNextReviewProposalValue(id: string): string {
    return this.handleNextPreviousValues(id, this.getAll());
  }

  getPreviousReviewProposalValue(id: string): string {
    return this.handleNextPreviousValues(id, this.getAll(), false);
  }

  handleNextPreviousValues(id: string, allValues: Distribution[], next = true) {
    let currentIndex = allValues.findIndex((item) => item._id === id);

    if (next && currentIndex + 1 < allValues?.length) {
      return allValues[currentIndex + 1]._id;
    } else if (!next && currentIndex - 1 >= 0) {
      return allValues[currentIndex - 1]._id;
    } else {
      return null;
    }
  }

  getNonPurchasedDistributions(): Observable<Distribution[]> {
    return this.selectAll({
      filterBy: (vd: Distribution) => vd.purchase?.status !== ProcessStatus.CONFIRMED,
      limitTo: 10,
    });
  }

  compareGenre(genre1: Genre[], genre2: Genre[]): boolean {
    let matched = false;
    for (const res of genre1) {
      if (genre2.find((genre: Genre) => genre.slug == res.slug)) {
        matched = true;
        break;
      }
    }

    return matched;
  }

  compareLanguage(lang1: Language[], lang2: Language[]): boolean {
    let matched = false;
    for (const res of lang1) {
      if (lang2.find((lang: Language) => lang._id == res._id)) {
        matched = true;
        break;
      }
    }
    return matched;
  }

  getSimilarDistributions(selectedProject: Distribution): Distribution[] {
    const allProjects = this.getAll();

    const similarByGenre = allProjects.filter((project) => {
      return (
        project.purchase?.status !== ProcessStatus.CONFIRMED &&
        project._id !== selectedProject._id &&
        this.compareGenre((project.genres ?? []) as Genre[], selectedProject.genres as Genre[])
      );
    });
    if (similarByGenre.length > 0) {
      return similarByGenre;
    }

    const similarByMpaaRating = allProjects.filter((project) => {
      return (
        project.purchase?.status !== ProcessStatus.CONFIRMED &&
        project._id !== selectedProject._id &&
        project.mpa_rating?.slug === selectedProject.mpa_rating.slug
      );
    });

    if (similarByMpaaRating.length > 0) {
      return similarByMpaaRating;
    }

    const similarByTargetAudience = allProjects.filter((project) => {
      return (
        project.purchase?.status !== ProcessStatus.CONFIRMED &&
        project._id !== selectedProject._id &&
        this.compareLanguage((project.languages ?? []) as Language[], selectedProject.languages as Language[])
      );
    });

    return similarByTargetAudience;
  }

  getAllModerationDistribution(userId: string): Observable<Distribution[]> {
    return this.selectAll({
      filterBy: (entity: Distribution) => {
        return entity.status === 'to-be-moderated' && entity.user_id !== userId;
      },
    });
  }

  getFeaturedDistributions(): Observable<Distribution[]> {
    return this.selectAll({
      filterBy: (entity: Distribution) => {
        return entity.status === ProcessStatus.APPROVED && entity.is_featured;
      },
    });
  }

  getContinueWatchingDistributions(): Observable<Distribution[]> {
    return this.selectAll({
      filterBy: (entity: Distribution) => {
        return entity.status === ProcessStatus.APPROVED && entity.purchase?.play_duration > 0;
      },
    });
  }

  getDistributionsByGenre(genre: string): Observable<Distribution[]> {
    return this.selectAll({
      filterBy: (entity: Distribution) => {
        return (
          entity.status === ProcessStatus.APPROVED &&
          (entity.genres as Genre[]).some((item: Genre) => item.slug === genre)
        );
      },
    });
  }

  getUsersDistribution(userId: string, purchased: boolean): Observable<Distribution[]> {
    return this.selectAll({
      filterBy: (vd: Distribution) => {
        let returnValue = vd.user_id === userId;
        if (purchased) {
          returnValue = vd.purchase?.status === ProcessStatus.CONFIRMED || vd.status === ProcessStatus.APPROVED;
        }
        return returnValue;
      },
    });
  }
}
