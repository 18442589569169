<div class="flex justify-between mb-16">
  <a
    title="Join our Discord Group"
    href="https://discord.gg/n4V6E4FGnD"
    target="blank"
    class="opacity-50 hover:opacity-100"
  >
    <app-icon [icon]="'discord'" class="w-16 h-16 white"></app-icon>
  </a>
  <a
    title="Subscribe to our Medium"
    href="https://medium.com/@DCP_Foundation"
    target="blank"
    class="opacity-50 hover:opacity-100"
  >
    <app-icon [icon]="'medium'" class="w-16 h-16 white"></app-icon>
  </a>
  <a
    title="Join our GitHub Repo"
    href="https://github.com/Decentralized-Pictures"
    target="blank"
    class="opacity-50 hover:opacity-100"
  >
    <app-icon [icon]="'git'" class="w-16 h-16 white"></app-icon>
  </a>
  <a
    title="Follow us on Twitter"
    href="https://twitter.com/DCP_Foundation"
    target="blank"
    class="opacity-50 hover:opacity-100"
  >
    <app-icon [icon]="'x'" class="w-16 h-16 white"></app-icon>
  </a>
  <a
    title="Shoot us an eMail"
    href="mailto:support@decentralized.pictures"
    target="blank"
    class="opacity-50 hover:opacity-100"
  >
    <app-icon [icon]="'mail'" class="w-16 h-16 white"></app-icon>
  </a>
  <a
    title="Follow us on YouTube"
    href="https://www.youtube.com/@decentralizedpictures"
    target="blank"
    class="opacity-50 hover:opacity-100"
  >
    <app-icon [icon]="'youtube'" class="w-16 h-16 white"></app-icon>
  </a>
</div>
<div class="flex justify-between">
  <a
    title="Terms of Service"
    target="blank"
    href="https://decentralized.pictures/terms-of-service"
    class="text-white hover:opacity-50 text-sm"
    >Terms</a
  >
  <a
    title="Privacy Policy"
    target="blank"
    href="https://decentralized.pictures/privacy-policy"
    class="text-white hover:opacity-50 text-sm"
    >Privacy</a
  >
  <a
    title="Report or Inquire about Site Security"
    target="blank"
    href="https://decentralized.pictures/privacy-policy"
    class="text-white hover:opacity-50 text-sm"
    >Security</a
  >
</div>
<div class="flex justify-center text-white opacity-25 text-sm">{{ versionName }}-{{ buildId }}</div>
