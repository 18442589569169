<div class="h-64 border-b border-borderdark relative px-16">
  <h3 class="mt-16">Notifications</h3>
  <button class="absolute top-8 right-8" (click)="showInboxBarFn()">
    <app-icon [icon]="'close'" class="w-16 h-16 white opacity-75 hover:opacity-100"></app-icon>
  </button>
</div>
<div class="border-b border-borderdark px-16 py-8 relative">
  <span class="float-left">{{ notificationCount }} <span class="px-6">Unread</span></span>
  <button class="float-right" (click)="onMarkAllRead()">
    <span class="mark-unread">Mark All as Read</span>
  </button>
</div>
<div class="flex-grow overflow-y-auto h-full pb-24 px-16" @itemsAnimation>
  <app-notification
    [notification]="notification"
    (setRead)="readNotification($event)"
    class="item"
    *ngFor="let notification of userNotifications"
  ></app-notification>

  <div
    class="text-center my-24 transition duration-300 transition-delay-500 flex justify-center"
    *ngIf="userNotifications.length > 0"
  >
    <button class="text-white flex items-center justify-center whitespace-nowrap" (click)="loadMore()">
      <app-icon class="w-20 h-20 white mr-16" [icon]="'load-more'"></app-icon>
      Load More
    </button>
  </div>
</div>
