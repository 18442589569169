import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { ProposalRound, DialogData } from '../../../../app.datatypes';
import { TourModalComponent } from '../../../../containers/tour-modal/tour-modal.component';
import { DialogContext } from '../../../../shared/dialog/dialog-context';
import { DialogService } from '../../../../shared/dialog/dialog.service';
import { ProposalRoundService, ProposalService } from '../../../../shared/services';
import { AuthService } from '../../../../shared/services/auth.service';
import { PaymentStateService } from '../../../../shared/state';

@Component({
  selector: 'app-prompt-submission-dialog',
  templateUrl: './prompt-submission-dialog.component.html',
  styleUrls: ['./prompt-submission-dialog.component.scss'],
})
export class PromptSubmissionDialogComponent implements OnInit {
  rounds: ProposalRound[];
  showPromptForm: FormGroup = new FormGroup({
    stopPrompt: new FormControl(false),
  });
  loading = true;
  constructor(
    public dialog: DialogContext<DialogData>,
    public dialogService: DialogService,
    private proposalRoundService: ProposalRoundService,
    private proposalService: ProposalService,
    private paymentService: PaymentStateService,
    private router: Router,
    private authService:AuthService
  ) {}

  ngOnInit(): void {
    this.proposalRoundService.getOpenProposalRounds().subscribe(
      result => {
        this.loading = false;
        this.rounds = result;
      },
      _error => {
        this.loading = false;
      }
    );
  }

  submitProposal(round: ProposalRound) {
    this.proposalService.startCreateProposalProcess(round._id);
    this.dialog.close();
  }

  onCancel(): void {
    this.dialog.close();
    this.submitSetting();
  }

  onBuy(): void {
    this.dialog.close();
    this.submitSetting();
    this.router.navigate(['/payment/buy']);
  }

  onSubmit(): void {
    this.dialog.close();
    this.submitSetting();
    this.proposalService.startCreateProposalProcess(null);
  }

  submitSetting() {
    if (this.showPromptForm.get('stopPrompt').value) {
      this.authService.postSetUserSettings('hide_submit_now_popup', true).subscribe();
    } 
  }

  calculateFilmFee(fee: number): number {
    return this.paymentService.calculateDollarToFilmPricing(fee);
  }
  startTour() {
    this.dialog.close();
    this.dialogService
      .open(TourModalComponent)
      .pipe(take(1))
      .subscribe(_result => {
        // intentionally blank
      });
  }
}
