import bcrypt from 'bcryptjs';

/**
 * Utils for encrypting and decrypting a string with password using in-browser webcrypto -- AKA Subtle Crypto.
 * Note: I had issues getting nodejs crypto to work with angular and webpack compilation, so I took this route using
 * this relatively new web browser standard.
 */

/**
 * initialization vector length constant
 * @type {number}
 */
const ivLen = 16;

export class CryptoUtil {
  /**
   * Use crypto Subtle (in-browser) to encrypt a string with a password and return the encrypted data as a base64 encoded string
   * @param text
   * @param password
   * @returns {Promise<any>}
   */
  public static encrypt(text, password) : Promise<string> {
    return new Promise(async function(resolve) {
      const initializationVector = new Uint8Array(ivLen);
      const pwHash = await crypto.subtle.digest('SHA-256', CryptoUtil._str2ab(password));
      const key = await crypto.subtle.importKey('raw', pwHash, 'AES-CBC', false, ['encrypt']);
      // create a random initialization vector for encryption
      crypto.getRandomValues(initializationVector);
      const encrypted = await crypto.subtle.encrypt(
        { name: 'AES-CBC', iv: initializationVector },
        key,
        CryptoUtil._str2ab(text)
      );
      // form a base64 string for storage by joining the IV and encrypted data together since we need both to decrypt
      resolve(
        btoa(
          String.fromCharCode.apply(null, CryptoUtil._joinIvAndData(initializationVector, new Uint8Array(encrypted)))
        )
      );
    });
  }

  /**
   * Decrypt the encrypted text with a password.
   * @param {!string} encryptedText base64 encoded encyption data as string
   * @param {!string} password
   * @returns {Promise<any>}
   */
  public static decrypt(encryptedText, password): Promise<string> {
    // first convert back to uint8 buffer from the stored base64 encoded string
    const buf = new Uint8Array(
      atob(encryptedText)
        .split('')
        .map(c => c.charCodeAt(0))
    );
    // split into parts for decryption
    const parts = CryptoUtil._separateIvFromData(buf);
    // decrypt and reform string from buffer
    return new Promise(async function(resolve) {
      const pwHash = await crypto.subtle.digest('SHA-256', CryptoUtil._str2ab(password));
      const key = await crypto.subtle.importKey('raw', pwHash, 'AES-CBC', false, ['decrypt']);
      return resolve(
        CryptoUtil._ab2str(await crypto.subtle.decrypt({ name: 'AES-CBC', iv: parts.iv }, key, parts.data))
      );
    });
  }

  /**
   * Used during encryption.  Join the initialization vector to the encryption data -- we need to store both to decrypt.
   * This packs it in a single array buffer for convenience
   * @param iv
   * @param data
   * @returns {Uint8Array}
   * @private
   */
  private static _joinIvAndData(iv, data) {
    const buf = new Uint8Array(iv.length + data.length);
    Array.prototype.forEach.call(iv, function(byte, i) {
      buf[i] = byte;
    });
    Array.prototype.forEach.call(data, function(byte, i) {
      buf[ivLen + i] = byte;
    });
    return buf;
  }

  /**
   * Used during decryption.  Separate the initialization vector from the encrypted data and return them both.
   * @param {!Uint8Array} buf the data combined previously by _joinIvAndData to be decomposed and used in decryption.
   * @returns {{iv: Uint8Array; data: Uint8Array}}
   * @private
   */
  private static _separateIvFromData(buf) {
    const iv = new Uint8Array(ivLen);
    const data = new Uint8Array(buf.length - ivLen);
    Array.prototype.forEach.call(buf, function(byte, i) {
      if (i < ivLen) {
        iv[i] = byte;
      } else {
        data[i - ivLen] = byte;
      }
    });
    return { iv: iv, data: data };
  }

  /**
   * Uint array to utf8 string
   * @param buf
   * @returns {any}
   * @private
   */
  private static _ab2str(buf) {
    return String.fromCharCode.apply(null, new Uint16Array(buf));
  }

  /**
   * utf8 string to ArrayBuffer (bytes)
   * @param {!string} str
   * @returns {ArrayBuffer}
   * @private
   */
  private static _str2ab(str) {
    const buf = new ArrayBuffer(str.length * 2); // 2 bytes for each char
    const bufView = new Uint16Array(buf);
    for (let i = 0, strLen = str.length; i < strLen; i++) {
      bufView[i] = str.charCodeAt(i);
    }
    return buf;
  }

  /**
   * bcrypt hashing
   */
  static salt() {
    return '$2a$10$cKHSLjD1/78i1bflDq9o4O';
  }
  static bcryptHash(value) {
    return bcrypt.hashSync(value, this.salt());
  }
}
