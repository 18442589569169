import { OverlayModule } from '@angular/cdk/overlay';
import { PlatformModule } from '@angular/cdk/platform';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NoItemsComponent, ScrollInheritedComponent } from './components';
import { CopyToClipboardComponent } from './components/copy-to-clipboard/copy-to-clipboard.component';
import { ToggleButtonComponent } from './components/toggle-button/toggle-button.component';
import { UploadDialogComponent } from './components/upload-dialog/upload-dialog.component';
import {
  AdministratorGuardService,
  DynamicOverlay,
  DynamicOverlayContainer,
  EmbedVideoService,
  ErrorsHandlerService,
  LoggedInAuthGuard,
  ModeratorGuardService,
  ProgressService,
  SEOService,
  SharedService,
  TezosWalletService,
  ThemeService,
  ToasterService,
  UnAuthGuardService,
  WindowScrollService,
  WindowSizeService,
  ZeroDayPaymentGuardService,
} from './services';
import { CanDeactivateGuard } from './services/can-deactivate.guard';
import { DiscordChatService } from './services/discord-chat.service';
import { InputNumberDirective } from './directives/input-number.directive';
import { ScrollEventDirective } from './directives/scroll-event.directive';
import { StatusComponent } from './components/component-status/status.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { JoyrideModule } from 'ngx-joyride';
import { TooltipModule } from './modules/directives/tooltip/tooltip.module';
import { FilmpriceModule } from './modules/pipes/filmprice/filmprice.module';
import { UserAvatarComponent } from '../components/user-avatar/user-avatar.component';
import { IconModule } from './modules/components/icon/icon.module';
import { TabsModule } from './modules/components/tabs/tabs.module';
import { LocalLoaderModule } from './modules/components/local-loader/local-loader.module';
import { VideoPlayerModule } from './modules/components/video-player/video-player.module';
import { FilePreviewModule } from './modules/components/file-preview/file-preview.module';
import { StatusTextModule } from './modules/pipes/status-text/status-text.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    IconModule,
    JoyrideModule.forChild(),
    TooltipModule,
    FilmpriceModule,
    TabsModule,
    LocalLoaderModule,
    VideoPlayerModule,
    FilePreviewModule,
    StatusTextModule,
  ],
  declarations: [
    ScrollInheritedComponent,
    UserAvatarComponent,
    NoItemsComponent,
    ScrollEventDirective,
    InputNumberDirective,
    CopyToClipboardComponent,
    UploadDialogComponent,
    ToggleButtonComponent,
    StatusComponent,
  ],
  exports: [
    LocalLoaderModule,
    ScrollInheritedComponent,
    UserAvatarComponent,
    NoItemsComponent,
    // Directives
    InputNumberDirective,
    ScrollEventDirective,
    // modules
    CommonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    OverlayModule,
    PlatformModule,
    JoyrideModule,
    CopyToClipboardComponent,
    UploadDialogComponent,
    ToggleButtonComponent,
    StatusComponent,
    IconModule,
    TooltipModule,
    FilmpriceModule,
    TabsModule,
    FilePreviewModule,
    StatusTextModule,
  ],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        { provide: 'windowObject', useValue: window },

        UnAuthGuardService,
        LoggedInAuthGuard,
        ModeratorGuardService,
        ZeroDayPaymentGuardService,
        AdministratorGuardService,
        ToasterService,
        ErrorsHandlerService,
        EmbedVideoService,
        WindowSizeService,
        ThemeService,
        SEOService,
        ProgressService,
        DynamicOverlay,
        DynamicOverlayContainer,
        // dcp phone
        WindowScrollService,
        CanDeactivateGuard,
        DiscordChatService,
        TezosWalletService,
        SharedService,
      ],
    };
  }
}
