<form [formGroup]="form" class="pt-1" autocomplete="off">
  <p class="mb-16">Please select your preferred payment type(s) for your project from the list below.</p>
  <div class="flex mb-24 gap-24">
    <app-checkbox text="FILMCredits" formControlName="is_film_supported"> </app-checkbox>
    <app-checkbox text="USDC" formControlName="is_usdc_supported"> </app-checkbox>
  </div>
  <ng-container *ngIf="form.value.is_film_supported">
    <p class="mb-16">
      Please list all stakeholders for your project in the section below. Stakeholders are individuals or entities
      entitled to earnings or royalties from the project. You may edit this section at any time prior to a payout of
      earnings.
    </p>
    <ng-container formArrayName="royalty_map">
      <div
        class="border bg-white relative mb-16 rounded border-gray-border p-16"
        *ngFor="let controls of royaltyMapFields?.controls; let i = index"
        [formGroupName]="i"
      >
        <label>Stakeholder {{ i + 1 }}</label>
        <button
          class="btn btn-icon-transparent icon-red absolute right-8 top-8 p-0 min-w-0"
          *ngIf="i > 0"
          (click)="removeStakeHolder(i)"
        >
          <app-icon class="!w-20 !h-20" icon="trash"></app-icon>
        </button>
        <div class="grid grid-cols-1 md:gap-24 mt-8 md:grid-cols-4" *ngIf="this.royaltyMapFields.length">
          <div class="form-group w-full p-0">
            <label>Label</label>
            <input type="text" formControlName="name" />
          </div>
          <div class="form-group w-full p-0 md:col-span-2">
            <label
              >Wallet Address<app-icon
                [icon]="'info'"
                class="ml-4 w-12 h-12 opacity-50 hover:opacity-100 inline-block"
                tooltipClass="lg"
                appTooltip="Enter DCP users Account Address (for example; tz1…, or tz2… ). If you do not know this stakeholder’s wallet address, you may omit them for now and update your Royalty Map later at any time. Earnings are calculated at time of payout, so please confirm relevant stakeholders included prior to initiating payout."
                placement="top"
              ></app-icon
            ></label>
            <input type="text" formControlName="wallet_address" />
          </div>
          <div class="form-group md:w-160 p-0 addon-e">
            <label>Ownership percentage</label>
            <input type="number" appOnlyNumber [maxDecimals]="2" formControlName="reward_percentage" />
            <app-icon [icon]="'percentage'"></app-icon>
          </div>
          <div class="info-box md:col-span-4 mt-16 md:mt-0">
            <p class="flex">
              Priority Stakeholder(Optional)
              <app-icon
                [icon]="'info'"
                tooltipClass="lg"
                class="ml-4 opacity-50 hover:opacity-100"
                style="position: inherit !important; width: 18px !important; height: 18px !important"
                appTooltip="Enable this option to give this stakeholder priority over earnings. Enter the amount ($USD) this user must recoup before non-priority stakeholders receive earnings."
                placement="top"
              ></app-icon>
            </p>
            <label class="switch">
              <input type="checkbox" formControlName="is_priority_stakeholder" (change)="changePriorityHolder(i)" />
              <span class="slider rounded"></span>
            </label>
          </div>
          <div class="form-group addon-f md:col-span-2" *ngIf="royaltyMapFields.at(i)?.value?.is_priority_stakeholder">
            <label>Ownership Amount ($USD)</label>
            <input type="number" appOnlyNumber [maxDecimals]="2" formControlName="priority_stakeholder_amount" />
            <app-icon [icon]="'usd'"></app-icon>
          </div>
        </div>
      </div>
    </ng-container>
    <div class="info-box warning mb-16" *ngIf="royaltyMapFields?.errors">
      <app-icon icon="warning"></app-icon>
      Sum of ownershipe percentages should equal to 100
    </div>
    <button class="btn btn-link btn-sm mb-24" style="padding: 0 !important" (click)="addStakeHolders()">
      <app-icon icon="plus" class="w-12 h-12"></app-icon>
      Add additional stakeholders
    </button>
  </ng-container>
  <ng-container *ngIf="form.value.is_usdc_supported">
    <div class="form-group w-full p-0">
      <label
        >Enter coinbase email or network address<app-icon
          [icon]="'info'"
          class="ml-4 w-12 h-12 opacity-50 hover:opacity-100 inline-block"
          tooltipClass="lg"
          appTooltip="Enter coinbase email or network address"
          placement="top"
        ></app-icon
      ></label>
      <input type="text" formControlName="wallet_address_usdc" />
    </div>
    <div class="info-box mt-24">
      <p class="w-full">
        All earnings will be delivered to this Coinbase account. It is the sole responsibility of the DCP+ Project Owner
        to deliver USDC earnings to all applicable stakeholders on this project.
      </p>
    </div>
  </ng-container>
</form>
