import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Toastr } from '../toastr';

@Component({
  selector: 'app-toastr',
  templateUrl: './toastr.component.html'
})
export class ToastrComponent {
  @Input() toast: Toastr;
  @Input() i: number;
  @Output() remove = new EventEmitter<number>();

  removeToastr(event) {
    this.remove.emit(event);
  }
}
