import { Component, DestroyRef, Input, OnInit } from '@angular/core';
import {environment} from '../../../../../../environments';
import {ReferralData} from '../../../../../app.datatypes';
import {DialogService} from '../../../../dialog/dialog.service';
import {ToasterService, UserService} from '../../../../services';
import {AuthenticatedUserQuery} from '../../../../state/authenticated-user/authenticated-user.query';
import {AuthenticatedUserService} from '../../../../state/authenticated-user/authenticated-user.service';
import {EmailShareDialogComponent} from '../email-share-dialog/email-share-dialog.component';
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

@Component({
  selector: 'app-referral-box',
  templateUrl: './referral-box.component.html',
  styleUrls: ['./referral-box.component.scss'],
})
export class ReferralBoxComponent implements OnInit {
  @Input() revert = false;
  @Input() boxMode = false;
  loading = true;
  shareButtons = [
    { icon: '/assets/img/social-icons/email-share.svg' },
    { icon: '/assets/img/social-icons/fb-share.svg', link: 'https://www.fb.com' },
    { icon: '/assets/img/social-icons/x-share.svg', link: 'https://www.twitter.com' },
    { icon: '/assets/img/social-icons/linkedin-share.svg', link: 'https://www.linkedin.com' },
  ];
  referallData: ReferralData;
  type: number;
  item: { link: null };
  unAuthAction: string;
  constructor(
    private toasterService: ToasterService,
    private dialogService: DialogService,
    private userService: UserService,
    public authenticatedUserQuery: AuthenticatedUserQuery,
    public authenticatedUserService: AuthenticatedUserService,
    private destroyRef: DestroyRef
  ) {}

  ngOnInit(): void {
    if (!this.boxMode) {
      this.userService
        .getReferralData()
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(
          (referralData: ReferralData) => {
            if (referralData.usersTier === '0 to 100 points') {
              referralData.usersTier = 'Tier 1';
            } else if (referralData.usersTier === '100 to 1000 points') {
              referralData.usersTier = 'Tier 2';
            } else {
              referralData.usersTier = 'Tier 3';
            }
            this.referallData = referralData;
            this.loading = false;
          },
          (error) => (this.loading = false)
      );
      this.authenticatedUserQuery.unAuthAction.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((action) => {
        if (action === null && this.unAuthAction && this.authenticatedUserQuery.isRegistrationComplete) {
          this.socialShare(this.item, this.type);
        }
      });
    }
  }

  socialShare(item: { link: null }, type: number): void {
    if (!this.authenticatedUserQuery.isRegistrationComplete) {
      this.item = item;
      this.type = type;
       this.unAuthAction = 'verify';
       this.authenticatedUserQuery.unAuthAction.next({ slug: 'Referral Program', action: this.unAuthAction });
      this.toasterService.openToastr(
        'You need to complete Phone/Email verification and pending information first',
        'Complete Verification',
        'info',
        3000
      );
      return;
    } else if (environment.readOnlyMode) {
      this.toasterService.openErrorToastr(
        'The blockchain is temporarily unavailable/offline for maintenance.',
        'BlockChain Disabled'
      );
      return;
    }
    if (type === 0) {
      navigator.clipboard.writeText(this.referallData.referralURL);
      this.toasterService.show('Link copied to clipboard', 'copied');
    } else {
      if (item.link) {
        window.open(item.link, '_blank');
      } else {
        this.dialogService.open(EmailShareDialogComponent);
      }
    }
  }
}
