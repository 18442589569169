import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../environments';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  versionName = '';
  buildId = '';
  ngOnInit() {
    this.versionName = environment.version_name;
    this.buildId = environment.build_id;
  }
}
