import { Injectable } from '@angular/core';
import { StoreConfig, Store } from '@datorama/akita';
import { AuthenticatedUserState } from './authenticated-user.state';

function createInitialState(): AuthenticatedUserState {
  return {
    tokens: {
      access_token: localStorage.getItem('access_token') ? localStorage.getItem('access_token') : null,
      refresh_token: localStorage.getItem('refresh_token') ? localStorage.getItem('refresh_token') : null,
      refresh_at: localStorage.getItem('refresh_at') ? new Date(localStorage.getItem('refresh_at')) : null,
    },
  } as AuthenticatedUserState;
}

@Injectable({ providedIn: 'root' })
@StoreConfig({
  name: 'authenticated-user',
  resettable: true,
})
export class AuthenticatedUserStore extends Store<AuthenticatedUserState> {
  constructor() {
    super(createInitialState());
  }
}
