import { ComponentFactoryResolver, ComponentRef, Injectable, Injector, Type, ViewContainerRef } from '@angular/core';
import { Subject } from 'rxjs';
import { DialogContainer } from './dialog-container';
import { DialogContainerComponent } from './dialog-container/dialog-container.component';
import { DialogContext } from './dialog-context';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  private viewContainerRef: ViewContainerRef;
  dialogBehaviour: Subject<any> = new Subject(); // any is ok here

  constructor(private componentFactoryResolver: ComponentFactoryResolver) {}

  registerViewContainer(vcf: ViewContainerRef) {
    if (this.viewContainerRef) {
      this.viewContainerRef.clear();
    }
    this.viewContainerRef = vcf;
  }

  open<T>(
    type: Type<any>, // any is ok here
    data?: any, // any is ok here
    className?: string,
    options: { hideOnBackdropClick?: boolean; containerType: Type<any> } = {
      // any is ok here
      containerType: DialogContainerComponent,
    }
  ): Subject<any> {
    // any is ok here
    if (!this.viewContainerRef) {
      this.dialogBehaviour.next({ error: 'No view container' });
    }
    const container = <ComponentRef<DialogContainer>>this.container(options.containerType);
    container.instance.className = className;
    let context;

    const containerInterval = setTimeout(() => {
      if (container.instance.container) {
        const optionsNew = {
          providers: [{ provide: DialogContext, deps: [] }],
          parent: container.instance.container?.injector,
        };
        const injector = Injector.create(optionsNew);
        context = injector.get(DialogContext);
        context.data = data;
        if (!options || options.hideOnBackdropClick) {
          container.instance.context = context;
        }
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(type);
        container.instance.container.createComponent(componentFactory, 0, injector);
        context.promise(container, this.viewContainerRef);
        clearInterval(containerInterval);
      }
    });
    return this.dialogBehaviour;
  }

  container(containerType: Type<any>): ComponentRef<any> {
    // any is ok here
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(containerType);
    return this.viewContainerRef.createComponent(componentFactory, this.viewContainerRef.length);
  }
}
