export const environment_common = {
  production: false,
  staging: false,
  local: true,
  version_name: 'v1.8.1',
  build_id: 'devhashid', // set during prod/staging build time
  echo_server_url: 'https://ws-staging.decentralized.pictures',
  echo_server_key: 'b640f3c6abc484e9ced0999d99f3f745',
  google_analytics_id: 'G-Q33EB4NPTC',
  echo_enabled: true,
  largo_ai_enabled: true,
  buy_enabled: false,
  url: 'https://app-staging.decentralized.pictures/',
  client_id: '5ae07cda48d3e9211c00478d',
  client_secret: 'zaMwdRanryWWZPYsmchqAbeFqGiG7nMhnZQt7pDY',
  chain_id: '632a1fad',
  film_controller_address: 'KT1A9aF12EYyNXxxMXEzaDcpRyYJ566P1UCv',
  film_ledger_address: 'KT18bXmxVBdwhRqiCieVW5NHmeB4qo3fKsf8',
  video_distribution_address: 'KT1XKbE6XfzR1fc5gYgwqTPE9sJKjmxb6MpK',
  explorer_url: 'https://explorer.test.tlnt.net',
  max_pdf_doc_size: 50, // currently in MB
  max_image_size: 2.5,
  max_images_allowed: 25,
  max_docs_allowed: 15,
  min_video_upload_height: 360,
  grid_skip_limit: 50, // skip limit for infinite scrolling
  chat_invite_url: 'https://discord.gg/8QgEgeF23C',
  discord_base_url: 'https://discord.com/channels',
  discord_server_id: '945656797858000927',
  discord_general_channel_id: '945657020340662282',
  discord_support_channel_id: '890933170520879124',
  profile_placeholder: 'https://www.gravatar.com/avatar/0.43227576652898625',
  vimeo_client_key:
    'FidtwZTcMqi79w+HnSZ73Odz0Udyms7RMZdDj5FMkVKRFoM7hxeX7r2UiBhwJM5S+6rpfngJLhhyDzHUeTxDb5SFf5dh3XpWEHs2B7XOEQVhwtiXyVgjU8BSIV4M96Zi',
  vimeo_client_id: '0bd733e4f7a078c3ecd3e1d5d128c5d3952d1140',
  vimeo_token: '564bb19d0836cffc97c16416c5edd88a',
  youtube_token: 'AIzaSyB_QSVzjqjgxmMp_uiI_fpPrwNQwNjgGJs',
  chart_colors: ['#00C851', '#16ABFF', '#9E00FF', '#FF6363', '#01F2D5', '#FFA500', '#FF1493', '#563BFD'],
  idle_timeout_seconds: 30 * 60,
  idle_warning_seconds: 60,
  limited_swag_functionality: false,
  limited_proposal_functionality: false,
  limited_cq_functionality: false,
  limited_quiz_functionality: false,
  limited_reputation_functionality: false,
  limited_moderation_functionality: false,
  limited_watch_functionality: false,
  referral_program: true,
  zero_day_fee: 1,
  kukai_wallet_url: 'https://staging-kukai.decentralized.pictures',
  t4l3nt_rpc_endpoint: 'https://staging-rpc.decentralized.pictures',
  simplex_url: 'https://sandbox.test-simplexcc.com',
  review_skip_limit: 10,
  nft_skip_limit: 6,
  max_video_size: 2147483648,
  vd_max_file_size: 32212325120,
  round_earning_skip_limit: 20,
  jira_servicedesk_url: 'https://support-dcp.atlassian.net/servicedesk/customer/portal/1',
  defaultProposalDetails: {
    round_tooltip: 'For wining the award',
    proposal_header_fields: {
      block_title: 'Project Title',
      block_subtitle: 'Please add a title for your project.',
      title: {
        caption: 'Project Title',
        tooltip: 'The title of your Project. This title will also appear on the project card.',
        placeholder: 'What is the title of your project?',
      },
    },
    proposal_description_fields: {
      proposal_detail_changed: false,
      block_title: 'Project Details',
      block_subtitle: 'Please add all your project details below. ',
      summary: {
        title: 'Log line',
        tooltip: 'One-sentence summary or description of the project',
        placeholder: 'Briefly describe what your project is about',
      },
      description: {
        title: 'Project Description',
        tooltip: 'The complete description of the project',
        placeholder: 'The complete description of the project',
      },
    },
  },
  cqDemo: {
    _id: '6331c5ae0c74db27dc0c2542',
    bounty: 150,
    title: 'Demo CQ',
    cover: 'artist',
    cover_color: 'olive',
    query_age: 1,
    user_id: '630ea349a8ecdfe8970b14c5',
    status: 'approved',
    created_at: '2022-09-26T15:30:54.062000Z',
    balance: 174.68176,
    staked: 29,
    approved_at: '2022-09-26T15:36:04.694000Z',
    expired_at: '2022-09-27T15:36:04.694000Z',
    total_votes: 5,
    total_stake: 29,
    is_reported_by_current_user: false,
    current_user_submit: {
      _id: '632af094a8556d5fec0e38f3',
      chosen_option: ['6322c89c9fa4763ca60f5a95'],
      chosen_mongo_option: [
        {
          $oid: '6322c89c9fa4763ca60f5a95',
        },
      ],
      creative_query_mongo_id: '6322c89c9fa4763ca60f5a92',
      creative_query_id: '6322c89c9fa4763ca60f5a92',
      creative_query_blockchain_id: 'c9b478f22eb4a49f06128bba91d22d77005f0987929bcb274ecbce710e7277f2',
      creative_query_question_id: '6322c89c9fa4763ca60f5a94',
      user_mongo_id: '630f0cb871a9e531080ffa55',
      user_id: '630f0cb871a9e531080ffa55',
      stake: 88,
      status: 'confirmed',
      selected_index: 0,
      selected_value: '/////',
      updated_at: '2022-09-27T00:20:04.833000Z',
      raw_updated_at: '2022-09-27 00:20:04.833228',
      created_at: '2022-09-21T11:08:04.311000Z',
      raw_created_at: '2022-09-21 11:08:04.311965',
      blockchain_id: '36ad8c78b2f03e319526125c2cd626f29bbb4d5c936f4f5f7c87054a6db9da03',
      submission_stake: '7e505fb4483e240dd07b8978550a32aaa282b72914fd04fc12139873f96a827e',
      submission_transaction: '4b7f53b54602169fb1d407b8a15cf9d45a357bb62c1de40bfed877d041f000b1',
      confirmed_at: {
        $date: {
          $numberLong: '1663758529186',
        },
      },
      algorithmic_score: 0.06337426983859293,
    },
    is_own_cq: false,
    has_answer_waiting_for_submission: false,
    questions: [
      {
        _id: '6331c5ae0c74db27dc0c2544',
        description: 'Pick an image',
        type: 'vote',
        index: 0,
        creative_query_mongo_id: '6331c5ae0c74db27dc0c2542',
        creative_query_id: '6331c5ae0c74db27dc0c2542',
        updated_at: '2022-09-26T15:30:54.077000Z',
        raw_updated_at: '2022-09-26 15:30:54.077246',
        created_at: '2022-09-26T15:30:54.077000Z',
        raw_created_at: '2022-09-26 15:30:54.077267',
        options: [
          {
            type: 'text',
            value: 'Text Answer',
            isSaved: true,
            question_id: null,
            index: 0,
            isEdit: false,
          },
          {
            type: 'video',
            value: 'https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4',
            isSaved: true,
            question_id: null,
            index: 1,
            isEdit: false,
            title: 'Video Answer',
            description: '',
            videoData: '',
            isDirectVideoUpload: true,
            thumbnail_url: '/assets/img/no-img.svg',
            videoBlobUrl: 'https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4',
          },
          {
            type: 'image',
            value: '/assets/img/no-img.svg',
            isSaved: true,
            question_id: null,
            index: 0,
            isEdit: false,
            title: 'Image Answer',
            description: '',
            videoData: '',
            isDirectVideoUpload: false,
          },
        ],
      },
    ],
    demography: {
      _id: '6331c5ae0c74db27dc0c2543',
      age_group: null,
      region: null,
      gender: null,
      type: 'creative-query',
      reference_mongo_id: '6331c5ae0c74db27dc0c2542',
      reference_id: '6331c5ae0c74db27dc0c2542',
      updated_at: '2022-09-26T15:30:54.072000Z',
      raw_updated_at: '2022-09-26 15:30:54.072002',
      created_at: '2022-09-26T15:30:54.072000Z',
      raw_created_at: '2022-09-26 15:30:54.072027',
    },
    user: {
      _id: '630ea349a8ecdfe8970b14c5',
      name: 'bcon',
      appeal_name: 'bcon',
    },
  },
  demoReview: {
    _id: '63410831016a70c9df0ebe18',
    scores: [
      {
        name: 'Commercial viability',
        value: 7,
      },
      {
        name: 'Creativity',
        value: 7,
      },
      {
        name: 'Characters',
        value: 7,
      },
    ],
    content: null,
    blockchain_score: 49,
    average_score: 7,
    recommendation: 'consider',
    status: 'waiting-for-confirmation',
    user_id: '630f0cb871a9e531080ffa55',
    proposal_id: '633d718ebc72ac730b046747',
    proposal_blockchain_id: 'bc43ba24b16d266eae93c97ae59652448c638750302cc45656dcbfa8581c1515',
    reputation_at_submission: 23.076923,
    blockchain_id: '4a37e43a2cbfdab7f3c7bfc0a8f8e2e5807868abf4e66a2071b17be938dd87b7',
    stake: 0,
    bounty: 0,
    updated_at: '2022-10-08T05:18:47.138000Z',
    created_at: '2022-10-08T05:18:41.988000Z',
    is_liked_by_current_user: false,
    is_current_user_waiting_for_confirmation: false,
    is_reported_by_current_user: false,
    is_own_review: true,
    moderations: [],
    user: {
      _id: '630f0cb871a9e531080ffa55',
      name: 'Demo',
      avatar_url: '',
    },
    proposal: {},
    likes: [],
  },
  proposalDemo: {
    _id: '633d718ebc72ac730b046744',
    balance: 1191.857343,
    score: 4.536391360232706,
    votes_score: 10,
    avg_score: 5.561904761904761,
    reviews_score: 5,
    evaluations_score: 3,
    likes_score: 2,
    proposal_round_mongo_id: '633c201a2b885bb6d00b9662',
    proposal_round_id: '633c201a2b885bb6d00b9662',
    title: 'Demo Project',
    summary: '<p>custom project description - edit</p>',
    content: 'custom log line - this is an edit',
    status: 'approved',
    genres: [
      {
        _id: '6329b025d71106f83902b152',
        slug: 'N/A',
        name: 'Not Applicable',
      },
      {
        _id: '630e35ecbd1f2bec74077147',
        slug: 'spy',
        name: 'Spy',
      },
    ],
    languages: [
      {
        _id: '630e35ecbd1f2bec74077156',
        iso: 'az',
        name: 'Azerbaijani',
      },
      {
        _id: '630e35ecbd1f2bec74077155',
        iso: 'hy',
        name: 'Armenian',
      },
    ],
    user_id: '630e3f3a0e9dec44e1099a52',
    images: [
      {
        url: '/assets/img/no-img.svg',
        mediaTitle: 'test image',
        description: 'test image',
      },
    ],
    docs: [],
    last_extended_at: '2022-10-07T18:20:18.849000Z',
    enable_chat: true,
    chat_active: true,
    cover_url: '/assets/img/no-img.svg',
    cover_type: 'image',
    videos: [],
    updated_at: '2022-10-10T05:50:36.644000Z',
    raw_updated_at: '2022-10-10 05:50:36.644439',
    created_at: '2022-10-05T11:59:10.647000Z',
    raw_created_at: '2022-10-05 11:59:10.647871',
    submission_fee: 58.452528,
    blockchain_id: 'bc43ba24b16d266eae93c97ae59652448c638750302cc45656dcbfa8581c1515',
    submission_contribution: '3e2c72c68bda50aca50a522d687e57cd27802ffef05f8e52b89c8928191d59d3',
    submission_transaction: '977a01763e912545d6ab4b1ed03bdddec223000c2deb0d0b96a48001a9e7f65f',
    chat_channel_id: '1027188554197114881',
    blockchain_confirmed: true,
    staked: 195,
    approved_at: '2022-10-07T18:22:45.775000Z',
    stake_score: 195,
    extra_bounty: 0,
    resubmission_fee: 30.312583,
    is_own_proposal: false,
    has_reviewed_proposal: false,
    own_review_status: 'approved',
    is_general_review: false,
    is_reported_by_current_user: false,
    is_expired: false,
    total_stake: 195,
    total_contributed_bounty: 1203.765111,
    statistics: {
      score: 4.536391360232706,
      scores: [
        {
          date: 0,
          score: 0,
        },
        {
          date: '2022-10-05',
          score: 0.7646112863972498,
        },
        {
          date: '2022-10-06',
          score: 1.5268347854060238,
        },
        {
          date: '2022-10-07',
          score: 2.289044104103519,
        },
        {
          date: '2022-10-08',
          score: 2.6100937121219743,
        },
        {
          date: '2022-10-09',
          score: 3.258149620726647,
        },
        {
          date: '2022-10-10',
          score: 3.906205529331319,
        },
        {
          date: '2022-10-11',
          score: 3.906205529331319,
        },
      ],
      balance: 1191.857343,
      total_bounty: 1203.765111,
      bounty: [
        {
          date: 0,
          total: 0,
        },
        {
          date: '2022-10-05',
          total: 1173.452528,
        },
        {
          date: '2022-10-07',
          total: 1203.765111,
        },
        {
          date: '2022-10-11',
          total: 1203.765111,
        },
      ],
      total_votes: 10,
      votes: [
        {
          date: 0,
          total: 0,
        },
        {
          date: '2022-10-05',
          total: 6,
        },
        {
          date: '2022-10-08',
          total: 7,
        },
        {
          date: '2022-10-11',
          total: 7,
        },
      ],
      total_stake: 195,
      stake: [
        {
          date: 0,
          total: 0,
        },
        {
          date: '2022-10-05',
          total: 185,
        },
        {
          date: '2022-10-06',
          total: 195,
        },
        {
          date: '2022-10-10',
          total: 200,
        },
        {
          date: '2022-10-11',
          total: 200,
        },
      ],
      average_votes: 0,
      average_bounty: 0,
    },
    user: {
      _id: '630e3f3a0e9dec44e1099a52',
      name: 'bryan',
      appeal_name: 'bryan',
    },
    proposal_round: {
      _id: '123456789123456789',
      status: 'active',
      title: 'Tutorial Round',
      start_date: '2022-10-03T00:00:00.000000Z',
      end_date: '2022-10-20T00:00:00.000000Z',
      submission_fee: 10,
      minimum_bounty: 10,
      reward: 100,
      submission_deadline_date: '2022-10-18T00:00:00.000000Z',
      rules: '<p>qwqwqw</p>',
      stats: {
        _id: '636507210628c24c900eac02',
        total_proposals: 4,
        total_reviews: 5,
        total_likes: 0,
        top_proposal_score: 8,
        top_proposal_review_bounty: 7820,
        is_demography: true,
        is_open: false,
        proposal_count: 4,
        proposal_vote_count: 7,
        total_stake: 4147,
      },
      scores: [
        {
          name: 'Commercial viability',
        },
        {
          name: 'Creativity',
        },
        {
          name: 'Characters',
        },
      ],
      proposal_description_fields: {
        proposal_detail_changed: true,
        block_title: 'custom details',
        block_subtitle: 'custom detail desc',
        proposal_header_fields: {
          block_title: 'Custom title',
          block_subtitle: 'custom itle desc',
          title: {
            caption: 'custom title\u00a0',
            tooltip: 'custom title tooltip',
            placeholder: 'custom title placeholder',
          },
        },
        summary: {
          title: 'custom log',
          tooltip: 'custom tooltip',
          placeholder: 'custom placeholder',
        },
        description: {
          title: 'Proposal Description',
          tooltip: 'The complete description of the project',
          placeholder: 'The complete description of the project',
        },
      },
      is_demography: true,
      is_open: true,
      proposal_count: 3,
      proposal_vote_count: 13,
      total_stake: 195,
    },
    score_logs: {
      _id: '633d76726068072e50099f42',
      updated_at: '2022-10-10T00:00:03.519000Z',
      raw_updated_at: '2022-10-10 00:00:03.519278',
      created_at: '2022-10-05T12:20:02.746000Z',
      raw_created_at: '2022-10-05 12:20:02.746117',
      proposal_id: '633d718ebc72ac730b046747',
      scores: {
        '2022-10-05': 4.587667718383499,
        '2022-10-06': 4.573340994052643,
        '2022-10-07': 4.573255912184973,
        '2022-10-08': 4.536391360232706,
        '2022-10-09': 4.536391360232706,
        '2022-10-10': 4.536391360232706,
      },
    },
  },
  readOnlyMode: false,
  personalDetailsPending: null,
  proposalByRoundsLimit: 6,
  byRoundsLimit: 3,
};

const _CONSTANTS = {
  NAME: 'T4L3NT Testnet',
  // https://gitlab.com/tezos-domains/contracts/-/blob/master/deployed/mainnet.json#L9
  NETWORK: 'mainnet',
  MAINNET: false,
  API_URL: 'https://explorer-api.test.tlnt.net/v1',
  NODE_URL: 'https://staging-rpc.decentralized.pictures',
  BLOCK_EXPLORER_URL: 'https://explorer.test.tlnt.net',
};
export const CONSTANTS_COMMON = _CONSTANTS;
