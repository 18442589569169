import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { of, Observable, MonoTypeOperatorFunction, from as fromPromise, iif, throwError } from 'rxjs';
import { catchError, retryWhen, flatMap, timeout, delay as delayOperator, concatMap } from 'rxjs/operators';
import { Buffer } from 'buffer';
import { blake2b } from 'blakejs';
import { sign as naclSign } from 'tweetnacl';
import * as Bs58check from 'bs58check';
import Big from 'big.js';
import { localForger } from '@taquito/local-forging';
import { CONSTANTS } from '../../../environments/environment';
import { ErrorHandlingPipe } from '../pipes/error-handling.pipe';
import * as elliptic from 'elliptic';

const httpOptions = { headers: { 'Content-Type': 'application/json' } };

export interface KeyPair {
  sk: string | null;
  pk: string | null;
  pkh: string;
}
@Injectable()
export class OperationService {
  nodeURL = CONSTANTS.NODE_URL;
  prefix = {
    tz1: new Uint8Array([6, 161, 159]),
    tz2: new Uint8Array([6, 161, 161]),
    tz3: new Uint8Array([6, 161, 164]),
    tz4: new Uint8Array([6, 161, 166]),
    edpk: new Uint8Array([13, 15, 37, 217]),
    sppk: new Uint8Array([3, 254, 226, 86]),
    edsk: new Uint8Array([43, 246, 78, 7]),
    spsk: new Uint8Array([17, 162, 224, 201]),
    edsig: new Uint8Array([9, 245, 205, 134, 18]),
    spsig: new Uint8Array([13, 115, 101, 19, 63]),
    sig: new Uint8Array([4, 130, 43]),
    o: new Uint8Array([5, 116]),
    B: new Uint8Array([1, 52]),
    TZ: new Uint8Array([3, 99, 29]),
    KT: new Uint8Array([2, 90, 121])
  };
  microTez = new Big(1000000);
  feeHardCap = 100; //tez
  constructor(private http: HttpClient, private errorHandlingPipe: ErrorHandlingPipe) {}
  /*
    Returns an observable for the activation of an ICO identity
  */
  opCheck(final: any, newKT1s: string[] = null): Observable<any> {
    if (typeof final === 'string' && final.length === 51) {
      return of({
        success: true,
        payload: {
          opHash: final,
          newKT1s: newKT1s
        }
      });
    } else {
      return of({
        success: false,
        payload: {
          opHash: null,
          msg: final
        }
      });
    }
  }
  /*
    Returns an observable for the transaction of tez.
  */
  transfer(from: string, transactions: any, fee: number, keys: KeyPair, tokenTransfer: string = ''): Observable<any> {
    return this.getHeader()
      .pipe(
        flatMap((header: any) => {
          return this.getRpc(`chains/main/blocks/head/context/contracts/${keys.pkh}/counter`).pipe(
            flatMap((actions: any) => {
              return this.getRpc(`chains/main/blocks/head/context/contracts/${keys.pkh}/manager_key`).pipe(
                flatMap((manager: any) => {
                  if (fee > this.feeHardCap) {
                    throw new Error('TooHighFee');
                  }
                  const counter: number = Number(actions);
                  const fop = this.createTransactionObject(header.hash, counter, manager, transactions, keys.pkh, keys.pk, from, fee, tokenTransfer);
                  return this.operation(fop, header, keys);
                })
              );
            })
          );
        })
      )
      .pipe(catchError((err) => this.errHandler(err)));
  }
  createTransactionObject(
    hash: string,
    counter: number,
    manager: string,
    transactions: any,
    pkh: string,
    pk: string,
    from: string,
    fee: number,
    tokenTransfer: string
  ): any {
    const fop: any = {
      branch: hash,
      contents: []
    };
    if (manager === null) {
      // Reveal
      fop.contents.push({
        kind: 'reveal',
        source: pkh,
        fee: '0',
        counter: (++counter).toString(),
        gas_limit: '1000',
        storage_limit: '0',
        public_key: pk
      });
    }
    for (let i = 0; i < transactions.length; i++) {
      const currentFee = i === transactions.length - 1 ? this.microTez.times(fee).toString() : '0';
      const gasLimit = transactions[i].gasLimit.toString();
      const storageLimit = transactions[i].storageLimit.toString();
      if (tokenTransfer) {
        // token transfer unsupported
      } else if (from.startsWith('tz')) {
        const transactionOp: any = {
          kind: 'transaction',
          source: from,
          fee: currentFee,
          counter: (++counter).toString(),
          gas_limit: gasLimit,
          storage_limit: storageLimit,
          amount: this.microTez.times(transactions[i].amount).toString(),
          destination: transactions[i].destination
        };
        if (transactions[i].parameters) {
          transactionOp.parameters = transactions[i].parameters;
        }
        fop.contents.push(transactionOp);
      } else if (from.startsWith('KT')) {
        if (transactions[i].parameters) {
          throw new Error('Unsupported Operation');
        }
        if (transactions[i].destination.startsWith('tz')) {
          const managerTransaction = this.getContractPkhTransaction(transactions[i].destination, this.microTez.times(transactions[i].amount).toString());
          fop.contents.push({
            kind: 'transaction',
            source: pkh,
            fee: currentFee,
            counter: (++counter).toString(),
            gas_limit: gasLimit,
            storage_limit: storageLimit,
            amount: '0',
            destination: from,
            parameters: managerTransaction
          });
        } else if (transactions[i].destination.startsWith('KT')) {
          const managerTransaction = this.getContractKtTransaction(transactions[i].destination, this.microTez.times(transactions[i].amount).toString());
          fop.contents.push({
            kind: 'transaction',
            source: pkh,
            fee: currentFee,
            counter: (++counter).toString(),
            gas_limit: gasLimit,
            storage_limit: storageLimit,
            amount: '0',
            destination: from,
            parameters: managerTransaction
          });
        }
      }
    }
    return fop;
  }
  createOperationObject(hash: string, counter: number, manager: string, operations: any, pkh: string, pk: string, fee: number): any {
    const fop: any = {
      branch: hash,
      contents: []
    };
    if (manager === null) {
      // Reveal
      fop.contents.push({
        kind: 'reveal',
        source: pkh,
        fee: '0',
        counter: (++counter).toString(),
        gas_limit: '200',
        storage_limit: '0',
        public_key: pk
      });
    }
    for (let i = 0; i < operations.length; i++) {
      const currentFee = i === operations.length - 1 ? this.microTez.times(fee).toString() : '0';
      const gas_limit = operations[i].gas_limit.toString();
      const storage_limit = operations[i].storage_limit.toString();
      delete operations[i].gasRecommendation;
      delete operations[i].storageRecommendation;
      fop.contents.push({ ...operations[i], counter: (++counter).toString(), source: pkh, gas_limit, storage_limit, fee: currentFee });
    }
    return fop;
  }
  /*
    Returns an observable for the delegation of baking rights.
  */
  delegate(from: string, to: string, fee: number = 0, keys: KeyPair): Observable<any> {
    return this.getHeader()
      .pipe(
        flatMap((header: any) => {
          return this.getRpc(`chains/main/blocks/head/context/contracts/${keys.pkh}/counter`).pipe(
            flatMap((actions: any) => {
              return this.getRpc(`chains/main/blocks/head/context/contracts/${keys.pkh}/manager_key`).pipe(
                flatMap((manager: any) => {
                  if (fee > this.feeHardCap) {
                    throw new Error('TooHighFee');
                  }
                  let counter: number = Number(actions);
                  let delegationOp: any;
                  if (from.startsWith('tz')) {
                    delegationOp = {
                      kind: 'delegation',
                      source: from,
                      fee: this.microTez.times(fee).toString(),
                      counter: (++counter).toString(),
                      gas_limit: '200',
                      storage_limit: '0'
                    };
                    if (to !== '') {
                      delegationOp.delegate = to;
                    }
                  } else if (from.startsWith('KT')) {
                    delegationOp = {
                      kind: 'transaction',
                      source: keys.pkh,
                      fee: this.microTez.times(fee).toString(),
                      counter: (++counter).toString(),
                      gas_limit: '4380',
                      storage_limit: '0',
                      amount: '0',
                      destination: from,
                      parameters: to !== '' ? this.getContractDelegation(to) : this.getContractUnDelegation()
                    };
                  }
                  const fop: any = {
                    branch: header.hash,
                    contents: [delegationOp]
                  };
                  if (manager === null) {
                    fop.contents[1] = fop.contents[0];
                    fop.contents[0] = {
                      kind: 'reveal',
                      source: keys.pkh,
                      fee: '0',
                      counter: counter.toString(),
                      gas_limit: '200',
                      storage_limit: '0',
                      public_key: keys.pk
                    };
                    fop.contents[1].counter = (Number(fop.contents[1].counter) + 1).toString();
                  }
                  return this.operation(fop, header, keys);
                })
              );
            })
          );
        })
      )
      .pipe(catchError((err) => this.errHandler(err)));
  }
  /*
  Help function for operations
  */
  private operation(fop: any, header: any, keys: KeyPair): Observable<any> {
    return this.postRpc('chains/main/blocks/head/helpers/forge/operations', fop).pipe(
      flatMap((opbytes: any) => {
        return this.localForge(fop).pipe(
          flatMap((localOpbytes: string) => {
            if (opbytes !== localOpbytes) {
              throw new Error('ValidationError');
            }
            if (!keys.sk) {
              fop.signature = 'edsigtXomBKi5CTRf5cjATJWSyaRvhfYNHqSUGrn4SdbYRcGwQrUGjzEfQDTuqHhuA8b2d8NarZjz8TRf65WkpQmo423BtomS8Q';
              return this.postRpc('chains/main/blocks/head/helpers/scripts/run_operation', { operation: fop, chain_id: header.chain_id }).pipe(
                flatMap((applied: any) => {
                  this.checkApplied([applied]);
                  return of({
                    success: true,
                    payload: {
                      unsignedOperation: opbytes
                    }
                  });
                })
              );
            } else {
              fop.protocol = header.protocol;
              const signed = this.sign('03' + opbytes, keys.sk);
              const sopbytes = signed.sbytes;
              fop.signature = signed.edsig;
              return this._preapplyAndInject(fop, sopbytes);
            }
          })
        );
      })
    );
  }
  private _preapplyAndInject(fop, sopbytes) {
    return this.postRpc('chains/main/blocks/head/helpers/preapply/operations', [fop]).pipe(
      flatMap((applied: any) => {
        console.log('preapply result', applied);
        this.checkApplied(applied);
        let newKT1s = [];
        try {
          for (let i = 0; i < applied[0].contents.length; i++) {
            if (applied[0].contents[i].kind === 'origination') {
              newKT1s = newKT1s.concat(applied[0].contents[i].metadata.operation_result.originated_contracts);
            }
          }
        } catch (e) {}
        return this.postRpc('injection/operation', JSON.stringify(sopbytes))
          .pipe(timeout(30000))
          .pipe(
            flatMap((final: any) => {
              return this.opCheck(final, newKT1s);
            })
          );
      })
    );
  }
  checkApplied(applied: any) {
    let failed = false;
    for (let i = 0; i < applied[0].contents.length; i++) {
      if (applied[0].contents[i].metadata.operation_result.status !== 'applied') {
        failed = true;
        if (applied[0].contents[i].metadata.operation_result.errors) {
          console.log('Error in operation_result');
          throw applied[0].contents[i].metadata.operation_result.errors[applied[0].contents[i].metadata.operation_result.errors.length - 1];
        } else if (applied[0].contents[i].metadata.internal_operation_results) {
          for (const ior of applied[0].contents[i].metadata.internal_operation_results) {
            if (ior?.result?.status === 'failed') {
              console.log('Error in internal_operation_results', ior);
              throw ior.result.errors[ior.result.errors.length - 1];
            }
          }
        }
      }
    }
    if (failed) {
      console.error(applied);
      throw new Error('Uncaught error in applied');
    }
  }
  errHandler(error: any): Observable<any> {
    console.log(error);
    if (error.error && typeof error.error === 'string') {
      // parsing errors
      error = error.error;
      const lines = error.split('\n').map((line: string) => {
        return line.trim();
      });
      if (lines?.length) {
        for (const i in lines) {
          if (lines[i].startsWith('At /') && !lines[i].startsWith('At /kind')) {
            const n = Number(i) + 1;
            if (lines[n]) {
              error = `${lines[i]} ${lines[n]}`;
            }
          }
        }
      }
    }
    if (error?.error[0]) {
      error = error.error[0];
    }
    if (error.message) {
      error = this.errorHandlingPipe.transform(error.message);
    } else if (error.id) {
      if (error.with) {
        error = this.errorHandlingPipe.transform(error.id, error.with, error?.location);
      } else if (error.id === 'failure' && error.msg) {
        error = this.errorHandlingPipe.transform(error.msg);
      } else {
        error = this.errorHandlingPipe.transform(error.id);
      }
    } else if (error.statusText) {
      error = error.statusText;
    } else if (typeof error === 'string') {
      error = this.errorHandlingPipe.transform(error);
    } else {
      console.warn('Error not categorized', error);
      error = 'Unrecogized error';
    }
    return of({
      success: false,
      payload: {
        msg: error
      }
    });
  }
  // Local forge with Taquito
  localForge(operation: any): Observable<string> {
    return fromPromise(localForger.forge(operation)).pipe(
      flatMap((localForgedBytes: string) => {
        return of(localForgedBytes);
      })
    );
  }
  getHeader(): Observable<any> {
    return this.getRpc(`chains/main/blocks/head~3/header`);
  }
  getBalance(pkh: string): Observable<any> {
    return this.getRpc(`chains/main/blocks/head/context/contracts/${pkh}/balance`)
      .pipe(
        flatMap((balance: any) => {
          return of({
            success: true,
            payload: {
              balance: balance
            }
          });
        })
      )
      .pipe(catchError((err) => this.errHandler(err)));
  }
  pk2pkh(pk: string): string {
    if (pk.length === 54 && pk.startsWith('edpk')) {
      const pkDecoded = this.b58cdecode(pk, this.prefix.edpk);
      return this.b58cencode(blake2b(pkDecoded, null, 20), this.prefix.tz1);
    } else if (pk.length === 55 && pk.startsWith('sppk')) {
      const pkDecoded = this.b58cdecode(pk, this.prefix.edpk);
      return this.b58cencode(blake2b(pkDecoded, null, 20), this.prefix.tz2);
    }
    throw new Error('Invalid public key');
  }
  spPrivKeyToKeyPair(secretKey: string) {
    let sk;
    if (secretKey.match(/^[0-9a-f]{64}$/g)) {
      sk = this.b58cencode(this.hex2buf(secretKey), this.prefix.spsk);
    } else if (secretKey.match(/^spsk[1-9a-km-zA-HJ-NP-Z]{50}$/g)) {
      sk = secretKey;
    } else {
      throw new Error('Invalid private key');
    }
    const keyPair = new elliptic.ec('secp256k1').keyFromPrivate(new Uint8Array(this.b58cdecode(sk, this.prefix.spsk)));
    const yArray = keyPair.getPublic().getY().toArray();
    const prefixVal = yArray[yArray.length - 1] % 2 ? 3 : 2; // Y odd / even
    const pad = new Array(32).fill(0); // Zero-padding
    const publicKey = new Uint8Array([prefixVal].concat(pad.concat(keyPair.getPublic().getX().toArray()).slice(-32)));
    const pk = this.b58cencode(publicKey, this.prefix.sppk);
    const pkh = this.pk2pkh(pk);
    return { sk, pk, pkh };
  }
  spPointsToPkh(pubX: string, pubY: string): string {
    const key = new elliptic.ec('secp256k1').keyFromPublic({
      x: pubX,
      y: pubY
    });
    const yArray = key.getPublic().getY().toArray();
    const prefixVal = yArray[yArray.length - 1] % 2 ? 3 : 2;
    const pad = new Array(32).fill(0);
    let publicKey = new Uint8Array([prefixVal].concat(pad.concat(key.getPublic().getX().toArray()).slice(-32)));
    let pk = this.b58cencode(publicKey, this.prefix.sppk);
    const pkh = this.pk2pkh(pk);
    return pkh;
  }
  hex2buf(hex) {
    return new Uint8Array(
      hex.match(/[\da-f]{2}/gi).map(function (h) {
        return parseInt(h, 16);
      })
    );
  }
  buf2hex(buffer) {
    const byteArray = new Uint8Array(buffer),
      hexParts = [];
    for (let i = 0; i < byteArray.length; i++) {
      const hex = byteArray[i].toString(16);
      const paddedHex = ('00' + hex).slice(-2);
      hexParts.push(paddedHex);
    }
    return hexParts.join('');
  }
  b58cencode(payload: any, prefixx?: Uint8Array) {
    const n = new Uint8Array(prefixx.length + payload.length);
    n.set(prefixx);
    n.set(payload, prefixx.length);
    return Bs58check.encode(Buffer.from(this.buf2hex(n), 'hex'));
  }
  b58cdecode(enc, prefixx) {
    let n = Bs58check.decode(enc);
    n = n.slice(prefixx.length);
    return n;
  }
  sign(bytes: string, sk: string): any {
    if (!['03', '05', '80'].includes(bytes.slice(0, 2))) {
      throw new Error('Invalid prefix');
    }
    if (sk.startsWith('spsk')) {
      const hash = blake2b(this.hex2buf(bytes), null, 32);
      bytes = bytes.slice(2);
      const key = new elliptic.ec('secp256k1').keyFromPrivate(new Uint8Array(this.b58cdecode(sk, this.prefix.spsk)));
      let sig = key.sign(hash, { canonical: true });
      const pad = new Array(32).fill(0);
      const r = pad.concat(sig.r.toArray()).slice(-32);
      const s = pad.concat(sig.s.toArray()).slice(-32);
      sig = new Uint8Array(r.concat(s));
      const spsig = this.b58cencode(sig, this.prefix.spsig);
      const sbytes = bytes + this.buf2hex(sig);
      return {
        bytes: bytes,
        sig: sig,
        edsig: spsig,
        sbytes: sbytes
      };
    } else {
      const hash = blake2b(this.hex2buf(bytes), null, 32);
      bytes = bytes.slice(2);
      const sig = naclSign.detached(hash, this.b58cdecode(sk, this.prefix.edsk));
      const edsig = this.b58cencode(sig, this.prefix.edsig);
      const sbytes = bytes + this.buf2hex(sig);
      return {
        bytes: bytes,
        sig: sig,
        edsig: edsig,
        sbytes: sbytes
      };
    }
  }
  verify(bytes: string, sig: string, pk: string): boolean {

    const hash = blake2b(this.hex2buf(bytes), null, 32);
    const signature = this.b58cdecode(sig, this.prefix.edsig);
    const publicKey = this.b58cdecode(pk, this.prefix.edpk);
    return naclSign.detached.verify(signature, hash, publicKey);
  }
  sig2prefixedSig(sig: string, isEdsig = true): any {
    return this.b58cencode(this.hex2buf(sig), isEdsig ? this.prefix.edsig : this.prefix.spsig);
  }
  getContractDelegation(pkh: string) {
    return {
      entrypoint: 'do',
      value: [
        { prim: 'DROP' },
        {
          prim: 'NIL',
          args: [{ prim: 'operation' }]
        },
        {
          prim: 'PUSH',
          args: [
            { prim: 'key_hash' },
            {
              string: pkh
            }
          ]
        },
        { prim: 'SOME' },
        { prim: 'SET_DELEGATE' },
        { prim: 'CONS' }
      ]
    };
  }
  getContractUnDelegation() {
    return {
      entrypoint: 'do',
      value: [
        { prim: 'DROP' },
        {
          prim: 'NIL',
          args: [{ prim: 'operation' }]
        },
        {
          prim: 'NONE',
          args: [{ prim: 'key_hash' }]
        },
        { prim: 'SET_DELEGATE' },
        { prim: 'CONS' }
      ]
    };
  }
  getContractPkhTransaction(to: string, amount: string) {
    return {
      entrypoint: 'do',
      value: [
        { prim: 'DROP' },
        { prim: 'NIL', args: [{ prim: 'operation' }] },
        {
          prim: 'PUSH',
          args: [
            { prim: 'key_hash' },
            {
              string: to
            }
          ]
        },
        { prim: 'IMPLICIT_ACCOUNT' },
        {
          prim: 'PUSH',
          args: [{ prim: 'mutez' }, { int: amount }]
        },
        { prim: 'UNIT' },
        { prim: 'TRANSFER_TOKENS' },
        { prim: 'CONS' }
      ]
    };
  }
  getContractKtTransaction(to: string, amount: string) {
    return {
      entrypoint: 'do',
      value: [
        { prim: 'DROP' },
        { prim: 'NIL', args: [{ prim: 'operation' }] },
        {
          prim: 'PUSH',
          args: [{ prim: 'address' }, { string: to }]
        },
        { prim: 'CONTRACT', args: [{ prim: 'unit' }] },
        [
          {
            prim: 'IF_NONE',
            args: [[[{ prim: 'UNIT' }, { prim: 'FAILWITH' }]], []]
          }
        ],
        {
          prim: 'PUSH',
          args: [{ prim: 'mutez' }, { int: amount }]
        },
        { prim: 'UNIT' },
        { prim: 'TRANSFER_TOKENS' },
        { prim: 'CONS' }
      ]
    };
  }
  postRpc(path: string, payload: any): Observable<any> {
    return this.http.post(`${this.nodeURL}/${path}`, payload, httpOptions).pipe(this.retryPipeline(path));
  }
  getRpc(path: string): Observable<any> {
    return this.http.get(`${this.nodeURL}/${path}`).pipe(this.retryPipeline(path));
  }
  private retryPipeline(path: string, retries: number = 3): MonoTypeOperatorFunction<unknown> {
    const retryWithWarning = (i, e) => {
      if (i < retries) {
        console.warn(`Retry ${i + 1}: ${path}`, e);
      }
      return of(e).pipe(delayOperator(250));
    };
    return retryWhen((errors) =>
      errors.pipe(concatMap((e, i) => iif(() => i >= retries || e?.name !== 'HttpErrorResponse', throwError(e), retryWithWarning(i, e))))
    );
  }
}
