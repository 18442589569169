import { Component, Input } from '@angular/core';
import { UserBalanceData } from '../../../../../../src/app/app.datatypes';

@Component({
  selector: 'app-balance-details',
  templateUrl: './balance-details.component.html',
  styleUrls: ['./balance-details.component.scss'],
})
export class BalanceDetailsComponent {
  @Input() data: UserBalanceData;
}
