import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filmPrice',
})
export class FilmPricePipe implements PipeTransform {
  transform(value: number): string {
    const truncateUntilOneDecimals = /^-?\d+(?:\.\d?)?/;
    if (value === null || value === undefined || value.toString()==='Infinity') {
      return '0';
    } else if (value > 1000000000) {
      return `${(value / 1000000000).toString().match(truncateUntilOneDecimals)[0]}B`;
    } else if (value > 1000000) {
      return `${(value / 1000000).toString().match(truncateUntilOneDecimals)[0]}M`;
    } else if (value > 1000) {
      return `${(value / 1000).toString().match(truncateUntilOneDecimals)[0]}K`;
    } else {
      const matched = value.toString().match(truncateUntilOneDecimals);
      return matched?.length ? matched[0] : '0';
    }
  }
}
