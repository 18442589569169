import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { WindowScrollService } from '../../services';
import { filter, map, pairwise, tap } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

enum Direction {
  Up = 'Up',
  Down = 'Down',
}

enum Position {
  Hidden = -80,
  Initial = 30,
  Fixed = 0,
}

@Component({
  selector: 'app-scroll-inherited',
  template: '',
})
export class ScrollInheritedComponent implements OnInit {
  public direction: any;
  scrollOnNotTop = false;
  scrollTopPosition = Position.Initial;

  destroyRef = inject(DestroyRef);

  constructor(public windowScrollService: WindowScrollService) { }

  windowScrollFilter = (position): boolean => {
    throwError('WindowScrollFilter is not implemented');
    return true;
  };
  setWindowScrollFilter(func: (position) => any) {
    this.windowScrollFilter = func;
  }

  appendItems = (scroll): void => {
    throwError('AppendItems is not implemented');
  };
  setAppendItems(func: (scroll) => void) {
    this.appendItems = func;
  }

  setItemsUpdateOnScroll(): Observable<any> {
    return this.windowScrollService.getScrollPositionEnd().pipe(
      filter(this.windowScrollFilter),
      tap(position => {
        this.appendItems(position);
      })
    );
  }

  setScrollSubscription(): Observable<any> {
    return this.windowScrollService.getScrollPosition().pipe(
      tap(pos => (this.scrollOnNotTop = pos !== 0)),
      pairwise(),
      map(([y1, y2]) => (y2 < y1 ? [Direction.Up, y2] : [Direction.Down, y2])),
      tap(([direction, scrollTop]) => {
        if (direction === Direction.Down && scrollTop > Position.Initial) {
          this.scrollTopPosition = Position.Hidden;
        } else {
          this.scrollTopPosition = Position.Initial;

          // Experimental
          this.windowScrollService.resetMaxPosition();
        }
      })
    );
  }

  ngOnInit(): void {
    this.setScrollSubscription().pipe(takeUntilDestroyed(this.destroyRef)).subscribe();
    this.setItemsUpdateOnScroll().pipe(takeUntilDestroyed(this.destroyRef)).subscribe();
  }
}
