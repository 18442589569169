import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomVideoPlayerComponent } from './custom-video-player.component';
import { IconModule } from '../../modules/components/icon/icon.module';
import { DropdownMenuModule } from '../../modules/components/dropdown-menu/dropdown-menu.module';



@NgModule({
  declarations: [CustomVideoPlayerComponent],
  exports: [CustomVideoPlayerComponent],
  imports: [CommonModule, IconModule, DropdownMenuModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CustomVideoPlayerModule {}
