import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DcpSelectModule } from '../dcp-select/dcp-select.module';
import { CheckoutComponent } from './checkout.component';
import { SharedModule } from '../../../shared.module';
import { BountyInfoComponent } from './bounty-info/bounty-info.component';
import { OnlyNumberDirective } from '../../directives/only-number/only-number.directive';
import { AutoFocusModule } from '../../directives/auto-focus/auto-focus.module';
import { AvailableDailyLimitService } from "../../../services/available-daily-limit.service";



@NgModule({
  declarations: [CheckoutComponent, BountyInfoComponent],
  imports: [CommonModule, SharedModule, DcpSelectModule, OnlyNumberDirective, AutoFocusModule],
  exports: [CheckoutComponent, BountyInfoComponent],
  providers: [AvailableDailyLimitService],
})
export class DcpCheckoutModule {}
