import { Component, DestroyRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import {
  DcpPlusType,
  Distribution,
  ExternalCheckoutAction,
  ExternalCheckoutRequest,
  ProcessStatus,
  PurchaseRequest,
  VideoDistributionPurchase,
} from '../../../../app.datatypes';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  AuthenticatedUser,
  AuthenticatedUserQuery,
  ErrorsHandlerService,
  PaymentStateService,
  TezosWalletService,
  ToasterService,
} from '../../../../shared';
import { DistributionService } from '../../../../shared/services/distribution.service';
import { Router } from '@angular/router';
import { from, switchMap } from 'rxjs';

export enum PurchaseType {
  NONE = 'none',
  RENT = 'rent',
  BUY = 'buy',
}

@Component({
  selector: 'app-distribution-purchase-block',
  templateUrl: './distribution-purchase-block.component.html',
})
export class DistributionPurchaseBlockComponent implements OnChanges, OnInit {
  @Input() distribution: Distribution;
  @Output() purchaseAction: EventEmitter<boolean> = new EventEmitter();
  isDistributionPurchase: boolean;
  types = PurchaseType;
  @Input() purchaseType: PurchaseType;
  @Input() user: AuthenticatedUser;
  @Input() affiliateUser: string;
  submissionFee = 0;
  isExternalCheckout: boolean;
  isCheckoutFormValid = true;
  processStatus = ProcessStatus;
  isActivePurchase: boolean;
  isOwner: boolean;
  isPendingPayment: boolean;
  isSubmitting: boolean;
  isUsdc = false;
  constructor(
    private distributionService: DistributionService,
    private destroyRef: DestroyRef,
    private authenticatedUserQuery: AuthenticatedUserQuery,
    protected paymentService: PaymentStateService,
    private tezosWalletService: TezosWalletService,
    private toastService: ToasterService,
    private errorService: ErrorsHandlerService,
    private router: Router
  ) {}

  ngOnInit(): void {
    if (this.purchaseType) {
      this.buyRentVideo(this.purchaseType);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.distribution?.currentValue?.purchase?.status !== changes.distribution?.previousValue?.purchase?.status ||
      changes.distribution?.currentValue?._id !== changes.distribution?.previousValue?.purchase?._id ||
      changes.distribution?.currentValue._id !== changes.distribution?.previousValue._id
    ) {
      this.isOwner = this.user?._id === this.distribution.user_id || this.distribution.access_type === DcpPlusType.FREE;
      if (!this.distribution.purchase?.is_external_checkout) {
        this.isDistributionPurchase = false;
      }
      this.isSubmitting =
        this.distribution.purchase?.status === ProcessStatus.WAITING_FOR_SUBMISSION ||
        (this.distribution.purchase?.status === ProcessStatus.WAITING_FOR_PAYMENT &&
          this.distribution.purchase.external_checkout_payment === null);
      this.isActivePurchase =
        this.distribution.purchase?.status === ProcessStatus.CONFIRMED ||
        this.distribution.purchase?.status === ProcessStatus.WAITING_FOR_CONFIRMATION;
      this.isPendingPayment =
        !!this.distribution.purchase?.pending_payment_id && !!this.distribution.purchase.external_checkout_payment;
    }
  }

  buyRentVideo(type: PurchaseType): void {
    if (!this.user) {
      this.authenticatedUserQuery.unAuthAction.next({ slug: 'Create Distribution' });
    } else if (!this.authenticatedUserQuery.isRegistrationComplete) {
      this.authenticatedUserQuery.unAuthAction.next({ slug: 'Create Distribution', action: 'verify' });
    } else {
      this.isDistributionPurchase = true;
      this.purchaseType = type;
      this.purchaseAction.emit(true);
      if (!type) {
        this.router.navigate(['/dcp-plus', this.distribution?._id, 'play']);
      } else {
        this.submissionFee = this.paymentService.calculateDollarToFilmPricing(
          type === PurchaseType.RENT ? this.distribution.rental_price : this.distribution.purchase_price
        );
      }
    }
  }

  purchaseDistribution(): void {
    this.distributionService
      .rentBuyDistribution(
        this.distribution._id,
        this.isUsdc,
        this.purchaseType,
        this.isExternalCheckout,
        this.affiliateUser
      )
      .pipe(
        switchMap((purchase: PurchaseRequest) => {
          const submitReq = purchase;
          submitReq.nonce = TezosWalletService.randomNonce;
          return from(
            !this.isUsdc
              ? this.tezosWalletService.purchaseVideoDistribution(
                  this.distribution.blockchain_id,
                  purchase._id,
                  purchase.amount_film,
                  purchase.amount_usd,
                  purchase.purchase_fee,
                  {
                    transactionId: purchase.submission_transaction,
                  },
                  submitReq.nonce
                )
              : this.tezosWalletService.purchaseVideoDistributionUSDC(
                  this.distribution.blockchain_id,
                  purchase._id,
                  purchase.amount_usdc,
                  purchase.amount_usd,
                  {
                    transactionId: purchase.submission_transaction,
                  },
                  submitReq.nonce
                )
          ).pipe(
            switchMap((signature) => {
              submitReq.signature = signature.sig;
              submitReq.pk = this.tezosWalletService.connectedWalletPublicKey;
              return this.distributionService.submitPurchase(submitReq);
            })
          );
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe({
        next: (purchase: VideoDistributionPurchase) => {
          if (purchase && purchase.status === ProcessStatus.WAITING_FOR_PAYMENT) {
            const externalCheckoutRequest = new ExternalCheckoutRequest();
            externalCheckoutRequest.action = ExternalCheckoutAction.VIDEO_DISTRIBUTION_PURCHASE_SUBMIT;
            externalCheckoutRequest.reference_id = purchase._id;
            externalCheckoutRequest.payment_id = purchase.pending_payment_id;
            this.paymentService.externalCheckout$.emit(externalCheckoutRequest);
          } else {
            this.purchaseAction.emit(false);
            this.toastService.openToastr(
              'Thank you for your purchase. Your video will be available to watch once your transaction has confirmed. ',
              'Purchase Submitted',
              'info',
              5000
            );
          }
        },
        error: (error) => this.submitErrorHandler(error),
      });
  }

  submitErrorHandler(error: Error): void {
    this.toastService.openToastr('There was a problem purchasing your project', 'Submission Error', 'error', 3000);
    this.errorService.handleSubmitError(error);
  }

  changeCheckoutType(type: boolean): void {
    this.isExternalCheckout = type;
    this.isUsdc = this.isExternalCheckout && this.distribution.is_usdc_supported;
  }

  handleCheckoutFormValidity(validity: boolean): void {
    this.isCheckoutFormValid = validity;
  }

  continuePayment(): void {
    if (this.distribution.purchase.external_checkout_payment.coinbase_hosted_url) {
      window.location.href = this.distribution.purchase.external_checkout_payment.coinbase_hosted_url;
    } else if (this.distribution.purchase.external_checkout_payment.stripe_redirect_url) {
      window.location.href = this.distribution.purchase.external_checkout_payment.stripe_redirect_url;
    }
  }
}
