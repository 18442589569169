<app-toastr-template
  class="toast toast-{{ toast.type }}"
  [style.bottom.px]="toast.offsetHeight"
  [data]="toast"
  [index]="i"
  (remove)="removeToastr($event)"
>
  <div class="action" *ngIf="toast?.action">
    <p class="m-0 text-sm" *ngIf="toast?.action">{{ toast?.action }}</p>
  </div>
  <p class="text-sm" *ngIf="toast?.message">{{ toast?.message }}</p>

  <div class="flex justify-end mt-16" *ngIf="toast?.link">
    <button class="btn btn-sm btn-white" (click)="onRedirect()">
      {{ toast?.buttonLabel }}
    </button>
  </div>
</app-toastr-template>
