import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

export interface UserMenuData {
  userMenu: boolean;
}

export interface NotificationCountData {
  isRead: boolean;
  isAllRead: boolean;
}

export interface BadgeToggle {
  isToggle: boolean;
}
export interface OpenProposal {
  onOpen: boolean;
}

export interface CorrectAnswer {
  isCorrect: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  private toggleUserMenu = new Subject<UserMenuData>();
  private onPauseSliderVideo = new Subject<string>();
  private onCoinAnimation = new Subject<string>();
  private onRedirectActivity = new Subject<string>();
  private onToggleNav = new Subject<string>();
  private onToggleFilter = new Subject<string>();
  private closeToggleFilter = new Subject<string>();
  private resetFilter = new Subject<string>();
  private onUpdateNotificationCount = new Subject<NotificationCountData>();
  private onBadgeToggle = new Subject<BadgeToggle>();
  private onOpenProposal = new Subject<OpenProposal>();
  private onCorrectAnswer = new Subject<CorrectAnswer>();
  private onUpdateUser = new Subject<string>();
  private onStopLoader = new Subject<boolean>();
  private onCreateProposal = new Subject<string>();
  private onCreateDistribution = new Subject<string>();
  private onCreateCQ = new Subject<string>();
  constructor() {}

  setToggleUserMenu(value: boolean) {
    this.toggleUserMenu.next({ userMenu: value });
  }

  getToggleUserMenu(): Observable<UserMenuData> {
    return this.toggleUserMenu.asObservable();
  }

  setOnSliderPauseVideo() {
    this.onPauseSliderVideo.next('');
  }

  getOnSliderPauseVideo(): Observable<string> {
    return this.onPauseSliderVideo.asObservable();
  }

  setCoinAnimation() {
    this.onCoinAnimation.next('');
  }

  getCoinAnimation(): Observable<string> {
    return this.onCoinAnimation.asObservable();
  }

  setOnRedirectActivity() {
    this.onRedirectActivity.next('');
  }

  getOnRedirectActivity(): Observable<string> {
    return this.onRedirectActivity.asObservable();
  }

  setOnToggleNav() {
    this.onToggleNav.next('');
  }

  getOnToggleNav(): Observable<string> {
    return this.onToggleNav.asObservable();
  }

  setOnToggleFilter() {
    this.onToggleFilter.next('');
  }

  getOnToggleFilter(): Observable<string> {
    return this.onToggleFilter.asObservable();
  }

  setCloseToggleFilter() {
    this.closeToggleFilter.next('');
  }

  getCloseToggleFilter(): Observable<string> {
    return this.closeToggleFilter.asObservable();
  }

  setResetFilter() {
    this.resetFilter.next('');
  }

  getResetFilter(): Observable<string> {
    return this.resetFilter.asObservable();
  }

  setUpdateNotificationCount(isMessageRead: boolean, isAllMessageRead: boolean) {
    this.onUpdateNotificationCount.next({ isRead: isMessageRead, isAllRead: isAllMessageRead });
  }

  getUpdateNotificationCount(): Observable<NotificationCountData> {
    return this.onUpdateNotificationCount.asObservable();
  }

  setBadgeToggle(toggle: boolean) {
    this.onBadgeToggle.next({ isToggle: toggle });
  }

  getBadgeToggle(): Observable<BadgeToggle> {
    return this.onBadgeToggle.asObservable();
  }

  setOpenProposal(open: boolean) {
    this.onOpenProposal.next({ onOpen: open });
  }

  getOpenProposal(): Observable<OpenProposal> {
    return this.onOpenProposal.asObservable();
  }

  setCorrectAnswer(correct: boolean) {
    this.onCorrectAnswer.next({ isCorrect: correct });
  }

  getCorrectAnswer(): Observable<CorrectAnswer> {
    return this.onCorrectAnswer.asObservable();
  }

  setUpdateUser() {
    this.onUpdateUser.next('');
  }

  getUpdateUser(): Observable<string> {
    return this.onUpdateUser.asObservable();
  }

  setStopLoader(loaded: boolean) {
    this.onStopLoader.next(loaded);
  }

  getStopLoader(): Observable<boolean> {
    return this.onStopLoader.asObservable();
  }

  setCreateProposal() {
    this.onCreateProposal.next('');
  }

  getCreateProposal(): Observable<string> {
    return this.onCreateProposal.asObservable();
  }

  setCreateDistribution() {
    this.onCreateDistribution.next('');
  }

  getCreateDistribution(): Observable<string> {
    return this.onCreateDistribution.asObservable();
  }

  setCreateCQ() {
    this.onCreateCQ.next('');
  }

  getCreateCQ(): Observable<string> {
    return this.onCreateCQ.asObservable();
  }
}
