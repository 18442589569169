import { Injectable } from '@angular/core';
import { ActiveState, EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Distribution } from '../../../app.datatypes';

export interface DistributionState extends EntityState<Distribution>, ActiveState {
  skip: number;
  apiEndReached: boolean;
  moderationDistributionApiEndReached: boolean;
  moderationSkip: number;
  userDistributionSkip: number;
  purchasedDistributionSkip: number;
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'distribution', idKey: '_id', resettable: true })
export class DistributionStore extends EntityStore<DistributionState> {
  constructor() {
    super({
      skip: 0,
      apiEndReached: false,
      moderationDistributionApiEndReached: false,
      moderationSkip: 0,
      userDistributionSkip: 0,
      purchasedDistributionSkip: 0,
      // prevent loader showing on state initialization
      loading: false,
    });
  }
}
