import { ChangeDetectorRef, Component, DestroyRef, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, UntypedFormGroup, Validators } from '@angular/forms';
import { Country, Ethnicity, Language } from '../../../app.datatypes';
import { UserService } from '../../../shared/services';
import { environment } from '../../../../environments';
import { AuthenticatedUserService } from '../../../shared/state';
import { forkJoin } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-personal-detail-update-dialog',
  templateUrl: './personal-detail-update-dialog.component.html',
})
export class PersonalDetailUpdateDialogComponent implements OnInit {
  countryListDD = [];
  userPublicData = [];
  ethnicityListDD = [];
  languageList = [];
  genderList = [
    { slug: 'M', name: 'Male' },
    { slug: 'F', name: 'Female' },
    { slug: 'O', name: 'Other' },
    { slug: 'N/A', name: 'Choose not to answer' },
  ];
  personalDetailsForm: UntypedFormGroup = new FormGroup({
    ethnicity: new FormControl(null),
    sex: new FormControl(null),
    age_group: new FormControl(null),
    country: new FormControl(null),
    languages: new FormControl(null),
  });

  ageGroupList = [];
  personalDetailsPending = environment.personalDetailsPending;
  @Output() closePanel: EventEmitter<void> = new EventEmitter();
  constructor(
    private userService: UserService,
    private authenticatedUserService: AuthenticatedUserService,
    private cdr: ChangeDetectorRef,
    private destroyRef: DestroyRef
  ) {}

  ngOnInit(): void {
    for (const key in this.personalDetailsPending) {
      const element = this.personalDetailsPending[key];
      if (element) {
        this.personalDetailsForm.controls[key].setValidators(Validators.required);
        this.personalDetailsForm.controls[key].updateValueAndValidity();
      }
    }

    forkJoin([
      this.userService.getEthnicity(),
      this.userService.getAgeGroups(),
      this.userService.getCountries(),
      this.userService.getLanguages(),
    ])
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((res: [Ethnicity[], [], Country[], Language[]]) => {
        for (const item of res[0]) {
          this.ethnicityListDD.push({
            name: item.name,
            slug: item.name,
            visible: true,
          });
        }
        this.ageGroupList = res[1];
        this.countryListDD = [];
        for (const country of res[2]) {
          this.countryListDD.push({
            slug: country.iso_2,
            name: country.name,
            visible: true,
          });
        }
        res[3].forEach((value) => this.languageList.push({ ...value, visible: true }));
        const topOfTheList = ['es', 'fr'];
        const topOfTheCountryList = ['US', 'GB', 'CA'];
        this.countryListDD.forEach((elem, index) => {
          if (topOfTheCountryList.includes(elem.slug)) {
            this.countryListDD.unshift(this.countryListDD.splice(index, 1)[0]);
          }
        });

        this.languageList.forEach((elem, index) => {
          if (topOfTheList.includes(elem.iso)) {
            this.languageList.unshift(this.languageList.splice(index, 1)[0]);
          }
        });

        this.languageList.unshift(
          this.languageList.splice(
            this.languageList.findIndex((item) => item.iso === 'en'),
            1
          )[0]
        );
      });
  }

  selectLanguage(evt) {
    const result = [];
    for (const item of evt) {
      result.push(item.iso);
    }
    this.personalDetailsForm.controls['languages'].setValue(result);
  }

  public selectAgeGroup(evt) {
    this.personalDetailsForm.controls['age_group'].setValue(evt?.slug);
  }

  public selectEthnicity(evt) {
    const result = [];
    if (evt.length !== 0) {
      for (const item of evt) {
        result.push(item.name);
      }
    }
    this.personalDetailsForm.controls['ethnicity'].setValue(result);
  }

  public selectCountry(evt) {
    this.personalDetailsForm.controls['country'].setValue(evt?.slug);
  }

  submit() {
    const data = this.personalDetailsForm.value;
    data.name = this.authenticatedUserService.getUser().name;
    this.authenticatedUserService.updateMissingProfile(this.personalDetailsForm.value).subscribe(() => {
      environment.personalDetailsPending = null;
      this.closePanel.emit();
    });
  }

  public selectGender(evt) {
    this.personalDetailsForm.controls['sex'].setValue(evt?.slug);
  }
}
