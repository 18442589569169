import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProposalCoverComponent } from './proposal-cover/proposal-cover.component';
import { CardViewComponent } from './card-view/card-view.component';
import { SharedModule } from '../../../shared';
import { LayoutModule } from '@angular/cdk/layout';
import { ShareButtonsModule } from '../../../shared/modules/components/share-button/share-buttons.module';
import { RoundProgressWidgetComponent } from './round-progress-widget/round-progress-widget.component';
import { CustomVideoPlayerModule } from '../../../shared/components/custom-video-player/custom-video-player.module';
import { DcpImageComponent } from '../../../shared/components/dcp-image/dcp-image.component';
@NgModule({
  declarations: [ProposalCoverComponent, CardViewComponent, RoundProgressWidgetComponent],
  imports: [CommonModule, SharedModule, LayoutModule, ShareButtonsModule, CustomVideoPlayerModule,DcpImageComponent],
  exports: [ProposalCoverComponent, CardViewComponent, RoundProgressWidgetComponent],
})
export class ProposalCommonModule {}
