import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { environment } from '../../../../../../src/environments';
import { UserEngagementData } from '../../../../../../src/app/app.datatypes';
import { slideInLeft, slideInRight } from '../../../../app.animations';

@Component({
  selector: 'app-potential-earnings',
  templateUrl: './potential-earnings.component.html',
  styleUrls: ['./potential-earnings.component.scss'],
  animations: [slideInRight, slideInLeft],
})
export class PotentialEarningsComponent implements OnInit, OnDestroy {
  updateInterval = null;
  showCq = environment.limited_cq_functionality;

  @Input() data: UserEngagementData;

  ngOnInit() {
    this.updateInterval = setInterval(() => {
      this.data.potentialEarningTime.seconds--;
      if (this.data.potentialEarningTime.seconds === -1) {
        this.data.potentialEarningTime.minutes--;
        this.data.potentialEarningTime.seconds = 59;
      }

      if (this.data.potentialEarningTime.minutes === -1) {
        this.data.potentialEarningTime.hours--;
        this.data.potentialEarningTime.minutes = 59;
      }

      if (this.data.potentialEarningTime.hours === -1) {
        this.data.potentialEarningTime.hours = 0;
        this.data.potentialEarningTime.minutes = 0;
        this.data.potentialEarningTime.seconds = 0;
      }
    }, 1000);
  }

  ngOnDestroy() {
    clearInterval(this.updateInterval);
  }
}
