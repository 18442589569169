import { Injectable } from '@angular/core';
import { ReviewState, ReviewStore } from './review.store';
import { ID, QueryEntity } from '@datorama/akita';
import { Observable } from 'rxjs';
import { Review } from '../../../app.datatypes';

@Injectable({ providedIn: 'root' })
export class ReviewQuery extends QueryEntity<ReviewState> {
  selectLoading$ = this.selectLoading();
  selectReviews$ = this.selectAll();

  selectModerationSkip$ = this.select('moderationQuerySkip');
  selectModerationQueryScroll$ = this.select('moderationQueryScroll');
  selectModerationReviewActive$ = this.select('moderationReviewActive');
  selectModerationFilters$ = this.select('moderationFilters');

  constructor(protected store: ReviewStore) {
    super(store);
  }

  /**
   * set caches
   */
  setHasCache(cache: boolean): void {
    this.store.setHasCache(cache);
  }

  /**
   * Get reviews for proposal
   *
   * id: proposal Id
   */
  selectReviewsForProposal(id: ID | string): Observable<Review[]> {
    return this.selectAll({
      filterBy: (entity) => {
        return entity.proposal_id === id;
      },
    });
  }

  /**
   * Get reviews for users
   *
   * id: proposal Id
   */
  selectReviewsForUser(id: ID | string): Observable<Review[]> {
    return this.selectAll({
      filterBy: (entity) => {
        return entity.user_id === id;
      },
    });
  }

  /** Get all to-be-moderated reviews where owner is not a current user. */
  getAllModerationReview(userId: string, isQuiz: boolean, isDistribution: boolean): Observable<Review[]> {
    return this.selectAll({
      filterBy: (entity) => {
        let returnValue = entity.user_id !== userId;
        if (isQuiz) {
          returnValue = returnValue && entity.to_be_moderated;
        } else if (isDistribution) {
          returnValue = returnValue && !!entity.video_distribution_id && entity.status === 'to-be-moderated';
        } else {
          returnValue = returnValue && entity.status === 'to-be-moderated' && !entity.video_distribution_id;
        }
        if (this.getValue().moderationFilters.unlockedOnly) {
          returnValue = returnValue && !entity.locked_by;
        }
        return returnValue;
      },
      limitTo: this.getValue().moderationQuerySkip,
    });
  }
}
