import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToastrContainerComponent } from './toastr-container/toastr-container.component';
import { ToastrComponent } from './toastr/toastr.component';
import { ToastrTemplateComponent } from './toastr-template/toastr-template.component';
import { LinkToastrComponent } from './link-toastr/link-toastr.component';
import { ContactSupportToastrComponent } from './contact-support-toastr/contact-support-toastr.component';
import { MainToastrComponent } from './main-toastr/main-toastr.component';
import { LowBalanceToastrComponent } from './low-balance-toastr/low-balance-toastr.component';
import { ReloadToastrComponent } from './reload-toastr/reload-toastr.component';
import { IconModule } from '../modules/components/icon/icon.module';

@NgModule({
  declarations: [
    ToastrContainerComponent,
    ToastrComponent,
    ToastrTemplateComponent,
    LinkToastrComponent,
    ContactSupportToastrComponent,
    MainToastrComponent,
    LowBalanceToastrComponent,
    ReloadToastrComponent,
  ],
  imports: [CommonModule, IconModule],
  exports: [
    ToastrContainerComponent,
    ToastrComponent,
    ToastrTemplateComponent,
    LinkToastrComponent,
    ContactSupportToastrComponent,
    MainToastrComponent,
    LowBalanceToastrComponent,
    ReloadToastrComponent,
  ],
})
export class ToastrModule {}
