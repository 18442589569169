import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ProcessStatus } from '../../../app.datatypes';

@Component({
  selector: 'app-status',
  templateUrl: './status.component.html',
})
export class StatusComponent implements OnInit, OnChanges {
  @Input() status: ProcessStatus;
  public showLoader = false;

  ngOnInit(): void {
    this.setLoader();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.setLoader();
  }

  setLoader() {
    this.showLoader =
      this.status === ProcessStatus.WAITING_FOR_CONFIRMATION ||
      this.status === ProcessStatus.WAITING_FOR_SUBMISSION ||
      this.status === ProcessStatus.WAITING_FOR_PAYMENT ||
      this.status === ProcessStatus.TO_BE_MODERATED;
  }
}
