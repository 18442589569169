<form [formGroup]="stepTwoForm" class="pt-1" autocomplete="off" id="stepTwo">
  <h4 class="mb-8 font-semibold">
    {{ selectedRound?.proposal_header_fields?.block_title }}
  </h4>
  <div class="form-group">
    <input
      [placeholder]="selectedRound?.proposal_header_fields?.title?.placeholder"
      id="title"
      formControlName="title"
      required
      trim="blur"
      (blur)="validateFormData()"
      (keydown.enter)="$event.preventDefault()"
    />
    <p
      class="error-msg"
      *ngIf="
        stepTwoForm.get('title').errors && stepTwoForm.get('title').errors.required && stepTwoForm.get('title').touched
      "
    >
      {{ createTitle }} Title is <strong>Required</strong>
    </p>
    <p
      class="error-msg"
      *ngIf="
        stepTwoForm.get('title').errors && stepTwoForm.get('title').errors.maxlength && stepTwoForm.get('title').touched
      "
    >
      {{ createTitle }} Title is <strong>too long</strong>. Only
      {{ stepTwoForm.get('title').errors.maxlength.requiredLength }} allowed, current length is
      {{ stepTwoForm.get('title').errors.maxlength.actualLength }}.
    </p>
  </div>

  <h4 class="mb-16 font-semibold pb-8 border-b border-gray-border">{{ createTitle }} Media</h4>
  <div
    joyrideStep="proposalcreate5"
    (next)="this.globalTour.restrictScroll.emit(false)"
    [title]="joyrideService?.isTourInProgress() ? 'Create Project' : ''"
    text="Add your {{ createTitle }} card cover image and any other supporting media you would like to include."
    stepPosition="left"
  >
    <p class="text-lg font-semibold">Cover Image</p>
    <p class="mb-16">
      Add a cover image below. This will be the first image of your project that users will see. (required)
    </p>
    <div class="md:w-2/3" [ngClass]="{ 'rounded border border-error': !uploadedCroppedCoverImage }">
      <app-cover-image-cropper
        [data]="{ url: uploadedCroppedCoverImage }"
        (uploadedCover)="coverUploaded($event)"
      ></app-cover-image-cropper>
    </div>
    <p class="error-msg mt-8" *ngIf="!uploadedCroppedCoverImage">Cover image is <strong>Required</strong></p>
    <ng-container *ngFor="let media of mediaTypes">
      <div class="add-media-sect mt-16 pt-8 border-t border-gray-border add-media-sect">
        <p class="text-lg font-semibold">Add Additional {{ media.label }}</p>
        <p class="mb-16">{{ media.description }}</p>
        <div class="grid grid-cols-1 md:grid-cols-3 sm:grid-cols-2 gap-16 mt-16">
          <ng-container *ngFor="let item of mediaList">
            <div class="relative" *ngIf="item?.type === media.type">
              <div
                *ngIf="item?.type === mediaType.VIDEO"
                class="rounded ratio-16-9 overflow-hidden border border-gray-border video"
              >
                <ng-container *ngIf="item?.thumbnailUrl">
                  <img
                    class="absolute top-1/2 left-1/2 w-full h-full object-cover ng-star-inserted transform -translate-y-1/2 -translate-x-1/2"
                    [src]="item.thumbnailUrl"
                    alt="thumbnail"
                  />
                </ng-container>
              </div>
              <div
                *ngIf="item?.type === mediaType.IMAGE"
                class="rounded ratio-16-9 overflow-hidden border border-gray-border image"
              >
                <img
                  [src]="item?.value"
                  alt="img-group"
                  class="absolute top-1/2 left-1/2 w-full h-full object-cover ng-star-inserted transform -translate-y-1/2 -translate-x-1/2"
                />
              </div>

              <div
                *ngIf="item?.type === mediaType.FILE"
                class="rounded ratio-16-9 overflow-hidden border border-gray-border file"
              >
                <img
                  [src]="item?.fileData"
                  alt="img-group"
                  class="absolute top-1/2 left-1/2 w-full h-full object-cover ng-star-inserted transform -translate-y-1/2 -translate-x-1/2"
                />
              </div>

              <div class="flex absolute top-0 right-0 pt-8 pr-8">
                <button (click)="deleteMedia(item?.index)" class="btn-icon error btn-sm mr-8 btn-inverted deleteMedia">
                  <app-icon [icon]="'trash'"></app-icon>
                </button>
                <button
                  (click)="editMedia(item)"
                  *ngIf="item?.type !== 'video'"
                  class="btn-icon btn-sm edit btn-inverted editMedia"
                >
                  <app-icon [icon]="'edit'"></app-icon>
                </button>
              </div>
              <p>{{ item?.title }}</p>
            </div>
          </ng-container>
          <div
            class="bg-body rounded ratio-16-9 overflow-hidden border border-gray-border"
            id="addMedia"
            (click)="openAdditionalMedia()"
          >
            <div class="w-full h-full absolute flex top-0 flex-col justify-center items-center">
              <app-icon
                [icon]="media.type === 'file' ? 'pdf' : media.type"
                class="box-icon w-40 h-40 opacity-25 mb-16"
              ></app-icon>
              <p class="text-blue text-center text-sm">
                Click to add {{ media.type === mediaType.FILE ? 'pdf' : media.type }}
              </p>
              <input
                type="file"
                id="addMediaInput"
                [accept]="media.media"
                (change)="openModal($event)"
                class="opacity-0 absolute w-full h-full top-0 left-0 cursor-pointer"
              />
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
  <div
    joyrideStep="proposalcreate6"
    [title]="joyrideService?.isTourInProgress() ? 'Create Project' : ''"
    text="Enter your Log Line and Project Description in the given fields. Then set all Genre, Target Audience, Chat and Public Read options here."
    (next)="globalTour.tourAction.emit('next2')"
    stepPosition="left"
  >
    <h4 class="mt-24 mb-16 font-semibold pb-8 border-b border-gray-border">
      {{ selectedRound?.proposal_description_fields?.block_title }}
    </h4>
    <p class="mb-16">
      {{ selectedRound?.proposal_description_fields?.block_subtitle }}
    </p>
    <div class="form-group">
      <label class="flex"
        >{{ selectedRound?.proposal_description_fields?.summary.title }}
        <app-icon
          [icon]="'info'"
          class="ml-4 w-12 h-12 opacity-50 hover:opacity-100 inline-block"
          [appTooltip]="selectedRound?.proposal_description_fields?.summary.tooltip"
          placement="top"
        ></app-icon>
      </label>
      <textarea
        row="5"
        [placeholder]="selectedRound?.proposal_description_fields?.summary.placeholder"
        id="content"
        formControlName="content"
        #content
        required
        trim="blur"
        (blur)="validateFormData()"
      ></textarea>
      <p
        class="error-msg"
        *ngIf="
          stepTwoForm.get('content').errors &&
          stepTwoForm.get('content').errors.required &&
          stepTwoForm.get('content').touched
        "
      >
        Field is <strong>Required</strong>
      </p>
      <p
        class="error-msg"
        *ngIf="
          stepTwoForm.get('content').errors &&
          stepTwoForm.get('content').errors.maxlength &&
          stepTwoForm.get('content').touched
        "
      >
        Maximum character allowed are {{ stepTwoForm.get('content').errors.maxlength.requiredLength }}, currently log
        line have {{ stepTwoForm.get('content').errors.maxlength.actualLength }} characters
      </p>
      <p class="text-count">{{ content.value.length }} / 500</p>
    </div>

    <div class="form-group">
      <label class="flex"
        >{{ selectedRound?.proposal_description_fields?.description.title }}
        <app-icon
          [icon]="'info'"
          class="ml-4 w-12 h-12 opacity-50 hover:opacity-100 inline-block"
          [appTooltip]="selectedRound?.proposal_description_fields?.description.tooltip"
          placement="top"
        ></app-icon>
      </label>
      <ckeditor
        #summary
        class="form-control"
        required
        [config]="ckConfig"
        [editor]="Editor"
        formControlName="summary"
        type="inline"
        (blur)="validateFormData()"
      ></ckeditor>
      <p
        class="error-msg"
        *ngIf="
          stepTwoForm.get('summary').touched &&
          stepTwoForm.get('summary').errors &&
          stepTwoForm.get('summary').errors.required
        "
      >
        Field is <strong>Required</strong>
      </p>
    </div>
    <ng-container formArrayName="additional_fields">
      <div class="form-group" *ngFor="let controls of additionalFields.controls; let i = index" [formGroupName]="i">
        <label class="flex"
          >{{ additionalFields.controls[i].value.title }}
          <app-icon
            [icon]="'info'"
            class="ml-4 w-12 h-12 opacity-50 hover:opacity-100 inline-block"
            [appTooltip]="additionalFields.controls[i].value.tooltip"
            placement="top"
          ></app-icon>
        </label>
        <textarea
          row="5"
          [placeholder]="additionalFields.controls[i].value.placeholder"
          id="content"
          formControlName="value"
          required
          trim="blur"
          (blur)="validateFormData()"
        ></textarea>
        <p
          class="error-msg"
          *ngIf="
            additionalFields.controls[i].get('value').errors &&
            additionalFields.controls[i].get('value').errors.required &&
            additionalFields.controls[i].get('value').touched
          "
        >
          Field is <strong>Required</strong>
        </p>
      </div>
    </ng-container>

    <div class="grid grid-cols-1 md:grid-cols-2 md:gap-24">
      <div class="form-group">
        <app-dcp-multiselect
          *ngIf="genreList"
          [options]="genreList"
          [placeholder]="'Genre(s)'"
          [filterOption]="true"
          (selected)="selectGenre($event)"
          [selectedOptions]="proposal.genres ? proposal.genres : []"
        >
        </app-dcp-multiselect>
        <p
          class="error-msg"
          *ngIf="
            stepTwoForm.get('genres').errors &&
            stepTwoForm.get('genres').errors.required &&
            !stepTwoForm.value['genres']?.length
          "
        >
          Field is <strong>Required</strong>
        </p>
      </div>
      <div class="form-group">
        <app-dcp-multiselect
          *ngIf="languageList"
          [options]="languageList"
          [placeholder]="'Target Audience Language(s)'"
          [filterOption]="true"
          (selected)="selectLanguage($event)"
          [selectedOptions]="proposal.languages ? proposal.languages : []"
        >
        </app-dcp-multiselect>
        <p
          class="error-msg"
          *ngIf="
            stepTwoForm.get('languages').errors &&
            stepTwoForm.get('languages').errors.required &&
            !stepTwoForm.value['languages']?.length
          "
        >
          Field is <strong>Required</strong>
        </p>
      </div>
    </div>
  </div>
  <div class="grid grid-cols-1 md-gap-24">
    <div class="form-group">
      <div class="info-box">
        <p>Enable Chat (Create a discussion channel for your {{ createTitle }} in the DCP Discord)</p>
        <label class="switch mt-4">
          <input type="checkbox" formControlName="enable_chat" />
          <span class="slider rounded"></span>
        </label>
      </div>
    </div>
  </div>
  <div class="grid grid-cols-1 md-gap-24">
    <div class="form-group">
      <div class="info-box">
        <p>
          Disable public read: By turning this on only logged in users will be able to view your submission.<app-icon
            [icon]="'info'"
            class="ml-4 w-12 h-12 opacity-50 hover:opacity-100 inline-block"
            appTooltip="Allow non-logged in members to view your submission. Non-logged in users will only have permissions to view your submission. Any engagements with your submission will require a registered & logged in account."
            placement="top"
          ></app-icon>
        </p>
        <label class="switch mt-4">
          <input type="checkbox" formControlName="hidden_publicly" (change)="validateFormData()" />
          <span class="slider rounded"></span>
        </label>
      </div>
    </div>
  </div>
</form>

<div *ngIf="isOpenModal" class="backdrop flex flex-wrap items-center justify-center w-full h-full overflow-auto">
  <app-upload-dialog
    [type]="uploadType"
    [from]="'proposal'"
    [checkBucketType]="isOpenAdditionalModal"
    [distributionType]="distributionType"
    [mode]="mode"
    [mediaFile]="selectedMediaFile"
    [proposalIndex]="proposalIndex"
    [imageUploaded]="proposalImageUploaded"
    [fileUploaded]="proposalFileUploaded"
    [videoUploaded]="proposalVideoUploaded"
    [isDirectVideoUpload]="isProposalDirectVideoUpload"
    [modalTitle]="proposalModalTitle"
    [modalValue]="proposalModalValue"
    [modalDescription]="proposalModalDescription"
    [videoBlob]="proposalVideoBlob"
    (cancelModal)="cancelModal()"
    (saveMedia)="saveMedia($event)"
  ></app-upload-dialog>
</div>
