<div class="relative">
  <div
    class="login-wrapper bg-body md:rounded-tl-lg md:rounded-bl-lg fixed right-0 top-0 h-full w-full md:w-login flex flex-col justify-center items-center px-32 md:px-64 overflow-y-auto z-10 pb-24"
    @panelIn
  >
    <button class="absolute top-8 right-8 m-8" (click)="closePanel()" *ngIf="slug">
      <app-icon [icon]="'close'" class="w-16 h-16 black opacity-75 hover:opacity-100"></app-icon>
    </button>
    <app-logo class="logo-blue w-180 md:hidden my-32"></app-logo>
    <ng-container *ngIf="!goog2faRequired">
      <h3 class="text-base mb-48 font-bold text-center">Choose preferred method to Login or Register</h3>
      <div class="rounded relative bg-white border w-full border-gray-border">
        <div class="flex w-full items-center h-48">
          <span class="rounded-tl {{ selectedTab === 0 ? 'bg-white' : 'bg-body' }}  font-semibold text-base cursor-pointer h-full w-full flex items-center justify-center" [ngClass]="{ 'text-blue': selectedTab === 0 }" (click)="selectedTab = 0">Social</span>
          <span class="rounded-tr {{ selectedTab === 1 ? 'bg-white' : 'bg-body' }} font-semibold text-base cursor-pointer h-full w-full flex items-center justify-center" [ngClass]="{ 'text-blue ': selectedTab === 1 }" (click)="selectedTab = 1">Advanced</span>
        </div>
        <div class="p-24 flex flex-col items-center" >
          <div class="flex items-center justify-center w-full mb-24 gap-32" *ngIf="selectedTab === 1">
            <img [src]="item"  alt="" *ngFor="let item of tempalWallets">
          </div>
          <ng-container *ngIf="selectedTab === 0">
            <button class="btn btn-secondary w-full h-40 mb-16" (click)="loginKukai(verifierPlatform.GOOGLE)"><img class="mr-12" alt="google-icon" src="/assets/img/login-icons/google.svg">Continue with Google</button>
            <button class="btn btn-secondary w-full h-40 mb-16" (click)="loginKukai(verifierPlatform.TWITTER)"><img class="mr-12" alt="twiter-icon" src="/assets/img/login-icons/twitter.svg">Continue with X</button>
            <button class="btn btn-secondary w-full h-40 mb-16" (click)="loginKukai(verifierPlatform.APPLE)"><img class="mr-12" alt="apple-icon" src="/assets/img/login-icons/apple.svg">Continue with Apple</button>
          </ng-container>
          <button *ngIf="selectedTab === 1" class="btn btn-primary w-full h-40" (click)="loginBeacon()">Login/Register</button>
        </div>
        <div class="w-full h-full flex flex-col items-center justify-center absolute top-0 left-0 bg-backdrop" *ngIf="isLoginLoading">
          <app-local-loader [hasTimeout]="false" class="w-32 h-32"></app-local-loader>
        </div>
      </div>
    </ng-container>
    <form *ngIf="goog2faRequired" [formGroup]="form">
        <h3 class="text-base mb-48 font-bold text-center" >Two-Factor Authentication</h3>
        <p class="text-sm mb-8 font-normal text-center">Open Google Authenticator app on your device and type the generated code</p>
        <div class="goog2fa-auth mb-5 mt-4" id="goog2fa-auth" #goog2faAuth>
          <input
            formControlName="goog2fa_code1"
            (keyup)="keyDigit1DownFunction($event, $event.target.value)"
            (keypress)="isNumberKey($event)"
            [maxLength]="1"
            #goog2fa_code1
            id="digit1"
            maxlength="1"
            autocorrect="off"
            autocomplete="off"
            autocapitalize="off"
            spellcheck="false"
            type="tel"
          />

          <input
            formControlName="goog2fa_code2"
            (keyup)="keyDigit2DownFunction($event, $event.target.value)"
            (keypress)="isNumberKey($event)"
            [maxLength]="1"
            #goog2fa_code2
            id="digit2"
            type="tel"
          />

          <input
            formControlName="goog2fa_code3"
            (keyup)="keyDigit3DownFunction($event, $event.target.value)"
            (keypress)="isNumberKey($event)"
            [maxLength]="1"
            #goog2fa_code3
            id="digit3"
            type="tel"
          />

          <input
            formControlName="goog2fa_code4"
            (keyup)="keyDigit4DownFunction($event, $event.target.value)"
            (keypress)="isNumberKey($event)"
            [maxLength]="1"
            #goog2fa_code4
            id="digit4"
            type="tel"
          />

          <input
            formControlName="goog2fa_code5"
            (keyup)="keyDigit5DownFunction($event, $event.target.value)"
            (keypress)="isNumberKey($event)"
            [maxLength]="1"
            #goog2fa_code5
            id="digit5"
            type="tel"
          />

          <input
            formControlName="goog2fa_code6"
            (keyup)="keyDigit6DownFunction($event, $event.target.value)"
            (keypress)="isNumberKey($event)"
            [maxLength]="1"
            #goog2fa_code6
            id="digit6"
            type="tel"
          />
        </div>
        <div
            class="w-full h-full flex flex-col items-center justify-center absolute top-0 left-0 bg-backdrop" *ngIf="isLoginLoading"
          >
            <app-local-loader [hasTimeout]="false" class="w-32 h-32"></app-local-loader>
          </div>
      </form>
      <p
          class="text-sm mt-12 font-semibold text-center"
        >
          If you require assistance please contact<br/> <a
          href="https://support-dcp.atlassian.net/servicedesk/customer/portal/1" target="_blank"
          rel="noopener noreferrer">DCP Support</a>.
        </p>
  </div>
</div>
