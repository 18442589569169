import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlagPickerComponent } from './flag-picker.component';



@NgModule({
  declarations: [
    FlagPickerComponent
  ],
  imports: [
    CommonModule
  ],
  exports:[FlagPickerComponent]
})
export class FlagPickerModule { }
