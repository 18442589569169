import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'shorten' })
export class ShortenPipe implements PipeTransform {
  transform(text: string, length = 0, suffix = '', shouldTrim = true): string {
    if (text.length > length && shouldTrim) {
      return text.slice(0, length) + suffix;
    }
    return text;
  }
}
