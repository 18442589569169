<div
  *ngIf="!this.data.url && !this.data.videoUrl"
  class="bg-body ratio-16-9 relative rounded cursor-pointer hover:opacity-75 add-media !mb-0"
>
  <div class="w-full top-0 h-full absolute flex flex-col justify-center items-center">
    <app-icon [icon]="'image'" class="box-icon w-40 h-40 opacity-25 mb-16"></app-icon>
    <p class="text-blue text-center text-sm">Click to add your Cover</p>
    <input
      type="file"
      (change)="coverUpload($event)"
      [accept]="mediaType"
      class="opacity-0 absolute w-full !h-full top-0 left-0 cursor-pointer"
      (click)="$event.target.value = null"
    />
  </div>
  <ng-container *ngTemplateOutlet="loader"></ng-container>
</div>
<div *ngIf="this.data.videoUrl" class="ratio-16-9 relative rounded overflow-hidden mb-24 md:mb-0">
  <app-video-player class="absolute top-0 w-full !h-full" [videoConfig]="generateVideoConfig()"></app-video-player>
  <div class="flex absolute top-0 right-0 pt-8 pr-8">
    <button (click)="coverImageInput.click()" class="btn-icon btn-sm edit btn-inverted">
      <app-icon [icon]="'edit'"></app-icon>
    </button>
    <input
      #coverImageInput
      type="file"
      (change)="coverUploaded($event)"
      accept="video/*,.mkv,image/jpeg,image/jpg,image/png"
      hidden
    />
  </div>
  <ng-container *ngTemplateOutlet="loader"></ng-container>
</div>
<ng-template #loader>
  <div
    class="w-full !h-full flex flex-col items-center justify-center absolute top-0 left-0 bg-backdrop"
    *ngIf="fileUpload"
  >
    <app-local-loader [hasTimeout]="false" class="w-32 h-32"></app-local-loader>
  </div>
</ng-template>

<div *ngIf="this.data.url" class="ratio-16-9 rounded overflow-hidden mb-24 md:mb-0">
  <img
    [hidden]="!isImageLoaded"
    class="absolute top-0 left-0 w-full h-full object-cover"
    [src]="this.data.url"
    alt="cover-image"
    (load)="isImageLoaded = true"
  />
  <ng-container *ngTemplateOutlet="loader"></ng-container>
  <div *ngIf="isImageLoaded" class="flex absolute top-0 right-0 pt-8 pr-8">
    <button (click)="coverImageInput.click()" class="btn-icon btn-sm edit btn-inverted">
      <app-icon [icon]="'edit'"></app-icon>
    </button>
    <input #coverImageInput type="file" (change)="coverUpload($event)" [accept]="mediaType" hidden />
  </div>
</div>
<div *ngIf="fileChangedEvent && !isOpenModal" class="backdrop flex flex-wrap items-center justify-center w-full h-full">
  <div class="img-cropper-container relative min-h-300 w-full">
    <div class="cropper-content relative sm:flex overflow-y-auto">
      <div class="mb-16 mr-16 w-cropper-preview" [ngStyle]="{ width: cropperPreviewWidth + 'px' }">
        <img class="w-full" *ngIf="data.url" [src]="data.url" alt="Cover Image" />
        <div class="flex gap-16 my-16 justify-center">
          <button
            class="btn-icon-transparent btn-lg"
            [disabled]="cropperPreviewWidth === 190"
            (click)="handlePreviewWidth(true)"
          >
            <app-icon icon="search-"></app-icon>
          </button>
          <button
            class="btn-icon-transparent btn-lg"
            [disabled]="cropperPreviewWidth === 430"
            (click)="handlePreviewWidth(false)"
          >
            <app-icon icon="search+"></app-icon>
          </button>
        </div>
      </div>
      <app-dcp-image-cropper
        class="block flex-1"
        [imageChangedEvent]="fileChangedEvent"
        [maintainAspectRatio]="true"
        [aspectRatio]="16 / 9"
        (imageCropped)="imageCropped($event)"
      >
      </app-dcp-image-cropper>
      <div class="flex m-16 md:mt-16 md:absolute md:bottom-16 md:left-16">
        <button class="btn-icon error mr-16" (click)="reset()">
          <app-icon [icon]="'close'" class="w-16 h-16 relative" data-tooltip="Close" data-tooltip-conf="top">
          </app-icon>
        </button>
        <button class="btn-icon success" (click)="uploadCroppedCoverImage()">
          <app-icon [icon]="'check'" class="w-16 h-16 relative" data-tooltip="Crop" data-tooltip-conf="top"> </app-icon>
        </button>
      </div>
    </div>
  </div>
</div>
<app-upload-dialog
  *ngIf="isOpenModal"
  type="video"
  [checkBucketType]="true"
  [from]="'round'"
  [mode]="mode"
  [mediaFile]="fileChangedEvent"
  (saveMedia)="saveMedia($event)"
  (cancelModal)="isOpenModal = false; fileChangedEvent = null"
></app-upload-dialog>
