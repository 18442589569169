import { Component } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ConfirmBuyDialogData, Proposal, QueryParamsData } from '../../../../../src/app/app.datatypes';
import { DialogContext } from '../../../../../src/app/shared/dialog/dialog-context';
import { DialogService } from '../../../../../src/app/shared/dialog/dialog.service';

@Component({
  selector: 'app-confirm-to-buy-dialog',
  templateUrl: './confirm-to-buy-dialog.component.html'
})
export class ConfirmToBuyDialogComponent {
  form: UntypedFormGroup;
  proposal: Proposal;
  queryParams: QueryParamsData;

  constructor(
    private router: Router,
    public dialog: DialogContext<ConfirmBuyDialogData>,
    private dialogService: DialogService
  ) {
    this.proposal = this.dialog.data?.proposal;
    this.queryParams = this.dialog.data?.queryParams;
  }

  submit() {
    if (this.queryParams) {
      this.router.navigate(['payment/buy'], {
        queryParams: this.queryParams,
      });
    }
    if (this.proposal) {
      this.router.navigate(['/payment/buy'], {
        queryParams: { redirectUri: '/' },
      });
    }
    this.dialog.close();
    const data = { success: true };
    this.dialogService.dialogBehaviour.next(data);
  }
}
