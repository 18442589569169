import { Injectable } from '@angular/core';
import { TezosToolkit } from '@taquito/taquito';
import { AuthenticatedUserQuery } from '../state/authenticated-user/authenticated-user.query';
import { ToasterService } from './toaster.service';
import {TezosWalletImpl, TezosWalletService} from './tezos-wallet.service';
import { packDataBytes } from '@taquito/michel-codec';
import { TorusService } from '../../wallet/torus.service';
import { OperationService } from '../../wallet/operation/operation.service';
import { KeyPair } from '../../wallet/interfaces';
import {Router} from "@angular/router";

@Injectable({
  providedIn: 'root',
})
export class TezosSocialWalletService implements TezosWalletImpl {
  tezos: TezosToolkit;
  private initialized = false;
  private isSilentEnabled: boolean;
  private _user;
  private keys: KeyPair;
  private _selectedVerifier = 'google';

  constructor(
    private authUserQuery: AuthenticatedUserQuery,
    private toasterService: ToasterService,
    private torusService: TorusService,
    private operationService: OperationService,
    private router: Router
  ) {
    this.isSilentEnabled = true;
    this.torusService.initTorus();
    // Try to restore a session user
    this.torusService.loadSessionUser().then((user) => {
      if (user?.userData) {
        this._user = user;
        this.keys = { pk: this._user.pk, sk: this._user.sk, pkh: this._user.pkh };
        this._selectedVerifier = this._user.userData['typeOfLogin'];
      } else {
        this.authUserQuery?.authenticatedUser$?.subscribe((user) => {
          if (user.type_of_kukai_login) {
            this._selectedVerifier = user.type_of_kukai_login;
          }
        });
      }
    });
  }

  async connect(signBytes) {
    await this.login();
    const user = this.user;
    if (!user?.pkh) {
      throw new Error('No kukai user wallet, wallet connection failed');
    }
    if (!this.authUserQuery.user?._id) {
      throw new Error('No authenticated user id detected, wallet connection failed');
    }
    return {
      address: user.pkh,
      pk: user.pk,
      email: user.userData['email'] ?? null,
      sig: await this.signPackedData(
        signBytes,
        'Wallet Connection',
        'Please sign the message so we can verify you control the wallet.',
        'Connect Now'
      ),
    };
  }

  async loginVerification() {
    await this.login();
    const user = this.user;
    if (!user?.pkh) {
      throw new Error('No kukai user wallet, wallet connection failed');
    }
    if (!user?.pk) {
      throw new Error('No kukai public key detected, wallet connection failed');
    }
    const nonce = TezosWalletService.randomNonce;
    let packedExpr = packDataBytes({ string: `DCP Login Request ${user?.pkh} ${nonce}` });
    return {
      address: user.pkh,
      pk: user.pk,
      nonce,
      email: user.userData['email'] ?? null,
      sig: await this.signPackedData(
        packedExpr.bytes,
        'Connect',
        'Please sign the message so we can verify you control the account associated with the wallet.',
        'Connect Now'
      ),
    };
  }

  handleError() {
    this.toasterService.openToastr(
      'You have cancelled signing the action. Please sign your action to make your attribution count!',
      'Sign Error',
      'error',
      3000
    );
    throw new Error('Signing cancelled');
  }

  async login() {
    if (!this.user?.pkh) {
      let loaded = await this.torusService.loadSessionUser(this.authUserQuery.user?._id);
      if (loaded) {
        this._user = loaded;
        this.keys = { pk: this._user.pk, sk: this._user.sk, pkh: this._user.pkh };
        this._selectedVerifier = this._user.userData['typeOfLogin'];
      }
      if (!this.user) {
        try {
          let res = await this.torusService.loginTorus(this.selectedVerifier).catch(this.handleError.bind(this));
          this.keys = res.keyPair;
          this._user = {
            userData: res.userInfo,
            pk: res.keyPair.pk,
            pkh: res.keyPair.pkh,
            sk: res.keyPair.sk,
          };
          await this.torusService.storeSessionUser(this._user, this.authUserQuery.user?._id);
        } catch (ex) {
          // If we encounter an error on login just logout (Fixes peculiar iOS installed app bug where it crashes on res.keyPair
          return this.logout().then(() => {
            return this.router.navigate(['/login']);
          });
        }
      }
      if (this.authUserQuery.user?.wallet_address && this.user.pkh !== this.authUserQuery.user.wallet_address) {
        alert(
          'There was a problem connecting your wallet.' +
            "  The wallet address doesn't match your user account." +
            '  Please connect the correct wallet using the right account and type (Google, Facebook, Twitter etc).' +
            '  If you are unable to resolve this issue, please contact support.'
        );
        await this.logout();
        throw new Error('Wallet address mis-match');
      }
    }
    return this.user;
  }

  async logout() {
    this._user = null;
    this.keys = null;
    this._selectedVerifier = null;
    localStorage.removeItem('sw');
  }

  async validateWallet() {
    if (this.authUserQuery.user?.wallet_address) {
      if (this.initialized && this.connectedWalletAddress !== this.authUserQuery.user.wallet_address) {
        await this.logout();
      }
    } else {
      await this.logout();
    }
  }

  get connectedWalletPublicKey() {
    return this.user?.pk;
  }

  get connectedWalletAddress() {
    return this.user?.pkh;
  }

  get selectedVerifier() {
    // no 'verifier' for beacon, only for social/torus
    return this._selectedVerifier;
  }

  get user() {
    return this._user;
  }

  async sendOperation(tx) {
    let prepared = [tx].map((tx) => {
      return {
        ...tx,
        gasLimit: 2400,
        storageLimit: 1000,
      };
    });
    return this.operationService
      .transfer(this.user.pkh, prepared, 0.001, this.keys)
      .toPromise()
      .then((res) => {
        if (res.success) {
          return res.payload.opHash;
        }
      });
  }

  async signPackedData(data, actionTitle, actionText, buttonTitle) {
    return this.operationService.sign(data, this.user.sk).edsig;
  }

  setSelectedVerifier(selected) {
    this._selectedVerifier = selected;
  }
}
