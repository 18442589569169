import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { inject } from "@angular/core";
import { SharedService, ToasterService } from "../shared";
import { EMPTY, Observable } from "rxjs";
import { catchError } from "rxjs/operators";

export class ErrorHandlerInterceptor implements HttpInterceptor {

  #toasterService = inject(ToasterService);
  #sharedService = inject(SharedService);

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.#shouldHandleError(req)) {
      return next.handle(req).pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 422 || error.status === 403) {
            this.#toasterService.openErrorToastr(error.error.message, error.name);
            this.#sharedService.setStopLoader(false);
          }
          return EMPTY;
        })
      )
    } else {
      return next.handle(req);
    }
  }

  #shouldHandleError(req: HttpRequest<any>): boolean {
    const urlsToHandleError = [
      '/api/creative-query/prepare-for-submit',
      '/api/proposals/prepare-for-submit',
      '/api/reviews/'
    ];

    return urlsToHandleError.some(url => req.url.includes(url));
  }

}
