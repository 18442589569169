import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, OnChanges, DestroyRef } from '@angular/core';
import { AuthenticatedUserQuery, SharedService, AuthenticatedUser, AuthenticatedUserService } from '../../../../shared';
import { SwagProduct, UserBalanceData, UserEngagementData } from '../../../../app.datatypes';
import { environment } from '../../../../../environments';
import { SwagProductQuery } from '../../../../shared/state/swag-products/swag-products-query.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-topbar-menu',
  templateUrl: './topbar-menu.component.html',
  styleUrls: ['./topbar-menu.component.scss'],
})
export class TopbarMenuComponent implements OnInit, OnChanges {
  @Input() user: AuthenticatedUser;
  @Output() showInboxBar = new EventEmitter();

  refreshEngagementRetries = 0; // count engagement retries so we don't retry
  refreshEarningsRetries = 0; // count engagement retries so we don't retry
  hasRefreshTimeout = false;
  isCoinAnimation = false;
  notificationCount: number;
  showRegistrationOnly = environment.limited_proposal_functionality;
  showSwag = !environment.limited_swag_functionality;

  info: UserEngagementData = {
    likes: 0,
    likesRight: 0,
    review: 0,
    reviewRight: 0,
    creativeQuery: 0,
    creativeQueryRight: 0,
    fillChart: 0,
    fill: 0,
    dasharay: '0',
    all: 0,
    taken: 0,
    potentialEarning: 0,
    potentialEarningTime: {
      hours: 0,
      minutes: 0,
      seconds: 0,
    },
  };

  balance: UserBalanceData;
  cartCount: number;

  constructor(
    protected authenticatedUserQuery: AuthenticatedUserQuery,
    private readonly sharedService: SharedService,
    private readonly swagQuery: SwagProductQuery,
    private readonly authService: AuthenticatedUserService,
    private readonly destroyRef: DestroyRef
  ) {
    this.sharedService
      .getCoinAnimation()
      .pipe(takeUntilDestroyed(destroyRef))
      .subscribe(() => {
        this.isCoinAnimation = true;
        setTimeout(() => {
          this.isCoinAnimation = false;
        }, 3000);
      });
    this.sharedService
      .getUpdateNotificationCount()
      .pipe(takeUntilDestroyed(destroyRef))
      .subscribe((data) => {
        if (data.isRead === true && data.isAllRead === false) {
          --this.notificationCount;
        }
        if (data.isRead === true && data.isAllRead === true) {
          this.notificationCount = 0;
        }
      });
    this.swagQuery
      .getAllCartProducts()
      .pipe(takeUntilDestroyed(destroyRef))
      .subscribe((data: SwagProduct[]) => {
        this.cartCount = data?.length;
      });
  }

  ngOnInit(): void {
    this.notificationCount = this.user?.notifications_count;

    this.info = {
      likes: 0,
      likesRight: 0,
      review: 0,
      reviewRight: 0,
      creativeQueryRight: 0,
      creativeQuery: 0,

      all: 0,
      taken: 0,
      fillChart: 0,
      fill: 0,
      dasharay: '0',
      potentialEarning: 0.0,
      potentialEarningTime: {
        hours: 0,
        minutes: 0,
        seconds: 0,
      },
    };

    this.balance = {
      internalBalance: this.user?.balance,
      externalBalance: this.user?.external_balance,
      lockedBalance: this.user?.locked,
      total: this.user?.external_balance + this.user?.balance + this.user?.locked,
    };

    this.authenticatedUserQuery.authenticatedUser$.subscribe((state) => {
      if (state.user_engagement_data && state.potential_earnings !== undefined) {
        this.info = Object.assign({}, state.user_engagement_data);
        this.info.potentialEarningTime = Object.assign({}, state.user_engagement_data.potentialEarningTime);
        this.info.potentialEarning = state.potential_earnings;
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.user) {
      this.user = changes.user.currentValue;
      this.authService.getAllUserDraftProposal().pipe(takeUntilDestroyed(this.destroyRef)).subscribe();

      this.balance = {
        internalBalance: this.user?.balance,
        externalBalance: this.user?.external_balance,
        lockedBalance: this.user?.locked,
        total: this.user?.external_balance + this.user?.balance + this.user?.locked,
      };
    }
  }

  completeRegistration(): void {
    this.authenticatedUserQuery.unAuthAction.next({ slug: 'Registration', action: 'verify' });
  }

  showInfoBoxFn(): void {
    this.showInboxBar.emit();
  }
}
