import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DialogData } from '../../../../../app.datatypes';
import { DialogContext } from '../../../../dialog/dialog-context';
import { ToasterService, UserService } from '../../../../services';
import { AuthenticatedUserQuery } from '../../../../state';

@Component({
  selector: 'app-email-share-dialog',
  templateUrl: './email-share-dialog.component.html',
  styleUrls: ['./email-share-dialog.component.scss'],
})
export class EmailShareDialogComponent {
  progress: boolean;
  form: FormGroup = new FormGroup({
    email: new FormControl(null, [Validators.required, Validators.email]),
    message: new FormControl(null),
  });

  constructor(
    public dialog: DialogContext<DialogData>,
    private authenticatedUser: AuthenticatedUserQuery,
    private userService: UserService,
    private toasterService: ToasterService
  ) {
    this.userService.getSystemSetting('REF_USER_REWARD_USD').subscribe(refBonus => {
      const user = this.authenticatedUser.user;
      const message = `${user.name} has invited you to join the Decentralized Pictures Foundation platform.
      Click the link below to complete registration and you will both earn a welcome bonus of $${refBonus} worth of FILMCredits`;
      this.form.controls['message'].setValue(message);
    })
  }

  closeDialog() {
    this.dialog.close();
    this.progress = false;
  }

  share() {
    this.progress = true;
    this.userService.shareByEmail(this.form.value.email).subscribe((res) => {
      this.closeDialog();
      this.toasterService.show(
        'Thank you, your referral link has been delivered. You will be notified whenever anyone completes registration using your unique link.',
        'Referral sent'
      );
    }, (error) => {
      this.closeDialog();
    });
  }
}
