import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { SearchData } from '../../../../../src/app/app.datatypes';
import { SharedService } from '../../services';
@Component({
  selector: 'app-toggle-button',
  templateUrl: './toggle-button.component.html',
  styleUrls: ['./toggle-button.component.scss'],
})
export class ToggleButtonComponent implements OnInit {
  @Input() type: string;
  @Input() isMobileScreen: boolean;
  @Output() searchDetail: EventEmitter<SearchData> = new EventEmitter<SearchData>();

  isTogglelNav = false;
  isToggleFilter = false;
  isToggelSearch = false;
  filterForm = new UntypedFormGroup({
    search: new UntypedFormControl(),
  });

  constructor(private sharedService: SharedService) {
    this.sharedService.getCloseToggleFilter().subscribe(() => {
      this.isToggleFilter = !this.isToggleFilter;
    });
    this.sharedService.getOnToggleNav().subscribe(() => {
      this.isToggleFilter = false;
    });
  }

  ngOnInit(): void {}

  onToggle() {
    this.isTogglelNav = !this.isTogglelNav;
    this.sharedService.setOnToggleNav();
  }

  onToggleFilter() {
    this.isToggleFilter = !this.isToggleFilter;
    this.sharedService.setOnToggleFilter();
  }

  openSearch() {
    this.isToggelSearch = true;
  }

  closeSearch() {
    this.isToggelSearch = false;
    const filterFormValues = this.filterForm.value;
    if (filterFormValues.search) {
      this.filterForm.setValue({
        search: '',
      });
      this.searchData();
    }
  }

  searchData() {
    const filter = {
      search: this.filterForm.value.search,
    };
    this.searchDetail.emit(filter);
  }
}
