import { NgModule } from '@angular/core';
import { LogoModule } from '../../shared/modules/components/logo/logo.module';
import { SidebarComponent } from './sidebar/sidebar.component';
import { PotentialEarningsComponent } from './header/potential-earnings/potential-earnings.component';
import {BalanceDetailsComponent} from './header/balance-details/balance-details.component'
import { UserMenuComponent } from './sidebar/user-menu/user-menu.component';
import { TopbarComponent } from './topbar/topbar.component';
import { TopbarMenuComponent } from './topbar/topbar-menu/topbar-menu.component';
import { FooterComponent } from './footer/footer.component';
import { NavComponent } from './nav/nav.component';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../shared';
import { DropdownMenuModule } from '../../shared/modules/components/dropdown-menu/dropdown-menu.module';
import { ReferralModule } from '../../shared/modules/components/referral/referral.module';
import { IconModule } from '../../shared/modules/components/icon/icon.module';

@NgModule({
  imports: [SharedModule,IconModule, LogoModule, RouterModule,DropdownMenuModule,ReferralModule],
  declarations: [
    NavComponent,
    SidebarComponent,
    BalanceDetailsComponent,
    PotentialEarningsComponent,
    FooterComponent,
    TopbarComponent,
    TopbarMenuComponent,
    UserMenuComponent,
  ],
  exports: [SidebarComponent, TopbarComponent, UserMenuComponent],
})
export class LayoutModule {}
