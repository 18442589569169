import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { PricingFees } from '../../../../../src/app/app.datatypes';
import { PaymentState, PaymentStore } from './payment.store';

@Injectable({ providedIn: 'root' })
export class PaymentQuery extends QueryEntity<PaymentState> {
  constructor(protected store: PaymentStore) {
    super(store);
  }

  selectPricingFees$ = this.select(state => state.fees.price_of_film);

  get fees(): PricingFees {
    return this.getValue().fees;
  }

  get proposalResubmissionFee(): number {
    return this.getValue().fees.proposal_resubmission_fee_in_dollar;
  }

  get proposalResubmissionFilmFee(): number {
    return this.getValue().fees.proposal_resubmission_fee;
  }

  get querySubmissionFee(): number {
    return this.getValue().fees.creative_query_submission_fee_in_dollar;
  }

  get queryResubmissionFee(): number {
    return this.getValue().fees.creative_query_resubmission_fee_in_dollar;
  }

  get querySubmissionFilmFee(): number {
    const value = this.getValue();
    return value.fees.creative_query_submission_fee;
  }

  get queryResubmissionFilmFee(): number {
    const value = this.getValue();
    return value.fees.creative_query_resubmission_fee;
  }

  get filmPricing(): number {
    return this.getValue().fees.price_of_film;
  }

  get saleFilmPricing(): number {
    return this.getValue().fees.price_of_film_sale;
  }

  get lastFilmPricingUpdate(): string {
    return this.getValue().fees.price_of_film_last_updated;
  }

  get extensionFee(): number {
    return this.getValue().fees.proposal_extension_fee;
  }

  getPaymentStatus(id: string) {
    return this.selectEntity(id, e => e.status);
  }
}
