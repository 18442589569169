import { NgModule } from '@angular/core';
import { ProposalCreateComponent } from './proposal-create.component';
import { ProposalCreateStepOneComponent } from './proposal-create-steps/proposal-create-step-one/proposal-create-step-one.component';
import { SharedModule } from '../../../shared';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { DcpCheckoutModule } from '../../../shared/modules/components/checkout/dcp-checkout.module';
import { MultiselectModule } from '../../../shared/modules/components/dcp-multiselect/multiselect.module';
import { SafeHtmlModule } from '../../../shared/pipes/safe-html/safe-html.module';
import { ExpandedInfoModule } from '../../../shared/components/expanded-info/expanded-info.module';
import { RoundOverviewModule } from '../../../shared/components/round-overview/round-overview.module';
import { CustomVideoPlayerModule } from '../../../shared/components/custom-video-player/custom-video-player.module';
import { VideoPlayerModule } from '../../../shared/modules/components/video-player/video-player.module';
import { ProposalCreateStepThreeComponent } from './proposal-create-steps/proposal-create-step-three/proposal-create-step-three.component';
import { ProposalCreateStepFourComponent } from './proposal-create-steps/proposal-create-step-four/proposal-create-step-four.component';
import { LogoModule } from '../../../shared/modules/components/logo/logo.module';
import { GalleryModule } from '../../../shared/components/gallery/gallery.module';
import { AttachmentModule } from '../../../shared/components/attachments/attachment.module';
import { ProposalCreateStepTwoComponent } from './proposal-create-steps/proposal-create-step-two/proposal-create-step-two.component';
import { UploadAvatarModule } from '../../../shared/modules/components/image-cropper/upload-avatar.module';
@NgModule({
  declarations: [
    ProposalCreateComponent,
    ProposalCreateStepOneComponent,
    ProposalCreateStepTwoComponent,
    ProposalCreateStepThreeComponent,
    ProposalCreateStepFourComponent,
  ],
  imports: [
    SharedModule,
    CKEditorModule,
    DcpCheckoutModule,
    MultiselectModule,
    AttachmentModule,
    SafeHtmlModule,
    ExpandedInfoModule,
    RoundOverviewModule,
    CustomVideoPlayerModule,
    VideoPlayerModule,
    LogoModule,
    GalleryModule,
    UploadAvatarModule,
  ],
  exports: [ProposalCreateComponent],
})
export class ProposalCreateModule {}
