import {
  Component,
  ContentChildren,
  QueryList,
  AfterContentInit,
  Input,
  AfterContentChecked,
  OnChanges,
  SimpleChanges,
  EventEmitter,
  Output,
} from '@angular/core';
import { TabItemComponent } from './tab-item.component';
import { Observable } from 'rxjs';
import { startWith, map, delay } from 'rxjs/operators';
import { tabsAnimation } from '../../../../app.animations';
import { GlobalTourService } from '../../../services/global-tour-service.service';

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
  animations: [tabsAnimation],
})
export class TabsComponent implements AfterContentInit, AfterContentChecked, OnChanges {
  @ContentChildren(TabItemComponent)
  tabs: QueryList<TabItemComponent>;
  tabItems$: Observable<TabItemComponent[]>;
  activeTab: TabItemComponent;

  @Input() setActiveTab = false;
  @Output() tabChange = new EventEmitter();
  scrolrestricted = false;
  constructor(private globalTour: GlobalTourService) {
    this.globalTour.restrictScroll.subscribe((res) => (this.scrolrestricted = res));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.setActiveTab.currentValue === true) {
      this.activeTab = this.tabs?.first;
      this.tabChange.emit();
    }
  }

  ngAfterContentInit(): void {
    this.tabItems$ = this.tabs.changes
      .pipe(startWith(''))
      .pipe(delay(0))
      .pipe(map(() => this.tabs.toArray()));
  }

  ngAfterContentChecked() {
    // choose the default tab
    // we need to wait for a next VM turn,
    // because Tab item content, will not be initialized yet
    if (!this.activeTab) {
      Promise.resolve().then(() => {
        this.activeTab = this.tabs.first;
      });
    }
  }

  selectTab(tabItem: TabItemComponent, index: number) {
    if (this.activeTab === tabItem) {
      return;
    }

    if (this.activeTab) {
      this.activeTab.isActive = false;
    }

    this.activeTab = tabItem;

    tabItem.isActive = true;
    this.tabChange.emit(index);
  }
}
