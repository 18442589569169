<ng-container *ngFor="let toast of toasts; let i = index">
  <div class="toast-element{{ i }}">
    <ng-container *ngIf="toast?.toastrType === 'default'">
      <app-toastr [toast]="toast" [i]="i" (remove)="remove($event)"></app-toastr>
    </ng-container>
    <ng-container *ngIf="toast?.toastrType === 'main'">
      <app-main-toastr
        [toast]="toast"
        [i]="i"
        (remove)="remove($event)"
        (setOffset)="setOffset($event)"
      ></app-main-toastr>
    </ng-container>
    <ng-container *ngIf="toast?.toastrType === 'link'">
      <app-link-toastr
        [toast]="toast"
        [i]="i"
        (remove)="remove($event)"
        (setOffset)="setOffset($event)"
      ></app-link-toastr>
    </ng-container>
    <ng-container *ngIf="toast?.toastrType === 'contactSupport'">
      <app-contact-support-toastr [toast]="toast" [i]="i" (remove)="remove($event)"></app-contact-support-toastr>
    </ng-container>
    <ng-container *ngIf="toast?.toastrType === 'lowBalanceSheet'">
      <app-low-balance-toastr [toast]="toast" [i]="i" (remove)="remove($event)"></app-low-balance-toastr>
    </ng-container>
    <ng-container *ngIf="toast?.toastrType === 'reload'">
      <app-reload-toastr [toast]="toast" [i]="i" (remove)="remove($event)"></app-reload-toastr>
    </ng-container>
  </div>
</ng-container>
