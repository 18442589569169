import {
  ChangeDetectorRef,
  Component,
  DestroyRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { dropdown } from '../../../../app.animations';
import { SharedService } from '../../../services/shared.service';
import { VideoConfig } from '../../../interface/video-config.interface';
import { Style } from '../../../interface/style-model';
import { CustomVideoPlayerComponent } from '../../../components/custom-video-player/custom-video-player.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-video-player',
  templateUrl: './video-player.component.html',
  animations: [dropdown],
})
export class VideoPlayerComponent implements OnInit {
  @ViewChild(CustomVideoPlayerComponent) videoPlayer: CustomVideoPlayerComponent;
  @Input() videoConfig: VideoConfig;
  @Input() styleConfig: Style = {};
  @Output() mouseEntered = new EventEmitter<MouseEvent>();
  @Output() mouseLeft = new EventEmitter<MouseEvent>();
  @Output() videoEvent = new EventEmitter<Event>();
  @Output() loadedData = new EventEmitter<any>();
  oldVideoUrl: string | SafeUrl;
  screenWidth: number;
  screenHeight: number;
  isActive = false;

  constructor(
    private cdRef: ChangeDetectorRef,
    private sharedService: SharedService,
    private sanitizer: DomSanitizer,
    private destroyRef: DestroyRef
  ) {
    this.sharedService
      .getOnSliderPauseVideo()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.videoPlayer.videoElement.nativeElement.pause();
      });
  }

  ngOnInit(): void {
    if (this.videoConfig?.videoBlob && this.videoConfig.videoBlob.size) {
      this.videoConfig.src = this.sanitizeVideoBlob();
    }
  }

  private sanitizeVideoBlob(): SafeUrl {
    return this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(this.videoConfig?.videoBlob));
  }

  onScreenChange(url: string | SafeUrl): void {
    this.videoConfig.src = null;
    this.videoConfig.src = url;
    this.cdRef.detectChanges();
  }

  playVideo(event: Event): void {
    this.videoEvent.emit(event);
  }

  onMouseEnter(event: MouseEvent): void {
    this.mouseEntered.emit(event);
  }

  onMouseLeave(event: MouseEvent): void {
    this.mouseLeft.emit(event);
  }

  onLoadedData(event: any): void {
    this.loadedData.emit(event);
  }
}
