import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModerationsOverviewComponent } from './moderations-overview.component';
import { SharedModule } from '../../shared.module';
import { DashReplaceModule } from '../../pipes/dash-replace/dash-replace.module';
import { ShortenPipeModule } from '../../modules/pipes/shorten-pipe/shorten-pipe.module';
import { StatusTextModule } from '../../modules/pipes/status-text/status-text.module';

@NgModule({
  declarations: [ModerationsOverviewComponent],
  imports: [
    CommonModule,
    SharedModule,
    DashReplaceModule,
    ShortenPipeModule,
    StatusTextModule
  ],
  exports: [
    ModerationsOverviewComponent
  ]
})
export class ModerationsOverviewModule { }
