import { Component, DestroyRef, EventEmitter, Input, Output } from '@angular/core';
import { ProposalService, ToasterService } from '../../../../../shared/services';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { environment } from '../../../../../../environments';
import { SaveProposalMediaList } from '../../../../../app.datatypes';
import { SafeHtml } from '@angular/platform-browser';
import { VideoConfig } from '../../../../../shared/interface/video-config.interface';
import { ImageCroppedEvent } from '../dcp-image-cropper/interfaces';
export interface CroppedImage {
  url: string;
  id?: string;
  thumbnailUrl?: string;
  cover_file_id?: string;
  videoUrl?: string | SafeHtml;
}
@Component({
  selector: 'app-cover-image-cropper',
  templateUrl: './cover-image-cropper.component.html',
  styleUrls: ['./cover-image-cropper.component.scss'],
})
export class CoverImageCropperComponent {
  @Output() uploadedCover: EventEmitter<CroppedImage> = new EventEmitter();
  @Input() mediaType: string = 'image/*';
  fileChangedEvent: any;
  fileUpload: boolean;
  isImageLoaded: boolean;
  @Input() data: CroppedImage;
  cropperPreviewWidth = 190;
  file: Blob;
  isOpenModal: boolean;

  constructor(
    private toastService: ToasterService,
    private proposalService: ProposalService,
    private destroyRef: DestroyRef
  ) {}

  coverUpload(event): void {
    const files = event.srcElement.files;
    if (files[0].type.match('video.*')) {
      if (files[0].size > environment.max_video_size) {
        this.toastService.openErrorToastr('File too Big. The file size can not exceed 1GB', 'Video upload error');
        return;
      }
      this.isOpenModal = true;
      this.data.url = null;
      this.fileChangedEvent = files[0];
    } else {
      // Validate for Filesize
      if (files[0].size / (1024 * 1024) >= environment.max_image_size) {
        this.toastService.openToastr('Upload File Size is greater than 2.5 Mb ', 'FILE SIZE ERROR', 'error', 3000);
        return;
      }
      this.fileChangedEvent = event;
    }
    this.fileUpload = true;
  }

  imageCropped(event: ImageCroppedEvent): void {
    this.file = event.blob;
    this.data.url = event.objectUrl;
  }

  uploadCroppedCoverImage(): void {
    this.fileChangedEvent = null;
    const fd = new FormData();
    fd.append('image', this.file);
    this.proposalService
      .uploadImage(fd)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (event) => {
          if (event.body?.data) {
            this.data = { url: event.body.data.file_url, id: event.body.data._id };
            this.uploadedCover.emit(this.data);
            this.fileUpload = false;
          }
        },
        error: () => {
          this.reset();
        },
      });
  }

  reset(): void {
    this.file = null;
    this.fileChangedEvent = null;
    this.fileUpload = false;
    this.data = {} as CroppedImage;
  }

  handlePreviewWidth(zoomOut: boolean): void {
    if (zoomOut) {
      this.cropperPreviewWidth = this.cropperPreviewWidth - 40;
    } else {
      this.cropperPreviewWidth = this.cropperPreviewWidth + 40;
    }
  }

  saveMedia(data: SaveProposalMediaList): void {
    this.data.videoUrl = data.videoData;
    this.data.cover_file_id = data.file_id;
    this.data.thumbnailUrl = data.thumbnailUrl;
    this.isOpenModal = false;
    this.fileChangedEvent = null;
    this.uploadedCover.emit(this.data);
  }

  generateVideoConfig(): VideoConfig {
    return {
      videoControlOptions: 'nofullscreen noremoteplayback',
      src: this.data.videoUrl,
      classes: 'absolute top-0 h-full w-full object-cover',
      poster: this.data.thumbnailUrl,
    };
  }
}
