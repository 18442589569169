import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StatusTextPipe } from './status-text.pipe';



@NgModule({
  declarations: [StatusTextPipe],
  imports: [
    CommonModule
  ],
  exports:[StatusTextPipe]
})
export class StatusTextModule { }
