import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-sub-entity-action',
  templateUrl: './sub-entity-action.component.html'
})
export class SubEntityActionComponent {
  @Input() entity: any; // any is ok here
  @Input() action: any; // any is ok here
  @Input() parent_entity: any; // any is ok here
  @Input() parent_entity_title: any; // any is ok here
  @Input() entityUrl: any; // any is ok here
  @Input() parentEntityUrl: any; // any is ok here
  @Input() actor: any; // any is ok here
}
