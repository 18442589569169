<div
  class="grid grid-cols-1 sm:grid-cols-1 gap-24"
  joyrideStep="proposalcreate10"
  text="Review proposal details, if correct, proceed to Checkout."
>
  <div class="col-span-13">
    <h4 class="mb-8">Your Project Details</h4>
    <app-gallery *ngIf="proposalReview?.images"></app-gallery>
    <div *ngIf="selectedRound" class="flex justify-between mt-16 pb-16 border-b border-gray-border">
      <div class="flex items-center">
        <app-icon [icon]="'round'" class="w-24 h-24 mr-16 opacity-50"></app-icon>
        <div>
          <p class="text-sm">Award</p>
          <h4 class="font-bold">{{ selectedRound.title }}{{ joyrideService?.isTourInProgress() ? 'Project' : '' }}</h4>
        </div>
      </div>
      <div class="flex items-center">
        <app-icon [icon]="'calendar'" class="w-24 h-24 mr-16 opacity-50"></app-icon>
        <div>
          <span class="text-sm">End Date</span>
          <h4 class="font-bold">
            {{ selectedRound.end_date | date }}
          </h4>
        </div>
      </div>
    </div>
    <h4 class="my-16 font-bold" *ngIf="stepTwoData?.title">
      {{ stepTwoData?.title }}
    </h4>
    <h5 class="font-bold">{{ selectedRound?.proposal_description_fields?.summary?.title }}:</h5>
    <pre class="proposal-content">{{ stepTwoData?.content }}{{ joyrideService?.isTourInProgress() ? 'Tour' : '' }}</pre>
    <br />
    <h5 class="font-bold mt-3">{{ selectedRound?.proposal_description_fields?.description?.title }}:</h5>
    <p *ngIf="joyrideService?.isTourInProgress()">Tour</p>
    <p [innerHTML]="stepTwoData?.summary | safeHtml" class="proposal-content"></p>
    <ng-container *ngFor="let field of stepTwoData.additional_fields">
      <h4 class="font-semibold mt-3">{{ field.title }}</h4>
      <p class="mb-16 proposal-content" [innerHTML]="field.value | safeHtml"></p>
    </ng-container>
    <br />
    <div class="attachments" *ngIf="proposalReview?.docs.length > 0">
      <app-attachments [proposalDocs]="proposalReview?.docs"></app-attachments>
    </div>
  </div>
</div>
