import { Injectable } from '@angular/core';

export interface WindowSize {
  height: number;
  width: number;
  factor?: number;
}

// REF : https://stackoverflow.com/questions/34177221/angular2-how-to-inject-window-into-an-angular2-service
export interface ICustomWindow extends Window {
  __custom_global_stuff: string;
}

function getWindow(): any {
  return window;
}

@Injectable()
export class WindowSizeService {
  public get nativeWindow(): ICustomWindow {
    return getWindow();
  }
  constructor() {}

  getHostname(): string {
    const { port, protocol, hostname } = this.nativeWindow.location;
    return port && port !== '' && port !== '80' ? `${protocol}//${hostname}:${port}/` : `${protocol}//${hostname}/`;
  }
}
