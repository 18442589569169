import { Directive, ViewContainerRef } from '@angular/core';
import { DialogService } from './dialog.service';

@Directive({
  selector: '[appDialogHolder]',
})
export class DialogHolderDirective {
  constructor(viewContainerRef: ViewContainerRef, dialogService: DialogService) {
    dialogService.registerViewContainer(viewContainerRef);
  }
}
