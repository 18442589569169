import { NgModule } from '@angular/core';
import { GalleryComponent } from './gallery.component';
import { SliderComponent } from './slider/slider.component';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [GalleryComponent],
  imports: [SliderComponent, CommonModule],
  exports: [GalleryComponent],
})
export class GalleryModule {}
