import { Injectable } from '@angular/core';
import { MichelsonData, MichelsonDataOption, MichelsonMapEltList, packDataBytes } from '@taquito/michel-codec';
import { environment } from '../../../environments';
import { TezosToolkit, UnitValue } from '@taquito/taquito';
import { Transaction, WalletLoginData } from '../../app.datatypes';
import { AuthenticatedUserQuery } from '../state/authenticated-user/authenticated-user.query';
import sha256 from 'crypto-js/sha256';
import { ToasterService } from './toaster.service';
import { TezosBeaconWalletService } from './tezos-beacon-wallet.service';
import { TezosSocialWalletService } from './tezos-social-wallet.service';

export interface TezosWalletImpl {
  get connectedWalletPublicKey(): string;

  get connectedWalletAddress(): string;

  get selectedVerifier(): string;

  get user(): any;

  connect(signBytes): Promise<any>;

  login(): Promise<any>;

  logout(): Promise<any>;

  validateWallet(): Promise<any>;

  sendOperation(tx): Promise<any>;

  signPackedData(data, actionTitle, actionText, buttonTitle): Promise<string>;

  loginVerification(): Promise<any>;

  setSelectedVerifier(selected): void;
}

interface RoyaltyMap {
  reward_percentage: number;
  wallet_address: string;
}

export enum TezosWalletType {
  KUKAI = 'kukai',
  BEACON = 'beacon',
}

@Injectable({
  providedIn: 'root',
})
export class TezosWalletService {
  tezos: TezosToolkit;
  private isSilentEnabled: boolean;
  private wallet: TezosWalletImpl;
  private userWalletType = TezosWalletType.KUKAI;

  constructor(
    private authUserQuery: AuthenticatedUserQuery,
    private toasterService: ToasterService,
    private beaconService: TezosBeaconWalletService,
    private kukaiService: TezosSocialWalletService
  ) {
    this.tezos = new TezosToolkit(environment.t4l3nt_rpc_endpoint);
    this.isSilentEnabled = false;
  }

  setupWallet(): void {
    if (this.userWalletType == TezosWalletType.BEACON) {
      this.wallet = this.beaconService;
    } else {
      this.wallet = this.kukaiService;
    }
  }

  get verifier(): string {
    if (this.walletType == TezosWalletType.KUKAI) {
      return this.wallet.selectedVerifier;
    }
    return null;
  }

  get userEmail(): string {
    if (this.walletType == TezosWalletType.KUKAI) {
      return this.wallet.user.userData['email'];
    }
    return null;
  }

  setWalletType(newType): void {
    this.userWalletType = newType;
    this.setupWallet();
  }

  setSocialLoginType(loginType): void {
    this.wallet.setSelectedVerifier(loginType);
  }

  get walletType(): string {
    if (this.wallet instanceof TezosSocialWalletService) {
      return TezosWalletType.KUKAI;
    } else if (this.wallet instanceof TezosBeaconWalletService) {
      return TezosWalletType.BEACON;
    } else {
      return null;
    }
  }

  async connect() {
    await this.login();
    let packedExpr = packDataBytes({ string: `${this.wallet.connectedWalletAddress}-${this.authUserQuery.user._id}` });
    return this.wallet.connect(packedExpr.bytes);
  }

  handleError() {
    this.toasterService.openToastr(
      'You have cancelled signing the action. Please sign your action to make your attribution count!',
      'Sign Error',
      'error',
      3000
    );
    throw new Error('Signing cancelled');
  }

  async login() {
    this.setupWallet();
    return this.wallet.login();
  }

  async logout() {
    this.setupWallet();
    return this.wallet.logout();
  }

  async validateWallet() {
    this.setupWallet();
    return this.wallet.validateWallet();
  }

  get connectedWalletPublicKey() {
    return this.wallet.connectedWalletPublicKey;
  }

  get connectedWalletAddress() {
    return this.wallet.connectedWalletAddress;
  }

  get chainID() {
    return environment.chain_id;
  }

  get filmControllerAddress() {
    return environment.film_controller_address;
  }

  get videoDistributionAddress() {
    return environment.video_distribution_address;
  }

  async getBalance(walletAddress: string) {
    return this.tezos.rpc.getBalance(walletAddress).then((balance) => {
      const tezBalance = balance.isGreaterThan(0) ? balance.dividedBy(1000000) : balance;
      return tezBalance.toNumber();
    });
  }

  async getFilmController() {
    return this.tezos.contract.at(environment.film_controller_address);
  }

  async getFilmLedger() {
    return this.tezos.contract.at(environment.film_ledger_address);
  }

  toMutez(amount: number) {
    return isFinite(Number(amount)) ? Math.round(this.round(Number(amount), 6) * 10 ** 6) : undefined;
  }

  toDollar(amount: number) {
    return isFinite(Number(amount)) ? Math.floor(amount * 10 ** 2) : undefined;
  }

  round = (num, places) => {
    return num.toFixed(places);
  };

  async sendUserTransfer(transaction: Transaction) {
    return this.wallet.sendOperation({
      kind: 'transaction',
      destination: transaction.to_address,
      amount: `${transaction.amount}`,
    });
  }

  async depositTokens(transaction: Transaction) {
    const filmLedger = await this.getFilmLedger();
    const deposit = filmLedger.methods.deposit(UnitValue);
    const tx = deposit.toTransferParams({
      amount: Number(transaction.amount),
      mutez: false,
    });
    const newTx = {
      kind: 'transaction',
      destination: tx.to,
      amount: `${this.userWalletType == TezosWalletType.KUKAI ? tx.amount : this.toMutez(tx.amount)}`,
      parameters: tx.parameter,
    };
    return this.wallet.sendOperation(newTx);
  }

  /**
   * Get a random nonce to use in the contract
   * @returns {number}
   */
  static get randomNonce() {
    const randomInt = (low, high) => {
      return Math.floor(Math.random() * (high - low) + low);
    };
    return randomInt(0, Number.MAX_SAFE_INTEGER);
  }

  toReferenceString(obj: any) {
    return encodeURIComponent(JSON.stringify(obj));
  }

  toNormalizedAddress = (address) => `${packDataBytes({ string: address }, { prim: 'address' }).bytes.substring(12)}`;
  toNormalizedKey = (key) => `${packDataBytes({ string: key }, { prim: 'key' }).bytes.substring(12)}`;

  stringToBytes = (val) => `${packDataBytes({ string: val }, { prim: 'string' }).bytes.substring(12)}`;

  sha256 = (val) => sha256(val).toString();

  someAddress(address): MichelsonDataOption {
    if (address) {
      return { prim: 'Some', args: [{ bytes: `${this.toNormalizedAddress(address)}` }] };
    } else {
      return { prim: 'None' };
    }
  }

  async submitProposalSignature(
    proposalBlockchainId: string,
    submissionFee: number,
    bounty: number,
    referenceObject: any,
    nonce: number
  ) {
    await this.login();
    const toSign: MichelsonData = {
      prim: 'Pair',
      args: [
        { string: `${proposalBlockchainId}` },
        {
          prim: 'Pair',
          args: [
            {
              prim: 'Pair',
              args: [
                { prim: 'Pair', args: [{ int: `${this.toMutez(submissionFee)}` }, { int: `${this.toMutez(bounty)}` }] },
                {
                  prim: 'Pair',
                  args: [
                    { bytes: `${this.toNormalizedAddress(this.connectedWalletAddress)}` },
                    { string: `${this.toReferenceString(referenceObject)}` },
                  ],
                },
              ],
            },
            {
              prim: 'Pair',
              args: [
                {
                  prim: 'Pair',
                  args: [{ bytes: `${this.toNormalizedKey(this.connectedWalletPublicKey)}` }, { int: `${nonce}` }],
                },
                {
                  prim: 'Pair',
                  args: [
                    { bytes: `${this.chainID}` },
                    { bytes: `${this.toNormalizedAddress(this.filmControllerAddress)}` },
                  ],
                },
              ],
            },
          ],
        },
      ],
    };
    const packedExpr = packDataBytes(toSign);
    let pkh = this.connectedWalletAddress,
      pk = this.connectedWalletPublicKey,
      sig = await this.wallet.signPackedData(
        packedExpr.bytes,
        'Submit Project',
        'Please sign the message so the app can verify you submitted the project.',
        'Submit Now'
      );
    return {
      address: pkh,
      pk,
      sig,
    };
  }

  async resubmitProposalSignature(
    proposalBlockchainId: string,
    resubmissionFee: number,
    bounty: number,
    referenceObject: any,
    nonce: number
  ) {
    await this.login();
    const toSign: MichelsonData = {
      prim: 'Pair',
      args: [
        { string: `${proposalBlockchainId}` },
        {
          prim: 'Pair',
          args: [
            {
              prim: 'Pair',
              args: [
                {
                  prim: 'Pair',
                  args: [{ int: `${this.toMutez(resubmissionFee)}` }, { int: `${this.toMutez(bounty)}` }],
                },
                {
                  prim: 'Pair',
                  args: [
                    { bytes: `${this.toNormalizedAddress(this.connectedWalletAddress)}` },
                    { string: `${this.toReferenceString(referenceObject)}` },
                  ],
                },
              ],
            },
            {
              prim: 'Pair',
              args: [
                {
                  prim: 'Pair',
                  args: [{ bytes: `${this.toNormalizedKey(this.connectedWalletPublicKey)}` }, { int: `${nonce}` }],
                },
                {
                  prim: 'Pair',
                  args: [
                    { bytes: `${this.chainID}` },
                    { bytes: `${this.toNormalizedAddress(this.filmControllerAddress)}` },
                  ],
                },
              ],
            },
          ],
        },
      ],
    };
    const packedExpr = packDataBytes(toSign);
    let pkh = this.connectedWalletAddress,
      pk = this.connectedWalletPublicKey,
      sig = await this.wallet.signPackedData(
        packedExpr.bytes,
        'Re-Submit Proposal',
        'Please sign the message so the app can verify you re-submitted the proposal.',
        'Re-Submit Now'
      );
    return {
      address: pkh,
      pk,
      sig,
    };
  }

  async submitReviewSignature(
    proposalBlockchainId: string,
    review: string,
    reviewScore: number,
    stake: number,
    contribution: number,
    referenceObject: any,
    nonce: number
  ) {
    await this.login();
    const reviewContent = review ? `${this.sha256(review)}` : '';
    const toSign: MichelsonData = {
      prim: 'Pair',
      args: [
        {
          prim: 'Pair',
          args: [
            {
              prim: 'Pair',
              args: [
                { prim: 'Pair', args: [{ string: `${proposalBlockchainId}` }, { string: reviewContent }] },
                { prim: 'Pair', args: [{ int: `${reviewScore}` }, { int: `${this.toMutez(stake)}` }] },
              ],
            },
            {
              prim: 'Pair',
              args: [
                {
                  prim: 'Pair',
                  args: [
                    { int: `${this.toMutez(contribution)}` },
                    { bytes: `${this.toNormalizedAddress(this.connectedWalletAddress)}` },
                  ],
                },
                { string: `${this.toReferenceString(referenceObject)}` },
              ],
            },
          ],
        },
        {
          prim: 'Pair',
          args: [
            {
              prim: 'Pair',
              args: [{ bytes: `${this.toNormalizedKey(this.connectedWalletPublicKey)}` }, { int: `${nonce}` }],
            },
            {
              prim: 'Pair',
              args: [
                { bytes: `${this.chainID}` },
                { bytes: `${this.toNormalizedAddress(this.filmControllerAddress)}` },
              ],
            },
          ],
        },
      ],
    };
    const packedExpr = packDataBytes(toSign);
    let pkh = this.connectedWalletAddress,
      pk = this.connectedWalletPublicKey,
      sig = await this.wallet.signPackedData(
        packedExpr.bytes,
        'Review Proposal',
        'Please sign the message so the app can verify you reviewed the proposal.',
        'Review Now'
      );
    return {
      address: pkh,
      pk,
      sig,
    };
  }

  async submitLikeReviewSignature(
    likeReviewProposalBlockchainId: string,
    likeReviewAuthorAddress: string | null,
    review: string,
    unlikeReviewAuthorAddress: string | null,
    referenceObject: any,
    nonce: number
  ) {
    await this.login();
    const toSign: MichelsonData = {
      prim: 'Pair',
      args: [
        {
          prim: 'Pair',
          args: [
            {
              prim: 'Pair',
              args: [
                {
                  prim: 'Pair',
                  args: [{ string: `${likeReviewProposalBlockchainId}` }, this.someAddress(likeReviewAuthorAddress)],
                },
                {
                  prim: 'Pair',
                  args: [
                    { bytes: `${this.toNormalizedAddress(this.connectedWalletAddress)}` },
                    { string: `${this.sha256(review)}` },
                  ],
                },
              ],
            },
            {
              prim: 'Pair',
              args: [
                {
                  prim: 'Pair',
                  args: [
                    this.someAddress(unlikeReviewAuthorAddress),
                    { string: `${this.toReferenceString(referenceObject)}` },
                  ],
                },
                {
                  prim: 'Pair',
                  args: [{ int: `${nonce}` }, { bytes: `${this.toNormalizedKey(this.connectedWalletPublicKey)}` }],
                },
              ],
            },
          ],
        },
        {
          prim: 'Pair',
          args: [{ bytes: `${this.chainID}` }, { bytes: `${this.toNormalizedAddress(this.filmControllerAddress)}` }],
        },
      ],
    };
    const action = !likeReviewAuthorAddress && unlikeReviewAuthorAddress ? 'Unlike' : 'Like';
    const packedExpr = packDataBytes(toSign);
    let pkh = this.connectedWalletAddress,
      pk = this.connectedWalletPublicKey,
      sig = await this.wallet.signPackedData(
        packedExpr.bytes,
        `${action} Review`,
        `Please sign the message so the app can verify you ${action.toLocaleLowerCase()} the review.`,
        `${action} Now`
      );
    return {
      address: pkh,
      pk,
      sig,
    };
  }

  /*
   * Creative Query Related Signatures
   */

  async submitCreativeQuerySignature(
    cqBlockchainId: string,
    submissionFee: number,
    bounty: number,
    referenceObject: any,
    nonce: number
  ) {
    await this.login();
    const toSign: MichelsonData = {
      prim: 'Pair',
      args: [
        { string: `${cqBlockchainId}` },
        {
          prim: 'Pair',
          args: [
            {
              prim: 'Pair',
              args: [
                { prim: 'Pair', args: [{ int: `${this.toMutez(submissionFee)}` }, { int: `${this.toMutez(bounty)}` }] },
                {
                  prim: 'Pair',
                  args: [
                    { bytes: `${this.toNormalizedAddress(this.connectedWalletAddress)}` },
                    { string: `${this.toReferenceString(referenceObject)}` },
                  ],
                },
              ],
            },
            {
              prim: 'Pair',
              args: [
                {
                  prim: 'Pair',
                  args: [{ bytes: `${this.toNormalizedKey(this.connectedWalletPublicKey)}` }, { int: `${nonce}` }],
                },
                {
                  prim: 'Pair',
                  args: [
                    { bytes: `${this.chainID}` },
                    { bytes: `${this.toNormalizedAddress(this.filmControllerAddress)}` },
                  ],
                },
              ],
            },
          ],
        },
      ],
    };
    const packedExpr = packDataBytes(toSign);
    let pkh = this.connectedWalletAddress,
      pk = this.connectedWalletPublicKey,
      sig = await this.wallet.signPackedData(
        packedExpr.bytes,
        'Submit Creative Query',
        'Please sign the message so the app can verify you submitted the creative query.',
        'Submit Now'
      );
    return {
      address: pkh,
      pk,
      sig,
    };
  }

  async resubmitCreativeQuerySignature(
    cqBlockchainId: string,
    resubmissionFee: number,
    bounty: number,
    referenceObject: any,
    nonce: number
  ) {
    await this.login();

    const toSign: MichelsonData = {
      prim: 'Pair',
      args: [
        {
          prim: 'Pair',
          args: [
            { prim: 'Pair', args: [{ int: `${this.toMutez(bounty)}` }, { bytes: `${this.chainID}` }] },
            {
              prim: 'Pair',
              args: [
                { bytes: `${this.toNormalizedAddress(this.filmControllerAddress)}` },
                { string: `${cqBlockchainId}` },
              ],
            },
          ],
        },
        {
          prim: 'Pair',
          args: [
            {
              prim: 'Pair',
              args: [{ bytes: `${this.toNormalizedKey(this.connectedWalletPublicKey)}` }, { int: `${nonce}` }],
            },
            {
              prim: 'Pair',
              args: [
                { string: `${this.toReferenceString(referenceObject)}` },
                {
                  prim: 'Pair',
                  args: [
                    { int: `${this.toMutez(resubmissionFee)}` },
                    { bytes: `${this.toNormalizedAddress(this.connectedWalletAddress)}` },
                  ],
                },
              ],
            },
          ],
        },
      ],
    };

    const packedExpr = packDataBytes(toSign);
    let pkh = this.connectedWalletAddress,
      pk = this.connectedWalletPublicKey,
      sig = await this.wallet.signPackedData(
        packedExpr.bytes,
        'Re-Submit Creative Query',
        'Please sign the message so the app can verify you re-submitted the creative query.',
        'Re-Submit Now'
      );
    return {
      address: pkh,
      pk,
      sig,
    };
  }

  async reviewCreativeQuerySignature(
    cqBlockchainId: string,
    selectedOptionValue: string,
    selectedOptionIndex: number,
    stake: number,
    referenceObject: any,
    nonce: number
  ) {
    await this.login();
    const toSign: MichelsonData = {
      prim: 'Pair',
      args: [
        {
          prim: 'Pair',
          args: [
            {
              prim: 'Pair',
              args: [
                {
                  prim: 'Pair',
                  args: [
                    { string: `${cqBlockchainId}` },
                    { string: `${selectedOptionValue ? this.sha256(selectedOptionValue) : ''}` },
                  ],
                },
                { prim: 'Pair', args: [{ int: `${selectedOptionIndex}` }, { int: `${this.toMutez(stake)}` }] },
              ],
            },
            {
              prim: 'Pair',
              args: [
                { bytes: `${this.toNormalizedAddress(this.connectedWalletAddress)}` },
                { string: `${this.toReferenceString(referenceObject)}` },
              ],
            },
          ],
        },
        {
          prim: 'Pair',
          args: [
            {
              prim: 'Pair',
              args: [{ bytes: `${this.toNormalizedKey(this.connectedWalletPublicKey)}` }, { int: `${nonce}` }],
            },
            {
              prim: 'Pair',
              args: [
                { bytes: `${this.chainID}` },
                { bytes: `${this.toNormalizedAddress(this.filmControllerAddress)}` },
              ],
            },
          ],
        },
      ],
    };
    const packedExpr = packDataBytes(toSign);
    let pkh = this.connectedWalletAddress,
      pk = this.connectedWalletPublicKey,
      sig = await this.wallet.signPackedData(
        packedExpr.bytes,
        'Answer Creative Query',
        'Please sign the message so the app can verify you answered the creative query.',
        'Answer Now'
      );
    return {
      address: pkh,
      pk,
      sig,
    };
  }

  async submitWithdrawSignature(walletAddress: string, amount: number, referenceObject: any, nonce: number) {
    await this.login();
    const toSign: MichelsonData = {
      prim: 'Pair',
      args: [
        {
          prim: 'Pair',
          args: [
            {
              prim: 'Pair',
              args: [
                { bytes: `${this.toNormalizedAddress(walletAddress)}` },
                { bytes: `${this.toNormalizedAddress(walletAddress)}` },
              ],
            },
            {
              prim: 'Pair',
              args: [{ int: `${this.toMutez(amount)}` }, { string: `${this.toReferenceString(referenceObject)}` }],
            },
          ],
        },
        {
          prim: 'Pair',
          args: [
            {
              prim: 'Pair',
              args: [{ int: `${nonce}` }, { bytes: `${this.toNormalizedKey(this.connectedWalletPublicKey)}` }],
            },
            {
              prim: 'Pair',
              args: [
                { bytes: `${this.chainID}` },
                { bytes: `${this.toNormalizedAddress(this.filmControllerAddress)}` },
              ],
            },
          ],
        },
      ],
    };

    const packedExpr = packDataBytes(toSign);
    let pkh = this.connectedWalletAddress,
      pk = this.connectedWalletPublicKey,
      sig = await this.wallet.signPackedData(
        packedExpr.bytes,
        'Withdraw Internal Balance',
        'Please sign the message so the app can verify you want to make the withdrawal.',
        'Withdraw Now'
      );
    return {
      address: pkh,
      pk,
      sig,
    };
  }

  async loginVerification(): Promise<WalletLoginData> {
    this.setupWallet();
    return this.wallet.loginVerification();
  }

  /**
   * Video Distribution related
   */
  async submitResubmitVideoDistribution(
    videoDistributionId: string,
    videoTitle: string,
    ownerAddress: string,
    submissionFee: number,
    moderatorFee: number,
    contribution: number,
    royaltyMap: RoyaltyMap[],
    metadata: {},
    nonce: number
  ) {
    await this.login();
    const royaltyList: MichelsonMapEltList = [];
    const royaltyMapObj = {};
    royaltyMap.forEach((el) => {
      royaltyMapObj[el.wallet_address] = el.reward_percentage;
    });
    for (const address of Object.keys(royaltyMapObj).sort((a, b) => a.localeCompare(b))) {
      const percentageInt = Math.floor(royaltyMapObj[address] * 100);
      royaltyList.push({
        prim: 'Elt',
        args: [{ bytes: `${this.toNormalizedAddress(address)}` }, { int: `${percentageInt}` }],
      });
    }
    const metadataList: MichelsonMapEltList = [];
    for (const key of Object.keys(metadata).sort((a, b) => a.localeCompare(b))) {
      const value = metadata[key];
      metadataList.push({ prim: 'Elt', args: [{ string: `${key}` }, { bytes: `${this.stringToBytes(value)}` }] });
    }
    const toSign: MichelsonData = {
      prim: 'Pair',
      args: [
        {
          prim: 'Pair',
          args: [
            {
              prim: 'Pair',
              args: [
                { bytes: `${this.chainID}` },
                {
                  prim: 'Pair',
                  args: [
                    { bytes: `${this.toNormalizedAddress(this.videoDistributionAddress)}` },
                    { int: `${this.toMutez(contribution)}` },
                  ],
                },
              ],
            },
            {
              prim: 'Pair',
              args: [
                { int: `${this.toMutez(submissionFee)}` },
                {
                  prim: 'Pair',
                  args: [
                    { string: `${videoDistributionId}` },
                    { bytes: `${this.toNormalizedKey(this.connectedWalletPublicKey)}` },
                  ],
                },
              ],
            },
          ],
        },
        {
          prim: 'Pair',
          args: [
            {
              prim: 'Pair',
              args: [
                metadataList,
                { prim: 'Pair', args: [{ int: `${this.toMutez(moderatorFee)}` }, { int: `${nonce}` }] },
              ],
            },
            {
              prim: 'Pair',
              args: [
                { bytes: `${this.toNormalizedAddress(ownerAddress)}` },
                {
                  prim: 'Pair',
                  args: [royaltyList, { string: `${videoTitle}` }],
                },
              ],
            },
          ],
        },
      ],
    };

    const packedExpr = packDataBytes(toSign);
    let pkh = this.connectedWalletAddress,
      pk = this.connectedWalletPublicKey,
      sig = await this.wallet.signPackedData(
        packedExpr.bytes,
        'Submit Resubmit Distribution',
        'Please sign the message so the app can verify you want to make the submission.',
        'Submit Now'
      );
    return {
      address: pkh,
      pk,
      sig,
    };
  }

  async purchaseVideoDistribution(
    videoDistributionId: string,
    videoPurchaseId: string,
    amountFilm: number,
    dollars: number,
    purchaseFee: number,
    referenceObject: any,
    nonce: number
  ) {
    await this.login();
    const toSign: MichelsonData = {
      prim: 'Pair',
      args: [
        {
          prim: 'Pair',
          args: [
            { prim: 'Pair', args: [{ int: `${this.toMutez(amountFilm)}` }, { bytes: `${this.chainID}` }] },
            {
              prim: 'Pair',
              args: [
                { bytes: `${this.toNormalizedAddress(this.videoDistributionAddress)}` },
                { prim: 'Pair', args: [{ int: `${this.toDollar(dollars)}` }, { int: `${this.toMutez(purchaseFee)}` }] },
              ],
            },
          ],
        },
        {
          prim: 'Pair',
          args: [
            {
              prim: 'Pair',
              args: [
                { bytes: `${this.toNormalizedKey(this.connectedWalletPublicKey)}` },
                { prim: 'Pair', args: [{ int: `${nonce}` }, { string: `${videoPurchaseId}` }] },
              ],
            },
            {
              prim: 'Pair',
              args: [
                { string: `${this.toReferenceString(referenceObject)}` },
                {
                  prim: 'Pair',
                  args: [
                    { string: `${videoDistributionId}` },
                    { bytes: `${this.toNormalizedAddress(this.connectedWalletAddress)}` },
                  ],
                },
              ],
            },
          ],
        },
      ],
    };
    const packedExpr = packDataBytes(toSign);
    let pkh = this.connectedWalletAddress,
      pk = this.connectedWalletPublicKey,
      sig = await this.wallet.signPackedData(
        packedExpr.bytes,
        'Purchase Video',
        'Please sign the message so the app can verify you want to purchase the video.',
        'Purchase Now'
      );
    return {
      address: pkh,
      pk,
      sig,
    };
  }

  async purchaseVideoDistributionUSDC(
    videoDistributionId: string,
    videoPurchaseId: string,
    amountUSDC: number,
    dollars: number,
    referenceObject: any,
    nonce: number
  ) {
    await this.login();
    const toSign: MichelsonData = {
      prim: 'Pair',
      args: [
        {
          prim: 'Pair',
          args: [
            { prim: 'Pair', args: [{ int: `${this.toMutez(amountUSDC)}` }, { bytes: `${this.chainID}` }] },
            {
              prim: 'Pair',
              args: [
                { bytes: `${this.toNormalizedAddress(this.videoDistributionAddress)}` },
                {
                  prim: 'Pair',
                  args: [
                    { int: `${this.toDollar(dollars)}` },
                    { bytes: `${this.toNormalizedKey(this.connectedWalletPublicKey)}` },
                  ],
                },
              ],
            },
          ],
        },
        {
          prim: 'Pair',
          args: [
            {
              prim: 'Pair',
              args: [{ int: `${nonce}` }, { string: `${videoPurchaseId}` }],
            },
            {
              prim: 'Pair',
              args: [
                { string: `${this.toReferenceString(referenceObject)}` },
                {
                  prim: 'Pair',
                  args: [
                    { string: `${videoDistributionId}` },
                    { bytes: `${this.toNormalizedAddress(this.connectedWalletAddress)}` },
                  ],
                },
              ],
            },
          ],
        },
      ],
    };
    const packedExpr = packDataBytes(toSign);
    let pkh = this.connectedWalletAddress,
      pk = this.connectedWalletPublicKey,
      sig = await this.wallet.signPackedData(
        packedExpr.bytes,
        'Purchase Video',
        'Please sign the message so the app can verify you want to purchase the video.',
        'Purchase Now'
      );
    return {
      address: pkh,
      pk,
      sig,
    };
  }

  async reviewVideoDistribution(
    videoDistributionId: string,
    amountFilm: number,
    dollars: number,
    review: string,
    referenceObject: any,
    nonce: number
  ) {
    await this.login();
    const reviewContent = review ? `${this.sha256(review)}` : '';
    const toSign: MichelsonData = {
      prim: 'Pair',
      args: [
        {
          prim: 'Pair',
          args: [
            { prim: 'Pair', args: [{ int: `${this.toMutez(amountFilm)}` }, { bytes: `${this.chainID}` }] },
            {
              prim: 'Pair',
              args: [
                { bytes: `${this.toNormalizedAddress(this.videoDistributionAddress)}` },
                {
                  prim: 'Pair',
                  args: [{ int: `${this.toDollar(dollars)}` }, { bytes: `${this.toNormalizedKey(this.connectedWalletPublicKey)}` }],
                },
              ],
            },
          ],
        },
        {
          prim: 'Pair',
          args: [
            { prim: 'Pair', args: [{ int: `${nonce}` }, { string: `${this.toReferenceString(referenceObject)}` }] },
            {
              prim: 'Pair',
              args: [
                { string: `${reviewContent}` },
                {
                  prim: 'Pair',
                  args: [
                    { string: `${videoDistributionId}` },
                    { bytes: `${this.toNormalizedAddress(this.connectedWalletAddress)}` },
                  ],
                },
              ],
            },
          ],
        },
      ],
    };
    const packedExpr = packDataBytes(toSign);
    let pkh = this.connectedWalletAddress,
      pk = this.connectedWalletPublicKey,
      sig = await this.wallet.signPackedData(
        packedExpr.bytes,
        'Purchase Video',
        'Please sign the message so the app can verify you want to purchase the video.',
        'Purchase Now'
      );
    return {
      address: pkh,
      pk,
      sig,
    };
  }
}
