<div class="tabs-header">
  <div
    class="tab-label"
    (click)="selectTab(item, i)"
    [class.active]="activeTab === item"
    [ngClass]="{
      'tab-pointer': item.cursorClass === 'pointer' && activeTab !== item,
      'tab-disabled': item.cursorClass === 'disabled'
    }"
    *ngFor="let item of tabItems$ | async; let i = index"
  >
    <ng-container *ngIf="item.labelComponent">
      <ng-container *ngTemplateOutlet="item.labelComponent.labelContent"></ng-container>
    </ng-container>

    <ng-container *ngIf="!item.labelComponent">
      {{ item.label }}
    </ng-container>
  </div>
</div>
<div class="tabs-body" [ngClass]="{ 'overflow-hidden': scrolrestricted }">
  <ng-container *ngIf="activeTab && activeTab.bodyComponent">
    <ng-container *ngTemplateOutlet="activeTab.bodyComponent.bodyContent"></ng-container>
  </ng-container>
</div>
