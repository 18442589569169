<app-toastr-template
  class="toast toast-{{ toast.type }}"
  [style.bottom.px]="i * 100"
  [data]="toast"
  [index]="i"
  (remove)="removeToastr($event)"
>
  <p class="text-sm">{{ toast?.message }}</p>
  <p class="text-sm">
    Please try to refresh this page or
    <a href="https://support.decentralized.pictures" target="_blank">contact support</a>
    if the problem persists.
  </p>
</app-toastr-template>
