import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DcpSelectComponent } from './dcp-select.component';
import { IconModule } from '../icon/icon.module';



@NgModule({
  declarations: [DcpSelectComponent],
  imports: [
    CommonModule,IconModule
  ],
  exports:[DcpSelectComponent]
})
export class DcpSelectModule { }
