import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DropdownMenuDirective } from './dropdown.directive';
import { DropdownMenuComponent } from './dropdown-menu.component';



@NgModule({
  declarations: [DropdownMenuComponent, DropdownMenuDirective],
  imports: [CommonModule],
  exports: [DropdownMenuDirective, DropdownMenuComponent],
})
export class DropdownMenuModule {}
