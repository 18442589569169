<div class="balance-detail">
  <div class="header">BALANCE OVERVIEW</div>
  <div class="balance-content">
    <div class="flex justify-between mb-16">
      <div class="">
        <label>External</label>
        <p>FILM in your own wallet</p>
      </div>
      <h3>{{( data.externalBalance || 0) | filmPrice }} FILM</h3>
    </div>

    <div class="flex justify-between mb-16">
      <div class="">
        <label>Internal</label>
        <p>Amount Deposited at DCP Platform</p>
      </div>
      <h3>{{ (data.internalBalance ||0) | filmPrice }} FILM</h3>
    </div>
    <div class="flex justify-between">
      <div class="">
        <label>Locked</label>
        <p>FILM you have staked on proposals or locked to become a moderator</p>
      </div>
      <h3>{{ (data.lockedBalance || 0) | filmPrice }} FILM</h3>
    </div>
  </div>
  <div class="flex justify-between footer">
    <h3>Total</h3>
    <h3>{{ (data.total || 0) | filmPrice }} FILM</h3>
  </div>
</div>
