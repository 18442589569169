import { Injectable } from '@angular/core';
import { ToasterService } from './toaster.service';
import { capitalize } from '@datorama/akita/schematics/ng-g/utils/string';
import { CreativeQuery, Proposal, Review } from '../../../../src/app/app.datatypes';

@Injectable({
  providedIn: 'root',
})
export class PopupMessageService {
  constructor(private toasterService: ToasterService) {}

  public onWsEvent(type: string, value) {
    switch (type) {
      case 'proposal-status-change':
        this.entityStatusChange(value, 'project');
        break;
      case 'creative-query-status-change':
        this.entityStatusChange(value, 'creative query');
        break;
      case 'review-status-change':
        this.entityStatusChange(value, 'review');
        break;
      case 'evaluation-status-change':
        this.evaluationStatusChange(value);
        break;
      case 'moderator-access-flow':
        this.moderatorAccessFlow(value);
        break;
      case 'proposal-extend-general-review':
        this.onExtendProposal(value);
        break;
    }
  }

  private entityStatusChange(entity: Proposal | CreativeQuery | Review, type: string) {
    const entityName = capitalize(type);
    let entityTitle = '';
    if ('title' in entity) {
      entityTitle = `'${entity.title}'`;
    }
    switch (entity.status) {
      case 'to-be-moderated':
        this.toasterService.openToastr(
          `Your ${entityName} ${entityTitle}` +
            ' was confirmed and is waiting for moderation. When a moderator approves it, it will become active on the platform.',
          `${entityName} is Waiting for moderation`,
          'info',
          10000
        );
        break;
      case 'approved':
        this.toasterService.openToastr(
          `Your ${entityName} ${entityTitle}` +
            ` was approved by a moderator. This means that your ${entityName} is active and users can review it.`,
          `${entityName} was Approved`,
          'info',
          10000
        );
        break;
      case 'denied':
        this.toasterService.openToastr(
          `Your ${entityName} ${entityTitle}` +
            ` was denied by a moderator. A message has been sent to your email with an explanation from the moderator. Please edit your ${entityName} and resubmit it.`,
          `${entityName} was Denied`,
          'warning',
          null
        );
        break;
      case 'cancelled':
        this.toasterService.openToastr(
          `Your ${entityName} ${entityTitle}` +
            ' was canceled by you or an administrator. All funds will be returned to their rightful owners.',
          `${entityName} was Canceled`,
          'warning',
          null
        );
        break;
      case 'failed':
        this.toasterService.openToastr(
          `Your ${entityName} ${entityTitle}` +
            ` submission has failed. Please edit your ${entityName} and resubmit it. If this problem keeps occurring, contact our support.`,
          'Submission Failed',
          'warning',
          null
        );
        break;
    }
  }

  private evaluationStatusChange(evaluation: Review) {
    switch (evaluation.status) {
      case 'approved':
        this.toasterService.openToastr(
          `Your Evaluation has been confirmed. Thank you for your participation in the DCP Community.`,
          'Evaluation Confirmed',
          'info',
          null
        );
        break;
      case 'cancelled':
        this.toasterService.openToastr(
          'Your Evaluation has been canceled by you or administrator. All funds will be returned to their rightful owners.',
          'Evaluation Canceled',
          'warning',
          null
        );
        break;
      case 'failed':
        this.toasterService.openToastr(
          `Your Evaluation submission has failed. Try to resubmit it. If this problem keeps occurring, contact our support.`,
          'Submission Failed',
          'warning',
          null
        );
        break;
    }
  }

  private moderatorAccessFlow(state) {
    switch (state) {
      case 'access-requested':
        this.toasterService.openToastr('Your request to become a moderator is waiting for confirmation.', 'info');
        break;
      case 'refuse-requested':
        this.toasterService.openToastr('Your request to step down as a moderator is waiting for confirmation.', 'info');
        break;
      case 'access-granted':
        this.toasterService.openToastr('Congratulations! From now on you are a DCP moderator.', 'Info', 'info', 3000);
        break;
      case 'access-refused':
        this.toasterService.openToastr('Your moderator access has been successfully revoked.', 'Info', 'info', 3000);
        break;
    }
  }

  private onExtendProposal(proposal) {
    this.toasterService.openToastr(
      'Your project ' + proposal.title || '' + ' was successfully extended.',
      'Info',
      'info',
      3000
    );
  }
}
