import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExpandedInfoComponent } from './expanded-info.component';
import { IconModule } from '../../modules/components/icon/icon.module';
import { SafeHtmlModule } from '../../pipes/safe-html/safe-html.module';
import { TabsModule } from "../../modules/components/tabs/tabs.module";

@NgModule({
  declarations: [
    ExpandedInfoComponent
  ],
  exports: [
    ExpandedInfoComponent
  ],
  imports: [
    CommonModule,
    IconModule,
    SafeHtmlModule,
    TabsModule
  ]
})
export class ExpandedInfoModule { }
