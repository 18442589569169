import { Injectable } from '@angular/core';

export interface HorizontalEntitiesLimit {
  parent: number;
  child: number;
  skip: number;
}

@Injectable({
  providedIn: 'root',
})
export class HorizontalSliderService {
  limit = {
    parent: 5,
    child: 6,
    skip: 0,
    cardWidth: 0,
    parentWidth: 0,
  };

  handlePaginationProperties(length: number, page = 1, leftPositionSlider = 0): object {
    const offset = this.limit.cardWidth * length + 1;

    const paginationCount = Math.ceil(offset / this.limit.parentWidth);
    return {
      page: page,
      leftPositionSlider: leftPositionSlider,
      paginationCount,
      nxtDisabled: paginationCount === page,
    };
  }

  setLimitsDependOnScreenWidth(innerWidth?: number, innerHeight?: number): void {
    const width = innerWidth ?? window.innerWidth;
    const height = innerHeight ?? window.innerHeight;

    if (height > 1200 || (width < 1600 && height > 800)) {
      this.limit.parent = 5;
    }

    if (width <= 860 && width > 540) {
      this.limit.child = 4;
    } else if (width <= 540) {
      this.limit.child = 3;
    }
  }
}
